
export const DEPLOY_MODE = false;

export const config = {
  app: {
    // api_url: "http://192.168.1.170:9000/api",
    // api_url: "http://localhost:9000/api",
    // api_url: DEPLOY_MODE ? "https://api.fntec.cm/api" : "http://apitest.fntec.cm/api",
    // api_url: DEPLOY_MODE ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_TEST_URL,
    api_url: process.env.REACT_APP_API_URL,

    // api_url: "https://apitest.fntec.cm/api",

    mapbox_token: "pk.eyJ1Ijoid2lsZnJpZDIwMjMiLCJhIjoiY2xrMXJtdDF1MDhhZTNkcG9qb3RrcG5hayJ9.O2_u5z7Njds5oXV7Vv1b0w",
    firebaseConfig: {

      apiKey: "AIzaSyBCJLOxOK3C4S6mf9ILsQX6UQ1f1LjloiM",

      authDomain: "fntec-92dec.firebaseapp.com",

      projectId: "fntec-92dec",

      storageBucket: "fntec-92dec.appspot.com",

      messagingSenderId: "950331806775",

      appId: "1:950331806775:web:e6f239c2950b43ae36dbb2",

      measurementId: "G-6GSKS45ZM7"

    },
    firebaseVapidKey : "BA_BZMT6aRVvEUO_Mbxko31Jz2I9jB7H3N1fc4VYAj3Tc2VrDDT6RVFuK0KPM-aK5Gzs6CQMhKv_1Bxc99S0MTM"
  },
};

