import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import usePostAxiosData from "hooks/usePostAxiosData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { config } from "variables/config";
import { ROLES } from "Constaints";

const UpdateUserInformations = (props) => {
  const { isOpen, onClose, setIsOpen, userId, fetchData } = props;

  /** get user by id */
  const [loadingU, errorU, user, fetchDataU] = useDataFetching(
    `${config.app.api_url}/users/${userId}`
  );

  /** Form validation config */
  const schema = yup.object().shape({
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
    phone: yup.string().nullable(),
    email: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleUpdate(data);
    // reset();
  };

  //   console.log('user', user?.data?.lastName);

  const [roles, setRoles] = useState();

  useEffect(() => {
    setValue("lastname", user?.data?.lastName);
    setValue("firstname", user?.data?.firstName);
    setValue("phone", user?.data?.phone);
    setValue("email", user?.data?.mail);
  }, [user?.data]);

  //   console.log("lastname", lastname);

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users/${userId}`, "POST");

  const handleUpdate = async (dataYub) => {
    // console.log('dataYub', dataYub);
    const data = {
      firstName: dataYub?.firstname,
      lastName: dataYub?.lastname,
      phone: dataYub?.phone,
      email: dataYub?.email,
      roles: roles,
    };

    await postAxiosData(data);
  };

  const handleChangeValue = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(value);
  };

  /** gestion des erreurs et succès */
  useEffect(() => {}, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      setIsOpen(false);
      fetchData();

      // handleSignin(result);
    }
  }, [successMessage]);

  return (
    <>
      <AlertDialog isOpen={isOpen} isCentered={true}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div className="text-center">
                <span className="text-3xl uppercase">
                  Modifier les informations du compte
                </span>
              </div>
            </AlertDialogHeader>

            <AlertDialogBody>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="my-4 grid gap-2 md:grid-cols-1">
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div>
                    <span className="ms-1">Change user role(s)</span>
                    <Select
                      className="mt-2"
                      height={20}
                      multiple
                      value={roles}
                      onChange={(e) => handleChangeValue(e)}
                    >
                      {ROLES?.map((role) => {
                        return <option value={role}>{role}</option>;
                      })}
                    </Select>
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Prénom(s)"
                      required={true}
                      placeholder="Prénom"
                      id="firstname"
                      name="firstname"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom(s)"
                      required={true}
                      placeholder="Nom"
                      id="lastname"
                      name="lastname"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Numéro de téléphone"
                      required={true}
                      placeholder="690128925"
                      id="phone"
                      name="phone"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Adresse Mail"
                      required={true}
                      placeholder="adresse@gmail.com"
                      id="email"
                      name="email"
                      type="email"
                    />
                  </div>
                </div>
                <div className="flex justify-between p-4">
                  <div>
                    <Button onClick={() => setIsOpen(false)}>Annuler</Button>
                  </div>
                  <div>
                    <Button type="submit" colorScheme="green">
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </form>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default UpdateUserInformations;
