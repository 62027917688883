import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useCustomSignin from "hooks/useCunstomSigin";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Error from "components/message/Error";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Transition } from "views/admin/tables/AddEnterprise";
import { Button } from "@chakra-ui/react";

export default function EmailVerification() {
  const [open, setOpen] = useState(false);
const navigate  = useNavigate()
  /** Form validation config */
  const schema = yup.object().shape({
    email: yup.string().required("Phone is a required field"),
    code: yup.string().required("Password is a required field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // console.log("🚀 ~ EmailVerification ~ errors:", errors)

  const onSubmitHandler = (data) => {
    // console.log("🚀 ~ onSubmitHandler ~ data:", data)
    
    handleVerified(data);
  };

  /** redirection  */


  /** Login function */
  /** post registration */
  const [loading, postVerifyEmail, errorMassage, successMessage, result] = usePostAxiosData(`auth/email-verification`, "POST");

    

  const handleVerified = async (dataYub) => {
    // console.log("dataYub", dataYub);
    const data = {
      email: dataYub?.email,
      code: dataYub?.code,
    };

    await postVerifyEmail(data);
  };

  // const handleSendCode = async (dataYub) => {
  //   // console.log("dataYub", dataYub);
  //   const data = {
  //     email: dataYub?.email,
  //   };

  //   await postSendCode(data);
  // };



  /** gestion des erreurs et succès */
  // useEffect(() => {}, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        icon: "👏",
        className: "bg-success text-white",
      });

      // handleSignin(result);
      navigate("/auth/sign-in")
    }
  }, [successMessage]);
  return (
    <>
    <SendCodeAgainst open={open} onClose={()=>setOpen(false)}/>
    <div
    // style={{backgroundImage : 'url(/assets/img/image1.jpeg)', }}
    className=" mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start  ">
      {/* Sign in section */}
      <div className=" w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {errorMassage?.message && (
          <Error
            message={errorMassage?.message !== "" && errorMassage?.message}
          />
        )}

        <h4 className="mb-2.5 mt-2.5 text-4xl font-bold text-green-500 dark:text-white">
          Verification d'email
        </h4>
        <p className="mb-9 ml-1 text-base text-white">
          Enter your phone and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {/* Email */}
          <InputField
            register={register}
            variant="auth"
            extra="mb-3"
            label="Email"
            // required={true}
            name="email"
            autoComplete={false}
            color={"white"}

            placeholder="Email ici"
            id="email"
            type="text"
            error={errors.email?.message !== "" ? errors.email?.message : ""}
          />

          {/* Password */}
          <InputField
            register={register}
            variant="auth"
            extra="mb-3"
            label="Code"
            color={"white"}

            // required={true}
            // autoComplete={false}
            placeholder="Min. 8 characters"
            id="code"
            name="code"
            type="code"
            error={
              errors.code?.message !== "" ? errors.code?.message : ""
            }
          />
          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            {/* <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div> */}
            <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            onClick={()=>setOpen(true)}
          >
            Send code against ?
          </a>
          </div>
          {loading ? (
            <button type="button" class="flex justify-center bg-indigo-400 linear mt-2 w-full rounded-[20px] bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" disabled>
              <svg class="mr-3 h-5 w-5 animate-spin" viewBox="0 0 24 24"></svg>
              Chargement...
            </button>
          ) : (
            <button
              type="submit"
              className="linear mt-2 w-full rounded-[20px] bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Sign In
            </button>
          )}
        </form>
      </div>
    </div>
    </>

  );
}


const SendCodeAgainst = ({ open, onClose  }) => {
  const navigate = useNavigate();

    const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`auth/send-email-verification`, "POST");
    // console.log("🚀 ~ ConfirmRejectModal ~ error:", error)
  
    const schema = yup.object().shape({
      email: yup.string().email('Adresse email invalide') // Valide l'email
      .required('Adresse email requise')
  
    });
  
    const {
      register,
      handleSubmit,
      resetField,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
  
  
  
    const onSubmitHandler = (data) => {
      console.log("🚀 ~ onSubmitHandler ~ data:", data)
      
      handleLogin(data);
    };
  
    const handleLogin = async (dataYub) => {
      // console.log("dataYub", dataYub);
      const data = {
        email: dataYub?.email,
      };
  
      await postAxiosData(data);
    };
  
    /** gestion des erreurs et succès */
    // useEffect(() => {}, [errorMassage]);
    useEffect(() => {
      // if (successMessage?.status === 200 || successMessage?.status === 201) {
      //   toast.success(`${successMessage?.message} `, {
      //     duration: 4000,
      //     icon: "👏",
      //     className: "bg-success text-white",
      //   });
  
      //   // handleSignin(result);
      // }
      if(successMessage) {
        resetField()
        onClose()}
    }, [successMessage]);

    const handleCloseIside = () => {
      onClose()
    }
  
  
    
    return (
      <Dialog open={open} onClose={onClose}
        fullWidth={true}
  
        maxWidth={"md"}
  
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
         
      >
        <DialogTitle>{"Confirmation"}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
  
        <DialogContent     
        style={{
                backdropFilter: 'blur(6px)',
                backgroundColor: ' rgba(5,5,5,0.4)',
                // width: '70%',
                // height: '80%'
  
              }} className="bg-gray-600">
          <DialogContentText variant="h5" >
            Un code vous sera envoye sous cette addresse email
          </DialogContentText>
          <InputField
              register={register}
              variant="auth"
              extra="mb-3"
              label="Email"
              // required={true}
              name="email"
              autoComplete={false}
              color={"white"}
  
              placeholder="690128905"
              id="email"
              type="text"
              error={errors.email?.message !== "" ? errors.email?.message : ""}
            />
  
    
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseIside}
            color="error"
            variant="outlined"
          >
            Annuler
          </Button>
          <Button
          type="submit"
            // onClick={handleConfirm}
            color="success"
            variant="contained"
            autoFocus
          >
            Continuer
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    );
  };