import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Paper } from '@mui/material';

export default function MuiAUtocomplete({ options, multiple, wrapperClasses, label, onChange, value, format, defaultFormat,inputValue, onInputChange, handleNoOption,disabled }) {
    console.log("🚀 ~ MuiAUtocomplete ~ value:", label+"========>>>"+value)
    // let default = 

    if (defaultFormat) format = { value: 'id', label: 'name' }

    // let formatedOptions = format ? options?.map((d) => { return { value: d[format?.value], label: d[format?.label] } }) : options;
    // let defaultSelect = formatedOptions?.find((o) => o.value == value)
    // console.log("🚀 ~ MuiAUtocomplete ~ value:", formatedOptions?.find((o) => o.value = value?.toString()))
    const [formatedOptions, setFormatedOptions] = useState([]);
    // console.log("🚀 ~ MuiAUtocomplete ~ formatedOptions:", formatedOptions)
    // console.log("options?.lenght ", options?.lenght)
    React.useEffect(() => {
        if (options?.length > 0) {
            // alert("lololo")

            let dd = format ? options?.map((d) => { return { value: d[format?.value], label: d[format?.label] } }) : options;
            setFormatedOptions(dd)
        }
    }, [options, format]);
    const [defaultSelect, setDefaultSelect] = React.useState(null);
    console.log("🚀 ~ MuiAUtocomplete ~ defaultSelect:", defaultSelect)

    React.useEffect(() => {
        if (multiple) {
            if (value) {
                // alert("yes")
                setDefaultSelect(value)
                // let d = formatedOptions?.find((o) => o.value == value)
                // setDefaultSelect(d)
                // let dd =[]
                // value?.map((val)=>{

                // let d = formatedOptions?.find((o) => o.value == val?.value)
                // dd.push(d)
                // })
                // console.log("🚀 ~ value?.map ~ dd:", dd)
            }
        } else {


            if (value && formatedOptions?.length > 0) {
                let d = formatedOptions?.find((o) => o.value == value) 
            
                setDefaultSelect(d)
            }else{
                // !value && setDefaultSelect({})
            }
        }
    }, [formatedOptions, value,multiple]);
    // console.log("🚀 ~ MuiAUtocomplete ~ defaultSelect: "+ label +" ==> ", defaultSelect?.label)
    // console.log("🚀 ~ MuiAUtocomplete ~ formatedOptions:", formatedOptions)
    // console.log("🚀 ~ MuiAUtocomplete ~ defaultSelect:", defaultSelect)
    return (
        <div className={wrapperClasses}>

        {(defaultSelect || !value) &&     <Autocomplete
                // defaultValue={"10"}
                defaultValue={defaultSelect}
                // defaultValue={{ value: "Senegal", label: "Sénégalaise" }}
                //  inputValue={inputValue}
                 onInputChange={onInputChange}
                // value={defaultSelect || []}
                onChange={onChange}
                disabled={disabled}
                multiple={multiple}
                // disablePortal
                id="combo-box-demo"
                // style={{backgroundColor : 'red'}}
                options={formatedOptions}
                //   sx={{ width: 300 }}value
                // sx={{
                //     '& .MuiInputBase-root': { color: 'red' }, // Text color
                //     '& .MuiInputBase-input': { borderColor: 'green' }, // Border color
                //     '& .MuiInputLabel-formControl': { color: 'blue' }, // Placeholder color (standard variant)
                //   }}
                noOptionsText={handleNoOption}
                sx={{
                    // backgroundColor : 'red',
                    minWidth: 150,
                    '& .MuiInputLabel-root': { color: 'green', borderColor: 'red' }, // Placeholder color
                    '& .MuiInputBase-input': { color: 'green' }, // Text color
                    '& .MuiOutlinedInput-root': {
                        // '& fieldset': { borderColor: 'green' }, // Border color
                    },
                    '& .MuiAutocomplete-option': {
                        backgroundColor: 'red', // Set the background color
                      },
                }}
                // componentsProps={} 
                PaperComponent={(props) => (
                    <Paper {...props} sx={{  backgroundColor: '#ffffff'}}>
                        {/* Your list content */}
                    </Paper>
                )}
                
                renderInput={(params) => <TextField  {...params} label={label} />}
            />}
        </div>

    );
}

