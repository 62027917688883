import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "../employes/modals/EmployeeModalInfos";
import { useAuthHeader } from "react-auth-kit";

const UserEnterprises = () => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
    );
    const [region, setRegion] = useState("");
const authHeader = useAuthHeader()
  useEffect(() => {
    const fetchEnt = async (region) => {
      if (region && region != null) {
        setLoading(true);
        await fetch(`${config.app.api_url}/enterprises/users/${id}/regions/${region}`,
          { headers: { Authorization: authHeader() } }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Enterprises ", data)
            setEnterprises(data?.data)})
          .finally(() => setLoading(false));
      }
    };
    fetchEnt(region);
  }, [region]);

  useEffect(() => {
    // console.log("🚀 ~ file: index.jsx:45 ~ useEffect ~ regions:", regions)
    // console.log("🚀 ~ file: index.jsx:46 ~ useEffect ~ region:", !region)
    if(regions && regions?.data && !region){
      setRegion(regions?.data[0]?.id)
    }
    
  }, [regions]);

  return (
    <div>
      {(loading || loadingR) && <PreloaderSpinner />}
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <div>
          <label
            htmlFor={"region"}
            className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
          >
            Region
          </label>
          <select
            onChange={(e) => setRegion(e.target.value)}
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
            name="region"
            id="region"
          >
            <option value=''>...</option>
            {regions?.data &&
              regions.data.map((item) => {
                return <option selected={region===item?.id} value={item?.id}>{item?.name}</option>;
              })}
          </select>
        </div>

        <Card extra={"w-full h-full p-4"}>
          {/* <TableContainer> */}
            <div className="tableFixHead">
              <Table variant="simple">
                {/* <TableCaption>Top 5 des entreprises par secteur d'activite</TableCaption> */}
                <Thead>
                  <Tr>
                    <Th>Raison sociale</Th>
                    {/* <Th>Region</Th> */}
                    <Th>Secteur d'activite</Th>
                    <Th>Arrondissement</Th>
                    <Th>Nombre de travailleurs étrangers</Th>
                    <Th>Option</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {enterprises &&
                    enterprises.map((item) => {
                      return (
                        <>
                          <Tr>
                            <Td>{item?.socialRaison}</Td>
                            {/* <Td>{item?.enterprise?.region?.name}</Td> */}
                            <Td>{item?.activitySector?.name}</Td>
                            <Td>{item?.arrondissement?.name}</Td>
                            <Td>{item?.employersNumber}</Td>
                            <Td>
                              <Button
                                onClick={() =>
                                  navigation(
                                    `/admin/user/enterprises/employes/${id}/${item?.id}`
                                  )
                                }
                              >
                                Consulter
                              </Button>
                            </Td>
                          </Tr>
                      
                        </>
                      );
                    })}
                </Tbody>
                {/* <Tfoot>
                <Tr>
                  <Th>Raison sociale</Th>
                  <Th>Region</Th>
                  <Th>Secteur d'activite</Th>
                  <Th>Arrondissement</Th>
                  <Th>Nombre de travailleurs étrangers</Th>
                  <Th>Option</Th>
                </Tr>
              </Tfoot> */}
              </Table>
            </div>
          {/* </TableContainer> */}
        </Card>
      </div>
    </div>
  );
};

export default UserEnterprises;
