/* eslint-disable */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { CHECK_ROLES_PERMISSIONS } from "Constaints";
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Skeleton } from "@mui/material";
import { COLORS } from "constant/COLORS";
// chakra imports


export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();
  const navigate = useNavigate()
  const { routes } = props;

  const auth = useAuthUser();

  const [loadingU, errorU, user, fetchDataU] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );
  const [loadingR, errorR, roles, fetchDataR] = useDataFetching(
    `${config.app.api_url}/roles`
  );
  const SideBarCard = ({ route, index }) => {
    return CHECK_ROLES_PERMISSIONS(user?.data?.roles, route?.permissionName) && route.displayOnSideBar && (
      <MenuItem 
      key={index}
      selected={activeRoute(route.path) === true}
      // selec
        // href={route.layout + "/" + route.path}
        // LinkComponent={'a'}
        onClick={() => { navigate(route.layout + "/" + route.path) }}
        // sx={activeRoute(route.path) === true ?{ backgroundColor: COLORS.PRIMARY, borderTopLeftRadius : 20, borderBottomLeftRadius : 20 } : {}}
      // TouchRippleProps={{color : '', }}
      // to={route.layout + "/" + route.path} 
      // className={"hover:bg-red-500"}
      // TouchRippleProps={{color : 'green'}}

      >
        {/* <div className="relative mb-2 flex hover:cursor-pointer"> */}

        <ListItemIcon
          color="red"
          // className={`${activeRoute(route.path) === true
          //   ? "sidebar-icon font-bold text-green-500 dark:text-white"
          //   : "sidebar-icon font-medium text-gray-600"
          //   }`}
          sx={activeRoute(route.path) === true ? {color : "white"} : {}}
        >
          {route.icon ? route.icon : <DashIcon />}{" "}
        </ListItemIcon>
        <ListItemText
          // sx={{fontSize : 20}}

          className={` flex ${activeRoute(route.path) === true
            ? " font-bold text-white dark:text-white"
            : ""
            }`}
        >
          {route.name}
        </ListItemText>
        {/* {activeRoute(route.path) ? (
          <div
            class="absolute right-0 top-px h-12 w-1 rounded-lg bg-white dark:bg-brand-400  "
            style={{ right: -1, height: "100%" }}
          />
        ) : null} */}
        {/* </div> */}
      </MenuItem>
    )
  }
  // console.log("user", auth()?.user?.id);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {

      if (
        (route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl") &&
        route.name != "Employees" &&
        route.name != "Regions" &&
        route.name != "Agent Enterprises" &&
        route.name != "Agent Employees" &&
        route.name != "EntreprisesUs"

      ) {
        return (

          user?.data?.roles?.filter((role) =>
            route?.roles?.includes(role?.name)
          ).length > 0 &&
          // user?.data?.roles?.map((role) => {
          //   return (
          <SideBarCard route={route} index={index} />
          //   );
          // })
        );
      }
    });
  };


  // BRAND
  return loadingU ?
    <div className={`flex flex-col gap-2 `}>
      <div className="relative mb-2 flex hover:cursor-pointer flex flex-col">
        {
          Array(routes?.length).fill(0)?.map((k) => {
            return (<li key={k} className={` sidebar-link  my-[3px]  cursor-pointer`}>
              <Skeleton variant='rectangular' height={50} width={'100%'} />
            </li>)
          }
          )

        }
      </div>

    </div> :
    <MenuList className="flex flex-col gap-1 side-bar" >

      {createLinks(routes)}
    </MenuList>

}

export default SidebarLinks;
