import { Tooltip } from "@chakra-ui/tooltip";

const MButton = ({ children, variant = "white", size = "medium", disabled, isLoading, onClick, className , tipLabel}) => {
    const buttonClasses = `
      px-4 py-2 rounded-[20px] font-medium flex gap-2 items-center content-center border shadow-md hover:border-green-500 hover:text-green-500
      ${variant === 'danger' ? 'bg-red-500 text-white' : ''}
      ${variant === 'primary' ? 'bg-green-500 text-white hover:border-white hover:text-white' : ''}
      ${variant === 'secondary' ? 'bg-orange-300 text-black-700' : ''}
      ${variant === 'white' ? 'bg-gray-50 text-gray-900 border boder-gray-900 ' : ''}
      ${size === 'small' ? 'text-sm' : ''}
      ${size === 'large' ? 'text-lg' : ''}
      ${disabled ? 'bg-gray-400 cursor-not-allowed opacity-50' : ''}
    `;

    return (
        <Tooltip hasArrow label={tipLabel} bg='green.200' color='black' placement="bottom">

        <button  className={buttonClasses} onClick={onClick} disabled={disabled || isLoading}>

            {children} {isLoading && '...'}
        </button>
        </Tooltip>
    );
};

export default MButton;