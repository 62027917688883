import React, { useEffect, useState } from "react";
/** validation de formulaire */
import usePostAxiosData from "hooks/usePostAxiosData";
import toast from "react-hot-toast";

import { Alert, AlertIcon, AlertTitle, } from "@chakra-ui/react";
import dayjs from "dayjs";
import 'dayjs/locale/fr' // load on demand
import { AppBar, Avatar, Chip, Dialog, DialogActions, DialogContent, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Skeleton, Slide, Toolbar, Typography, capitalize } from "@mui/material";
import { CloseModalButton } from "components/Utils";

import { EMPLOYER_STATUS } from "Constaints";
import PreloaderSpinner from "components/PreloaderSpinner";
import { GoneIcon } from "components/TableIcon";
import { BsClockHistory } from "react-icons/bs";
import MButton from "components/Mbutton";
import InputField from "components/fields/InputField";
import MultilineTextInputList from "./MultilineTextInputList";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { CancelOutlined, Check, CheckCircle } from "@mui/icons-material";
import { blue, blueGrey, deepOrange, green, orange } from "@mui/material/colors";
import MRating from "components/MRating";
import { formatDate } from "utils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ReportModalInfos = ({ open, handleClose, get, selectedReport }) => {
  // console.log("🚀 ~ ReportModalInfos ~ selectedReport:", selectedReport)
  // console.log("🚀 ~ selectedReport adminObservations:", selectedReport?.adminObservations)

  const [loadingGet, error, responses, getResponses] = useDataFetching(
    selectedReport?.id ? `  ${config.app.api_url}/reports/${selectedReport?.id}/responses` : null
  );

  const [loadingR, errors, report, fetchDatas] =
  useDataFetching(selectedReport?.id ? `${config.app.api_url}/reports/${selectedReport?.id}` : null);
  // console.log("🚀 ~ ReportModalInfos ~ report:", report)
  // console.log("🚀 ~ ReportModalInfos ~ selectedReport:", selectedReport)
  /** Form validation config */
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  const [isValidated, setIsValidated] = useState(null);

  const [adminObservations, setAdminObservations] = useState([]);
  // console.log("🚀 ~ ReportModalInfos ~ adminObservations:", adminObservations)
  // console.log("🚀 ~ ReportModalInfos ~ responses:", responses)
const [rating, setRating] = useState(null);
  useEffect(() => {
    if (report) {
      // console.log("🚀 ~ useEffect ~ selectedReport:", selectedReport)

      setIsValidated(report?.data?.isValidated)
      setAdminObservations(report?.data?.adminObservations)
      setRating(report?.data?.rating || null)
    }
  }, [report]);

  /** post create employer */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`reports/${selectedReport?.id}`, "PUT");

  const auth = useAuthUser()
  const handleSave = async (valid) => {
    // console.log("data to saved", data);
    if (adminObservations?.length < 1) { alert("veillez entrer au mois une observation"); return }
    adminObservations?.length > 0 && await postAxiosData({ isValidated: valid, rating, adminObservations, adminId: auth()?.user?.id });
  };


  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      get()
      handleCloseInside()
      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : `Expatrié ${selectedReport ? "modifié" : "ajouté"} avec succès.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessage]);




  const handleCloseInside = () => {
    handleClose()
    setIsValidated(null)
    setAdminObservations([])
    // setRating(null)
  }




  useEffect(() => {
    if (errorMassage) {

      toast.error(
        `${errorMassage?.message != null
          ? errorMassage?.message
          : "Etat de l'employé mis a jour avec success."
        } `

      );
    }
  }, [errorMassage]);


  const Section = ({ title, items, bgColor, index }) => {
    return (
      loadingGet ? <Skeleton />
        :
        selectedReport && <div className=" bg-gray-100 shadow-lg rounded-lg">
          <div className="flex gap-2">
          <Avatar sx={{ bgcolor: blue[500],  width : 25, height : 25 }}>{index}</Avatar>
          <Typography  variant="h6" >{title}</Typography>
          </div>
          {items?.length > 0 ? <List className="      shadow">
            {items?.map((d, index) => {
              return (
                <ListItem key={d?.id} disablePadding className=" px-2" divider >
                  {/* <ListItemAvatar> */}
                      {d.answer?.toLocaleLowerCase() == 'yes'.toLocaleLowerCase() ?
                      <CheckCircle  color="success"  />
                      : <CancelOutlined color="error" />
                      }
                  {/* </ListItemAvatar> */}
                  <ListItemButton key={index} component="a" href="#simple-list">
                    <ListItemText key={index} primary={d?.title || d?.question?.title}
                      secondary={`${d.answer ? d.answer?.toUpperCase() : ''} : ${d.reason || ''} ${d?.supportCollectedEnterprise || d?.supportCollectedEmployees || ''} ${d.meetDate ? formatDate(d.meetDate) : ''}`.replace(',,','')}

                    />
                  </ListItemButton>

                </ListItem>
              )
            })}
          </List>
            : <Typography>--</Typography>
          }
        </div>
    )

  }

  const SectionPast = ({ title, items, bgColor }) => {
    return (
      loadingR ? <Skeleton />
        :
        selectedReport && <div className="shadow">
          <Typography  >{title}</Typography>
          {items?.length > 0 ? <List >
            {items?.map((d, index) => {
              return (
                <ListItem divider={true} key={d?.id || index} disablePadding>
                  <ListItemButton key={index} component="a" href="#simple-list">
                    <ListItemText key={index} primary={d}
                    />
                  </ListItemButton>

                </ListItem>
              )
            })}
          </List>
            : <Typography>--</Typography>
          }
        </div>
    )

  }


  return (
    <>
      <div>
        {false && <PreloaderSpinner />}

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleCloseInside}
          aria-labelledby="customized-dialog-title"
          open={open}
          TransitionComponent={Transition}
        >
          <AppBar
            sx={{ position: 'relative' }}>
            <Toolbar
              className={"bg-gray-50"}
            >

              <Typography color={'green'} sx={{ ml: 2, flex: 1 }} variant="h6" component="div" >
                Details sur le rapport

                {selectedReport && <strong className=" text-center ms-2">
                  {"<<" + selectedReport?.fullName + "--" + selectedReport?.title + " " + selectedReport?.date + ">>"}

                </strong>}
              </Typography>

          

              <CloseModalButton iconColor={"red"} handleClose={handleCloseInside} />
            </Toolbar>
          </AppBar>
          <DialogContent dividers>
            {(selectedReport?.isValidated == true) ?
              <Alert className="mb-5 rounded-lg shadow" status={'success'}>
                <AlertIcon />


                <AlertTitle>raport valide
                </AlertTitle>
              </Alert>
              :
              selectedReport?.isValidated == false ? <Alert className="mb-5 rounded-lg shadow" status={'error'}>
                <AlertIcon />


                <AlertTitle>Raport rejette
                </AlertTitle>
              </Alert>
                : <Alert className="mb-5 rounded-lg shadow" status={'warning'}>
                  <AlertIcon />

                  <AlertTitle>Raport en attente de verififcation
                  </AlertTitle>
                </Alert>

            }
            <div className={`grid grid-cols-2 gap-4`}>
              {responses?.data && Object?.keys(responses?.data)?.map((key, index) => {
                return <Section key={key} index={index+1} title={key} items={responses?.data[key]} />

              })}

              {report?.data?.events && <SectionPast title={"Observations"} items={report?.data?.events} />}
              {report?.data?.issues && <SectionPast title={"Problems"} items={report?.data?.issues} />}
              {report?.data?.recommendations && <SectionPast title={"Recommendations"} items={report?.data?.recommendations} />}


            </div>
            <div className="mt-5">
            <Divider />
              <Typography variant="h6" marginBlock={2}>
                Votre observation ici
              </Typography>
              <MultilineTextInputList elements={adminObservations} labelName={"observations"} setElemets={setAdminObservations}

                handleFetechRemoveInput={(item) => {
                  item?.id && axios.delete(`${config.app.api_url}/reports/${selectedReport?.id}/removeAdminObservation/${item?.id}`)
                    .then(getResponses)

                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <MRating
            value={rating}
            setValue={setRating}
            handleChange={(value)=>setRating(value) }
            />
            <MButton
              variant={'danger'}
              isLoading={loading}
              disabled={ rating==null || rating>=2.5}
              onClick={(e) => {
                e?.stopPropagation()
                e?.preventDefault()
                handleSave(false);

                // get();
              }}
            >
              Mauvais
            </MButton>
            <MButton
              isLoading={loading}
              disabled={rating==null || rating<2.5}
              variant={'success'}
              onClick={(e) => {
                e?.stopPropagation()
                e?.preventDefault()
                handleSave(true);
                // get();
              }}
            >
              Valider
            </MButton>
          </DialogActions>
        </Dialog>
      </div>
    </>

  );
};

export default ReportModalInfos;
