import { Text } from "@chakra-ui/react"
import { IconButton } from "@mui/material"
import { BsXCircleFill } from "react-icons/bs"

export const NotFoundResults=({title})=>{

    return(
        <Text className='text-red-500 text-center my-2 text-xl'>{title ?? "😕 Aucun résultat trouvé !"}</Text>

    )
}

export const CloseModalButton = ({handleClose, iconColor})=>{


    return (
        <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
    >
        <BsXCircleFill color={iconColor ?? ''} className='cursor-pointer'   />

    </IconButton>
    )
}