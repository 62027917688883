import { AppBar, Box, capitalize, Chip, Tab, Tabs } from "@mui/material";
import MButton from "components/Mbutton";
import useDataFetching from "hooks/useDataFetching";
import { useParams } from "react-router-dom";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import { useEffect, useState } from "react";
import { BsBank2, BsCardHeading, BsGeoAlt, BsList, BsPeople, BsSearch } from "react-icons/bs";

import { ENTERPRISE_REQUEST_HEADER } from "Constaints";
import { formatDate } from "utils";
import { EMLOYEE_REQUEST_HEADER } from "Constaints";
import { formatNumber } from "utils";
import { CONTRACT_REQUEST_HEADER } from "Constaints";
import RequestDetails from "./RequestDetails";
import { castToRequestParams } from "utils";
import MuiSelect from "components/MuiSelect";
import PaginateBox from "components/PaginateBox";
import { Title } from "components/Title";




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const Requests = () => {
    // const [params, setParams] = useState(
    //     {page : 0, 
    //     limit : 50, 
    //     requestStatus : '',
    //     status : '',
    //   });
    const [params, setParams] = useState({
        page: 0,
        limit: 50,
        status: 'PENDING',
    });

    const [loadingEnter, errorEnter, enterprises, getEnterprises] = useDataFetching(`${config.app.api_url}/user-requests?${castToRequestParams(params)}&requestEntityType=ENTERPRISE`);
    const [loadingEM, errorEm, employees, getEmployee] = useDataFetching(`${config.app.api_url}/user-requests?${castToRequestParams(params)}&requestEntityType=EMPLOYEE`);
    const [loadingCOn, errorCon, contracts, getContracts] = useDataFetching(`${config.app.api_url}/user-requests?${castToRequestParams(params)}&requestEntityType=CONTRACT`);
    const getAll = () => { getEnterprises(); getEmployee(); getContracts() }
    const [open, setopen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [selectedTypeUrl, setSetSelectedTypeUrl] = useState('');

    function createDataEnterprise(N, Raison, NIU, Motif, Type, Date, Status, Options) {
        // const density = population / size;
        return { N, Raison, NIU, Motif, Type, Date, Status, Options };
    }

    function createDataEmployees(N, Noms, Nationality, Motif, Type, Date, Status, Options) {
        // const density = population / size;
        return {
            N, Noms, Nationality, Motif, Type, Date, Status, Options

        };
    }

    function createDataContracts(N, Noms, Nationality, Salary, Motif, Type, Date, Status, Options) {
        // const density = population / size;
        return {
            N, Noms, Nationality, Salary, Motif, Type, Date, Status, Options

        };
    }
    const handleClick = (item, selectedTypeUrl) => {
        if (item) {
            setSelectedRequest(item);
            setSetSelectedTypeUrl(selectedTypeUrl)
            setopen(true);
        }
    }



    const enterpriseRows = enterprises?.data?.content?.filter((f) => f?.requestType !== "CREATE").map((item, index) => {
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: () => handleClick(item, 'ENTERPRISE'),

            datas: createDataEnterprise((params?.page * params?.limit) + (index + 1),

                <span className="fs-c">{(item?.socialRaison || item?.enterprise?.socialRaison)}</span>
                ,
                item?.nui?.toUpperCase() || item?.enterprise?.nui?.toUpperCase(),
                item?.motif,
                item?.requestType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'COMPLETED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>
            )

        }
    }
    )
    const employeesRows = employees?.data?.content?.map((item, index) => {
        // const l = getLastContract(item?.contracts)
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: (e) => handleClick(item, 'EMPLOYEE'),

            datas: createDataEmployees((index + 1),

                item?.employer?.fullName || item?.employer?.firstName + " " + item?.employer?.lastName,
                item?.employer?.nationality
                ,
                item?.motif,
                item?.requestType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'COMPLETED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>

            ),

        }
    }
    )


    const contractsRows = contracts?.data?.content?.map((item, index) => {
        // const l = getLastContract(item?.contracts)
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: (e) => handleClick(item, 'CONTRACT'),

            datas: createDataContracts(
                (index + 1),

                item?.contract?.employer?.fullName || item?.contract?.employer?.firstName + " " + item?.contract?.employer?.lastName,
                item?.contract?.employer?.nationality
                ,
                item?.motif,
                formatNumber(item?.contract?.salary),
                item?.requestType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'COMPLETED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>

            ),

        }
    }
    )



    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getEnterprises()
        getEmployee()
        getContracts()
    }, [params]);
    return (
        <>
            {selectedRequest && open && <RequestDetails
                open={open}
                selectedRequest={selectedRequest}
                requestEntityType={selectedTypeUrl}
                get={getAll}
                handleClose={() => {
                    setopen(false);
                    setSelectedRequest(false)
                }} />}
            <Box sx={{}}>
                <AppBar position="static" className="flex items-center flex-1" sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}

                    <Tabs
                        centered
                        value={value}
                        onChange={handleChange}
                        aria-label="Request"
                        indicatorColor="primary"
                        textColor="inherit"
                    // variant="fullWidth"
                    >
                        <Tab icon={<><BsBank2 size={25} /> </>} label={<span>Emtreprise<span className="text-red-500 ms-1">{`(${enterprises?.data?.totalElements || '--'})`}</span></span>} {...a11yProps(0)} />
                        <Tab icon={<><BsPeople size={25} /> </>} label={<span>Employees<span className="text-red-500 ms-1">{`(${employees?.data?.totalElements || '--'})`}</span></span>} {...a11yProps(1)} />
                        <Tab icon={<BsCardHeading size={25} />} label={<span>Contracts<span className="text-red-500 ms-1">{`(${contracts?.data?.totalElements || '--'})`}</span></span>}  {...a11yProps(2)} />
                    </Tabs>
                    {/* </Box> */}
                </AppBar>
                <div className="flex justify-end  ">

                    <MuiSelect
                        wrapperClasses={"bg-white"}
                        label={'Choisir un status'}
                        sort={false}
                        value={params.status}
                        emptyLabel={"Tous"}
                        options={[
                            // {label : "Tous", value : '*'},
                            { label: "En Attente", value: 'PENDING' },
                            { label: "Valider", value: 'COMPLETED' },
                            { label: "Rejetter", value: 'REJECTED' },
                        ]}
                        onChange={(event) => setParams({ ...params, status: event.target.value })}
                    />
                </div>

                <div className="bg-white"  >

                    <CustomTabPanel value={value} index={0}>
                        <MuiTable loading={loadingEnter} className={''} hidePagiation={true} columns={ENTERPRISE_REQUEST_HEADER} rows={enterpriseRows} textEmpty={"Pas de demande"} />
                        <PaginateBox

                            total={enterprises?.data?.totalElements}
                            rowsPerPage={params?.limit}
                            handleChangeRowsPerPage={(event) => {
                                setParams({
                                    ...params
                                    , limit: parseInt(event.target.value),
                                    page: 0,
                                });
                            }}
                            page={params.page}
                            count={enterprises?.data?.totalPages - 1}
                            handleChange={(event, value) => {
                                setParams({ ...params, page: value });
                            }} />

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <MuiTable loading={loadingEM} className={''} hidePagiation={true} columns={EMLOYEE_REQUEST_HEADER} rows={employeesRows} textEmpty={"Pas de demande"} />
                        <PaginateBox

                            total={employees?.data?.totalElements}
                            rowsPerPage={params?.limit}
                            handleChangeRowsPerPage={(event) => {
                                setParams({
                                    ...params
                                    , limit: parseInt(event.target.value),
                                    page: 0,
                                });
                            }}
                            page={params.page}
                            count={employees?.data?.totalPages - 1}
                            handleChange={(event, value) => {
                                setParams({ ...params, page: value });
                            }} />

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <MuiTable loading={loadingCOn} className={''} hidePagiation={true} columns={CONTRACT_REQUEST_HEADER} rows={contractsRows} textEmpty={"Pas de demande"} />
                        <PaginateBox

                            total={contracts?.data?.totalElements}
                            rowsPerPage={params?.limit}
                            handleChangeRowsPerPage={(event) => {
                                setParams({
                                    ...params
                                    , limit: parseInt(event.target.value),
                                    page: 0,
                                });
                            }}
                            page={params.page}
                            count={contracts?.data?.totalPages - 1}
                            handleChange={(event, value) => {
                                setParams({ ...params, page: value });
                            }} />

                    </CustomTabPanel>
                </div>
            </Box>
        </>
    )
}

export default Requests