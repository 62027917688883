import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "../employes/modals/EmployeeModalInfos";
import { CHECK_PERMISSIONS } from "Constaints";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import PrintButtonExcel from "../default/components/PrintButtonExcel";
import { MdAdd } from "react-icons/md";
import AddEnterprise from "./AddEnterprise";
import PaginateBox from "components/PaginateBox";
import axios from "axios";
import { BsGeoAlt, BsList, BsMap, BsPersonFill, BsPlusCircle, BsSearch, BsThreeDotsVertical, BsXCircle } from "react-icons/bs";
import MenuDrop from "components/MenuDrop";
import MButton from "components/Mbutton";
import SearchModal from "components/SearchModal";
import { formatNumber } from "utils";
import { getExpiredPermitDate } from "utils";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";
import EmployesByEnterpriseModal from "../employes/EmployesByEnterpriseModal";
import MuiTable from "components/MuiTable";
import { ENTERPRISE_HEADER } from "Constaints";
import { ButtonGroup, Chip, Skeleton, capitalize } from "@mui/material";
import { createMapLink } from "utils";
import MuiDatePicker from "components/MuiDatePicker";
import MuiSelect from "components/MuiSelect";
import { formatDate } from "utils";
import { castToRequestParams } from "utils";

const Enterprises = () => {
  // const { isOpenSearc, onOpenSearc, onClose } = useDisclosure();
  const [open, setOpen] = useState(false)
  const authHeader = useAuthHeader();
  const location = useLocation()

  const handleClose = () => { setSelectedEnterprise(null); setOpen(false) }
  const navigation = useNavigate();
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );

  const [loadingM, errorM, missions, fetchDataM] = useDataFetching(
    `${config.app.api_url}/missions`
  );

  const [loadingSearch, errorSearch, searchEnterprises, fetchDataSearch] = useDataFetching(
    searchTerm?.length > 2 ? `${config.app.api_url}/enterprises/search-with-deleted/${searchTerm}` : null
  );

  // console.log("🚀 ~ Enterprises ~ searchEnterprises:", searchEnterprises)
  const [region, setRegion] = useState("*");

  const [departement, setDepartement] = useState(null);
  useEffect(() => {
    setRegion(location?.state?.regionId)
  }, [location]);
  const [arrondissement, setArrondissement] = useState(null);
  // fetcing departments by region id
  useEffect(() => {
    setDepartement(location?.state?.departmentId)
  }, [location]);
  const [loadingD, errorD, departements, fetchDataD] = useDataFetching(
    regions ? `${config.app.api_url}/regions/${region !== "*" && region}/departments` : null
  );

  // fetcing arrondisements by department id
  const [loadingA, errorA, arrondisements, fetchDataA] = useDataFetching(
    departement ? `${config.app.api_url}/departments/${departement}/arrondissements` : null
  );
  const [loadingZ, errorZ, zones, fetchZones] = useDataFetching(
    (region && region != "*") ? `${config.app.api_url}/regions/${region}/zones` : `${config.app.api_url}/zones`
  );
  const auth = useAuthUser();
  const [loadingU, errorU, user, fetchDataU] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const [openList, setOpenList] = useState(false);
  // console.log("🚀 ~ location:", location)

  const [params, setParams] = useState({
    enterpriseId: location.state?.enterpriseId,
    start: location.state?.start,
    end: location.state?.end,
    startUpdate: location.state?.startUpdate,
    endUpdate: location.state?.endUpdate,
    isRegular: location.state?.isRegular,
    regionId: location.state?.regionId,
    departmentId: location.state?.departmentId,
    arrondissementId: location.state?.arrondissementId,
    page: 0,
    limit: 50,
    fromArchive: location.state?.fromArchive,
    located: location.state?.located,
    creatorId: location.state?.creator?.id,
    zoneId: location.state?.zone?.id,
    missionId: location.state?.mission?.id,
    taskId: location.state?.task?.id


  });
  const [creator, setCreator] = useState(null);
  const [mission, setMission] = useState(null);
  const [zone, setZone] = useState(null);
  const [task, setTask] = useState(null);
  const [hideOptions, setHideOptions] = useState(false);
  useEffect(() => {
    if (creator || mission || zone || task) {
      setHideOptions(true)
    }
  }, [creator, mission, zone, task]);

  // console.log("🚀 ~ params:", params)
  useEffect(() => {
    if (location.state) {
      // console.log("🚀 ~ useEffect ~ location.state:", location.state?.creator?.firstName)
      setCreator(location.state?.creator)
      setMission(location.state?.mission)
      setZone(location.state?.zone)
      setTask(location.state?.task)

      setParams({
        enterpriseId: location.state?.enterpriseId,
        start: location.state?.start,
        end: location.state?.end,
        startUpdate: location.state?.startUpdate,
        endUpdate: location.state?.endUpdate,
        isRegular: location.state?.isRegular,
        regionId: location.state?.regionId,
        departmentId: location.state?.departmentId,
        arrondissementId: location.state?.arrondissementId,
        page: 0,
        limit: 50,
        fromArchive: location.state?.fromArchive,
        located: location.state?.located,
        creatorId: location.state?.creator?.id,
        zoneId: location.state?.zone?.id,
        missionId: location.state?.mission?.id,
        taskId: location.state?.task?.id
      })
    }
  }, [location]);

  const get = () => {

    axios
      .get(`${config.app.api_url}/enterprises/query-names-only?${castToRequestParams(params)}`, {
        headers: {
          Authorization: authHeader(),
        }
      })
      .then((res) => {
        setEnterprises(res?.data?.data);
        // console.log("🚀 ~ .then ~ res?.data?:", res?.data?.data?.content)
      })
      .catch((err) => {
        console.log(err);
        setEnterprises([]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);
    // const rr = Object.keys(params)?.map((key) => {
    //   // console.log(typeof params[key] == "string");
    //   // if(params[key]){
    //   return (params[key] != null && params[key] != undefined) ? key + "=" + params[key] : "-1";
    //   // }
    // });
    // const tt = rr?.filter((r) => r !== "-1")?.join("&");
    get()
  }, [params
  ]);

  const HeaderTable = [
    "#",
    "Raison sociale",
    "NIU",
    "Sigle",
    "Secteur d'activite",
    "Nombre de travailleurs déclaré",
    "Arrondissement",
    "Rue",
    "Contacts Entreprise/DRH",
  ];
  // const items = ['Apple', 'Banana', 'Cherry', 'Orange'];
  const exportPdf = <PrintButtonExcel
    text={"Exporter"}
    filename={
      "Entreprises_" +
      regions?.data?.find((r) => r?.id == params?.regionId)?.name

      + `_page_${params?.page}SUR${enterprises?.totalPages}`
    }
    header={HeaderTable}
    body={enterprises?.content?.map((e, index) => {
      // console.log("🚀 ~ body={enterprises?.content?.map ~ e:", e)

      return [
        index+1,
        e?.socialRaison?.toUpperCase(),
        e?.nui?.toUpperCase(),
        e?.sigle?.toUpperCase(),
        e?.activity?.toUpperCase(),
        // e?.employeesNumber,
        e?.region?.toUpperCase(),
        e?.arrondissement?.toUpperCase(),
        // e?.street,
        // `${e?.phone ? e?.phone : '--'}/${e?.rhphone ? +e?.rhphone : '--'}`,

        // e?.enterprise?.socialRaison,
        // e?.enterprise?.activitySectors
        //   ?.map((ac) => {
        //     return ac?.name;
        //   })
        //   ?.join(", "),
        // e?.enterprise?.arrondissement?.name,
        // e?.employerCount,
      ];
    })}
  />
  const menu = {
    icon: <BsThreeDotsVertical />,
    label: "Options",
    Items: [
      {
        label: "Ajouter une entreprise",
        icon: <BsPlusCircle />,
        handleClick: () => setOpen(true)
      },
      {
        label: null,
        icon: exportPdf,
        // handleClick: () => console.log("add")

      },
      {
        label: "Réinitialiser le filtre",
        icon: <BsXCircle />,
        handleClick: () => alert("building")

      },
    ]
  }
  const handleClick = (item) => {
    if (item) {
      // setSetselectedEm(item);
      // navigation(`/admin/enterprises/${item.id}`)
      setSelectedEnterprise(item)

      setOpen(true);

    }
  };


  function createData(N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options) {
    // const density = population / size;
    return { N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options };
  }
  const rows = enterprises?.content?.map((item, index) => {
    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: () => handleClick(item),

      datas: createData((params?.page * params?.limit) + (index + 1), <>{item?.fromArchive ?
        <FromArchiveIcon />
        :

        <FromTheFieldIcon />}

        <span className="fs-c">{(item?.socialRaison)}</span>
      </>
        ,
        item?.activity,
        item?.arrondissement,
        (item?.employeesNumber != null ? item?.employeesNumber : '--'),
        user?.data?.roles.length > 0 &&
        user?.data?.roles?.map((role) => CHECK_PERMISSIONS(
          role?.permissions,
          "Voir une entreprise sur google map"
        ) ? (
          <>
            {(item?.latitude && item?.longitude) ?
              <>
                <Chip
                  icon={<BsGeoAlt />}
                  color="primary"
                  className="capitalize-none"

                  variant="outlined"
                  label={capitalize("Voir sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();

                    if (item?.latitude && item?.longitude) {
                      window.open(createMapLink(`${item?.latitude},${item?.longitude}`), '_blank', 'noopener,noreferrer');
                    } else { alert("Coordonées incomplètes"); }
                  }} />
                {/* <a
                  onClick={(e) => { if (item?.latitude && item?.longitude) { } else { e.preventDefault(); alert("Coordonées incomplètes") } }}
                  target="_blank"
                  className={`text-sm text-${(item?.latitude && item?.longitude) ? 'blue' : 'red'}-500 underline`}
                  href={`http://maps.google.com/maps/?q=${item?.latitude},${item?.longitude}`}
                >
                  Voir sur la map
                </a> */}
              </>
              :
              (item?.searchLatitude && item?.searchLongitude) ?
                <>
                  <Chip
                    icon={<BsGeoAlt />}
                    color="warning"
                    className="capitalize-none"

                    variant="outlined"
                    label={capitalize("Vérifier sur la map")}
                    onClick={(e) => {
                      e.preventDefault();
                      e?.stopPropagation();

                      if (item?.searchLatitude && item?.searchLongitude) {
                        window.open(createMapLink(`${item?.searchLatitude},${item?.searchLongitude}`), '_blank', 'noopener,noreferrer');
                      } else { alert("Coordonées incomplètes"); }
                    }} />
                  {/* <a
                  onClick={(e) => { if (item?.latitude && item?.longitude) { } else { e.preventDefault(); alert("Coordonées incomplètes") } }}
                  target="_blank"
                  className={`text-sm text-${(item?.latitude && item?.longitude) ? 'blue' : 'red'}-500 underline`}
                  href={`http://maps.google.com/maps/?q=${item?.latitude},${item?.longitude}`}
                >
                  Voir sur la map
                </a> */}
                </> :
                <Chip
                  icon={<BsSearch />}
                  // color="warning"
                  // variant="outlined"
                  className="capitalize-none"
                  label={capitalize("Recherher sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();
                    window.open(createMapLink(`${item?.socialRaison} ${item?.sigle} ${item?.street} CAMEROON`), '_blank', 'noopener,noreferrer');
                  }} />

            }
          </>
        ) : (
          <></>

        ))
        // ,
        // <span>--</span>
        ,
        <MButton
          variant={"white"}
          onClick={(e) => {
            setSelectedEnterprise(item)
            e.preventDefault(); e?.stopPropagation(); setOpenList(true)
          }}>
          <BsList />
          Ses expatriés
        </MButton>)

    }
  }
  )
  return (

    <div>
      {/* {(loading || loadingR) && <PreloaderSpinner />} */}
      <div className=" grid h-full grid-cols-1 gap-5">
        {loadingR ? <Skeleton variant="rectangular" width={'100%'} height={90} /> : <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"rgba(255, 255, 255, 1)"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-end"
        >
          {/* <div className="flex flex-wrap gap-2 items-center content-center"> */}

          <MuiDatePicker
            label={"Debut de la periode"}
            value={params?.start}
            onChange={(newValue) => {
              setParams({
                ...params,
                start: new Date(newValue)?.getTime(),
              })
            }}
            wrapperClasses={"flex-1 filter-item anime slideLeft"}

          />
          <MuiDatePicker
            value={params?.end}

            label={"Fin de la periode"}
            onChange={(newValue) => {
              setParams({
                ...params,
                end: new Date(newValue)?.getTime(),
              })
            }}
            wrapperClasses={"flex-1 filter-item anime slideLeft"}

          />


          <MuiDatePicker
            label={"Debut Modif"}
            value={params?.startUpdate}
            onChange={(newValue) => {
              setParams({
                ...params,
                startUpdate: new Date(newValue)?.getTime(),
              })
            }}
            wrapperClasses={"flex-1 filter-item anime slideLeft"}

          />
          <MuiDatePicker
            value={params?.endUpdate}

            label={"Fin Modif"}
            onChange={(newValue) => {
              setParams({
                ...params,
                endUpdate: new Date(newValue)?.getTime(),
              })
            }}
            wrapperClasses={"flex-1 filter-item anime slideLeft"}

          />

          <MuiSelect
            // size={"small"}
            label={'Région'}
            options={regions?.data}
            emptyLabel={'National'}
            value={params?.regionId}
            defaultFormat={true}
            onChange={(e) => {
              console.log("e.target.value", e.target.value)
              setRegion(e.target.value);
              setArrondissement("");
              setDepartement(""
              );
              setParams({
                ...params,
                regionId: e.target.value,
                departmentId: null,
                arrondissementId: null,
              });
            }}
            wrapperClasses={"flex-1 filter-item anime slideLeft"}
          />


          {/* <div className="flex-1 filter-item anime slideLeft">
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Region
              </label>
              <select
                onChange={(e) => {
                  setRegion(e.target.value);
                  setArrondissement("");
                  setDepartement("");
                  setParams({
                    ...params,
                    regionId: e.target.value,
                    departmentId: null,
                    arrondissementId: null,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="region"
                id="region"
              >
                <option selected value="*">
                  National
                </option>
                {regions?.data &&
                  regions.data.map((item) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
              </select>
            </div> */}
          {/* Filtre departement */}
          {/* {departements?.data && <div
              className="flex-1 filter-item anime slideDown"
            >
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Departement
              </label>
              <select
                onChange={(e) => {
                  setDepartement(e.target.value);
                  setArrondissement("");
                  setParams({
                    ...params,
                    departmentId: e.target.value,
                    arrondissementId: null,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="department"
                id="department"
              >
                <option value="">--- Sélectionner le département ---</option>
                {departements?.data &&
                  departements.data.map((item) => {
                    return (
                      <option
                        selected={item?.id == departement}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>} */}
          {departements?.data &&

            <MuiSelect
              label={'Département'}
              options={departements?.data}
              emptyLabel={'Régional'}

              value={params?.departmentId}
              defaultFormat={true}
              onChange={(e) => {
                setDepartement(e.target.value);
                setArrondissement("");
                setParams({
                  ...params,
                  departmentId: e.target.value,
                  arrondissementId: null,
                });
              }}
              wrapperClasses={"flex-1 filter-item anime slideDown"}
            />

          }

          {/* Filtre arrondissement */}
          {/* {arrondisements?.data && <div
              className="flex-1 filter-item anime slideDown"
            >
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Arrondissement
              </label>
              <select
                onChange={(e) => {
                  setArrondissement(e.target.value);
                  setParams({
                    ...params,
                    arrondissementId: e.target.value,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="arrondissement"
                id="arrondissement"
              >elelements////
                <option value="">--- sélectionner l'arrondissement ---</option>
                {arrondisements?.data &&
                  arrondisements.data.map((item) => {
                    return (
                      <option
                        selected={item?.id == arrondissement}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>} */}

          {zones?.data &&

            <MuiSelect
              // disabled={!canEdit}
              label={"Zone"}
              // marginRight={5}
              value={params.zoneId}
              options={zones?.data?.map((z) => { return { value: z?.id, label: `Zone ${z?.number} ${z?.description ? "--" + z?.description : ''}` } })}
              // format={{ value: "value", label: 'number' }}
              onChange={(e) => {
                setArrondissement(e.target.value);
                setParams({
                  ...params,
                  zoneId: e.target.value,
                });
              }}
              emptyLabel={"Aucun"}

            />
          }


          {arrondisements?.data &&


            <MuiSelect
              label={'Arrondissement'}
              emptyLabel={'Départemental'}

              options={arrondisements?.data}
              value={params?.arrondissementId}
              defaultFormat={true}
              onChange={(e) => {
                setArrondissement(e.target.value);
                setParams({
                  ...params,
                  arrondissementId: e.target.value,
                });
              }}
              wrapperClasses={"flex-1 filter-item anime slideDown"}
            />
          }

          {missions?.data &&

            <MuiSelect
              // disabled={!canEdit}
              label={"Mission"}
              // marginRight={5}
              value={params.missionId}
              options={[{ value: '-1', label: 'Pas encore de Mision de Controle' }, { value: '-2', label: 'Déja effectue mission de Controlle' }, ...missions?.data?.map((m) => { return { value: m?.id, label: `${m?.description} du ${formatDate(m?.start)} ${formatDate(m?.end)}` } })]}
              // format={{ value: "value", label: 'number' }}
              onChange={(e) => {
                setArrondissement(e.target.value);
                setParams({
                  ...params,
                  missionId: e.target.value,
                });
              }}
              emptyLabel={"Aucun"}

            />
          }
          {/* <div
              className="flex-1 filter-item anime slideLeft"
            >
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Situation régilieres ?
              </label>
              <select
                onChange={(value) => {
                  setParams({
                    ...params,
                    isRegular:
                      value.target.value != "-1"
                        ? value.target.value
                        : null,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="arrondissement"
                id="arrondissement"
              >
                <option value={"-1"}>Selectionner</option>
                <option value={false}>Non</option>
                <option value={true}>Oui</option>

              </select>
            </div> */}

          <MuiSelect
            label={'Situation régilieres ?'}
            emptyLabel={'Tous'}
            options={[
              { value: false, label: 'Non' },
              { value: true, label: 'Oui' },
            ]}
            value={params?.isRegular}
            onChange={(value) => {
              alert("Fucntionality under maintenance...")
              // return
              // setParams({
              //   ...params,
              //   isRegular:
              //     value.target.value != "-1"
              //       ? value.target.value
              //       : null,
              // });
              setParams({
                ...params,
                isRegular: null
              });
            }}
            wrapperClasses={"flex-1 filter-item anime slideRight"}
          />

          {/* <div
              className="flex-1 filter-item anime slideRight"
            >
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Source
              </label>
              <select
                onChange={(value) => {
                  setParams({
                    ...params,
                    fromArchive:
                      value.target.value != "-1"
                        ? value.target.value
                        : null,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="arrondissement"
                id="arrondissement"
              >
                <option value={"-1"}>Tous</option>
                <option value={true}>Archive</option>
                <option value={false}>Terrain</option>

              </select>
            </div> */}

          <MuiSelect
            label={'Source'}
            emptyLabel={'Tous'}
            value={params?.fromArchive}

            options={[
              { value: false, label: 'Terrain' },
              { value: true, label: 'Archive' },
            ]}
            onChange={(value) => {
              setParams({
                ...params,
                fromArchive: value.target.value
                // value.target.value != "-1"
                //   ? value.target.value
                //   : null,
              });
            }}
            wrapperClasses={"flex-1 filter-item anime slideRight"}
          />
          {/* <div className="flex-1 filter-item anime slideRight">
              <label
                htmlFor={"region"}
                className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
              >
                Localisées ?
              </label>
              <select
                onChange={(value) => {
                  alert("Fonctionalitée en cours de réalisation...")
                  return
                  setParams({
                    ...params,
                    located:
                      value.target.value != "-1"
                        ? value.target.value
                        : null,
                  });
                }}
                className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                name="arrondissement"
                id="arrondissement"
              >
                <option value={"-1"}>Selectionner</option>
                <option value={true}>Non</option>
                <option value={false}>Oui</option>

              </select>


            </div> */}
          <MuiSelect
            label={'Visitées (Localisées)?'}
            emptyLabel={'Tous'}
            options={[
              { value: false, label: 'Non' },
              { value: true, label: 'Oui' },
            ]}
            value={params?.located}

            onChange={(value) => {
              // alert("Fonctionalitée en cours de réalisation...")
              // return
              setParams({
                ...params,
                located:
                  value.target.value != "-1"
                    ? value.target.value
                    : null,
              });
            }}
            wrapperClasses={"flex-1 filter-item anime slideRight"}
          />
          <div className=" anime slideRight">
            <MenuDrop menu={menu} />
          </div>
          {/* </div> */}
        </Box>}
        {creator && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"yellow.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex gap-2">
            <BsPersonFill size={20} className="text-blue-400" />
            Entreprises collectées par :
            <strong>
              {creator?.creator?.firstName} {creator?.lastName}
            </strong>
          </span>
        </Box>
        }
        {mission && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"yellow.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex gap-2 ">
            {/* <BsPersonFill size={20} className="text-blue-400" /> */}
            Entreprises collectées lors de la mission  :
            <strong>
              {`  ${mission?.description}
              du : ${formatDate(mission?.start)} au: ${formatDate(mission?.end)}`}
            </strong>
          </span>
        </Box>
        }
        {zone && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"yellow.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex gap-2 ">
            <BsPersonFill size={20} className="text-blue-400" />
            Entreprises collectées dans la  :
            <strong>
              {` Zone ${zone?.number}
               -- ${zone?.description}`}
            </strong>
          </span>
        </Box>
        }

        {task && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"yellow.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex flex-col gap-0 ">
            <span className="flex">
              <BsPersonFill size={20} className="text-blue-400" />
              Entreprises Assignnes a la tache :
              <strong>
                {task?.description}
                {console.log("🚀 ~ body={enterprises?.content?.map ~ task:", task)}
              </strong>
            </span>
            <snap className="w-full flex flex-wrap gap-1">
              {task?.participants?.map((p) => {
                return (
                  <>
                    <Chip color="info" variant="outlined" label={p?.firstName + " " + p?.lastName} />
                  </>
                )
              })}
            </snap>
          </span>
        </Box>
        }

        <Card extra={"w-full h-full pb-4"}>
          <div className="flex justify-between"

          >
            <div>
              <strong>

                Total :
                <Badge
                  fontSize={13}
                  rounded={5}
                  padding={1}
                  paddingInline={2}
                  colorScheme="green"
                  className="m-0 ms-2"
                >
                  {formatNumber(enterprises?.totalElements) || '--'}
                </Badge>
              </strong>
            </div>
            {<div className="flex flex-1 justify-center">

              <span
                style={{ marginTop: -15 }}

              >
                <ButtonGroup variant="text" aria-label="Basic button group">
                  {!hideOptions && <>

                    <SearchModal

                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      searchResults={searchEnterprises?.data}
                      searchFunction={fetchDataSearch}
                      iconLabel={"Rechercher"}
                      placeholder={"Entrer son NIU ou sa raison sociale"}
                      title={"Rechercher une entreprise"}
                      handleClickResult={(item) => {
                        setSelectedEnterprise(item)
                        setOpen(true)
                      }}
                      loading={loadingSearch}
                    />

                    <MButton
                      variant={"white"}
                      onClick={() => setOpen(true)}
                    >
                      <BsPlusCircle />
                      Ajouter
                    </MButton>
                  </>
                  }
                  {exportPdf}

                </ButtonGroup>
              </span>
            </div>}


          </div>
          {/* <div className="flex justify-between gap-2 p-2">
            <div>
            </div>

            <div className="">
              {user?.data?.roles.length > 0 &&
                user?.data?.roles?.map((role) => {
                  return (
                    CHECK_PERMISSIONS(
                      role?.permissions,
                      "Ajouter une entreprise"
                    ) && (
                      <Button onClick={() => onOpen()} colorScheme="blue">
                        <MdAdd className="h-6 w-6" />
                        Ajouter une entreprise
                      </Button>
                    )
                  );
                })}
            </div>
            <PrintButtonExcel
              filename={
                "Entreprises_" +
                (regions?.data?.find((r) => r?.id == region)?.name ||
                  region == "*"
                  ? "Nationales"
                  : null)
              }
              header={HeaderTable}
              body={enterprises?.map((e) => {
                return [
                  e?.socialRaison,
                  e?.activitySectors
                    ?.map((ac) => {
                      return ac?.name;
                    })
                    ?.join(", "),
                  e?.arrondissement?.name,
                  e?.employerCount,
                  // e?.enterprise?.socialRaison,
                  // e?.enterprise?.activitySectors
                  //   ?.map((ac) => {
                  //     return ac?.name;
                  //   })
                  //   ?.join(", "),
                  // e?.enterprise?.arrondissement?.name,
                  // e?.employerCount,
                ];
              })}
            />
          </div> */}
          {/* <TableContainer> */}
          {<MuiTable
            loading={loading}
            columns={ENTERPRISE_HEADER}
            limit={params?.limit}
            hidePagiation={true}
            rows={rows} />
          }
          {/* </TableContainer> */}
          <PaginateBox
            total={enterprises?.totalElements}
            rowsPerPage={params?.limit}
            handleChangeRowsPerPage={(event) => {
              // alert("change")
              // console.log("🚀 ~ EmployeList ~ event:", event.target.value)
              setParams({
                ...params
                , limit: parseInt(event.target.value),
                page: 0,
              });
              // (0);
            }}
            page={params.page}
            count={enterprises?.totalPages - 1}
            handleChange={(event, value) => {
              // event.preventDefault()
              setParams({ ...params, page: value });
            }}

          />
        </Card>
      </div>

      <AddEnterprise get={get} open={open} handleClose={handleClose} selectedEnterpriseP={selectedEnterprise}

      />
      <EmployesByEnterpriseModal Fullscreen={true} title={"titre 1"} open={openList}
        setOpen={setOpenList} handleClose={() => { setOpenList(false); setSelectedEnterprise(null) }} selectedEnterprise={selectedEnterprise} />

    </div>
  );
};

export default Enterprises;
