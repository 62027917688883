import { formatDateFrom } from "utils";
import { formatDate } from "utils";

const NotificationBox = ({ title, body, date }) => {
    return (
      <div className="bg-white shadow-md rounded-lg p-4 py-2 mb-2 flex items-start justify-between">
      <div className="">
        <h2 className="text-xl font-bold text-gray-750 mb-2">{title}</h2>
        <p className="text-gray-700 mb-2">{body}</p>
      </div>
      <p className="text-gray-500 text-sm">{formatDateFrom(date)}</p>
    </div>
    );
  };
  
  export default NotificationBox;