import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Select } from "@chakra-ui/react";
// import { ROLES } from "Constaints";
import { useGetRoles } from "Constaints";

const AddUser = (props) => {
  const { isOpen, onClose } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    firstname: yup.string().required("First Name is a required field"),
    lastname: yup.string().required("Last Name is a required field"),
    phone: yup.string().required("Phone Number is a required field"),
    password: yup.string(),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is a required field"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    console.log("🚀 ~ file: AddUser.jsx:47 ~ onSubmitHandler ~ data:", data)
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`auth/register`, "POST");

  const handleSave = async (dataYub) => {
    const data = {
      firstname: dataYub?.firstname,
      lastname: dataYub?.lastname,
      phone: dataYub?.phone,
      email: dataYub?.email,
      password: dataYub?.password || "123456789",
      roles: roles?.length == 0 ? ["AGENT"] : roles,
    };
    // console.log("🚀 ~ file: AddUser.jsx:65 ~ handleSave ~ data:", data)

    await postAxiosData(data);
  };

  const handleChangeValue = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(value);
  };

  const [roles, setRoles] = useState();

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
    reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Utilisateur ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
      
    }
  }, [successMessage]);
  
const ROLES  = useGetRoles()
// console.log("🚀 ~ AddUser ~ roles:", rolesD)


  // console.log("roles", roles)

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered={true}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div className="text-center">
                <span className="text-3xl uppercase">Ajouter un compte</span>
              </div>
            </AlertDialogHeader>

            <AlertDialogBody>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="my-4 grid gap-2 md:grid-cols-1">
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Prénom(s)"
                      required={true}
                      placeholder="Prénom"
                      id="firstname"
                      name="firstname"
                      type="text"
                      error={
                        errors.firstname?.message !== ""
                          ? errors.firstname?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom(s)"
                      required={true}
                      placeholder="Nom"
                      id="lastname"
                      name="lastname"
                      type="text"
                      error={
                        errors.lastname?.message !== ""
                          ? errors.lastname?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Numéro de téléphone"
                      required={true}
                      placeholder="690128925"
                      id="phone"
                      name="phone"
                      type="text"
                      error={
                        errors.phone?.message !== ""
                          ? errors.phone?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Adresse Mail"
                      required={true}
                      placeholder="adresse@gmail.com"
                      id="email"
                      name="email"
                      type="email"
                      error={
                        errors.email?.message !== ""
                          ? errors.email?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Mot de passe"
                      required={false}
                      placeholder="Mot de passe"
                      id="password"
                      name="password"
                      type="password"
                      error={
                        errors.password?.message !== ""
                          ? errors.password?.message
                          : ""
                      }
                    />
                  </div>
                  <span className="ms-1">Role</span>
                  <Select
                    className="my-2"
                    height={20}
                    multiple
                    value={roles}
                    onChange={(e) => handleChangeValue(e)}
                  >
                    {ROLES?.map((role) => {
                      return <option value={role}>{role}</option>;
                    })}
                  </Select>
                </div>
                <div className="flex justify-between p-4">
                  <div>
                    <Button onClick={() => {
                      onClose();
                      window.location.reload();
                    }}>Annuler</Button>
                  </div>
                  <div>
                    <Button type="submit" colorScheme="green">
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </form>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddUser;
