import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';


export default function MuiBarCharts({ xLabel, data, title, color }) {
  return (
    <div className='bg-white shadow-lg p-2 rounded'>
      
    <strong className="text-xl font-bold  text-navy-700 dark:text-white">
        {title}
        </strong>

      <BarChart
      // layout='horizontal'
        // width={700}
        height={300}
        series={[
          { data: data , color : color ||  'blue',
        
        },
          
        ]}
        xAxis={[{ data: xLabel, scaleType: 'band' }]}
        
      />
    </div>
  );
}