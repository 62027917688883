import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useDataFetching from 'hooks/useDataFetching';
import { config } from 'variables/config';
// import { NotFoundResults } from './Utils';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export default function MuiAsyncAutocomplete({error, fullWidth,fetch, value, label, handleChange, format, defaultFormat, wrapperClasses, multiple, disabled }) {
  if (defaultFormat) format = { value: 'id', label: 'name' }

  // alert(format?.value)

  const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  const [serachTerm, setSearchTerm] = React.useState(null);
  // const loading = open && options.length === 0;

  const [loading, errorO, options, fetchDataO] = useDataFetching(
    serachTerm ? `${config.app.api_url}/${fetch}/${serachTerm}` : null
  );


  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      // readOnly={true}

      className={wrapperClasses}
      id="asynchronous-demo"
      sx={ fullWidth ? '': { width:300 }}
      open={open}
      onOpen={() => {
        // alert('yes')
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.label}
      filterOptions={(x) => x}
      options={options?.data ? options?.data?.map((d) => { return { label: d[format?.label], value: d[format?.value] } }) : []}
      loading={loading}
      onChange={handleChange}
      value={value}
      noOptionsText='Aucun résultat !'
      onInputChange={(e) => setSearchTerm(e?.target?.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

