import { Tooltip } from "@chakra-ui/tooltip"

export const FromArchiveIcon = () => {
    return (
        <Tooltip hasArrow label={"Provenant des archives"} bg='yellow.200' color='black' placement="bottom">

            <i className="bi bi-archive-fill text-success text-yellow-500 me-2" />

        </Tooltip>)
}


export const FromTheFieldIcon = () => {
    return(
        <Tooltip hasArrow label={"Collecté sur le terrain"} bg='blue.200' color='black' placement="bottom">
        
        <i className="bi bi-tablet-fill text-success text-blue-500 me-2" />
        </Tooltip>)
        
}


export const ClosedIcon = () => {
    return(
        <Tooltip hasArrow label={"Entreprise fermée"} bg='red.200' color='black' placement="bottom">
        
        <i className="bi bi-door-closed-fill text-success text-red-300 me-2" />
        </Tooltip>)
        
}

export const GoneIcon = () => {
    return(
        <Tooltip hasArrow label={"Employé parti"} bg='red.200' color='black' placement="bottom">
        
        <i className="bi bi-x-octagon text-success text-red-300 me-2" />
        </Tooltip>)
        
}

export const DeletedIcon = () => {
    return(
        <Tooltip hasArrow label={"Supprimé"} bg='red.200' color='black' placement="bottom">
        
        <i className="bi bi-trash-fill text-success text-red-300 me-2" />
        </Tooltip>)
        
}