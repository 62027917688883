import React, { useEffect, useState } from "react";
/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import toast from "react-hot-toast";

import InputField from "components/fields/InputField";
import { Alert, AlertIcon, AlertTitle, Badge, Button, Tooltip } from "@chakra-ui/react";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import axios from "axios";
import { format } from "prettier";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import { BsBan, BsDoorClosed, BsPencilSquare, BsPlusCircle, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import { checkIsValidPermit } from "utils";
import { getExpiredPermitDate } from "utils";
import 'dayjs/locale/fr' // load on demand
import { checkIsValidPermitDate } from "utils";
import { AppBar, Chip, Dialog, DialogContent, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Skeleton, Slide, Toolbar, Typography, capitalize } from "@mui/material";
import { CloseModalButton } from "components/Utils";
import MuiAsyncAutocomplete from "components/MuiAsyncAutocomplete";
import MuiCollapse from "components/MuiCollapse";
import MButton from "components/Mbutton";
import { formatDate } from "utils";
import AddContract from "./AddContract";
import { getLastContract } from "utils";
import { checkIsValidContrat } from "utils";
import { checkIsValidContractDate } from "utils";
import MuiAUtocomplete from "components/MuiAutocomplete";
import MuiDatePicker from "components/MuiDatePicker";
import MuiSelect from "components/MuiSelect";
import MMenus from "components/MMenus";
import { BlindsClosed, DeleteForeverSharp, RestorePageRounded } from "@mui/icons-material";
import { EMPLOYER_STATUS } from "Constaints";
import PreloaderSpinner from "components/PreloaderSpinner";
import { GoneIcon } from "components/TableIcon";
import { formatDateTime } from "utils";
import { EWORK_DEMANDE_STATUS } from "Constaints";
import FormSkeleton from "components/FormSkeleton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddEmployer = ({ open, handleClose, handleCloseOnly, get, selectedEmployerP, fixedEnterprise }) => {
  // console.log("🚀 ~ selectedEmployerP:", selectedEmployerP)
  const [contractWithEmp, setContractWithEmp] = useState(null);
  // console.log("🚀 ~ contract:", contractWithEmp)

  const [selectedEmployer, setSelectedEmployer] = useState(selectedEmployerP);
  // console.log("🚀 ~ selectedEmployer:", selectedEmployer)
  //   fetch diplome
  const [loadEmp, errorEMp, employee, fetchDataEmp] = useDataFetching(
    selectedEmployerP?.id ? `${config.app.api_url}/employers/${selectedEmployerP?.id}` : null, false
  );

  // console.log("🚀 ~ employee:", employee)
  const [loadingM, errorM, modifications, fetcModifs] = useDataFetching(
    selectedEmployerP?.id ? `${config.app.api_url}/employers/${selectedEmployerP?.id}/modifications` : null
  );

  // const [loadCont, errorCOntracts, contracts, fetchContracts] = useDataFetching(
  //   selectedEmployer?.id ? `${config.app.api_url}/employers/${selectedEmployer?.id}/contracts` :null
  // );
  const [contracts, setContracts] = useState([]);
  // console.log("🚀 ~ contracts:", contracts)
  // useEffect(() => {
  //   selectedEmployerP && setSelectedEmployer(selectedEmployerP)
  // }, [selectedEmployerP]);

  useEffect(() => {
    selectedEmployerP && fetchDataEmp()
  }, [selectedEmployerP]);
  useEffect(() => {
    employee && setSelectedEmployer(employee?.data)
  }, [employee]);

  // console.log("🚀 ~ AddEmployer ~ selectedEmployer:", selectedEmployer)
  /** Form validation config */
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)


  const schema = yup.object().shape({
    fullName: yup.string().required("Ce champ est requis"),
    contratNumber: yup.string(),
    // contartDuration: yup.string(),
    salary: yup.string().default("0"),
    indTransport: yup.string().default("0"),
    indLogement: yup.string().default("0"),
    indResponsabilite: yup.string().default("0"),
    indRepresentation: yup.string().default("0"),
    primeExploitation: yup.string().default("0"),
    sursalaire: yup.string().default("0"),
    primeSalissure: yup.string().default("0"),
    indCaisse: yup.string().default("0"),
    bonus: yup.string().default("0"),
    nui: yup.string().default("0"),
    primePanier: yup.string().default("0"),
    billetAvion: yup.string().default("0"),
    autresPrimes: yup.string().default("0"),



    visaNumber: yup.string(),
    passportNumber: yup.string(),
    qualification: yup.string(),
    permitNumber: yup.string(),
    observation: yup.string(),

    residencePermitNumber: yup.string(),
  });
  const auth = useAuthUser();
  const [sexe, setSexe] = useState(null);
  // const [nationalities, setNationalities] = useState([]);
  const [contractDuration, setContractDuration] = useState(null);

  // const [hasPermit, setHasPermit] = useState("Non");
  const [hasVisa, setHasVisa] = useState("Non");
  const [hasResidentPermit, sethasResidentPermit] = useState("Non");
  // useEffect(() => {
  //   if (hasPermit == "Non") {
  //     setValue("permitNumber", undefined)
  //     setDate(null)
  //   }
  // }, [hasPermit]);

  useEffect(() => {
    if (hasVisa == "Non") {
      setValue("visaNumber", undefined)
      setVisaOptDate(null)
      setVisaType(null)

    }
  }, [hasVisa]);

  useEffect(() => {
    if (hasResidentPermit == "Non") {
      setValue("residencePermitNumber", undefined)
      setresidencePermitExpDate(null)
      setResidentType(null)
    }
  }, [hasResidentPermit]);

  const [residencePermitExpDate, setresidencePermitExpDate] = useState(null)
  const [date, setDate] = useState(null);
  const [birthDat, setBirthDat] = useState(null);
  const [visaOptDate, setVisaOptDate] = useState(null);
  const [passportExpDate, setpassportExpDate] = useState(null);
  const [diplome, setDiplonme] = useState(null);
  const [occupation, setOccupation] = useState(null);
  // console.log("🚀 ~ AddEmployer ~ occupation:", occupation)
  // const [category, setCategory] = useState(null);
  const [enterprise, setEnterprise] = useState(null);
  const [nationality, setNationality] = useState(null);
  // console.log("🚀 ~ nationality:", nationality)

  const [visaType, setVisaType] = useState(null);
  const [entryType, setEntryType] = useState(null);
  const [residentType, setResidentType] = useState(null);

  const [canEdit, setCanEdit] = useState(false);
  const handleReset = () => {
    reset()
    setresidencePermitExpDate(null)
    setDate(null)
    setBirthDat(null)
    setVisaOptDate(null)
    setpassportExpDate(null)
    setDiplonme(null)
    setOccupation(null)
    setEnterprise(null)
    setNationality(null)
    setContractDuration(null)
    setEntryType(null)
    setVisaType(null)
    setResidentType(null)
    setContractWithEmp(null)
    // setCanEdit(false)
    // setHasPermit("Non")
    setHasVisa("Non")
    sethasResidentPermit("Non")
    setSexe(null)
  }

  const durations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];



  const [loadingD, errorD, diplomes, fetchDataD] = useDataFetching(
    `${config.app.api_url}/diplomes`
  );



  const [loadingN, errorN, nationalities, fetchDataN] = useDataFetching(
    `${config.app.api_url}/countries`
  );

  //   fetch categories
  const [loadingC, errorC, categories, fetchDataC] = useDataFetching(
    `${config.app.api_url}/categories`
  );

  //   fetch categories
  const [loadingE, errorE, enterprises, fetchDataE] = useDataFetching(
    `${config.app.api_url}/enterprises/labels`
  );

  //   fetch categories
  const [loadingO, errorO, occupations, fetchDataO] = useDataFetching(
    `${config.app.api_url}/occupations/all`
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log("🚀 ~ AddEmployer ~ errors:", errors)

  const onSubmitHandler = (data) => {
    handleSave(data);
    // reset();
  };

  /** post create employer */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`employers`, "POST");

  const handleSave = async (formData) => {
    const data = {
      ...formData,
      id: selectedEmployer?.id || null,
      creatorId: selectedEmployer?.id ? null : auth()?.user?.id,
      editorId: auth()?.user?.id,
      enterpriseId: fixedEnterprise?.id ? fixedEnterprise?.id : enterprise ? parseInt(enterprise) : null,
      sexe: sexe,
      nationality: nationality,
      contratDuration: parseInt(contractDuration),
      visaOptDate: visaOptDate ? dayjs(new Date(visaOptDate)).format('YYYY-MM-DD') : null,

      passportExpDate: passportExpDate ? dayjs(new Date(passportExpDate)).format('YYYY-MM-DD') : null,
      residencePermitExpDate: residencePermitExpDate ? dayjs(new Date(residencePermitExpDate)).format('YYYY-MM-DD') : null,

      permitDate: date ? dayjs(new Date(date)).format('YYYY-MM-DD') : null,
      birthDate: birthDat ? dayjs(new Date(birthDat)).format('YYYY-MM-DD') : null,

      visaType,
      residentType,
      entryType,
      // hasPermit: hasPermit == "Oui" ? "true" : "false",
      hasResidentPermit: hasResidentPermit == "Oui" ? "true" : "false",
      hasVisa: hasVisa === 'Oui' ? 'true' : 'false',

      diplomeId: parseInt(diplome),
      occupationId: parseInt(occupation),
      contract: contractWithEmp
      // categoryId: parseInt(category),

      // resLatitude: parseFloat(formData.resLatitude).toFixed(4),
      // resLongitude: parseFloat(formData.resLongitude).toFixed(4),
    };

    // console.log("data to saved", data);
    await postAxiosData(data);
  };

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      get()
      handleReset()
      handleCloseInside()
      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : `Expatrié ${selectedEmployer ? "modifié" : "ajouté"} avec succès.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessage]);

  /* fetch nationalities */
  // useEffect(() => {
  //   if (nationalities.length <= 0) {
  //     axios.get("https://restcountries.com/v3.1/all").then((response) => {
  //       setNationalities(response.data);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    setCanEdit(!loading)
  }, [loading]);

  useEffect(() => {
    if (selectedEmployer) {
      setContracts(selectedEmployer?.contracts)
      setCanEdit(false)

      setValue('fullName', selectedEmployer?.fullName || (selectedEmployer?.firstName + " " + selectedEmployer?.lastName) || undefined)
      setValue('nui', selectedEmployer?.nui || undefined)

      setValue('contratNumber', selectedEmployer?.contratNumber || undefined)
      setValue('permitNumber', selectedEmployer?.permitNumber || undefined)
      setValue('salary', selectedEmployer?.salary || undefined)
      setValue('indTransport', selectedEmployer?.indTransport || undefined)
      setValue('indLogement', selectedEmployer?.indLogement || undefined)
      setValue('indResponsabilite', selectedEmployer?.indResponsabilite || undefined)
      setValue('indRepresentation', selectedEmployer?.indRepresentation || undefined)
      setValue('primeExploitation', selectedEmployer?.primeExploitation || undefined)
      setValue('sursalaire', selectedEmployer?.sursalaire || undefined)
      setValue('primeSalissure', selectedEmployer?.primeSalissure || undefined)
      setValue('indCaisse', selectedEmployer?.indCaisse || undefined)
      setValue('bonus', selectedEmployer?.bonus || undefined)
      setValue('primePanier', selectedEmployer?.primePanier || undefined)
      setValue('billetAvion', selectedEmployer?.billetAvion || undefined)
      setValue('autresPrimes', selectedEmployer?.autresPrimes || undefined)
      setValue('observation', selectedEmployer?.observation || undefined)


      setContractDuration(selectedEmployer?.contratDuration)
      // setHasPermit((selectedEmployer?.permitDate?.length > 0 || selectedEmployer?.permitDate?.length > 0 || selectedEmployer?.hasPermit == true) ? 'Oui' : 'Non')
      setDate(selectedEmployer?.permitDate)
      setOccupation(selectedEmployer?.occupation?.id)
      setEnterprise(selectedEmployer?.enterprise?.id)



      setValue('visaNumber', selectedEmployer?.visaNumber || undefined)
      setValue('passportNumber', selectedEmployer?.passportNumber || undefined)
      setValue('qualification', selectedEmployer?.qualification || undefined)
      setValue('residencePermitNumber', selectedEmployer?.residencePermitNumber || undefined)

      setSexe(selectedEmployer?.sexe)
      setNationality(selectedEmployer?.nationality)
      // setHasPermit(selectedEmployer?.hasPermit == true ? 'Oui' : 'Non')
      setHasVisa((selectedEmployer?.visaOptDate?.length > 0 || selectedEmployer?.visaNumber?.length > 0 || selectedEmployer?.hasVisa == true) ? 'Oui' : 'Non')
      sethasResidentPermit((selectedEmployer?.residencePermitExpDate?.length > 0 || selectedEmployer?.residencePermitNumber?.length > 0 || selectedEmployer?.hasResidentPermit == true) ? 'Oui' : 'Non')

      setresidencePermitExpDate(selectedEmployer?.residencePermitExpDate)
      setBirthDat(selectedEmployer?.birthDate)
      setVisaOptDate(selectedEmployer?.visaOptDate)
      setpassportExpDate(selectedEmployer?.passportExpDate)

      setDiplonme(selectedEmployer?.diplome?.id)

      setVisaType(selectedEmployer?.residentType)
      setResidentType(selectedEmployer?.visaType)
      setEntryType(selectedEmployer?.entryType)

    } else {
      setCanEdit(true)

      // alert("rien")
      handleReset()
    }
  }, [selectedEmployer]);

  const handleCloseInside = () => {
    if (handleCloseOnly) {
      handleCloseOnly()
    } else {

      setSelectedEmployer(null)
      setSetselectedContract(null)
      setContractWithEmp(null)
      setContracts([])
      handleReset()
      handleClose()
      setCanEdit(true)
    }
  }
  const [openAddContract, setOpenAddContract] = useState(false);
  const [selectedContract, setSetselectedContract] = useState(null);

  const [loadingCh, postAxiosChange, errorMassageC, successMessageC, resultC] =
    usePostAxiosData(`employers/${selectedEmployer?.id}/change-status`, "PUT");

  const changeStatus = (status) => {

    window.confirm((EMPLOYER_STATUS[status]?.alert)) && postAxiosChange({ employeeStatus: status, userId: auth()?.user?.id })
  }

  useEffect(() => {
    if (successMessageC?.status === 200 || successMessageC?.status === 201) {
      handleCloseInside()
      get()

      toast.success(
        `${successMessageC?.message != null
          ? successMessageC?.message
          : "Etat de l'employé mis a jour avec success."
        } `,
        {
          duration: 4000,
          className: "bg-success text-white",
        }
      );
    }
  }, [successMessageC]);

  useEffect(() => {
    if (errorMassageC) {

      toast.error(
        `${errorMassageC?.message != null
          ? errorMassageC?.message
          : "Etat de l'employé mis a jour avec success."
        } `

      );
    }
  }, [errorMassageC]);

  const [lastContract, setLastContract] = useState(null);
  useEffect(() => {
    contracts && setLastContract(getLastContract(contracts))
  }, [contracts]);


  function Notif({ id }) {
    const [loadingM, errorM, modificationsC, fetcModifs] = useDataFetching(
      id ? `${config.app.api_url}/contracts/${id}/modifications` : null
    );
    // console.log("🚀 ~  `${config.app.api_url}/contracts/${id}/modifications`:", `${config.app.api_url}/contracts/${id}/modifications`)

    return modificationsC && <MuiCollapse
      title={`Historique des modifications du contract (${modificationsC?.data?.length || 0})`}
      bgColor={'rgba(150,0,0,0.1)'}
    >
      <List>
        {modificationsC?.data?.sort((a, b) => a?.editedAt < b?.editedAt)?.map((m) => {

          return (

            <ListItem disablePadding divider>
              <ListItemButton>
                <ListItemText primary={`${m?.editedBy?.firstName || ''} ${m?.editedBy?.lastName || ''} --${formatDateTime(m?.editedAt)}`} />
              </ListItemButton>

            </ListItem>
          )
        })}
      </List>
    </MuiCollapse>
  }
  return (
    <>
      <div>
        {loadingCh && <PreloaderSpinner />}

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleCloseInside}
          aria-labelledby="customized-dialog-title"
          open={open}
          TransitionComponent={Transition}
        >
          <AppBar
            sx={{ position: 'relative' }}>
            <Toolbar
              className={selectedEmployer?.status == EMPLOYER_STATUS.GONE.value ? "bg-yellow-200" :
                selectedEmployer?.status == EMPLOYER_STATUS.DELETED.value ? "bg-red-300" : "bg-gray-50"}
            >

              <Typography color={'green'} sx={{ ml: 2, flex: 1 }} variant="h6" component="div" >


                {selectedEmployer ? ` ${!canEdit ? "Informations sur l'" : "Editer l'"}   expatrié` : `Ajouter un nouvel expatrié ${fixedEnterprise ? `à ${fixedEnterprise?.socialRaison}` : ``}`}
                {selectedEmployer && <strong className=" text-center ms-2">
                  {"<<" + (selectedEmployer?.fullName || (selectedEmployer?.firstName + " " + selectedEmployer?.lastName)) + ">>"}

                </strong>}
              </Typography>

              {/* <PrintButton
                // toPDF={toPDF} 
              /> */}
              <div className="flex justify-end gap-2 pr-10">

                {selectedEmployer && <Tooltip hasArrow label={canEdit ? "Cliquez ici pour vérouiller" : "Cliquez ici pour éditer"} bg='red.200' color='black' placement="bottom">

                  {/* <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setCanEdit(!canEdit)}
                > */}
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setCanEdit(!canEdit)}

                    aria-label="close"

                  >

                    {canEdit ? <BsBan className="text-red-500" /> : <BsPencilSquare className="text-blue-500" />}

                  </IconButton>
                </Tooltip>}
                {selectedEmployer && <MMenus options={[
                  {
                    icon: <BsDoorClosed color="Warning" />,
                    label: "Employé parti",
                    handleClick: () => changeStatus('GONE')
                  },
                  {
                    icon: <DeleteForeverSharp color="danger" />,
                    label: "Supprimer",
                    handleClick: () => changeStatus('DELETED')

                  },
                  selectedEmployer?.status != EMPLOYER_STATUS.CREATED.value && {
                    icon: <RestorePageRounded color="action" />,
                    label: "Restaurer",
                    handleClick: () => changeStatus('CREATED')

                  }
                ]
                } />}
              </div>
              <CloseModalButton iconColor={"red"} handleClose={handleCloseInside} />
            </Toolbar>
          </AppBar>
          {
            loadEmp ? <FormSkeleton />
              :
              <DialogContent dividers>
                {(selectedEmployer?.status == EMPLOYER_STATUS.GONE.value || selectedEmployer?.status == EMPLOYER_STATUS.DELETED.value) &&
                  <Alert className="mb-5 rounded-lg shadow" status={EMPLOYER_STATUS.GONE.value == selectedEmployer?.status ? 'warning' : 'error'}>
                    <GoneIcon />
                    <AlertTitle>{EMPLOYER_STATUS.GONE.value == selectedEmployer?.status ? "Employé partie" : "Employé supprimée"}
                    </AlertTitle>
                    {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                  </Alert>
                }
                {selectedEmployer && lastContract && !checkIsValidContrat(lastContract) ?
                  <Alert className="mb-5 rounded-lg shadow " status={checkIsValidContractDate(lastContract) ? 'warning' : 'error'}>
                    <AlertIcon />
                    <AlertTitle>{lastContract?.permitNumber ? '' : '(Numéro de permis non foruni)'}-Permit de travail {checkIsValidContractDate(lastContract) ? 'expire le' : 'a expiré le'} : {dayjs(getExpiredPermitDate(lastContract?.permitDate)).format('DD-MM-YYYY') || '--'} </AlertTitle>

                    ( {lastContract?.permitDate ? dayjs(getExpiredPermitDate(lastContract?.permitDate)).locale('fr').fromNow() : "--"})
                  </Alert>
                  :
                  selectedEmployer && !lastContract ?
                    <Alert className="mb-5 rounded-lg shadow" status='error'>
                      <AlertIcon />
                      <AlertTitle> Pas de contrat
                      </AlertTitle>
                      {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                    </Alert> :
                    selectedEmployer && <Alert className="mb-5 rounded-lg shadow" status='success'>
                      <AlertIcon />
                      <AlertTitle>Permit de travail valide jusqu'au : {dayjs(getExpiredPermitDate(lastContract?.permitDate)).format('DD-MM-YYYY') || '--'}
                        ( Expire {lastContract?.permitDate ? dayjs(new Date()).locale('fr').to(dayjs(getExpiredPermitDate(lastContract?.permitDate)).locale('fr')) : "--"})
                      </AlertTitle>
                      {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                    </Alert>
                }
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  {/* Infors perso */}
                  <div>
                    <div>
                      <h2 className="font-bold text-green-500">
                        Informations personnelles
                      </h2>
                      <hr />
                    </div>
                    <div className="my-4 grid gap-5 md:grid-cols-2">

                      <InputField
                        disabled={!canEdit}
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Nom complet"
                        required={true}
                        // placeholder="Furel Teguimene"
                        id="fullName"
                        name="fullName"
                        type="text"
                        error={
                          errors.fullName?.message !== ""
                            ? errors.fullName?.message
                            : ""
                        }
                      />

                      {/* Sexe diplome */}
                      <MuiSelect
                        disabled={!canEdit}

                        // marginRight={5}
                        value={sexe}
                        label={"Selectionner le sexe"}
                        options={[
                          { value: '', label: 'Aucun' },
                          { value: "Masculin", label: 'Masculin' },
                          { value: "Feminin", label: 'Feminin' },
                        ]}
                        onChange={(event) => { setSexe(event.target.value) }}
                      />
                      {/* birthdate */}
                      <MuiDatePicker
                        label={"Date de naissance"}
                        value={birthDat}
                        disabled={!canEdit}
                        fullWidth={true}
                        onChange={(newValue) => setBirthDat(new Date(newValue)?.getTime())}

                        wrapperClasses={"flex-1 filter-item anime  w-full slideLeft "}

                      />


                      {/* Select diplome */}
                      <MuiSelect
                        disabled={!canEdit}

                        // marginRight={5}
                        value={diplome}
                        label={"Selectionner le diplome"}

                        options={diplomes?.data}
                        onChange={(event) => { setDiplonme(event.target.value) }}
                        format={{ value: "id", label: 'name' }}
                        emptyLabel={"Aucun"}
                      />

                      {/* Select nationalities */}
                      <MuiAUtocomplete
                        disabled={!canEdit}
                        value={nationality}
                        defaultValue={nationality}
                        label={'Selectionner la nationalité'}
                        options={nationalities?.data?.map((item) => {
                          // console.log("🚀 ~ options={nationalities?.map ~ item:", item?.demonyms?.fra?.f)
                          // return { value: item?.name?.common, label: item?.demonyms?.fra?.f || item?.translations?.fra?.common }

                          return { value: item?.nameEn, label: item?.nameFr || item?.nameEn }
                        })}
                        // format={{ value: 'id', label: 'name' }}
                        // value={params?.regionId}
                        // defaultFormat={true}
                        onChange={(e, item) => {
                          setNationality(item.value)
                        }}

                        wrapperClasses={"flex-1 filter-item anime slideRight"}
                      />


                      {/* <div>
                    <label
                      htmlFor={"categorie"}
                      className={`ml-3 text-sm font-medium  text-navy-700 dark:text-white`}
                    >
                      Type de titre d'entré
                    </label>
                    <select
                      disabled={!canEdit}

                      onChange={(e) => setEntryType(e.target.value)}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
                      name="visaType"
                      id="visaType"
                      // defaultValue={"0"}
                      value={entryType}
                    >
                      <option value="">Selectionner le titre d'entré  </option>
                      <option value="0">Visa court séjour</option>
                      <option value="1">Visa Long séjour</option>
                      <option value="2">Carte séjour</option>
                      <option value="3">Carte de résident</option>
                    </select>
                  </div> */}
                      <MuiSelect
                        disabled={!canEdit}

                        // marginRight={5}
                        value={entryType}
                        label={"Type de titre d'entré"}

                        options={[
                          { value: '0', label: 'Visa court séjour' },
                          { value: '1', label: 'Visa Long séjour' },
                          { value: '2', label: 'Carte séjour' },
                          { value: '3', label: 'Carte de résident' },
                        ]}
                        onChange={(event) => { setEntryType(event.target.value) }}
                        // format={{ value: "id", label: 'name' }}
                        emptyLabel={"Aucun"}
                      />
                    </div>
                  </div>



                  {/* Informations sur le visa */}
                  <div>
                    <div>
                      <h2 className="font-bold text-green-500">
                        Informations sur le Visa
                      </h2>
                      <hr />
                    </div>
                    <MuiSelect
                      disabled={!canEdit}
                      // marginRight={5}
                      value={hasVisa}
                      label={"As t'il un visa ?"}
                      options={[
                        { value: 'Oui', label: 'Oui' },
                        { value: 'Non', label: 'Non' },
                      ]}
                      onChange={(event) => { setHasVisa(event.target.value) }}
                      // format={{ value: "id", label: 'name' }}
                      emptyLabel={"Aucun"}
                    />
                    {hasVisa == "Oui" &&
                      <div className="my-4 grid gap-2 md:grid-cols-2  anime slideUp">


                        <MuiSelect
                          disabled={!canEdit}
                          // marginRight={5}
                          value={visaType}
                          label={"Type de visa"}
                          options={[
                            { value: '0', label: 'Court séjour' },
                            { value: '1', label: 'Long séjour' },
                          ]}
                          onChange={(event) => { setVisaType(event.target.value) }}
                          // format={{ value: "id", label: 'name' }}
                          emptyLabel={"Aucun"}
                        />

                        <InputField
                          disabled={!canEdit}
                          register={register}
                          variant="auth"
                          extra="mb-3"
                          label="Numéro de visa"
                          // required={true}
                          // placeholder="DJDJHD5454KKKJKJ"
                          id="sej"
                          name="visaNumber"
                          type="text"
                          error={
                            errors.visaNumber?.message !== ""
                              ? errors.visaNumber?.message
                              : ""
                          }
                        />

                        <MuiDatePicker
                          label={"Date d'obtention du visa"}
                          value={visaOptDate}
                          disabled={!canEdit}
                          fullWidth={true}
                          onChange={(newValue) => setVisaOptDate(new Date(newValue)?.getTime())}
                          wrapperClasses={"flex-1 filter-item anime  w-full slideLeft "}
                        />
                      </div>

                    }

                  </div>
                  {/* Informations sur le Sejour */}
                  <div>
                    <div>
                      <h2 className="font-bold text-green-500">
                        Informations sur le Séjour
                      </h2>
                      <hr />
                    </div>
                    <MuiSelect
                      disabled={!canEdit}
                      // marginRight={5}
                      value={hasResidentPermit}
                      label={"As t'il une carte de séjour ?"}
                      options={[
                        { value: 'Oui', label: 'Oui' },
                        { value: 'Non', label: 'Non' },
                      ]}
                      onChange={(event) => { sethasResidentPermit(event.target.value) }}
                      // format={{ value: "id", label: 'name' }}
                      emptyLabel={"Aucun"}
                    />

                    {hasResidentPermit == "Oui" && <div className="my-4 grid gap-2 md:grid-cols-2 anime slideUp">

                      <MuiSelect
                        disabled={!canEdit}
                        // marginRight={5}
                        value={residentType}
                        label={" Type de titre de séjour"}
                        options={[
                          { value: '0', label: 'Court séjour' },
                          { value: '1', label: 'Résident' },
                        ]}
                        onChange={(event) => { setResidentType(event.target.value) }}
                        // format={{ value: "id", label: 'name' }}
                        emptyLabel={"Aucun"}
                      />

                      <InputField
                        disabled={!canEdit}
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Numéro de carte de séjour"
                        // required={true}
                        // placeholder="DJDJHD5454KKKJKJ"
                        id="residencePermitNumber"
                        name="residencePermitNumber"
                        type="text"
                        error={
                          errors.residencePermitNumber?.message !== ""
                            ? errors.residencePermitNumber?.message
                            : ""
                        }
                      />

                      <MuiDatePicker
                        label={"Date d'expiration de la carte de sejour"}
                        value={residencePermitExpDate}
                        disabled={!canEdit}
                        fullWidth={true}
                        onChange={(newValue) => setresidencePermitExpDate(new Date(newValue)?.getTime())}
                        wrapperClasses={"flex-1 filter-item anime  w-full slideLeft "}
                      />


                    </div>
                    }

                  </div>
                  {/* Informations sur le passport */}
                  <div>
                    <h2 className="font-bold text-green-500">
                      Informations sur le passeport
                    </h2>
                    <hr />
                    <div className="my-4 grid gap-2 md:grid-cols-2 ">

                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Numéro du passport"
                        // required={true}
                        // placeholder="SGFSHSJHJ522SS"
                        id="passportNumber"
                        name="passportNumber"
                        type="text"
                        error={
                          errors.passportNumber?.message !== ""
                            ? errors.passportNumber?.message
                            : ""
                        }
                      />

                      <MuiDatePicker
                        label={" Date d'expiration du passport"}
                        value={passportExpDate}
                        disabled={!canEdit}
                        fullWidth={true}
                        onChange={(newValue) => setpassportExpDate(new Date(newValue)?.getTime())}
                        wrapperClasses={"flex-1 filter-item anime  w-full slideLeft "}
                      />

                    </div>
                  </div>

                  <Paper className="p-3" >

                    <h1 className="font-bold text-green-500 text-xl">
                      Historique des contracts
                      <Badge
                        fontSize={15}
                        rounded={5}
                        padding={1}
                        paddingInline={2}
                        colorScheme="orange"
                        color={"black"}
                        marginLeft={2}
                      >{contracts?.length}</Badge>

                    </h1>
                    <hr />
                    {!fixedEnterprise && <>
                      {/* <div className="mb-3">
                    <label
                      htmlFor={"occupation"}
                      className={`ml-3 text-sm font-medium  text-navy-700 dark:text-white`}
                    >
                      Entrepise
                    </label>
                    <select
                      disabled={!canEdit}

                      onChange={(e) => setEnterprise(e.target.value)}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
                      name="entreprise"
                      id="entreprise"
                    >
                      <option value="">Selectionner l'entreprise</option>
                      {enterprises?.data &&
                        enterprises.data.map((item) => {
                          return (
                            <option
                              selected={enterprise == item?.id}
                              value={item?.id}>
                              {item?.socialRaison}
                            </option>
                          );
                        })}
                    </select>
                  </div> */}
                      <MuiAUtocomplete
                        disabled={!canEdit}
                        value={enterprise}
                        label={'Entrepise'}
                        options={enterprises?.data}
                        format={{ value: 'id', label: 'socialRaison' }}
                        // value={params?.regionId}
                        // defaultFormat={true}
                        onChange={(e, item) => setEnterprise(item?.value)}
                        wrapperClasses={"flex-1 filter-item anime slideRight mb-3"}
                      />
                      {(enterprise == "" || enterprise == null || enterprise == undefined)
                        ? <div className="anime slideRight mb-3" >
                          <InputField
                            disabled={!canEdit}
                            register={register}
                            variant="auth"
                            extra="mb-3"
                            label="NIU (Si vous ne souhaitez pas selectionner l'entreprise)"
                            // required={true}
                            // placeholder="FFGJKE4545FS"
                            id="nui"
                            name="nui"
                            type="text"
                            error={
                              errors.nui?.message !== ""
                                ? errors.nui?.message
                                : ""
                            }
                          />
                        </div>
                        :
                        <></>
                      }
                    </>}
                    {(contracts || (contractWithEmp ? Array(contractWithEmp) : []))?.map((contract, index) => {
                      // console.log("🚀 ~ {contracts?.map ~ contract:", contract)
                      return <MuiCollapse


                        title={<>{`${(contracts?.length - index)}${(contracts?.length - index) > 1 ? 'ième' : 'er'}-Date d'expiration du permis : ${contract?.permitDate ? formatDate(getExpiredPermitDate(contract?.permitDate)) : '--'}`}
                        </>}
                        subTitle={
                          <div className="flex gap-4 justify-between ">
                            {!checkIsValidContractDate(contract) ?
                              <Chip color='error' className="capitalize-none" label={capitalize("Expiré")} /> :
                              contract?.permitNumber
                                ?
                                <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                                <Chip className="capitalize-none" variant="outlined" color='warning' label="A vérifier" />
                            }


                            {/* {contract?.eWorkStatus ? EWORK_DEMANDE_STATUS[contract?.eWorkStatus] ?
                          <Chip color='success' className="capitalize-none" label={capitalize(EWORK_DEMANDE_STATUS[contract?.eWorkStatus]?.displayLabel)} /> :

                          <Chip className="capitalize-none" variant="outlined" color='success' label={capitalize(EWORK_DEMANDE_STATUS[contract?.eWorkStatus]?.displayLabel)} />
                          :
                          <Chip className="capitalize-none" variant="outlined" color='warning' label="Non renseigne" />
                        } */}
                            <IconButton title="Editer ce contrat"><BsPencilSquare color="blue"
                              onClick={(e) => {
                                e?.preventDefault(); e?.stopPropagation()
                                setSetselectedContract(contract)
                                setOpenAddContract(true)
                              }} /></IconButton>

                          </div>

                        }
                        index={index}>
                        {/* infos sur le contrat */}
                        <div>
                          <div className="flex justify-between">
                            <h2 className="font-bold text-green-500">
                              Informations sur le contrat
                            </h2>
                            {/* <BsPl<h2 className="font-bold text-green-500">
                  Informations sur le contrat
                </h2>usCircleFill color="green" size={30} /> */}
                          </div>
                          <hr />
                          {contract && !checkIsValidContrat(contract) ?
                            <Alert className="mb-5 rounded-lg shadow " status='error'>
                              <AlertIcon />
                              <AlertTitle>{contract?.permitNumber ? '' : '(Numéro de permis non foruni)'}-Permit de travail {checkIsValidPermitDate(contract) ? 'expire le' : 'expiré le'} : {dayjs(getExpiredPermitDate(contract?.permitDate)).format('DD-MM-YYYY') || '--'} </AlertTitle>

                              ( {contract?.permitDate ? dayjs(getExpiredPermitDate(contract?.permitDate)).locale('fr').fromNow() : "--"})
                            </Alert>
                            :
                            contract && <Alert className="mb-5 rounded-lg shadow" status='success'>
                              <AlertIcon />
                              <AlertTitle>Permit de travail valide jusqu'au : {dayjs(getExpiredPermitDate(contract?.permitDate)).format('DD-MM-YYYY') || '--'}
                                ( Expire {contract?.permitDate ? dayjs(getExpiredPermitDate(contract?.permitDate)).locale('fr').fromNow() : "--"})
                              </AlertTitle>
                              {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                            </Alert>
                          }
                          <div className="my-4 grid gap-2 md:grid-cols-2">
                            {/* Select enterprises */}
                            {/* Select occupations */}

                            <MuiAUtocomplete
                              disabled={true}
                              value={contract?.occupation?.id || contract?.occupationId}
                              label={'Selectionner le poste'}
                              options={occupations?.data}
                              format={{ value: 'id', label: 'name' }}
                              // value={params?.regionId}
                              // defaultFormat={true}
                              // onChange={(e, item) => setOccupation(item.value)}
                              wrapperClasses={"flex-1 filter-item anime slideRight"}
                            />
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                value={contract?.contratNumber}
                                variant="auth"
                                extra="mb-3"
                                label="Numéro de contrat"
                                // required={true}
                                // placeholder="FFGJKE4545FS"
                                id="contratNumber"
                                name="contratNumber"
                                type="text"
                                error={
                                  errors.contratNumber?.message !== ""
                                    ? errors.contratNumber?.message
                                    : ""
                                }
                              />
                            </div>
                            {/* Select contract duration */}


                            <InputField
                              disabled={true}
                              // register={register}
                              value={contract?.contratDuration}
                              variant="auth"
                              extra="mb-3"
                              label="Durée du contrat"
                              // required={true}
                              // placeholder="FFGJKE4545FS"
                              id="contratNumber"
                              name="contratNumber"
                              type="text"

                            />

                          </div>
                        </div>

                        {/* infos sur le salaire */}
                        <div>
                          <div>
                            <h2 className="font-bold text-green-500">
                              Informations sur le salaire
                            </h2>
                            <hr />
                          </div>
                          <div className="my-4 grid gap-2 md:grid-cols-6">
                            {/* Select categories */}

                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                value={contract?.salary}

                                variant="auth"
                                extra="mb-3"
                                label="Salaire de Base (XAF)"
                                // required={true}
                                defaultValue={"0"}
                                // placeholder="100000000"
                                id="salary"
                                name="salary"
                                type="number"
                                error={
                                  errors.salary?.message !== ""
                                    ? errors.salary?.message
                                    : ""
                                }
                                min="0"
                              />
                            </div>
                            <div>
                              <InputField
                                min="0"
                                disabled={true}
                                // register={register}
                                value={contract?.indTransport}
                                variant="auth"
                                defaultValue={"0"}
                                extra="mb-3"
                                label="indemnite de transport"
                                required={true}
                                // placeholder="20000000"
                                id="indTransport"
                                name="indTransport"
                                type="number"
                                error={
                                  errors.indTransport?.message !== ""
                                    ? errors.indTransport?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                min="0"
                                disabled={true}
                                // register={register}
                                value={contract?.indLogement}
                                variant="auth"
                                defaultValue={"0"}
                                extra="mb-3"
                                label="indemnite de logement"
                                required={true}
                                // placeholder="20000000"
                                id="indLogement"
                                name="indLogement"
                                type="number"
                                error={
                                  errors.indLogement?.message !== ""
                                    ? errors.indLogement?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="prime de responsabilite"
                                // required={true}
                                // placeholder="1000000"
                                id="indResponsabilite"
                                name="indResponsabilite"
                                value={contract?.indResponsabilite}

                                type="number"
                                error={
                                  errors.indResponsabilite?.message !== ""
                                    ? errors.indResponsabilite?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                variant="auth"
                                defaultValue={"0"}
                                extra="mb-3"
                                label="prime de representation"
                                // required={true}
                                // placeholder="105545"
                                id="indRepresentation"
                                name="indRepresentation"
                                value={contract?.indRepresentation}

                                type="number"
                                error={
                                  errors.indRepresentation?.message !== ""
                                    ? errors.indRepresentation?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="prime d'expatriation"
                                // required={true}
                                // placeholder="500000"
                                id="primeExploitation"
                                name="primeExploitation"
                                value={contract?.primeExploitation}

                                type="number"
                                error={
                                  errors.primeExploitation?.message !== ""
                                    ? errors.primeExploitation?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="Sursalaire"
                                // required={true}
                                // placeholder="125066"
                                id="sursalaire"
                                name="sursalaire"
                                value={contract?.sursalaire}

                                type="number"
                                error={
                                  errors.sursalaire?.message !== ""
                                    ? errors.sursalaire?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="prime de salissure"
                                // required={true}
                                // placeholder="125066"
                                id="primeSalissure"
                                name="primeSalissure"
                                value={contract?.primeSalissure}

                                type="number"
                                error={
                                  errors.primeSalissure?.message !== ""
                                    ? errors.primeSalissure?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="indenmite de caisse"
                                // required={true}
                                // placeholder="125066"
                                id="indCaisse"
                                name="indCaisse"
                                value={contract?.indCaisse}

                                type="number"
                                error={
                                  errors.indCaisse?.message !== ""
                                    ? errors.indCaisse?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="Bonus"
                                // required={true}
                                // placeholder="125066"
                                id="bonus"
                                name="bonus"
                                value={contract?.bonus}
                                type="number"
                                error={
                                  errors.bonus?.message !== ""
                                    ? errors.bonus?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="prime de panier"
                                // required={true}
                                // placeholder="125066"
                                id="primePanier"
                                name="primePanier"
                                value={contract?.primePanier}

                                type="number"
                                error={
                                  errors.primePanier?.message !== ""
                                    ? errors.primePanier?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="billet d'avion"
                                // required={true}
                                // placeholder="125066"
                                id="billetAvion"
                                name="billetAvion"
                                value={contract?.billetAvion}

                                type="number"
                                error={
                                  errors.bonus?.message !== ""
                                    ? errors.bonus?.message
                                    : ""
                                }
                              />
                            </div>
                            <div>
                              <InputField
                                disabled={true}
                                // register={register}
                                defaultValue={"0"}
                                variant="auth"
                                extra="mb-3"
                                label="Autres primes"
                                // required={true}
                                // placeholder="125066"
                                id="autresPrimes"
                                name="autresPrimes"
                                value={contract?.autresPrimes}

                                type="number"
                                error={
                                  errors.autresPrimes?.message !== ""
                                    ? errors.autresPrimes?.message
                                    : ""
                                }
                              />
                            </div>

                          </div>
                          <div>
                            <div>
                              <h2 className="font-bold text-green-500">
                                Informations sur le permis de travail
                              </h2>
                              <hr />
                            </div>


                            <div className="my-4 grid gap-2 md:grid-cols-2 anime slideUp ">

                              <div>
                                <InputField
                                  disabled={true}
                                  // register={register}
                                  variant="auth"
                                  extra="mb-3"
                                  label="Numéro de permis"
                                  // required={true}
                                  // placeholder="GJHJSDK65656JJJ"
                                  id="permitNumber"
                                  name="permitNumber"
                                  value={contract?.permitNumber}

                                  type="text"
                                  error={
                                    errors.permitNumber?.message !== ""
                                      ? errors.permitNumber?.message
                                      : ""
                                  }
                                />
                              </div>



                              <InputField
                                disabled={true}
                                // register={register}
                                value={contract?.permitDate ? formatDate(contract?.permitDate) : ''}

                                // value={contract?.contratDuration}
                                variant="auth"
                                extra="mb-3"
                                label="Date de signature du permis de travail"
                                // required={true}
                                // placeholder="FFGJKE4545FS"
                                id="contratNumber"
                                name="contratNumber"
                                type="text"

                              />

                            </div>

                          </div>
                        </div>
                        {contract && <Badge>{`Collecté par : ${contract?.creator ? ` ${contract?.creator?.firstName} ${contract?.creator?.lastName}` : '--'}`}</Badge>}
                        {contract && <Badge color={'black'} backgroundColor={'orange'} >{` ${contract ? ` ${formatDateTime(contract?.createdAt)}` : '--'}`}</Badge>}

                        <Notif id={contract?.id} />

                      </MuiCollapse>
                    })

                    }

                    {(selectedEmployer || !contractWithEmp) && <div className='flex justify-end my-2'>
                      <MButton
                        disabled={!canEdit}

                        variant="white" onClick={(e) => {
                          e?.preventDefault()
                          e?.stopPropagation()
                          setOpenAddContract(true)
                        }} > <BsPlusCircleFill size={20} /> Ajouter un nouveau contract</MButton>
                    </div>}
                  </Paper>
                  {selectedEmployer && <Badge>{`Collecté par : ${selectedEmployer?.creator ? ` ${selectedEmployer?.creator?.firstName} ${selectedEmployer?.creator?.lastName} ` : '--'}`}</Badge>}
                  {selectedEmployer && <Badge color={'black'} backgroundColor={'orange'} >{` ${selectedEmployer ? ` ${formatDateTime(selectedEmployer?.createdAt)}` : '--'}`}</Badge>}


                  {selectedEmployer && <MuiCollapse
                    title={`Historique des modifications (${modifications?.data?.length || 0})`}
                    bgColor={'rgba(150,0,0,0.1)'}
                  >
                    <List>
                      {modifications?.data?.sort((a, b) => a?.editedAt < b?.editedAt)?.map((m) => {

                        return (

                          <ListItem disablePadding divider>
                            <ListItemButton>
                              <ListItemText primary={`${m?.editedBy?.firstName || ''} ${m?.editedBy?.lastName || ''} --${formatDateTime(m?.editedAt)}`}
                                secondary={m?.description}

                              />
                            </ListItemButton>

                          </ListItem>
                        )
                      })}
                    </List>
                  </MuiCollapse>}
                  <div className="mt-2">
                    <InputField
                      // sx={{Height : 300}}
                      disabled={!canEdit}
                      multiline={true}
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Observations"
                      required={false}
                      // placeholder={selectedEnterprise ? "" : "12.3"}
                      id="observation"
                      name="observation"
                      type="text"
                      error={
                        errors.observation?.message !== ""
                          ? errors.observation?.message
                          : ""
                      }
                    />
                  </div>

                  <div className="flex justify-between p-4">
                    <div>
                      <MButton
                        variant={'danger'}
                        onClick={(e) => {
                          e?.stopPropagation()
                          e?.preventDefault()
                          handleCloseInside();
                          // get();
                        }}
                      >
                        Fermer
                      </MButton>
                    </div>
                    <div>
                      {((selectedEmployer && canEdit) || (selectedEmployer == null || selectedEmployer == undefined)) && <MButton type="submit" colorScheme="green" isLoading={loading}

                      >
                        {selectedEmployer ? "Enregistrer les Modifications" : "Sauvegarder"}
                      </MButton>}
                    </div>
                  </div>
                </form>
              </DialogContent>}
        </Dialog>
      </div>
      {/* {(selectedEmployer || contractWithEmp) && <AddContract contract={contractWithEmp} setContract={setContractWithEmp} get={() => { fetchDataEmp(); get() }} open={openAddContract} handleClose={() => { setSetselectedContract(null); setOpenAddContract(false) }} selectedEmployer={selectedEmployer} selectedContract={selectedContract} />} */}
      {<AddContract contract={contractWithEmp} setContract={setContractWithEmp} get={() => { fetchDataEmp();; get() }} open={openAddContract} handleClose={() => { setSetselectedContract(null); setOpenAddContract(false) }} selectedEmployer={selectedEmployer} selectedContract={selectedContract} />}
    </>

  );
};

export default AddEmployer;
