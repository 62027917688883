import { DeletedIcon } from "components/TableIcon";
import { GoneIcon } from "components/TableIcon";
import { ClosedIcon } from "components/TableIcon";
import useDataFetching from "hooks/useDataFetching";
import { formatDate } from "utils";
import { config } from "variables/config";

export const ROLESF = () => {
  const [loadingR, errorR, roles, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles`
  );
  let rolesArray = [];
  roles?.data &&
    roles?.data?.map((role) => {
      rolesArray.push(role?.name);
    });
  return rolesArray;
};

export const useGetRoles = () => {
  const [loadingR, errorR, roles, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles`
  );
  let rolesArray = [];
  roles?.data &&
    roles?.data?.map((role) => {
      rolesArray.push(role?.name);
    });
  return rolesArray;
};
export const ROLES = ["AGENT", "DGSN", "ADMIN", "MINREX", 'QUALITY'];

export const PERMISSIONS = [
  "Filtres et Affichages",
  "Imprimer toutes les statistiques",
  "Voir une entreprise sur google map",
  "Consulter une entreprise",
  "Consulter travailleur",
  "Voir map",
  "Ajouter un agent",
  "Ajouter un employer",
  "Ajouter une entreprise",
  "Modifier les informations d'un agent",
  "Ajouter un compte",
  "Modifier les informations d'un utilisateur",
  "Ajouter un rôle",
  "Attribuer des permissions à un rôle",
  "Modifier les informations d'un rôle",
  "Voir menu statistiques",
  "Voir menu entreprise",
  "Voir liste des employes",
  "Voir liste regions",
  "Voir menu comptes agents",
  "Voir menu gestion des comptes",
  "Entreprises enregistrées par un agent",
  "Employés enregistrées par un agent",
  "Voir menu gestion des roles",
  "Voir menu employé"
];

export function CHECK_PERMISSIONS(permissions, key) {
  // console.log("🚀 ~ CHECK_PERMISSIONS ~ permissions:", key?.length)
  let permArray = [];
  if (key == null || key == undefined) { return true }
  else {

    permissions?.length > 0 &&
      permissions?.map((perm) => {
        // console.log("permname", perm.name, "key", key)
        permArray.push(perm?.name);
      });
    if (permArray.includes(key)) {
      return true;
    }
    return false;
  }
}

export function CHECK_ROLES_PERMISSIONS(roles, key) {
  // console.log("🚀 ~ CHECK_PERMISSIONS ~ permissions:", key?.length)
  let res = false
  roles?.forEach(role => {
    if (CHECK_PERMISSIONS(role?.permissions, key)) {
      res = true
    }
  });
  return res;
}




export const ENTERPRISE_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Raison', label: 'Raison sociale', minWidth: 300 },

  {
    id: "Secteur",
    label: "Secteur(s) d'activité(s)",
    minWidth: 20,
    maxWidth: 20
  },
  {
    id: 'Arrondissement',
    label: 'Arrondissement',
    minWidth: 20,
    maxWidth: 50,

    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Expatriés',
    label: "Nombre d'expatriés",
    minWidth: 20,
    maxWidth: 50,

    align: 'right',

    // format: (value) => value?.toLowerCase(),
  },
  {
    id: "Coordonnées",
    label: "Localisation",
    minWidth: 10,
    align: 'center',

  },
  // {
  //   id: 'Status',
  //   label: 'Status',
  //   minWidth: 70,

  // },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 10,
    align: 'center',
  },
];


export const ENTERPRISE_REQUEST_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Raison', label: 'Raison sociale', minWidth: 150 },

  {
    id: "Niu",
    label: "NIU",
    minWidth: 20,
    maxWidth: 20
  },
  {
    id: 'Motif',
    label: 'Motif',
    minWidth: 200,
    // maxWidth : 150,
  },
  {
    id: 'Type',
    label: "Type de requete",
    minWidth: 20,
    maxWidth: 50,

    // align: 'right',

    // format: (value) => value?.toLowerCase(),
  },
  {
    id: "Date",
    label: "Date de soumission",
    minWidth: 10,
    maxWidth: 50,

    align: 'center',
    format: (value) => formatDate(value),

  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 70,

  },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 10,
    align: 'center',
  },
];
export const EMPLOYER_HEADER = [
  { id: ' ', label: ' ', minWidth: 20 },
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Noms', label: 'Noms', minWidth: 300 },

  {
    id: 'Poste',
    label: 'Poste',
    minWidth: 20,
    maxWidth: 50,
  },
  {
    id: 'entreprise',
    label: 'Entreprise',
    minWidth: 30,
    maxWidth: 50,

  },
  {
    id: 'Salaire',
    label: 'Salaire',
    minWidth: 100,
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Nationalité',
    label: 'Nationalité',
    minWidth: 100,
    format: (value) => value?.toLowerCase(),
  },
  {
    id: "Date",
    label: "Date d'expiriration du permis",
    minWidth: 100,
  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 70,
    align: 'center',

  },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 70,
    align: 'center',
  },
];
export const EMLOYEE_REQUEST_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Noms', label: 'Noms', minWidth: 150 },
  {
    id: "Nationality",
    label: "Nationalite",
    minWidth: 20,
    maxWidth: 20
  },
  {
    id: 'Motif',
    label: 'Motif',
    minWidth: 100,
    // maxWidth : 150,
  },
  {
    id: 'Type',
    label: "Type de requete",
    minWidth: 20,
    maxWidth: 50,

    // align: 'right',

    // format: (value) => value?.toLowerCase(),
  },
  {
    id: "Date",
    label: "Date de soumission",
    minWidth: 10,
    maxWidth: 50,

    align: 'center',
    format: (value) => formatDate(value),

  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 70,

  },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 10,
    align: 'center',
  },
];


export const REPORT_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Noms', label: 'Noms', minWidth: 50 },
  { id: 'Date', label: 'Date', minWidth: 50 },

  {
    id: 'Title',
    label: 'Title',
    minWidth: 20,
  },
  // {
  //   id: 'Issues',
  //   label: 'Difficultes',
  //   minWidth: 100,
  //   maxWidth : 300,

  // },
  {
    id: 'SubmitDate',
    label: 'Date de soumission',
    minWidth: 100,
    maxWidth: 300,

  },
  {
    id: 'Rating',
    label: 'Rating',
    maxWidth: 100,

  },
  {
    id: 'Valide',
    label: 'Status',
    minWidth: 100,
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'Options',
    label: 'Options',
    minWidth: 70,
    align: 'center',
  },
];


export const CONTRACTS_HEADER = [
  { id: ' ', label: ' ', minWidth: 20 },
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Noms', label: 'Noms', minWidth: 300 },

  {
    id: 'Poste',
    label: 'Poste',
    minWidth: 20,
    maxWidth: 50,
  },
  {
    id: 'entreprise',
    label: 'Entreprise',
    minWidth: 30,
    maxWidth: 50,

  },
  {
    id: 'Salaire',
    label: 'Salaire',
    minWidth: 100,
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Nationalité',
    label: 'Nationalité',
    minWidth: 100,
    format: (value) => value?.toLowerCase(),
  },
  {
    id: "Date",
    label: "Date d'expiriration du permis",
    minWidth: 100,
  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 70,
    align: 'center',

  },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 70,
    align: 'center',
  },
];

export const CONTRACT_REQUEST_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Noms', label: 'Noms', minWidth: 150 },
  {
    id: "Nationality",
    label: "Nationalite",
    minWidth: 20,
    maxWidth: 20
  },
  {
    id: "Salary",
    label: "Salaire",
    minWidth: 20,
    maxWidth: 20
  },
  {
    id: 'Motif',
    label: 'Motif',
    minWidth: 100,
    // maxWidth : 150,
  },
  {
    id: 'Type',
    label: "Type de requete",
    minWidth: 20,
    maxWidth: 50,

    // align: 'right',

    // format: (value) => value?.toLowerCase(),
  },
  {
    id: "Date",
    label: "Date de soumission",
    minWidth: 10,
    maxWidth: 50,

    align: 'center',
    format: (value) => formatDate(value),

  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 70,

  },
  {
    id: 'Options',
    label: 'Options',
    minWidth: 10,
    align: 'center',
  },
];

export const ENTRY_TYPE = {
  "SHORT_VISA": "Visa court séjour",
  "LONG_VISA": "Visa Long séjour",
  "SEJOUR_CARD": "Carte séjour",
  "RESIDENT_CARD": "Carte de résident"
}

export const ZONES = [
  { value: 1, label: 'Zone 1' },
  { value: 2, label: 'Zone 2' },
  { value: 3, label: 'Zone 3' },
  { value: 4, label: 'Zone 4' },
]

export const MISSION_HEADER = [
  { id: ' ', label: ' ', minWidth: 20 },
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'description', label: 'Description', minWidth: 200 },
  // {
  //   id: 'Sexe',
  //   label: 'Sexe',
  //   minWidth: 50,
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'debut',
    label: 'Debut',
    minWidth: 120,
  },
  {
    id: 'fin',
    label: 'Fin',
    minWidth: 120,
  },
  {
    id: 'region',
    label: 'Region',
    minWidth: 120,
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 120,
  },
]


export const TASK_HEADER = [
  { id: ' ', label: ' ', minWidth: 20 },
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'description', label: 'Description', minWidth: 200 },
  // {
  //   id: 'Sexe',
  //   label: 'Sexe',
  //   minWidth: 50,
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: 'debut',
    label: 'Debut',
    minWidth: 120,
  },
  {
    id: 'fin',
    label: 'Fin',
    minWidth: 120,
  },
  {
    id: 'region',
    label: 'Region',
    minWidth: 120,
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 120,
  },
  {
    id: 'arrondissement',
    label: 'Arrondissement',
    minWidth: 120,
  },
  {
    id: 'zone',
    label: 'Zone',
    minWidth: 120,
  },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 120,
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 120,
  },
]
export const ZONE_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'Zone', label: 'Zone', minWidth: 20 },
  { id: 'description', label: 'Description', minWidth: 200 },
  {
    id: 'departement',
    label: 'Departement',
    minWidth: 120,
  },
  {
    id: 'region',
    label: 'Region',
    minWidth: 120,
  },

  {
    id: 'actions',
    label: 'Actions',
    minWidth: 120,
  },
]

export const AGENT_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'noms', label: 'Non complet', minWidth: 200 },
  { id: 'phone', label: 'Telephone', minWidth: 100 },
  {
    id: 'department',
    label: 'Entreprises enregistrées',
    minWidth: 120,
  },
  {
    id: 'employees',
    label: 'Travailleur enregistrés',
    minWidth: 120,
  },

  {
    id: 'Options',
    label: 'Actions',
    minWidth: 120,
  },
]


export const ACCOUNT_HEADER = [
  { id: 'N', label: '#', minWidth: 20 },
  { id: 'FirstName', label: 'Prenoms', minWidth: 200 },
  { id: 'LastName', label: 'Nom', minWidth: 200 },
  {
    id: 'Email',
    label: 'Email',
    minWidth: 120,
  },
  {
    id: 'Phone',
    label: 'Telephone',
    minWidth: 120,
  },
  {
    id: 'Enable',
    label: 'Status',
    minWidth: 120,
  },
  {
    id: 'Options',
    label: 'Actions',
    minWidth: 120,
  },
]


export const ENTERPRISE_STATUS = {
  CREATED: {
    value: "CREATED", label: "Created",

    alert: 'Etes vous Sur de vouloir restaurer cette entrprises ? Cela impliquera egalement la restauration de ses employées'
  },
  CLOSED: {
    value: "CLOSED", label: "Closed",
    alert: 'Etes vous Sur de vouloir marquer cette entrprise comme fermer ?',
    icon: <ClosedIcon />

  },
  DELETED: {
    value: "DELETED", label: "Deleted",
    icon: <DeletedIcon />,
    alert: 'Etes vous Sur de vouloir supprimer cette entrprise ? Cela impliquera egalement la suppression de ses employées'

  },
};

export const EMPLOYER_STATUS = {
  CREATED: {
    value: "CREATED", label: "Created",

    alert: 'Etes vous Sur de vouloir restaurer cette employe ? '
  },
  GONE: {
    value: "GONE", label: "Parti",
    alert: 'Etes vous Sur de vouloir marquer cette entrprise comme parti ?',
    icon: <GoneIcon />

  },
  DELETED: {
    value: "DELETED", label: "Supprimer",

    alert: 'Etes vous Sur de vouloir supprimer cet expatrié ?',
    icon: <DeletedIcon />


  },
};


export const EWORK_DEMANDE_STATUS = {
  RQ_SUBMITTED: {
    label: "Submitted",
    value: 0,
    displayLabel: "Submitted",
  },
  RQ_UNDER_PROCESS: {
    label: "Under Process",
    value: 1,
    displayLabel: "Under Process",
  },
  // ... other enum members
  RQ_VERIFIED: {
    label: "Verified",
    value: 9,
    displayLabel: "Verified",
  },
  RQ_INCOMPLETED: {
    label: "Incomplete",
    value: 10,
    displayLabel: "Incomplete",
  },
};


export const NOTIFICATION_SCOPE = {
  "USER": "USER",
  "REGION": "REGION",
  "GLOBAL": "GLOBAL",
}




export const  REQUEST_TYPE = {
  'CREATE': { value: 'CREATE', label: 'creation' },
  'UPDATE': { value: 'UPDATE', label: 'mise a jour' },
  'DELETE': { value: 'DELETE', label: 'suppression' },
}