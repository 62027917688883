const { useParams, useNavigate } = require("react-router-dom")
const { default: AddEnterprise } = require("./AddEnterprise")

const AddEnterprisePage = ()=>{

const {id}= useParams()
const navigate = useNavigate()

    return <AddEnterprise open={true} selectedEnterpriseP={{id : id}} handleClose={()=>navigate(-1)} get={()=>null}/>
}


export default AddEnterprisePage