import { Tooltip } from "@chakra-ui/tooltip";
import MButton from "components/Mbutton";
import { BsFiletypeXls } from "react-icons/bs";
import { handleDownload } from "utils";

export default function PrintButtonExcel({ filename, header, body, text, bg, color }) {
  return (
    <>
      <Tooltip hasArrow label={"Exporter au format excel"} bg='red.200' color='black' placement="bottom">
        <MButton
          variant={"secondary"}
          // className={`flex items-center  gap-2 rounded-md p-1 shadow bg-yellow-200`}
          onClick={() => handleDownload({ filename: filename, header: header, body: body })}
        >
          <BsFiletypeXls className="text-secondary" /> {text}
        </MButton>
      </Tooltip>
    </>
  )
}