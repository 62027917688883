import Reports from "./Reports";
import StatsCard from "./StatsCard";

const Activities  =()=>{


    return(
        <div>
            <StatsCard/>

            <Reports compact={true} />
        </div>
    )

}

export default Activities;