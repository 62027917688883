import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";

import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Progress from "components/progress";
import { capitalyse } from "utils";
import { usePDF } from "react-to-pdf";
import { BsFilePdf } from "react-icons/bs";
import PrintButton from "views/admin/default/components/PrintButton";
import PrintButtonExcel from "views/admin/default/components/PrintButtonExcel";

const WorkerSATable = (props) => {
  const { columnsData, tableData, title } = props;
  
  const columns = useMemo(() => columnsData, [columnsData]);
  // console.log("🚀 ~ file: WorkerSATable.jsx:23 ~ WorkerSATable ~ tableData:", columns)
  const data = useMemo(() => tableData, [tableData]);

  // console.log("data", data)

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;
  const { toPDF, targetRef } = usePDF({ filename: title+".pdf" });

  return (
    <div>
      <div className="flex w-full justify-end gap-2">
        
        <PrintButton toPDF={toPDF} color={"text-primary"}/>
        <PrintButtonExcel
          filename={title}
          header={columns?.map((c)=>{return c?.Header})}
          body={tableData?.map((l) => {
            return [l?.label, l?.number , l?.percent];
          })}
        />
      </div>
      <div ref={targetRef}  className={"h-full w-full p-4 pt-0"}>
        <div class=" flex items-center justify-between">
          <div class="text-xl font-bold uppercase text-navy-700 dark:text-white">
            {title}
          </div>
          {/* <CardMenu /> */}
        </div>

        <div
          class="h-full overflow-x-scroll xl:overflow-x-hidden"
          style={{ maxHeight: "500px" }}
        >
          <table
            {...getTableProps()}
            className="mt-8 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pb-[10px] pr-32 text-start dark:!border-navy-700  "
                      key={index}
                    >
                      <div className="flex text-sm font-bold uppercase">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (
                        cell.column.Header === "Secteur d'activité" ||
                        cell.column.Header === "Nombre" ||
                        cell.column.Header === "Pourcentage" ||
                        cell.column.Header === "Position" ||
                        cell.column.Header === "Arrondissement" ||
                        cell.column.Header === "Département" ||
                        cell.column.Header === "Country"
                      ) {
                        data = (
                          <p className="text-sm  font-bold capitalize text-navy-700 dark:text-white">
                            {capitalyse(cell?.value)}
                            {/* {console.log("cell", cell.value)} */}
                          </p>
                        );
                      } else if (cell.column.Header === "TECH") {
                        data = (
                          <div className="flex items-center gap-2">
                            {cell.value.map((item, key) => {
                              if (item === "apple") {
                                return (
                                  <div
                                    key={key}
                                    className="text-[22px] capitalize text-gray-600 dark:text-white"
                                  >
                                    <DiApple />
                                  </div>
                                );
                              } else if (item === "android") {
                                return (
                                  <div
                                    key={key}
                                    className="text-[21px] capitalize text-gray-600 dark:text-white"
                                  >
                                    <DiAndroid />
                                  </div>
                                );
                              } else if (item === "windows") {
                                return (
                                  <div
                                    key={key}
                                    className="text-xl capitalize text-gray-600 dark:text-white"
                                  >
                                    <DiWindows />
                                  </div>
                                );
                              } else return null;
                            })}
                          </div>
                        );
                      } else if (cell.column.Header === "DATE") {
                        data = (
                          <p className="text-sm font-bold capitalize text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.Header === "PROGRESS") {
                        data = (
                          <div className="flex items-center gap-3">
                            <p className="text-sm font-bold capitalize text-navy-700 dark:text-white">
                              {cell?.sort((a, b) =>
                                a?.value >= b?.value ? -1 : 1
                              )}
                              %
                            </p>
                            <Progress width="w-[68px]" value={cell?.value} />
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pb-3 pt-[14px] text-[14px] capitalize"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WorkerSATable;
