import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useAuthUser } from "react-auth-kit";
import AccountMenu from "components/navbar/AccountMenu";
import { Paper } from "@mui/material";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Statistiques");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Statistiques";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const auth = useAuthUser();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          auth()?.user?.roles?.filter((role) => prop?.roles?.includes(role?.name)).length > 0 && <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full"
      style={{
        backgroundImage: 'url(/assets/img/image2.jpeg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100dvh'
      }}

    >
      <div className="flex h-full w-full "
        style={{
          backdropFilter: 'blur(3px)',

          height: '100dvh'

        }}

      >
        <Paper className={"flex flex-col items-between content-between bg-red-500"}
        //  sx={{  maxWidth: '100%' }}
        >
          <div style={{height : '100%', overflow : 'auto'}}>
            <Sidebar open={open} onClose={() => setOpen(false)} />
          </div>
          <div className="bg-gray-100 mt-auto">
            <Footer />
          </div>
        </Paper>

        {/* Navbar & Main Content */}
        <div
          className="flex flex-col h-full w-full bg-lightPrimary dark:!bg-navy-900">
          {/* Main Content */}
          <main
            style={{ height: '100dvh', overflow: 'auto' }}

          // className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[200px]`}
          >
            {/* Routes */}
            <div className="h-full p-0">
              <AccountMenu />

              <div className=" mx-auto mb-auto h-full min-h-[84vh] px-2 ">
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/admin/default" replace />}
                  />
                </Routes>
              </div>
            </div>
          </main>
          {/* <div className="">
          <p className="text-red-500">lololo</p>
          <Footer />
        </div> */}
        </div>
      </div>
    </div>
  );
}
