import { MISSION_HEADER } from "Constaints";
import useDataFetching from "hooks/useDataFetching";
import { formatDate } from "utils";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import MButton from "components/Mbutton";
import { useNavigate } from "react-router-dom";
import AddMission from "./AddTask";
import { useDisclosure } from "@chakra-ui/hooks";
import AddMissionEnterprise from "./AddTaskEnterprise";
import { useState } from "react";
import { BsArrowRepeat, BsBackpack3, BsCheck2All, BsCheck2Circle, BsList, BsPlusCircle } from "react-icons/bs";
import PreloaderSpinner from "components/PreloaderSpinner";
import AddTaskEnterprise from "./AddTaskEnterprise";
import AddTask from "./AddTask";
import { getFullName } from "utils";
import { Tooltip } from "@chakra-ui/tooltip";
import { Chip, IconButton, capitalize } from "@mui/material";
import { COLORS } from "constant/COLORS";
import { TASK_HEADER } from "Constaints";
import axios from "axios";
import toast from "react-hot-toast";
import { formatNumber } from "utils";
import { Badge } from "@chakra-ui/react";
import { useAuthHeader } from "react-auth-kit";

export default function Tasks() {
    const navigate = useNavigate()
    const authHeader = useAuthHeader()

    const { isOpen, onOpen, onClose } = useDisclosure();
    //   const { isOpenEn, onOpenEn, onCloseEn } = useDisclosure();
    const [open, setOpen] = useState(false);


    const [loading, errorE, tasks, fetchtaks] = useDataFetching(
        `${config.app.api_url}/tasks`
    );

    const [selectedTask, setselectedTask] = useState(null);

    const handleChangeStatus = ({ taskId, completed }) => {

        toast.promise(axios.put(`${config.app.api_url}/tasks/${taskId}/change-status?completed=${completed}`,   { headers: { Authorization: authHeader() } }), {
            loading: 'Loading',
            success: () => { fetchtaks(); return (completed ? 'tache completee avec success' : 'tache non completee') },
            error: 'Une erreur est survenu',
        });
    }
    const handleClick = (item) => {
        onOpen()
        setselectedTask(item)
    }
    function createData(N, description, start, end, region, department, arrondissement, zone, status, actions) {
        // const density = population / size;
        return {
            N, description, start, end, region, department, arrondissement, zone, status, actions

        };
    }
    const rows = tasks?.data?.sort((a, b) => new Date(b?.start) > new Date(a?.start) ? -1 : 1)?.map((item, index) => {

        return {
            //   color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: () => handleClick(item),


            datas: createData(index + 1, item?.description, formatDate(item.start), formatDate(item?.end),
                item?.region?.name, item?.department?.name, item?.arrondissement?.name, item?.zone?.description,
                (new Date(item?.end) < new Date() && !item?.completed ) ?
                    <Chip color='error' className="capitalize-none" label={capitalize("En retart")} /> :

                    item?.completed ?
                        <Chip color='success' className="capitalize-none" label={capitalize("Terminée")} /> :

                        <Chip className="capitalize-none" variant="outlined" color='info' label="En cours" />
                ,
                <div className="flex flex gap-0">
                    <Tooltip hasArrow label={"Liste des entreprises"} bg='green.200' color='black' placement="bottom">

                        <IconButton
                            color='primary'
                            onClick={(e) => { e?.stopPropagation(); navigate("/admin/enterprises", { state: { task: item } }) }}
                        >

                            <BsList
                            />
                        </IconButton>
                    </Tooltip>


                    <Tooltip hasArrow label={"Assigner une entreprise"} bg='green.200' color='black' placement="bottom">
                        <IconButton
                            color="success"
                            onClick={(e) => { e?.stopPropagation(); setselectedTask(item); setOpen(true) }}
                        >
                            <BsPlusCircle
                            />
                        </IconButton>
                    </Tooltip>


                    {!item?.completed ? <Tooltip hasArrow label={"Marquer comme terminee"} bg='green.200' color='black' placement="bottom">
                        <IconButton
                            color="success"
                            onClick={(e) => { e?.stopPropagation(); handleChangeStatus({ taskId: item?.id, completed: true }) }}
                        >
                            <BsCheck2Circle
                            />
                        </IconButton>
                    </Tooltip> :
                        <Tooltip hasArrow label={"Marquer comme nonterminee"} bg='green.200' color='black' placement="bottom">
                            <IconButton
                                color="warning"
                                onClick={(e) => { e?.stopPropagation(); handleChangeStatus({ taskId: item?.id, completed: false }) }}
                            >
                                <BsArrowRepeat
                                />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            ),
            history: item?.participants ? {
                title: "Liste des participants",
                header: [
                    "#",
                    "Nom complet",

                ],
                datas: item?.participants?.map((p, index) => {
                    return {
                        index: index + 1,
                        salary: getFullName(p),
                    }
                }
                )
            }
                : []
        }
    }
    )




    return (
        <>
            {/* {loading && <PreloaderSpinner />} */}

            <div className="flex justify-end my-2 shadow-lg bg-white rounded-lg border py-2">
                <div className="flex flex-1  justify-center mt-2">
                    <strong>
                        Total :
                        <Badge
                            fontSize={13}
                            rounded={5}
                            padding={1}
                            paddingInline={2}
                            colorScheme="orange"
                            className="mx-1"
                        // style={{ wi: -15 }}
                        >
                            {formatNumber(tasks?.data?.length) || '--'}
                        </Badge>
                    </strong>
                    <>
                        <strong>
                            Terminées :
                            <Badge
                                fontSize={13}
                                rounded={5}
                                padding={1}
                                paddingInline={2}
                                colorScheme="green"
                                className="mx-1"
                            // style={{ wi: -15 }}
                            >
                                {formatNumber(tasks?.data?.filter((e) => e?.completed)?.length) || '--'}
                            </Badge>
                        </strong>

                        <strong>
                            En cours :
                            <Badge
                                fontSize={13}
                                rounded={5}
                                padding={1}
                                paddingInline={2}
                                colorScheme="blue"
                                className="mx-1"
                            // style={{ wi: -15 }}
                            >
                                {formatNumber(tasks?.data?.filter((e) => !e?.completed)?.length) || '--'}

                            </Badge>
                        </strong>
                        <strong>
                            En retart :
                            <Badge
                                fontSize={13}
                                rounded={5}
                                padding={1}
                                paddingInline={2}
                                colorScheme="red"
                                className="mx-1"
                            // style={{ wi: -15 }}
                            >
                                {formatNumber(tasks?.data?.filter((e) => new Date(e?.end) < new Date())?.length) || '--'}

                            </Badge>
                        </strong>
                    </>
                </div>
                <MButton variant={"primary"} onClick={() => { onOpen() }} ><BsPlusCircle /> Creer une Tâche</MButton>
            </div>
            <MuiTable 
            loading={loading}
            
            hidePagiation={true} columns={TASK_HEADER}
                rows={rows} textEmpty={"Pas de missions"} />
            {/* <AddMission isOpen={isOpen} onClose={onClose} /> */}
            <AddTask selectedTask={selectedTask} endFetch={fetchtaks} isOpen={isOpen} onClose={()=>{ setselectedTask(null); onClose()}} />
            <AddTaskEnterprise endFetch={fetchtaks} task={selectedTask} isOpen={open} onClose={() =>{setselectedTask(null); setOpen(false)}} />

        </>
    )
}