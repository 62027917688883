import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from '@mui/material';

export default function MuiSelect({ label, wrapperClasses, options, value, onChange, multiple,
  emptyLabel, size, disabled, sort=true,
  format = { value: 'value', label: 'label' }, defaultFormat, marginRight }) {
  // console.log("🚀 ~ value:", value)
  if (defaultFormat) format = { value: 'id', label: 'name' }
if(!sort){
  options = options
}else{
  options =options?.sort((a, b) => a[format?.label] > b[format?.label] ? 1 : -1)
}
  return (
    <Box sx={{ minWidth: 150, marginRight: marginRight }}
      className={wrapperClasses}
    >
      <FormControl
        size={size || 'medium'}
        fullWidth>
        {disabled && false ? <span>{label}</span> : <InputLabel
          // sx={{ color: 'green' }}
          id={`label`}>{label}</InputLabel>

        }

        <Select 
        
          readOnly={disabled}
          // disabled={disabled}
          sx={{ color: 'green' }}
          labelId={`${label}-label`}
          id={label}
          value={value}
          label={label}
          onChange={onChange}
          multiline={multiple}
        // defaultValue={value}
        // variant='standard'
        >
          {emptyLabel && <MenuItem>
            --- {emptyLabel} ---
          </MenuItem>}
          {options && options?.map((option) => (
            <MenuItem key={option[format?.value]} value={option[format?.value]}>
              {option[format?.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}