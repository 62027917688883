import { MISSION_HEADER } from "Constaints";
import useDataFetching from "hooks/useDataFetching";
import { formatDate } from "utils";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import MButton from "components/Mbutton";
import { useNavigate } from "react-router-dom";
import AddMission from "./AddMission";
import { useDisclosure } from "@chakra-ui/hooks";
import AddMissionEnterprise from "./AddMissionEnterprise";
import { useState } from "react";
import { BsList, BsPlusCircle } from "react-icons/bs";
import PreloaderSpinner from "components/PreloaderSpinner";

export default function Missions() {
    const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure();
//   const { isOpenEn, onOpenEn, onCloseEn } = useDisclosure();
  const [open, setOpen] = useState(false);

    const [loading, errorE, missions, fetchMissions] = useDataFetching(
        `${config.app.api_url}/missions`
    );

    const [selectedMission, setselectedMission] = useState(null);

    const handleClick = (item)=>{
        setselectedMission(item)
    }
    function createData(N, description, start, end, region, actions) {
        // const density = population / size;
        return {
            N, description, start, end, region, actions

        };
    }
    const rows = missions?.data?.sort((a,b)=>new Date(b?.start) > new Date(a?.start) ? -1 : 1)?.map((item, index) => {

        return {
            //   color: !checkIsValidPermitDate(item) ? 'red' : '',
              handleClick: () => handleClick(item),


            datas: createData(index + 1, item?.description, formatDate(item.start), formatDate(item?.end), item?.region?.name,
                <div className="flex flex-col gap-2">
                    <MButton
                        onClick={() => navigate("/admin/enterprises", { state: { mission: item } })}
                    >
                        <BsList/>
                        Liste des entreprises</MButton>
                    <MButton
                        onClick={() => {setselectedMission(item); setOpen(true)}}
                    >
                        <BsPlusCircle/>
                        Assigner une entreprise</MButton>
                </div>
            ),
            history: item?.participants ? {
                title: "Liste des participants",
                header: [
                    "#",
                    "Nom complet",

                ],
                datas: item?.participants?.sort((a, b) => b - a)?.map((c, index) => {
                    return {
                        index: index + 1,
                        salary: c,
                    }
                }
                )
            }
                : []
        }
    }
    )




    return (
        <>
      {/* {loading && <PreloaderSpinner />} */}

        <div className="flex justify-end my-2">

        <MButton variant={"primary"}  onClick={()=>{onOpen()}} ><BsPlusCircle/> Creer Mission</MButton>
        </div>
            <MuiTable
            loading={loading}
            hidePagiation={true} columns={MISSION_HEADER}
                rows={rows} textEmpty={"Pas de missions"} />
      {/* <AddMission isOpen={isOpen} onClose={onClose} /> */}
      <AddMission  endFetch={fetchMissions}  isOpen={isOpen} onClose={onClose} />
      <AddMissionEnterprise  endFetch={fetchMissions} mission={selectedMission}  isOpen={open} onClose={()=>setOpen(false)} />

        </>
    )
}