import { CircularProgress } from "@mui/material";
import { getValue } from "@testing-library/user-event/dist/utils";
import useDataFetching from "hooks/useDataFetching";
import { useEffect, useState } from "react";
import { formatDate } from "utils";
import { config } from "variables/config";

const StatsCard = () => {
    // const  pasDays = 5
    const initialDateP = new Date();
const [pasDays, setpPasDays] = useState(5);
const [initialDate, setInitialDate] = useState(initialDateP.setDate(initialDateP?.getDate() - pasDays));
    // initialDate.setDate(initialDate.getDate() - pasDays);
    const [lastDay, setLastDay] = useState(null);
    useEffect(() => {
        initialDate && setLastDay(new Date(initialDate)?.getTime()?.toString())
    }, [initialDate]);

    useEffect(() => {
        pasDays && setInitialDate(initialDateP.setDate(initialDateP?.getDate() - pasDays))   
    }, [pasDays]);

    // console.log("🚀 ~ StatsCard ~ lastDay:", lastDay)
    const [currentDate, setCurrentDate] = useState(new Date().getTime());
    // console.log("🚀 ~ StatsCard ~ date:", date)
    // console.log("🚀 ~ StatsCard ~ date:", date)
    const [loadingsRTT, errorsRTT, statsEnterTotal, fetchDatasEnterTotal] = useDataFetching(
        `${config.app.api_url}/enterprises/stats-details`
    );

    const [loadingsPa, errorsPa, statsEnterTotalP, fetchDatasEnterTotalP] = useDataFetching(
        lastDay ? `${config.app.api_url}/enterprises/stats-details?end=${lastDay}` : null
    );
    const [loadingsRT, errorsRT, statsEnter, fetchDatasEnter] = useDataFetching(
        currentDate ? `${config.app.api_url}/enterprises/stats-details?start=${currentDate?.toString()}` : null
        );

    const [loadingsP, errorsP, statsEnterP, fetchDatasEnterPast] = useDataFetching(
        lastDay ? `${config.app.api_url}/enterprises/stats-details?start=${lastDay?.toString()}` : null
        );

        // console.log("🚀 ~ StatsCard ~ statsEnterP:", statsEnterP)

    const [loadingsEmp, errorEmp, statsEmp, fetchDatasEmp] = useDataFetching(
        currentDate ? `${config.app.api_url}/employers/stats-details?start=${currentDate?.toString()}` : null
        );

    const [loadingsEmpP, errorEmpP, statsEmpP, fetchDatasEmpP] = useDataFetching(
        lastDay ? `${config.app.api_url}/employers/stats-details?start=${lastDay?.toString()}` : null
        );


        // console.log("🚀 ~ StatsCard ~ statsEmpP:", statsEmpP?.data?.regular)
    useEffect(() => {
      if(lastDay ){  
        // fetchDatasEnter()
        // fetchDatasEmp()
        fetchDatasEnterPast()
        fetchDatasEmpP()}
    }, [lastDay, currentDate]);
    const getValue = (value) => {
        return value == null || value == undefined ? "0" : "+" + value
    }
    const Card = ({ label, value, loading }) => {

        return (
            <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {label}
                </dt>
               {loading ? <CircularProgress className="mx-auto" color="success" /> : <dd class="order-1 text-4xl font-extrabold text-green-500">{value}</dd>}
            </div>
        )
    }
    return (
        <>
            <div class="bg-gray-50 py-4 relative">
                <span className="absolute text-xl text-orange-400 top-2 left-0 p-5 rounded-tr-full rounded-br-full bg-primary shadow-lg ">
                {loadingsRTT ? <CircularProgress/> :((statsEnterTotal?.data?.visited / statsEnterTotal?.data?.total) * 100)?.toFixed(2) + "%"}
                </span>
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-7xl mx-auto text-center">
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                            Today Activities
                        </h2>
                        <p class="mt-3 text-xl text-gray-500 sm:mt-2">
                            Statistiques d'aujourd'hui le {formatDate(new Date())} sur les activites du terrain.
                        </p>
                    </div>
                </div>
                <div class="mt-5 pb-1">
                    <div class="relative">
                        <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="max-w-7xl mx-auto">
                                <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                                    <Card loading={loadingsRT} label={"New Enterprises collected"} value={getValue(statsEnter?.data?.total)} />
                                    <Card  loading={loadingsRT}  label={"New Enterprises located"} value={getValue(statsEnter?.data?.visited)} />
                                    {/* <Card label={"Enterprise Coverage"} value={((statsEnterTotal?.data?.visited / statsEnterTotal?.data?.total) * 100)?.toFixed(2) + "%"} /> */}
                                    <Card loading={loadingsEmp} label={"New Contracts collected"} value={getValue(statsEmp?.data?.contracts)} />
                                    <Card loading={loadingsEmp} label={"New empployees collected"} value={getValue(statsEmp?.data?.total)} />
                                    <Card loading={loadingsEmp} label={"New reports submitted"} value={getValue(statsEmp?.data?.reports)} />
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-50 py-4 ">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-7xl mx-auto text-center">
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                            Last {pasDays} days
                        </h2>
                        <input className="text-center w-10 shadow rounded border border-yellow-200" value={pasDays} onChange={(e)=>setpPasDays(e?.target?.value)} />
                        <p class="mt-3 text-xl text-gray-500 sm:mt-2">
                        Statistiques des {pasDays} deniers jours sur les activites du terrain.
                        
                        </p>
                    </div>
                </div>
                <div class="mt-5 pb-1">
                    <div class="relative">
                        <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="max-w-7xl mx-auto">
                                <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                                    <Card loading={loadingsP} label={"New Enterprises collected"} value={getValue(statsEnterP?.data?.total)} />
                                    <Card loading={loadingsP} label={"New Enterprises located"} value={getValue(statsEnterP?.data?.visited)} />
                                    {/* <Card label={"Enterprise Coverage"} value={((statsEnterTotalP?.data?.visited / statsEnterTotalP?.data?.total) * 100)?.toFixed(2) + "%"} /> */}
                                    <Card loading={loadingsEmpP}  label={"New Contracts collected"} value={getValue(statsEmpP?.data?.contracts)} />
                                    <Card loading={loadingsEmpP} label={"New empployees collected"} value={getValue(statsEmpP?.data?.total)} />
                                    <Card loading={loadingsEmpP} label={"New reports submitted"} value={getValue(statsEmpP?.data?.reports)} />

                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default StatsCard;