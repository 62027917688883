import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "variables/config";
import toast from "react-hot-toast";
import { BsHddNetwork, BsWifiOff, BsXCircleFill } from "react-icons/bs";
import { useAuthHeader } from "react-auth-kit";
// import { useAuthHeader } from "react-auth-kit";

const usePostAxiosData = (url, method = "POST") => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const [errorMassage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState({
    status: null,
    message: "",
  });
  useEffect(() => {
    
    console.log("🚀 ~ useEffect ~ errorMassage:", errorMassage)
    errorMassage?.data ? 
    toast.error(
     "Errors: "+errorMassage?.data?.message+"  \n" + Object.keys(errorMassage?.data)?.map((key)=>{return key!="message" ? key +" :"+ errorMassage?.data[key] +"\n" : ''}),
      {
        duration: 5000,
        icon: <BsXCircleFill size={30}/>,
        className: "bg-success text-white",
      }
    ) 
    : errorMassage?.message &&  toast.error(
      errorMassage?.message,
      {
        duration: 5000,
        icon: <BsXCircleFill size={30}/>,
        className: "bg-success text-white",
      }
    );
  }, [errorMassage]);
  const authHeader = useAuthHeader();
  const postAxiosData = async (data, urlP=url) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const configurationData = {
        method: method,
        url: `${config.app.api_url}/${urlP}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
        data: data,
      };
      await axios(configurationData)
        .then((response) => {
          setLoading(false);

          if (response?.status === 200 || response?.status === 201) {
            setResult(response?.data);
            setSuccessMessage({
              status: response?.status,
              message: response?.data?.message,
            });
          }
        })
        .catch((error) => {
          console.log("🚀 ~ file: usePostAxiosData.js:42 ~ postAxiosData ~ error:", error)
          setLoading(false);
         
          if (error.code === 'ERR_NETWORK') {
            // Network error
            console.error('Network error:', error);
            // Handle network error here (e.g., display an error message to the user)
            // toast.error(
            //   'Oups, une erreur est survenue lors de la connexion au serveur. Veuillez vérifier votre connexion internet et réessayer',
            //   {
            //     duration: 5000,
            //     icon: <BsWifiOff size={70}/>,
            //     className: "bg-success text-white",
            //   }
            // );
            setErrorMessage({
              status: error?.response?.status,
              message:<div className="flex gap-3"><BsWifiOff size={70}/><p>Oups, une erreur est survenue lors de la connexion au serveur. Veuillez vérifier votre connexion internet et réessayer</p></div>,
            });
          } else{
          setErrorMessage({
            data : error?.response?.data,
            status: error?.response?.status,
            message:error?.response?.status ==500 ? "Une erreur s'est produite, veillez réessayer plus tard s'il vous plaît" : error?.response?.data?.message || error?.response?.data?.toString() ,
          });
        }
          return null;
        });
    } catch (error) {
      console.log("🚀 ~ file: usePostAxiosData.js:50 ~ postAxiosData ~ error:", error)
      setLoading(false);
    
        setErrorMessage({
          data : error?.response?.data,
          status: error?.response?.data?.status,
          message: error?.response?.status ==500 ? "Une erreur s'est produite, veillez réessayer  plus tard s'il vous plaît" : error?.response?.message || error?.response?.data?.message,
        });
      
    }
  };

  return [loading, postAxiosData, errorMassage, successMessage, result];
};

export default usePostAxiosData;
