import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/logo.png";
import { Link, Routes, Route, Navigate, useNavigate } from "react-router-dom";
// import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/SignIn";
import TextLogo from "components/TextLogo";
import { authRoutes } from "routes";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div

    >

      {/* <main className={`mx-auto min-h-screen`}> */}
      {/* <div className="relative flex"> */}
      <div
        className="mx-auto flex min-h-full w-full flex-col justify-start 
             lg:h-screen 
            "
      //  className="h-full"
      >



        <div
          style={{
            backgroundImage: 'url(/assets/img/image2.jpeg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}

          className=" h-full flex justify-center items-center"
        >
          <div
            className="grid md:grid-cols-2 gap-10  justify-center items-center px-10  rounded-lg"
            style={{
              backdropFilter: 'blur(6px)',
              backgroundColor: ' rgba(5,5,5,0.4)',
              width: '70%',
              height: '80%'

            }}
          >

            {/* <SignIn /> */}
            <Routes>
              {getRoutes(authRoutes)}
              {/* <Route
            path="/auth/sign-in"
            element={<Navigate to="/auth/sign-in" replace />}
          /> */}
              {/* <Route
            path="/"
            element={<Navigate to="/auth/email-verification" replace />}
          /> */}
            </Routes>
            <div className="flex flex-col content-center">

              {/* <div
              className="hidden md:flex col  justify-center items-center bg-red-500"
              style={{ backgroundImage: `url(${authImg})`, height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}
              /> */}
              <img src="/assets/img/logo.png" />
              <TextLogo />
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
      {/* </main> */}
    </div>
  );
}
