import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function MuiDatePicker({ wrapperClasses, label, value, onChange, fullWidth, disabled }) {
  // const [value, setValue] = React.useState(null);

  return (
    <span
      className={wrapperClasses}
      // style={{height : 20}}
    >


      <LocalizationProvider dateAdapter={AdapterDayjs}
      >
        {/* <DemoContainer
          components={['DatePicker', 'DatePicker']}> */}
        {/* <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} /> */}
        <DatePicker
          className={` ${fullWidth ? 'w-full' : ''}`}
          sx={{ minWidth: 150 }}
          readOnly={disabled}

          // className='text-red-500'


          label={label}
          value={value ? dayjs(value) : null}
          onChange={onChange}
          format='DD-MM-YYYY'
        />
        {/* </DemoContainer> */}
      </LocalizationProvider>
    </span>
  );
}