import {
  Button,
  Card,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import AddUser from "./AddUser";
import UpdateUserInformations from "./UpdateUserInformations";
import { useState } from "react";
import PreloaderSpinner from "components/PreloaderSpinner";
import { ROLES } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import { capitalize, Chip } from "@mui/material";
import MButton from "components/Mbutton";
import MuiTable from "components/MuiTable";
import { ACCOUNT_HEADER } from "Constaints";
import usePostAxiosData from "hooks/usePostAxiosData";

const AdminComptes = () => {
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOpenUM, setIsOpenUM] = useState(false);
  const [closeUM, setCloseUM] = useState(false);

  const [loadingR, errorR, roles, fetchDataR] = useDataFetching(
    `${config.app.api_url}/roles`
  );
  const [role, setRole] = useState();
  // console.log("roles", role)

  const auth = useAuthUser();
  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  const [loadingU, errorU, users, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/${role || (roles?.data?.length > 0 && roles?.data[0]?.id)
    }/users`
  );

  const [userId, setUserId] = useState(null);
  const [loading, enableAccount, errorMassage, successMessage, result] = usePostAxiosData(`users/${userId?.id}/enable-account`, "POST");
  const [loadingD, disableAccount, errorMassageD, successMessageD, resultD] = usePostAxiosData(`users/${userId?.id}/disable-account`, "POST");


  useEffect(() => {
    fetchDataU()
  }, [result, resultD]);
  const handleAccount = ({isEnable, user}) => {
    
    if (isEnable===true) {

      window.confirm("Etes vous sur de voloir activer cet utilisateur ?") && enableAccount({}, `users/${user?.id}/enable-account`)
    } else {
      window.confirm("Etes vous sur de voloir desactiver cet utilisateur ?") && disableAccount({}, `users/${user?.id}/disable-account`)
    }
  }

  function createData(N, FirstName, LastName, Email, Phone, Enable, Options) {
    // const density = population / size;
    return {
      N, FirstName, LastName, Email, Phone, Enable, Options

    };
  }
  const handleClick = (user) => {
  
    setIsOpenUM(true);
    setUserId(user?.id);
  }
  const rows = users?.data?.map((item, index) => {
    // const l = getLastContract(item?.contracts)

    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: (e) => handleClick(item),

      datas: createData((index + 1),

        item.firstName,
        item?.lastName
        ,
        item?.mail,
        // ENTRY_TYPE[item?.entryType] || '--',

        item?.phone,

        item?.enable ?
          <Chip color='success' variant="outlined" className="capitalize-none" label={capitalize("Actif")} 
          onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            handleAccount({isEnable : false, user : item})}}
          />
          :
          <Chip color='error' className="capitalize-none" label={capitalize("Desactiver")} 
          onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            handleAccount({isEnable : true, user : item})}}
          
          />,
        <MButton
          variant={"white"}

          style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
          Editer
        </MButton>,

      ),

    }
  }
  )

  return (
    <div>
      {/* {loadingU && <PreloaderSpinner />} */}
      <div className="mt-2 grid h-full grid-cols-1 gap-5">
        <div className="mt-5 flex items-center justify-between">
          <div>
            <select
              onChange={(e) => setRole(e.target.value)}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
              name="region"
              id="region"
            >
              {/* {
                (role === undefined || role === null) && <option value={roles?.data?.length > 0 && roles?.data[0]?.id}>{roles?.data?.length > 0 && roles?.data[0]?.name}</option>
              } */}
              {/* <option value="">--- Selectionner un role ---</option> */}
              {roles?.data?.length > 0 &&
                roles?.data.map((item) => {
                  return (
                    <option
                      selected={
                        (role === undefined || role === null) &&
                        roles?.data?.length > 0 &&
                        roles?.data[0]?.id === item?.id
                      }
                      value={item?.id}
                    >
                      {item?.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            {usr?.data?.roles.length > 0 &&
              usr?.data?.roles?.map((role) => {
                return (
                  CHECK_PERMISSIONS(role?.permissions, "Ajouter un compte") && (
                    <Button onClick={() => onOpen()} colorScheme="blue">
                      <MdAdd className="h-6 w-6" />
                      Ajouter un compte
                    </Button>
                  )
                );
              })}
          </div>
        </div>
        {/* <div>
          <label
            className={`ml-3 text-3xl font-bold text-navy-700 dark:text-white`}
          >
            Liste des comptes
          </label>
        </div> */}

        <MuiTable
        loading={loadingU}
        hidePagiation={true} columns={ACCOUNT_HEADER}
          rows={rows} textEmpty={"Pas de permis de travail"} />
      </div>

      {/* Modal création d'un user */}
      <AddUser isOpen={isOpen} onClose={onClose} />

      {/* Modification des informations du user */}
      <UpdateUserInformations
        fetchData={fetchDataU}
        isOpen={isOpenUM}
        setIsOpen={setIsOpenUM}
        onClose={closeUM}
        userId={userId}
      />
    </div>
  );
};

export default AdminComptes;
