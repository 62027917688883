// src/firebase.js
// import { initializeApp } from 'firebase/app';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { DEPLOY_MODE } from 'variables/config';
import { config } from 'variables/config';
const firebaseConfig = {

    apiKey: "AIzaSyBCJLOxOK3C4S6mf9ILsQX6UQ1f1LjloiM",
  
    authDomain: "fntec-92dec.firebaseapp.com",
  
    projectId: "fntec-92dec",
  
    storageBucket: "fntec-92dec.appspot.com",
  
    messagingSenderId: "950331806775",
  
    appId: "1:950331806775:web:e6f239c2950b43ae36dbb2",
  
    measurementId: "G-6GSKS45ZM7"
  
  };

const app = initializeApp(firebaseConfig);
const messaging = DEPLOY_MODE ?  getMessaging(app) : null;
// const messaging = messaging.isSupported ? messaging.getMessaging(app) : null;
// const messaging = isSupported ? getMessaging(app) : null
// // const messaging = null
export const requestPermission = () => {
  messaging && Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      getToken(messaging, { vapidKey: 'BA_BZMT6aRVvEUO_Mbxko31Jz2I9jB7H3N1fc4VYAj3Tc2VrDDT6RVFuK0KPM-aK5Gzs6CQMhKv_1Bxc99S0MTM' }).then((currentToken) => {
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          const value = localStorage.getItem('_auth_state');
          if (value) {
            const parsedValue = JSON.parse(value);
            // console.log("🚀 ~ getToken ~ parsedValue:", parsedValue)
            const response =  axios.put(`${config.app.api_url}/auth/${parsedValue?.user?.id}/update-notification-token`, { token: currentToken });
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
