import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import WorkerSATable from "../tables/components/WorkerSATable";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { useParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";

const Regions = ({ route }) => {
  const { id } = useParams();

  const [employees, setEmployees] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(id);
  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );

  useEffect(() => {
    const fetchEnt = async (region) => {
      if (region && region != null) {
        setLoading(true);
        await fetch(`${config.app.api_url}/regions/${region}/enterprises`)
          .then((response) => response.json())
          .then((data) => setEnterprises(data))
          .finally(() => setLoading(false));
      }
    };

    const fetchEmp = async (region) => {
      if (region && region != null) {
        setLoading(true);
        await fetch(`${config.app.api_url}/regions/${region}/employers`)
          .then((response) => response.json())
          .then((data) => setEmployees(data))
          .finally(() => setLoading(false));
      }
    };
    fetchEnt(region);
    fetchEmp(region);
  }, [region]);

  const [loadingAr, errorAr, arrs, fetchDataAr] = useDataFetching(
    `${config.app.api_url}/arrondissements`
  );

  const [loadingSec, errorSec, activities, fetchDataAc] = useDataFetching(
    `${config.app.api_url}/activity-sectors`
  );

  const [loadingOc, errorOc, occupations, fetchDataOc] = useDataFetching(
    `${config.app.api_url}/occupations/all`
  );

  const [groupDepart, setGroupDepart] = useState([]);
  const [groupSA, setGroupSA] = useState([]);
  const [datasSAE, setDatasSAE] = useState([]);
  const [datasDepartE, setDatasDepartE] = useState([]);
  const [datasSA, setDatasSA] = useState([]);
  const [datasDepart, setDatasDepart] = useState([]);
  const [datasPoste, setDatasPoste] = useState([]);
  const [display, setDisplay] = useState(0);

  const changeDisplay = (value) => {
    setDisplay(!display);
  };

  useEffect(() => {
    setDatasDepartE([]);
    setDatasSAE([]);
    if (enterprises?.data && enterprises?.data?.length > 0) {
      const groupedSA = enterprises?.data.reduce((group, item) => {
        if (!group[item?.activitySector?.id]) {
          group[item?.activitySector?.id] = [];
        }
        group[item?.activitySector?.id].push(item);
        return group;
      }, {});
      const groupedDepart = enterprises?.data.reduce((group, item) => {
        if (!group[item?.arrondissement?.id]) {
          group[item?.arrondissement?.id] = [];
        }
        group[item?.arrondissement?.id].push(item);
        return group;
      }, {});
      if (groupedSA) {
        activities?.data.map((item) => {
          if (groupedSA[item?.id] != undefined && groupedSA[item?.id] != null) {
            setDatasSAE((datasSAE) => [
              ...datasSAE,
              {
                activity_sector: item?.name,
                label: item?.name,
                number: groupedSA[item?.id] ? groupedSA[item?.id]?.length : 0,
                percent: groupedSA[item?.id]
                  ? Math.round(
                      (groupedSA[item?.id]?.length * 100) /
                        enterprises?.data?.length
                    ) + "%"
                  : 0 + "%",
              },
            ]);
          }
        });
      }
      if (groupedDepart) {
        arrs?.data.map((item) => {
          if (
            groupedDepart[item?.id] != undefined &&
            groupedDepart[item?.id] != null
          ) {
            setDatasDepartE((datasDepartE) => [
              ...datasDepartE,
              {
                departement: item?.name,
                label: item?.name,
                number: groupedDepart[item?.id]
                  ? groupedDepart[item?.id]?.length
                  : 0,
                percent: groupedDepart[item?.id]
                  ? Math.round(
                      (groupedDepart[item?.id]?.length * 100) /
                        enterprises?.data?.length
                    ) + "%"
                  : 0 + "%",
              },
            ]);
          }
        });
      }
    }
  }, [enterprises]);

  useEffect(() => {
    setDatasDepart([]);
    setDatasSA([]);
    setDatasPoste([]);
    if (employees?.data && employees?.data?.length > 0) {
      const groupedSA = employees?.data.reduce((group, item) => {
        if (!group[item?.enterprise?.activitySector?.id]) {
          group[item?.enterprise?.activitySector?.id] = [];
        }
        group[item?.enterprise?.activitySector?.id].push(item);
        return group;
      }, {});
      const groupedDepart = employees?.data.reduce((group, item) => {
        if (!group[item?.enterprise?.arrondissement?.id]) {
          group[item?.enterprise?.arrondissement?.id] = [];
        }
        group[item?.enterprise?.arrondissement?.id].push(item);
        return group;
      }, {});
      const groupedPoste = employees?.data.reduce((group, item) => {
        if (!group[item?.occupation?.id]) {
          group[item?.occupation?.id] = [];
        }
        group[item?.occupation?.id].push(item);
        return group;
      }, {});
      if (groupedSA) {
        activities?.data.map((item) => {
          if (groupedSA[item?.id] != undefined && groupedSA[item?.id] != null) {
            setDatasSA((datasSA) => [
              ...datasSA,
              {
                activity_sector: item?.name,
                label: item?.name,
                number: groupedSA[item?.id] ? groupedSA[item?.id]?.length : 0,
                percent: groupedSA[item?.id]
                  ? Math.round(
                      (groupedSA[item?.id]?.length * 100) /
                        employees?.data?.length
                    ) + "%"
                  : 0 + "%",
              },
            ]);
          }
        });
      }
      if (groupedDepart) {
        arrs?.data.map((item) => {
          if (
            groupedDepart[item?.id] != undefined &&
            groupedDepart[item?.id] != null
          ) {
            setDatasDepart((datasDepart) => [
              ...datasDepart,
              {
                departement: item?.name,
                label: item?.name,
                number: groupedDepart[item?.id]
                  ? groupedDepart[item?.id]?.length
                  : 0,
                percent: groupedDepart[item?.id]
                  ? Math.round(
                      (groupedDepart[item?.id]?.length * 100) /
                        employees?.data?.length
                    ) + "%"
                  : 0 + "%",
              },
            ]);
          }
        });
      }
      if (groupedPoste) {
        occupations?.data.map((item) => {
          if (
            groupedPoste[item?.id] != undefined &&
            groupedPoste[item?.id] != null
          ) {
            setDatasPoste((datasPoste) => [
              ...datasPoste,
              {
                poste: item?.name,
                label: item?.name,
                number: groupedPoste[item?.id]
                  ? groupedPoste[item?.id]?.length
                  : 0,
                percent: groupedPoste[item?.id]
                  ? Math.round(
                      (groupedPoste[item?.id]?.length * 100) /
                        employees?.data?.length
                    ) + "%"
                  : 0 + "%",
              },
            ]);
          }
        });
      }
    }
  }, [employees]);

  const [columsActivity, setColumnsActivity] = useState([
    { Header: "Secteur d'activité", accessor: "activity_sector" },
    { Header: "Nombre", accessor: "number" },
    { Header: "Pourcentage", accessor: "percent" },
  ]);

  const [columsPostes, setColumnsPostes] = useState([
    { Header: "Position", accessor: "poste" },
    { Header: "Nombre", accessor: "number" },
    { Header: "Pourcentage", accessor: "percent" },
  ]);

  const [columsDepart, setColumnsDepart] = useState([
    { Header: "Departement", accessor: "departement" },
    { Header: "Nombre", accessor: "number" },
    { Header: "Pourcentage", accessor: "percent" },
  ]);

  const [columsActivityE, setColumnsActivityE] = useState([
    { Header: "Secteur d'activité", accessor: "activity_sector" },
    { Header: "Nombre", accessor: "number" },
    { Header: "Pourcentage", accessor: "percent" },
  ]);

  const [columsPostesE, setColumnsPostesE] = useState([
    { Header: "Departement", accessor: "departement" },
    { Header: "Nombre", accessor: "number" },
    { Header: "Pourcentage", accessor: "percent" },
  ]);

  return (
    <div>
      {(loadingOc ||
        loadingSec ||
        loadingAr ||
        loading  )&& <PreloaderSpinner />}

      <div className="mt-5 grid h-full  grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label
            htmlFor={"region"}
            className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
          >
            Region
          </label>
          <select
            onChange={(e) => setRegion(e.target.value)}
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
            name="region"
            id="region"
          >
            {regions?.data &&
              regions.data.map((item) => {
                return <option value={item?.id}>{item?.name}</option>;
              })}
          </select>
        </div>
        <div>
          <label
            htmlFor={"display"}
            className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
          >
            Affichage
          </label>
          <select
            onChange={(value) => changeDisplay(value)}
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
            name="display"
            id="display"
          >
            <option value="1">Tableau</option>
            <option value="2">Graph</option>
          </select>
        </div>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Entreprises"}
          subtitle={enterprises?.data ? enterprises?.data?.length : 0}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Travailleurs étrangers"}
          subtitle={employees?.data ? employees?.data?.length : 0}
        />
      </div>
      {display == 0 ? (
        <>
          <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
            <WorkerSATable
              columnsData={columsActivity}
              title={
                "Répartition des travailleurs étrangers par secteur d'activité"
              }
              tableData={datasSA}
            />
            <WorkerSATable
              columnsData={columsPostes}
              title={"Répartition des travailleurs étrangers par position"}
              tableData={datasPoste}
            />
            <WorkerSATable
              columnsData={columsDepart}
              title={"Répartition des travailleurs étrangers par département"}
              tableData={datasDepart}
            />
          </div>
          <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
            <WorkerSATable
              columnsData={columsActivityE}
              title={"Répartition des entreprises par secteur d'activité"}
              tableData={datasSAE}
            />
            <WorkerSATable
              columnsData={columsPostesE}
              title={"Répartition des entreprises par Departement"}
              tableData={datasDepartE}
            />
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
            {/* <DailyTraffic /> */}
            <PieChartCard
              legends={datasSA}
              data={datasSA.map((a) => parseInt(a.percent.replace("%", "")))}
              options={datasSA.map((a) => a.activity_sector)}
              title={
                "Répartition des travailleurs étrangers par secteur d'activité"
              }
            />
            <PieChartCard
              legends={datasPoste}
              data={datasPoste.map((a) => parseInt(a.percent.replace("%", "")))}
              options={datasPoste.map((a) => a.poste)}
              title={"Répartition des travailleurs étrangers par Position"}
            />
            <PieChartCard
              legends={datasDepart}
              data={datasDepart.map((a) =>
                parseInt(a.percent.replace("%", ""))
              )}
              options={datasDepart.map((a) => a.departement)}
              title={"Répartition des travailleurs étrangers par Département"}
            />
            <PieChartCard
              legends={datasSAE}
              data={datasSAE.map((a) => parseInt(a.percent.replace("%", "")))}
              options={datasSAE.map((a) => a.activity_sector)}
              title={"Répartition des entreprises par secteur d'activité"}
            />
            <PieChartCard
              legends={datasDepartE}
              data={datasDepartE.map((a) =>
                parseInt(a.percent.replace("%", ""))
              )}
              options={datasDepartE.map((a) => a.departement)}
              title={"Répartition des entreprises par Départment"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Regions;
