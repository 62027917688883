import { CardHeader } from "@chakra-ui/react";
import { Card, Typography } from "@mui/material";
import NotificationBox from "components/NotificationBox";
import { NOTIFICATION_SCOPE } from "Constaints";
import useDataFetching from "hooks/useDataFetching";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { buildParams } from "utils";
import { config } from "variables/config";

export default function Notifications() {
    const auth = useAuthUser()
    const [params, setParams] = useState({ page: 0, limit: 50, notificationScope: NOTIFICATION_SCOPE.USER });

    const [loading, error, notifications, getNotifications] = useDataFetching(
        `${config.app.api_url}/users/${auth()?.user?.id}/notifications?${buildParams({ ...params, userId: auth()?.user?.id })}`
    );


    const [loadingR, errorR, notificationsR, getNotificationsR] = useDataFetching(
        `${config.app.api_url}/users/${auth()?.user?.id}/notifications?${buildParams({ ...params, userId:null, notificationScope: NOTIFICATION_SCOPE.ADMIN })}`
    );

    
    return (
        <div className="container mx-auto p-4 ">
            <h1 className="text-2xl font-bold mb-4">Notifications</h1>
            <div className=" ">
                <Typography variant="h5" color={"white"}>
                    Mes notifications
                </Typography>
                <div className="max-h-96 overflow-y-scroll overflow-x-hidden ">

                    {notifications?.data?.content?.map((n) => {

                        return (
                            <>
                                <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                                <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                                <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                                <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                            </>
                        )
                    })}
                </div>
            </div>

            <div className=" ">
                <Typography variant="h5" color={"white"}>
                    Les notifications globales
                </Typography>
                <div className="max-h-96 overflow-y-scroll overflow-x-hidden ">

                    {notificationsR?.data?.content?.map((n) => {

                        return (
                            <>
                            <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                            <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                            <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                            <NotificationBox title={n?.subject} body={n?.content} date={n?.last_updated_on} />
                            </>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}