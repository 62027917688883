// import { Input, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import useDataFetching from 'hooks/useDataFetching';
import * as React from 'react';
import { config } from 'variables/config';
import TabContent from '../layouts/TabContent';
import { Box, Chip, Tab, Tabs } from '@mui/material';
import MuiSelect from 'components/MuiSelect';
import TabContent2 from '../TabContent2';
// import { Button, ButtonGroup } from '@mui/material';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabBar() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [region, setRegion] = React.useState(null);

    const [loadingR, errorR, regionsP, fetchDataR] = useDataFetching(
        `${config.app.api_url}/regions`
    );
    const [regions, setRegions] = React.useState([]);

    React.useEffect(() => {
        // regionsP && setRegions(regionsP?.data?.sort((a, b) => a.name > b.name ? 1 : -1))
        regionsP && setRegions(regionsP?.data)
    }, [regionsP]);

    // console.log("🚀 ~ TabBar ~ departements:", departements?.data)
    return (
        <div>


            {/* <Tabs variant='soft-rounded' colorScheme='green' >
                <TabList className='shadow rounded-2xl bg-white overflow-auto py-3'>
                    <Tab>National</Tab>
                    {regions?.data &&
                        regions.data?.sort((a, b) => { return a.name < b.name ? -1 : 1 })?.map((item, index) => {
                            return (
                                item &&    <Tab key={index}>{item?.name}</Tab>

                            );
                        })}
                </TabList>
                <TabPanels>
                    <TabPanel>

                        <TabContent2 id={null} />
                    </TabPanel>
                    {regions?.data &&
                        regions.data?.sort((a, b) => { return a.name < b.name ? -1 : 1 })?.map((item) => {
                            return (
                                item &&  <TabPanel key={item?.id}>
                                 
                                    <TabContent2 regionParams={item} regionId={item?.id} />
                                </TabPanel>
                            );
                        })}


                </TabPanels>
            </Tabs> */}


            <Tabs centered
                value={value}
                onChange={handleChange}
                aria-label="Request"
                indicatorColor="primary"

                // textColor="primary"
                // style={{backgroundColor : 'red', }}
                className='bg-white rounded-lg shadow-lg '
            >
                <Tab label={'National'} {...a11yProps(0)} />
                {regions &&
                    regions?.map((item, index) => {
                        return (
                            // item && <Tab key={index}>{item?.name}</Tab>
                            item && <Tab key={item?.id} label={item.name} {...a11yProps(index+1)} />

                        );
                    })}
            </Tabs>

            <div >

                <CustomTabPanel value={value} index={0}>


                    <TabContent2 id={null} />
                </CustomTabPanel>
                {regions &&
                    regions?.map((i,index) => {
                        return (
                            i && <CustomTabPanel key={i?.id} value={value} index={index+1}>

                                <TabContent2 regionParams={i} regionId={i?.id} />
                            </CustomTabPanel>
                        );
                    })}


            </div>

        </div>

    );
}




