import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({ label, wrapperClasses, options, value, onChange,
  emptyLabel, size, disabled,
  format = { value: 'value', label: 'label' }, defaultFormat, marginRight }) {
  if (defaultFormat) format = { value: 'id', label: 'name' }

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    onChange(event)
    // console.log("🚀 ~ handleChange ~ event:", event?.target)
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl fullWidth sx={{  }}>
        <InputLabel id="demo-multiple-chip-label">{value?.length < 1 ? label : value?.length +' Selectionnés'}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={value?.length} Selectionnés />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={options?.find((op)=>op[format?.value]==value)[format?.label] } />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {/* {options.map((name) => (
            <MenuItem
              key={name?.id}
              value={name?.label}
              style={getStyles(name?.label, personName, theme)}
            >
              {name?.label}
            </MenuItem>

            
          ))} */}

          {options && options?.sort((a, b) => a[format?.label] > b[format?.label] ? 1 : -1)?.map((option) => (
            <MenuItem key={option[format?.value]} value={option[format?.value]}
              style={getStyles(option[format?.label], personName, theme)}

            >
              {option[format?.label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}