import axios from 'axios'
import { createRefresh} from 'react-auth-kit'
import { config } from 'variables/config';
// import { config } from '../../constants/config'

const refreshApi = createRefresh({
  interval: 10,   // Refreshs the token in every 10 minutes
  refreshApiCallback: async (
    {   // arguments
      authToken,
      authTokenExpireAt,
      refreshToken,
      refreshTokenExpiresAt,
      authUserState
    }) => {

    try {

      // console.log('response refres => ', refreshTokenExpiresAt)
      axios.defaults.headers.post['Authorization'] = `Bearer ${authToken}`;
      const response = await axios.post(config.app.api_url+"/auth/refresh-token",  {
        headers: {'Authorization': `Bearer ${authToken}`}}
      )
      return {
        isSuccess: true,
        newAuthToken: response.data.access_token,
        newAuthTokenExpireIn: response.data.jwtExpiration,
        newRefreshTokenExpiresIn: response.data.refreshExpiration
      }
    }
    catch(error){
      console.error(error)
      return {
        isSuccess: false
      } 
    }    
  }
})

export default refreshApi