import { Skeleton } from "@chakra-ui/react";

export const FormSkeleton = ({ nb = 20 }) => {

    return (
        <div className="p-4 grid gap-5 md:grid-cols-2 justify-center">

            {new Array(nb).fill(null).map((_, index) => {
                return (
                    <div key={index}>
                    <Skeleton height={55} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    {/* <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" />
                    <Skeleton height={60} width={'90%'} animation={'wave'} variant="rectangular" className="rounded-lg" /> */}
                    </div>
                )
            })}

        </div>
    )
}


export default FormSkeleton;