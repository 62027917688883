import { Pagination, Skeleton, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  Badge,

} from "@chakra-ui/react";
import { formatNumber } from 'utils';
export default function PaginateBox({ page, handleChange, count, total, rowsPerPage, handleChangeRowsPerPage, loading }) {
  // const [page, setPage] = useState(0);
  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  return (
  <>
      <div className="flex justify-between shadow py-3">
        <div className=' flex flex-1 justify-center '>

          <Badge
            fontSize={15}
            rounded={5}
            padding={1}
            paddingInline={2}
            colorScheme="green"
            color={"black"}
          >
            {formatNumber(total) || 0}
          </Badge>
        </div>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 75, 100,200,500,750,1000,2000,5000,10000]}
          labelRowsPerPage={"Nombre de lignes par page"}
          labelDisplayedRows={({ from, to, count })=> `Page: ${page+1} | ${from}–${to} sur ${count !== -1 ? count : `supérieur à ${to}`}`}
          
          lang='fr' 
          showFirstButton={true}
          showLastButton={true}
          
        />
        {/* <Pagination count={count}
          page={page}
          onChange={handleChange}
          color="success"
          variant="outlined"
          shape="rounded" /> */}
      </div>
    </>
  );
}

