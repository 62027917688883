import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Select } from "@chakra-ui/react";
import { ROLES } from "Constaints";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import { AppBar, Dialog, DialogContent, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import { CloseModalButton } from "components/Utils";
import MultipleSelect from "components/MultipleSelect";

const AddTask = (props) => {
  const canEdit = true
  const { isOpen, onClose, selectedTask } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    description: yup.string(),
    observations: yup.string(),
    // start: yup.string().required("Phone Number is a required field"),
    // end: yup.string().required("Phone Number is a required field"),

  });

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  // const [departement, setDepartement] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [departmentId, setDepartmentsId] = useState(null);
  const [arrondissementId, setArrId] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  // const [participants, setParticipants] = useState([]);
  const [loadingZ, errorZ, zones, fetchDataZ] = useDataFetching(
    `${config.app.api_url}/zones`
  );


  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );
  const [loadingU, errorU, users, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/agents`
  );

  const [loadingD, errorD, departments, fetchDataD] = useDataFetching(
    `${config.app.api_url}/regions/${regionId !== "*" && regionId}/departments`
  );

  const [loadingA, errorA, arrondissements, fetchDataA] = useDataFetching(
    `${config.app.api_url}/departments/${departmentId !== "*" && departmentId}/arrondissements`
  );



  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("🚀 ~ AddMission ~ errors:", errors)
  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`tasks`, "POST");
  const handleSave = async (dataYub) => {
    // console.log("dataYub", dataYub);
    const data = {
      id : selectedTask?.id,
      ...dataYub,
      regionId,
      departmentId,
      arrondissementId,
      zoneId,
      start,
      end,
      participants


    };
    console.log("🚀 ~ handleSave ~ data:", data)

    await postAxiosData(data);
  };



  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Tache ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      props?.endFetch()
      handleClose()
      // handleSignin(result);
    }
  }, [successMessage]);



  // console.log("🚀 ~ AddUser ~ regions:", regions)
useEffect(() => {
  if(selectedTask){
    setStart(new Date(selectedTask?.start))
    setEnd(new Date(selectedTask?.end))
    setParticipants(selectedTask?.participants?.map((p)=>p?.id))
    setRegionId(selectedTask?.region?.id)
    setDepartmentsId(selectedTask?.department?.id)
    setArrId(selectedTask?.arrondissement?.id)
    setZoneId(selectedTask?.zone?.id)
    setValue('description', selectedTask?.description)
    setValue('observations', selectedTask?.observations)
  }
}, [selectedTask]);

const handleClose = ()=>{
  setArrId(null)
  setRegionId(null)
  setDepartmentsId(null)
  setArrId(null)
  setStart(null)
  setEnd(null)
  setParticipants([])
  setZoneId(null)
  reset()
  onClose()

}
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      // TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className="bg-gray-50"
          >            <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              {selectedTask ? "Editer" : "Ajouter"} une tâche</Typography>
            <CloseModalButton iconColor={'red'} handleClose={handleClose} />

          </Toolbar>
        </AppBar>

        <DialogContent dividers>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="my-4 grid gap-2 md:grid-cols-1">
              {errorMassage?.message && (
                <Error
                  message={
                    errorMassage?.message !== "" && errorMassage?.message
                  }
                />
              )}

              <MultipleSelect
                disabled={!canEdit}
                label={"Selectionner des participants"}
                // marginRight={5}
                value={participants}
                options={users.data?.map((u)=>u[0])}
                format={{ value: "id", label: 'firstName' }}
                onChange={(event) => { setParticipants(event.target.value) }}
                emptyLabel={"Aucun"}
                
              />


              <MuiSelect
                disabled={!canEdit}
                label={"Region"}
                // marginRight={5}
                value={regionId}
                options={regions.data}
                format={{ value: "id", label: 'name' }}
                onChange={(event) => { setRegionId(event.target.value) }}
                emptyLabel={"Aucun"}
              />


              <MuiSelect
                disabled={!canEdit}
                label={"Département"}
                // marginRight={5}
                value={departmentId}
                options={departments.data}
                format={{ value: "id", label: 'name' }}
                onChange={(event) => { setDepartmentsId(event.target.value) }}
                emptyLabel={"Aucun"}

              />
              <MuiSelect
                disabled={!canEdit}
                label={"Arrondissements"}
                // marginRight={5}
                value={arrondissementId}
                options={arrondissements.data}
                format={{ value: "id", label: 'name' }}
                onChange={(event) => { setArrId(event.target.value) }}
                emptyLabel={"Aucun"}

              />
              <MuiSelect
                disabled={!canEdit}
                label={"Zone"}
                // marginRight={5}
                value={zoneId}
                options={zones.data}
                format={{ value: "id", label: 'description' }}
                onChange={(event) => { setZoneId(event.target.value) }}
                emptyLabel={"Aucun"}

              />
              <InputField
                register={register}
                variant="auth"
                extra="mb-3"
                label="Description"
                required={true}
                placeholder="description"
                id="description"
                name="description"
                type="text"
                error={
                  errors.description?.message !== ""
                    ? errors.description?.message
                    : ""
                }
              />
              <div className="flex gap-2">

                <MuiDatePicker
                  label={"Debut"}
                  value={start}
                  onChange={(newValue) => {
                    setStart(new Date(newValue))
                  }}
                  wrapperClasses={"flex-1 filter-item anime slideLeft"}

                />
                <MuiDatePicker
                  label={"Fin"}
                  value={end}
                  onChange={(newValue) => {
                    setEnd(new Date(newValue))
                  }}
                  wrapperClasses={"flex-1 filter-item anime slideLeft"}

                />
              </div>

              <InputField
                register={register}
                variant="auth"
                extra="mb-3"
                label="Observations"
                required={true}
                placeholder="Observations"
                id="observations"
                name="observations"
                type="text"
                multiline={true}

                error={
                  errors.observations?.message !== ""
                    ? errors.observations?.message
                    : ""
                }
              />
            </div>
            <div className="flex justify-between p-4">
              <div>
                <MButton
                  variant={"danger"}
                  onClick={(e) => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    onClose();
                    // window.location.reload();
                  }}>Fermer</MButton>
              </div>
              <div>
                <MButton
                isLoading={loading}
                variant={"primary"} type="submit" colorScheme="green">
             {selectedTask ? "Editer"  :   "Ajouter"}
                </MButton>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTask;
