import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionActions } from '@mui/material';
import MButton from './Mbutton';
import { BsPlusCircleFill } from 'react-icons/bs';

export default function MuiCollapse({index, children, title, subTitle ,bgColor, isExpandedP}) {
    // console.log("🚀 ~ MuiCollapse ~ items:", items)
    const [expanded, setExpanded] = React.useState(isExpandedP ? `panel${index}` : false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion
            style={{backgroundColor : bgColor}}
            expanded={ expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ minWidth: '33%', flexShrink: 0 }}>
                        {title}
                    </Typography>
                 {subTitle &&   <Typography sx={{ color: 'text.secondary' }}>{subTitle} </Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
                <AccordionActions>
                </AccordionActions>
            </Accordion>
         
        </div>
    );
}