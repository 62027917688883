// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { config } from 'variables/config';
import { Typography } from '@mui/material';


const PDFViewer = ({ fileName }) => {

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Worker  workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {fileName ?  <Viewer
                fileUrl={`${config.app.api_url}/files/${fileName}`}
                plugins={[
                    // Register plugins
                    defaultLayoutPluginInstance,
                ]}
            /> :
            <Typography variant="body1" color={'red'} fontStyle={'italic'} >Aucun url fourni</Typography>
        }
        </Worker>
    )
}

export default PDFViewer;