/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";
import "./sidebar.css";
import { Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsBoxArrowInLeft, BsSignNoLeftTurn } from "react-icons/bs";
import { useSignOut } from "react-auth-kit";
const Sidebar = ({ open, onClose }) => {
  const signOut = useSignOut();

  return (
    <div
      // className={`sidebar sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col  pb-10 shadow-2xl shadow-white/5 transition-all 
      // dark:!bg-navy-800 dark:text-white 
      // md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
      //   }`}
    >
      {/* <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span> */}

      {/* <div className={`mx-[56px] mt-[5px] flex items-center`}> */}
      <div className=" font-poppins  font-bold uppercase text-green-500 py-3 bg-green-50 "
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span> <img src="/assets/img/logo.png" alt="logo-image" width={60} /></span>
        <span>FNTEC-<span class="font-small">MINEFOP</span></span>
      </div>
      {/* </div> */}
      {/* <div class=" mb-3 h-px  " /> */}
      <hr />
      {/* Nav item */}

      <ul
        className="mb-auto pt-1 mt-[10px] "
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Links routes={routes} />

      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
