import {
  Button,
  Card,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import MuiTable from "components/MuiTable";

import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import AddUser from "./AddUser";
import UpdateUserInformations from "./UpdateUserInformations";
import { useState } from "react";
import PreloaderSpinner from "components/PreloaderSpinner";
import axios from "axios";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import MButton from "components/Mbutton";
import { AGENT_HEADER } from "Constaints";

const Comptes = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authHeader = useAuthHeader()

  const [isOpenUM, setIsOpenUM] = useState(false);
  const [closeUM, setCloseUM] = useState(false);

  const [loadingU, errorU, users, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/agents`
  );

  const auth = useAuthUser();
  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  const [userId, setUserId] = useState(null);
  const [enterpriseSize, setenterpriseSize] = useState("");
  const [uersF, setUserF] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let en = [];
    let empl = [];
    if (users) {
      setLoading(true)
      users?.data?.forEach((user) => {
        axios
          .get(
            `${config.app.api_url}/enterprises/global-stats?creatorId=${user[0]?.id}`
          )
          .then((res) => {
            // console.log(res)
            en?.push({ id: user[0]?.id, nb: res?.data?.data });
            setenterpriseSize(en);
            
          })
          .catch((err) => {
            console?.log(err);
            // en?.push({id : user[0]?.id, nb : 0})
            en?.push({ id: user[0]?.id, nb: 0 });
          })
          .finally(()=>setLoading(false));

        // axios.get(`${config.app.api_url}/employers/global-stats?creatorId=${user[0]?.id}`)
        // .then((res)=>{
        //   // console.log(res)
        //   empl?.push({id : user[0]?.id, nb : res?.data?.data})
        //   setemployersSize(empl)
        // })
      });
      setLoading(false)
    }

   
    // console.log(users?.data);
  }, [users]);
  async function getEnSize(userID) {
    if (userID) {
      try {
        const response = await axios.get(
          `${config.app.api_url}/enterprises/global-stats?creatorId=${userID}`,
          { headers: { Authorization: authHeader() } }
        );
        console.log("🚀 ~ file: Comptes.jsx:84 ~ getEnSize ~ response:", response)
        return response?.data?.data
      } catch (error) {
        console.log(error)
        return null
      }
    }
  }
  
  function createData(N, noms, phone, enterprises, employees, Options) {
    // const density = population / size;
    return {
      N, noms, phone, enterprises, employees, Options

    };
  }

  const rows = users?.data?.map((user, index) => {
    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      // handleClick: () => handleClick(user),

      datas: createData((index + 1), `${user[0]?.firstName} ${user[0]?.lastName}`, user[0]?.phone,
      <div
      onClick={() => {
        navigate("/admin/enterprises" , {
          state: { creator: user[0] },
        });
      }}
      to={{ pathname: "data-tables" }}
    >
                 
      {usr?.data?.roles.length > 0 &&
        usr?.data?.roles?.map((role) => {
          return (
            CHECK_PERMISSIONS(
              role?.permissions,
              "Entreprises enregistrées par un agent"
            ) && (
              <MButton>
                Entreprises
                {/* ({user[1]}) */}(
               
                  {enterpriseSize
                  ? enterpriseSize?.find(
                      (e) => e?.id === user[0]?.id
                    )?.nb || 0
                  : "..."}
                )
              </MButton>
            )
          );
        })}
    </div>,
        <div
        onClick={() => {
          navigate("/admin/employes" , {
            state: { creator: user[0] },
          });
        }}
      // to={{ pathname: "data-tables" }}
    >
      {/* {usr?.data?.roles.length > 0 &&
        usr?.data?.roles?.map((role) => {
          return (
            CHECK_PERMISSIONS(
              role?.permissions,
              "Expatriés enregistrées par un agent"
            ) &&
          );
        })} */}
         <MButton>Travailleurs ({(user[1])})</MButton>
    </div>,
     <div className="flex justify-between">
     <div>
       {usr?.data?.roles.length > 0 &&
         usr?.data?.roles?.map((role) => {
           return (
             CHECK_PERMISSIONS(
               role?.permissions,
               "Modifier les informations d'un agent"
             ) && (
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke-width="1.5"
                 stroke="currentColor"
                 className="h-6 w-6 text-orange-400"
                 role="button"
                 onClick={() => {
                   setIsOpenUM(true);
                   setUserId(user[0]?.id);
                 }}
               >
                 <path
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                 />
               </svg>
             )
           );
         })}
     </div>
   </div>
    )
    

      
    }
  })

  return (
    
    <div>
      {loadingU && <PreloaderSpinner />}
     { 
     true ? 
     <MuiTable hidePagiation={false} columns={AGENT_HEADER}
     rows={rows} textEmpty={"Pas de missions"} /> :<div className="mt-2 grid h-full grid-cols-1 gap-5">
        <div className="mt-5 flex justify-end">
          {usr?.data?.roles.length > 0 &&
            usr?.data?.roles?.map((role) => {
              return (
                CHECK_PERMISSIONS(role?.permissions, "Ajouter un agent") && (
                  <Button onClick={() => onOpen()} colorScheme="blue">
                    <MdAdd className="h-6 w-6" />
                    Ajouter un agent
                  </Button>
                )
              );
            })}
        </div>
        {/* <div>
          <label
            className={`ml-3 text-3xl font-bold text-navy-700 dark:text-white`}
          >
            Liste des comptes des agents
          </label>
        </div> */}
        <div extra={"w-full h-full p-4"}>
          <TableContainer>
            <Table >
              <Thead>
                <Tr>
                  {/* <Th>userId</Th> */}
                  <Th>Nom & Prénoms</Th>
                  <Th>Email</Th>
                  <Th>Entreprises enregistrées</Th>
                  <Th>Travailleur enregistrés</Th>
                  <Th>Option</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {console.log('users', users)} */}
                {users?.data &&
                  users?.data?.map((user, index) => {
                    return (
                      <Tr key={index}>
                        {/* <Td>{user?.userId}</Td> */}
                        <Td>
                          {user[0]?.firstName} {user[0]?.lastName}
                        </Td>
                        <Td>{user[0]?.phone}</Td>
                        <Td>
                          <div
                            onClick={() => {
                              navigate("/admin/enterprises" , {
                                state: { creator: user[0] },
                              });
                            }}
                            to={{ pathname: "data-tables" }}
                          >
                                       
                            {usr?.data?.roles.length > 0 &&
                              usr?.data?.roles?.map((role) => {
                                return (
                                  CHECK_PERMISSIONS(
                                    role?.permissions,
                                    "Entreprises enregistrées par un agent"
                                  ) && (
                                    <MButton>
                                      Entreprises
                                      {/* ({user[1]}) */}(
                                     
                                        {enterpriseSize
                                        ? enterpriseSize?.find(
                                            (e) => e?.id === user[0]?.id
                                          )?.nb || 0
                                        : "..."}
                                      )
                                    </MButton>
                                  )
                                );
                              })}
                          </div>
                        </Td>
                        <Td>
                          {/* {console.log("user tr", user)} */}
                          <div
                              onClick={() => {
                                navigate("/admin/employes" , {
                                  state: { creator: user[0] },
                                });
                              }}
                            // to={{ pathname: "data-tables" }}
                          >
                            {/* {usr?.data?.roles.length > 0 &&
                              usr?.data?.roles?.map((role) => {
                                return (
                                  CHECK_PERMISSIONS(
                                    role?.permissions,
                                    "Expatriés enregistrées par un agent"
                                  ) &&
                                );
                              })} */}
                               <MButton>Travailleurs ({(user[1])})</MButton>
                          </div>
                        </Td>

                        <Td>
                          <div className="flex justify-between">
                            <div>
                              {usr?.data?.roles.length > 0 &&
                                usr?.data?.roles?.map((role) => {
                                  return (
                                    CHECK_PERMISSIONS(
                                      role?.permissions,
                                      "Modifier les informations d'un agent"
                                    ) && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6 text-orange-400"
                                        role="button"
                                        onClick={() => {
                                          setIsOpenUM(true);
                                          setUserId(user[0]?.id);
                                        }}
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </div>}

      {/* Modal création d'un user */}
      <AddUser  endFetch={fetchDataU}  isOpen={isOpen} onClose={onClose} />

      {/* Modification des informations du user */}
      <UpdateUserInformations
        fetchData={fetchDataU}
        isOpen={isOpenUM}
        setIsOpen={setIsOpenUM}
        onClose={closeUM}
        userId={userId}
      />
    </div>
  );
};

export default Comptes;
