import { useRef, useState } from "react"

const { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, ModalFooter, Input, useDisclosure, Center, Alert, AlertIcon, AlertTitle, AlertDescription } = require("@chakra-ui/react")
export default function MModal({ item, open, setOpen }) {

  const initialRef = useRef(null)
  // const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      {console.log(item)}

      <Modal
        initialFocusRef={initialRef}
        isOpen={open}
        onClose={onClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informations sur le travailleur étranger</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
              {new Date(item?.permitDate) <= new Date() && <Alert className="mb-5" status='error'>
                <AlertIcon />
                <AlertTitle>Permis de travail expiré</AlertTitle>
                {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
              </Alert>}
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du permis de travail</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.permitNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'expiration du permit de travail</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.permitDate} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Noms et prénoms</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Noms et prénoms' value={item?.fullName} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Poste</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.occupation?.name} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Sexe</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.sexe && item?.sexe === "M" ? "Masculin" : "Feminin"} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Salaire</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.salary} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>nationalité</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.nationality} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Numéro du permis</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.permitNumber} />
              </FormControl>
            </div>
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Entreprise</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.enterprise?.socialRaison} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>As t-il un permis de travail</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.hasPermit && (item?.hasPermit === true ? 'Oui' : 'Non')} />
              </FormControl>
            </div>
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du contrat</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.contratNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Duréé du contart</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.contratDuration + " an(s)"} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du visa</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.visaNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'obtention du visa</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.passportExpDate} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du passport</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Aucune valeur' value={item?.passportNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'expiration du passport</FormLabel>
                <Input readOnly placeholder='Aucune valeur' value={item?.passportExpDate} />
              </FormControl>
            </div>


          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3}>
                Save
              </Button> */}
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}