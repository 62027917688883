import { AppBar, Box, capitalize, Chip, Tab, Tabs } from "@mui/material";
import MButton from "components/Mbutton";
import useDataFetching from "hooks/useDataFetching";
import { Navigation } from "react-calendar";
import { useParams } from "react-router-dom";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import { EMPLOYER_HEADER } from "Constaints";
import { REPORT_HEADER } from "Constaints";
import { useState } from "react";
import { BsBank2, BsCardHeading, BsPeople } from "react-icons/bs";
import { formatDate } from "utils";
import { formatNumber } from "utils";
import { EMLOYEE_REQUEST_HEADER } from "Constaints";
import { CONTRACT_REQUEST_HEADER } from "Constaints";
import { ENTERPRISE_REQUEST_HEADER } from "Constaints";




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const RequestsAccount = () => {
    const { id } = useParams()

    const [loadingEnter, errorEnter, enterprises, getEnterprises] = useDataFetching(`${config.app.api_url}/user-requests?requestEntityType=ENTERPRISE`);

    const [params, setParams] = useState({page : 0, limit : 20});
    const [loadingEM, errorEm, employees, getEmployee] = useDataFetching(`${config.app.api_url}/enterprises/${id}/employee-requests`);
    const [loadingCOn, errorCon, contracts, getContracts] = useDataFetching(`${config.app.api_url}/enterprises/${id}/contracts-requests`);

    function createDataEnterprise(N, Raison, NIU, Motif, Type, Date,Status, Options) {
        // const density = population / size;
        return { N, Raison, NIU, Motif, Type, Date,Status, Options };
    }

    

    function createDataEmployees(N, Noms, Nationality, Motif, Type, Date, Status, Options) {
        // const density = population / size;
        return {
            N, Noms, Nationality, Motif, Type, Date, Status, Options

        };
    }

    function createDataContracts(N, Noms, Nationality, Salary, Motif, Type, Date, Status, Options) {
        // const density = population / size;
        return {
            N, Noms, Nationality, Salary, Motif, Type, Date, Status, Options

        };
    }
    const handleClick = (user) => {
        Navigation(`/admin/account/enterprises/${user.id}`, { state: { selectedUser: user } })
        // setIsOpenUM(true);
        // setselectedUser(user);
    }
    const enterpriseRows = enterprises?.data?.content?.map((item, index) => {
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: () => handleClick(item),

            datas: createDataEnterprise((params?.page * params?.limit) + (index + 1),

                <span className="fs-c">{(item?.targetedEnterprise?.socialRaison)}</span>
                ,
                item?.targetedEnterprise?.nui?.toUpperCase(),
                item?.motif,
                item?.requestEntityType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'VALIDATED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>
                )

        }
    }
    )
    const employeesRows = employees?.data?.content?.map((item, index) => {
        // const l = getLastContract(item?.contracts)
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: (e) => handleClick(item),

            datas: createDataEmployees((index + 1),

                item?.targetedEmployer?.fullName || item?.targetedEmployer?.firstName + " " + item?.targetedEmployer?.lastName,
                item?.targetedEmployer?.nationality
                ,
                item?.motif,
                item?.requestEntityType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'VALIDATED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>

            ),

        }
    }
    )


    const contractsRows = contracts?.data?.content?.map((item, index) => {
        // const l = getLastContract(item?.contracts)
        return {
            // color: !checkIsValidPermitDate(item) ? 'red' : '',
            handleClick: (e) => handleClick(item),

            datas: createDataContracts(
                (index + 1),

                item?.targetedContract?.employer?.fullName || item?.targetedContract?.employer?.firstName + " " + item?.targetedContract?.employer?.lastName,
                item?.targetedContract?.employer?.nationality
                ,
                item?.motif,
                formatNumber(item?.targetedContract?.salary),
                item?.requestEntityType,
                formatDate(item?.createdAt),
                item?.status === 'REJECTED' ?
                    <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Rejete")} />
                    :
                    item?.status === 'VALIDATED' ?
                        <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
                        <Chip className="capitalize-none" variant="outlined" color='warning' label="En attente" />,

                <MButton
                    variant={"white"}
                    onClick={(e) => {
                        // setSelectedEnterprise(item)
                        // e.preventDefault(); e?.stopPropagation(); setOpenList(true)
                    }}>
                    {/* <BsList /> */}
                    Consulter
                </MButton>

            ),

        }
    }
    )


    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{}}>
                <AppBar position="static" sx={{ width: '100%', backgroundColor: 'white', color: 'black' }}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                    <Tabs
                        centered
                        value={value}
                        onChange={handleChange}
                        aria-label="Request"
                        indicatorColor="primary"
                        textColor="inherit"
                    // variant="fullWidth"
                    >
                        <Tab icon={<><BsBank2 size={25} /> </>} label={<span>Emtreprise<span className="text-red-500 ms-1">{`(${enterprises?.data?.totalElements || '--'})`}</span></span>} {...a11yProps(0)} />

                        <Tab icon={<><BsPeople size={25} /> </>} label={<span>Employers<span className="text-red-500 ms-1">{`(${employees?.data?.totalElements || "--"})`}</span></span>} {...a11yProps(1)} />
                        <Tab icon={<BsCardHeading size={25} />} label={<span>Contracts<span className="text-red-500 ms-1">{`(${contracts?.data?.totalElements || '--'})`}</span></span>}  {...a11yProps(2)} />
                    </Tabs>
                    {/* </Box> */}
                </AppBar>

                <div className="">
                <CustomTabPanel value={value} index={0}>
                        <MuiTable loading={loadingEnter} className={''} hidePagiation={true} columns={ENTERPRISE_REQUEST_HEADER} rows={enterpriseRows} textEmpty={"Pas de demande"} />
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <MuiTable loading={loadingEM} className={''} hidePagiation={true} columns={EMLOYEE_REQUEST_HEADER} rows={employeesRows} textEmpty={"Pas de demande"} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <MuiTable loading={loadingCOn} className={''} hidePagiation={true} columns={CONTRACT_REQUEST_HEADER} rows={contractsRows} textEmpty={"Pas de demande"} />
                    </CustomTabPanel>
                </div>
            </Box>
        </>
    )
}

export default RequestsAccount