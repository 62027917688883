import { Tooltip } from "@chakra-ui/tooltip";
import { BsFilePdf } from "react-icons/bs";
import PrintButtonExcel from "./PrintButtonExcel";

export default function PrintButton({toPDF, text, bg, color}) {
    return(
        <>
        <Tooltip hasArrow label="Imprimer en pdf"  bg='red.200' color='black' placement="right">

         <button
              className={`flex items-center  gap-2 rounded-md p-1 shadow ${bg || ''} ' ' ${color || ''} `}
              onClick={() => toPDF()}
              >
              <BsFilePdf className="text-primary " /> {text}
            </button>
              </Tooltip>

        </>
    )
}