import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card } from "@chakra-ui/react";
import { usePDF } from "react-to-pdf";
import { BsFilePdf } from "react-icons/bs";
import PrintButton from "./PrintButton";
import PrintButtonExcel from "./PrintButtonExcel";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = (props) => {
  const { title, data, options, legends } = props;
  // console.log("🚀 ~ file: BarChart.jsx:30 ~ BarChart ~ legends:", legends)
  // console.log("data ", data)
  // console.log("options ", options)
  const [dataPie, setDataPie] = useState();
  const [colorsLengend, setColorsLegend] = useState();
  // background color generation
  const generateColor = (number) => {
    let table = [];
    for (let i = 0; i < number; i++) {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      if (!table.includes("#" + randomColor)) {
        table.push("#" + randomColor);
      }
    }
    return table;
  };

  let optionsBarChart = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Statistiques graphique",
      },
    },
    // scales: {
    //   y: {
    //     beginAtZero: true
    //   }
    // },
    maintainAspectRatio: false,
  };

  let labels = options;

  let colors = [];

  useEffect(() => {
    if (options && options?.length > 0) {
      setColorsLegend(generateColor(options?.length));
      colors = generateColor(options?.length);

      setDataPie({
        labels: labels?.map((str) => {
          return str?.charAt(0).toUpperCase() + str?.slice(1)?.toLowerCase();
        }),
        datasets: [
          {
            label: props?.noPercent ? "Nombre" : "%",
            data: data?.sort((a, b) => b - a),
            backgroundColor: colors,
            borderColor: colors,
            // borderWidth: 1,
            // barPercentage: 0.6,
            // borderRadius: 3,
            // inflateAmount: 1,
            // barThickness: 20,

            datalabels: {
              formatter: function (value, context) {
                return value == 0 ? null : props?.noPercent ? value :value +  "%";
              },
              color: "#ffffff",
              // backgroundColor: "rgba(0,0,0,0.7)",
              borderRadius: 3,
              padding: 3,
              anchor: "center",
              textAlign: "center",
              labels: {
                title: {
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
          },
        ],
      });
    }
  }, [options, data]);

  const { toPDF, targetRef } = usePDF({ filename: title + ".pdf" });
  return (
    <div className={`bg-white w-full h-full`}>
      <div className="flex w-full justify-end gap-2">
        <PrintButton toPDF={toPDF} color={"text-primary"} />
        <PrintButtonExcel
          filename={title}
          header={["Label", "Nombre", "Porcentage"]}
          body={legends?.map((l) => {
            return [l?.label, l?.number, l?.percent];
          })}
        />
      </div>
      <div
        ref={targetRef}
        className={
          "flex flex-col rounded-[20px] rounded-[5px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none"
        }
      >
        <div className="flex flex-row justify-between px-3 pt-2">
          <div>
            
            <strong className="text-xl text-navy-700 dark:text-white">
              {title}
            </strong>
          </div>
        </div>
        <div className="mx-10 mb-auto">
          {/* {console.log("🚀 ~ file: BarChart.jsx:96 ~ BarChart ~ dataPie:", dataPie)} */}
          {/* {titles && <PieChart options={titles} series={data} />} */}
          {dataPie ? (
            <Bar data={dataPie} height={500} options={optionsBarChart} />
          ) : (
            <div className="alert alert-warning">Aucune donnée...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
