import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import { Typography } from "@mui/material";
import { Badge } from "@chakra-ui/react";
import { formatNumber } from "utils";
import { NotFoundResults } from "components/Utils";
import { useAuthHeader } from "react-auth-kit";

export const Related = (props) => {
    // console.log("🚀 ~ Relateds ~ props:", props)
    const authHeader = useAuthHeader()
  
    const [datas, setdatas] = useState([]);
    // let res
    const get = () => {
        axios.get(`${config.app.api_url}/enterprises/${props?.enterprise?.id}/employers`,
            { headers: { Authorization: authHeader() } }
        )
            .then((res) => setdatas(res?.data?.data?.sort((a,b)=>a?.status > b?.status ? 1 : -1)))
            .catch((er) => { console.log(er) })


    }
    useEffect(() => {
        get()
    }, [props]);
    // console.log("🚀 ~ Relateds ~ res:", res)
    return (
        // datas?.length > 0 && datas?.sort((a,b)=>a?.status > b?.status ? 1 : -1)?.map((d) => {
            // return (
            <div>
                <Typography>{props?.enterprise?.socialRaison}
                <Badge
             fontSize={13}
             rounded={5}
             padding={1}
             paddingInline={2}
             colorScheme="red"
             className="mx-1"
             // style={{ wi: -15 }}
             >
             {formatNumber(datas?.length) || '--'}
           </Badge> 
                </Typography>
              {datas?.length >0  ? <MuiTable hidePagiation={true} columns={props?.columns}
                    rows={props?.rows(datas)} /> 
                    :
                    <NotFoundResults/>
                    }
            </div>
            )
        // })
    // )


    //  const data = response?.data?.data
    //  console.log("🚀 ~ Relateds ~ data:", data)
}