import React, { useEffect, useMemo } from 'react';

import MButton from './Mbutton';
import { BsSearch, } from 'react-icons/bs';
import { ClosedIcon, DeletedIcon, FromArchiveIcon, FromTheFieldIcon, GoneIcon } from './TableIcon';
import { AppBar, Avatar, Dialog, DialogContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { CloseModalButton, NotFoundResults } from './Utils';
import { ENTERPRISE_STATUS } from 'Constaints';
import { EMPLOYER_STATUS } from 'Constaints';
import { Text } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { capitalyse } from 'utils';
import { formatDate } from 'utils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const SearchModal = ({ searchResults, searchTerm, setSearchTerm, placeholder = "Search...", iconLabel, title = "Rechercher", searchFunction, handleClickResult, loading }) => {
    // console.log("🚀 ~ SearchModal ~ searchTerm:", searchTerm?.length)
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const [searchTerm, setSearchTerm] = useState("");
    // const [searchResults, setSearchResults] = useState([]);
    const debouncedSearch = useMemo(
        () => debounce(searchFunction, 500), // 500ms delay, adjust as needed
        [searchFunction]
    );
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (open) {
            const searchInput = document.getElementById('search-input');
            if (searchInput) {
                searchInput?.focus();
            }
        }
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };

    // useEffect(() => {
    //     // const filteredResults = data.filter((item) =>
    //     //     item.toLowerCase().includes(searchTerm.toLowerCase())
    //     // );
    //     // setSearchResults(filteredResults);

    //     searchFunction(searchTerm)
    // }, [searchTerm]);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleResultClick = (item) => {
        // Handle click on a search result (optional)
        handleClickResult(item)
        // console.log("Clicked:", item);
        // onClose(); // Close the modal after selection (optional)
    };


    useEffect(() => {
        debouncedSearch(searchTerm);
        // Cleanup the debounce on unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchTerm, debouncedSearch]);


    return (
        <>
            <MButton onClick={handleClickOpen} > <BsSearch /> {iconLabel} </MButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                // sx={{ marginTop: -40 }}
                fullWidth
                maxWidth={'md'}

            >
                <AppBar sx={{ backgroundColor: 'white', position: 'relative' }}>
                    <Toolbar className="bg-gray-50">
                        <Typography sx={{ ml: 2, flex: 1 }} color={'green'} variant="h6" component="div">
                            {title}
                        </Typography>
                        {/* <CloseModalButton
                            handleClose={handleClose}
                        /> */}
                        <CloseModalButton iconColor={"red"} handleClose={handleClose} />

                    </Toolbar>
                    <Divider />
                    <div className='flex justify-center mt-3'>
                        {/* <Input
                            style={{ width: '70%', height: 60 }}
                            className='shadow'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={placeholder}
                            mb={4}

                        /> */}
                        <TextField
                            style={{ width: '70%' }}
                            className='shadow'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={placeholder}
                            id="search-input"
                            label={placeholder}
                            type="search"
                            focused
                            autoFocus

                        />
                    </div>
                </AppBar>


                <DialogContent
                // className='flex flex-col items-center'
                >
                    {searchResults?.length > 0 ? (
                        <List className='mt-5' >
                            {searchResults?.map((item) => (

                                <ListItem
                                    className={`shadow cursor-pointer    hover:scale-105 hover:font-bold  hover:bg-gray-200 transition duration-300 
                                    ${item?.status == ENTERPRISE_STATUS.CLOSED.value ? 'text-gray-500' : ''}
                                    ${item?.status == ENTERPRISE_STATUS.DELETED.value ? 'text-gray-500' : ''}
                                    ${item?.status == EMPLOYER_STATUS.GONE.value ? 'text-gray-500' : ''}
                                    ${item?.status == EMPLOYER_STATUS.DELETED.value ? 'text-gray-500' : ''}
                                    `}
                                    onClick={() => handleResultClick(item)}
                                    key={item?.id}
                                >
                                    <ListItemAvatar>
                                            {
                                                item?.status == EMPLOYER_STATUS.GONE.value ?
                                                    <GoneIcon/> :
                                                    item?.status == ENTERPRISE_STATUS.CLOSED.value ?
                                                        <ClosedIcon/> :
                                                        item?.status == ENTERPRISE_STATUS.DELETED.value ?
                                                            <DeletedIcon/> :

                                                            item?.fromArchive ?
                                                                <FromArchiveIcon /> :
                                                                 <FromTheFieldIcon />
                                            }
                                        {/* <Avatar>
                                        </Avatar> */}
                                    </ListItemAvatar>
                                    {/* <ListIcon as={
                                        item?.status == EMPLOYER_STATUS.GONE.value ?
                                            GoneIcon :
                                            item?.status == ENTERPRISE_STATUS.CLOSED.value ?
                                                ClosedIcon :
                                                item?.status == ENTERPRISE_STATUS.DELETED.value ?
                                                    DeletedIcon :

                                                    item?.fromArchive ?
                                                        () => <FromArchiveIcon /> :
                                                        () => <FromTheFieldIcon />
                                    } color='green.500' /> */}


                                    {/* {item?.socialRaison || item?.fullName || item?.firstName + " " + item?.lastName}--{item?.nui || item?.enterprise?.socialRaison || item?.passportNumber}{typeof item?.employeesNumber === 'number' ? `--${item?.employeesNumber} expatriés.` : '--'}  */}
                                    
                                    <ListItemText primary={item?.socialRaison || item?.fullName || item?.firstName + " " + item?.lastName} 
                                    
                                    secondary={`${capitalyse(item?.enterpriseName?.toLowerCase()) || ''} ${ item?.passportNumber?.toLowerCase() || ''} ${ item?.nui?.toUpperCase() || ''} ${typeof item?.employeesNumber === 'number' ? `--${item?.employeesNumber} expatriés.` : '--'}`} />
                                        {/* secondary={`${item.answer ? item.answer?.toUpperCase()+" :" : ''} ${item.reason || ''} ${item?.supportCollectedEnterprise || ''}   ${item?.supportCollectedEmployees || ''} ${item?.meetDate ? 'rdv : '+formatDate(item?.meetDate) : ''}`}
                              /> */}
                                    </ListItem>

                            ))}

                        </List>


                    ) : loading ?
                        <Text >Recherche en cours...</Text>

                        : (
                            searchTerm?.length == 0 ? <></> : <NotFoundResults />
                        )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SearchModal;
