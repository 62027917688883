import dayjs from "dayjs"
import { useRef, useState } from "react"
import { BsFilePdf, BsPencilSquare } from "react-icons/bs"
import { usePDF } from "react-to-pdf"
import { checkIsValidPermit } from "utils"
import PrintButton from "views/admin/default/components/PrintButton"
import { Tooltip } from "@chakra-ui/tooltip";

const { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, ModalFooter, Input, useDisclosure, Center, Alert, AlertIcon, AlertTitle, AlertDescription } = require("@chakra-ui/react")
export default function MModal({ item, open, setOpen }) {

  const initialRef = useRef(null)
  // const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false)
  }
  const [canEdit, setcanEdit] = useState(false);

  const { toPDF, targetRef } = usePDF({ filename: item?.fullName + ".pdf" });

  return (
    <>
      {/* {console.log(item)} */}

      <Modal
        initialFocusRef={initialRef}
        isOpen={open}
        onClose={onClose}
        size={"6xl"}

      >

        <ModalOverlay />
        <ModalContent   >

          <ModalHeader>
            <div className="flex w-full justify-start gap-2">
              <span>Informations sur le travailleur étranger</span>
              {/* <button
          className="flex items-center  gap-2 rounded-md shadow p-1"
          onClick={() => toPDF()}
        >
          <BsFilePdf className="text-primary " /> 
        </button> */}
              <Tooltip hasArrow label="éditer" bg='red.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow `}
                  onClick={() => setcanEdit(!canEdit)}
                >

                  <BsPencilSquare className="text-info" />

                </button>
              </Tooltip>
              <PrintButton toPDF={toPDF} />
            </div>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={targetRef} pb={6}>
            {!checkIsValidPermit(item) && <Alert className="mb-5" status='error'>
              <AlertIcon />
              <AlertTitle>Permis de travail expiré</AlertTitle>
              {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
            </Alert>}
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du permis de travail</FormLabel>
                <Input readOnly={!canEdit} onChange={(e) => console.log(e?.target.value)} ref={initialRef} placeholder='Aucune valeur' value={item?.permitNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'expiration du permit de travail</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={dayjs((new Date(new Date(item?.permitDate).setFullYear(new Date(item?.permitDate).getFullYear() + 2))))?.format('DD-MM-YYYY')} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              {/* <FormControl>
                <FormLabel>prénoms</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Noms et prénoms' value={item?.firstName} />
              </FormControl>
              <FormControl>
                <FormLabel>Noms</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Noms et prénoms' value={item?.lastName} />
              </FormControl> */}
               <FormControl>
                <FormLabel>Noms et prénoms</FormLabel>
                <Input readOnly ref={initialRef} placeholder='Noms et prénoms' value={item?.firstName +" "+item?.lastName} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Poste</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={item?.occupation?.name} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Sexe</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.sexe && item?.sexe === "M" ? "Masculin" : "Feminin"} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Salaire (XAF)</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={item?.salary?.toLocaleString()} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Nationalité</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.nationality} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Numéro du permis</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={item?.permitNumber} />
              </FormControl>
            </div>
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Entreprise</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.enterprise?.socialRaison} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Permis de travail valide ?</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={item?.hasPermit && (item?.hasPermit === true ? 'Oui' : 'Non')} />
              </FormControl>
            </div>
            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du contrat</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.contratNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Duréé du contart</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={item?.contratDuration + " an(s)"} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du visa</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.visaNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'obtention du visa</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={dayjs(item?.passportExpDate).format('DD-MM-YYYY')} />
              </FormControl>
            </div>

            <div className="flex mb-3" >
              <FormControl>
                <FormLabel>Numéro du passeport</FormLabel>
                <Input readOnly={!canEdit} ref={initialRef} placeholder='Aucune valeur' value={item?.passportNumber} />
              </FormControl>

              <FormControl className="ml-2" >
                <FormLabel>Date d'expiration du passeport</FormLabel>
                <Input readOnly={!canEdit} placeholder='Aucune valeur' value={dayjs(item?.passportExpDate).format('DD-MM-YYYY')} />
              </FormControl>
            </div>


          </ModalBody>

          <ModalFooter>
           {canEdit &&  <Button colorScheme='blue' mr={3}>
                Save
              </Button>}
            <Button onClick={onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}