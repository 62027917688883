import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "react-auth-kit";
import Checkbox from '@mui/material/Checkbox';
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
  createTheme as muiCreateTheme,
  THEME_ID,
} from '@mui/material/styles';

import { orange } from '@mui/material/colors';
import refreshApi from "authUtils/refreshTokenApi";
import { COLORS } from "constant/COLORS";
const root = ReactDOM.createRoot(document.getElementById("root"));
// Import the functions you need from the SDKs you need



// TODO: Add SDKs for Firebase products that you want to use



// const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
//   // color: theme.status.danger,
//   // '&.Mui-checked': {
//   //   color: theme.status.danger,
//   // },
// }));
// const materialTheme = muiCreateTheme();
// const theme = createTheme({
//   status: {
//     // danger: orange[500],
//   },
// });

const materialTheme = createTheme({
  components: {
    // MuiTextField :{
    //   styleOverrides : {
    //     root : {
    //       color : 'red',
    //       borderColor : 'green'
    //     }
    //   }
    // },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: COLORS.PRIMARY,
              color: '#fff',
            }),
        }),
      },
  },
  },
});


root.render(
  <BrowserRouter>
    <ChakraProvider>
    <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
      <AuthProvider
        authType={"localstorage"}
        authName={"_auth"}
        // cookieDomain={window.location.hostname}
        // cookieSecure={window.location.protocol === "https:"}
        refresh={refreshApi}
      >
        <App />
      </AuthProvider>
      </MaterialThemeProvider>
    </ChakraProvider>
  </BrowserRouter>
);
