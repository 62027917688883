
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Error from "components/message/Error";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { AppBar, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import { CloseModalButton } from "components/Utils";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { formatDate } from "utils";
import { Alert, AlertIcon, AlertTitle, Badge } from "@chakra-ui/react";
import { useAuthUser } from "react-auth-kit";
import { isValidEmail } from "utils";

const Notify = (props) => {
  const canEdit = true
  const { isOpen, onClose, selectedEnterprise } = props;
  // console.log("🚀 ~ Notify ~ selectedEnterprise:", selectedEnterprise)
  const auth = useAuthUser();






  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(null, "POST");


  const [email, setEmail] = useState('');
  // console.log("🚀 ~ Notify ~ email:", email)

  // useEffect(() => {
  //   email && confirm("Etes vous sur de vouloir envoyer un mail sur cette addresse ? ") && postAxiosData()
  // }, [email]);
  const [error, setError] = useState('');

  const handleSend = (email) => {
    if(!isValidEmail(email)) {
       setError("Aucune des adresses mails n'est valide, veillez editer au prélable ces informations")
       return
      }else{
        setError('')
      }
      alert("Désolé , la fonctionalité non disponible pour le moment ! ")
      return 
    email && window.confirm("Etes vous sur de vouloir envoyer un mail sur cette addresse :  ? "+email)  && postAxiosData(null,`enterprises/${selectedEnterprise?.id}/notify-employees-status?email=${email}`)
  }
  /** gestion des erreurs et succès */
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Notification initier avec success."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      // props?.endFetch()
      // setSelectedEnterprise(null)
      onClose()

      // handleSignin(result);
    }
  }, [successMessage]);



  // console.log("🚀 ~ AddUser ~ regions:", regions)

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      // sx={{minHeight : 400}}
      // TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className="bg-gray-50"
          >
            <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              {`Verifiez les information de contact avant l'envoie de mail l'entreprise   ${selectedEnterprise?.socialRaison}   `}
            </Typography>
            <CloseModalButton iconColor={'red'} handleClose={onClose} />

          </Toolbar>
        </AppBar>

        <DialogContent dividers>
        <Alert className="mb-5 rounded-lg shadow" status={'warning'}>
                <AlertIcon />
                <AlertTitle> Si aucune des adresses mails n'est valide, veillez editer au prélable ces informations
                </AlertTitle>
                {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
              </Alert>

              {
                ((!isValidEmail(selectedEnterprise?.email) && !isValidEmail(selectedEnterprise?.rhemail) ) || error) &&  <Alert className="mb-5 rounded-lg shadow" status={'error'}>
                <AlertIcon />
                <AlertTitle> {error || "Aucune des adresses mails n'est valide, veillez editer au prélable ces informations"}
                </AlertTitle>
                {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
              </Alert>
              }


          <Typography variant="h6"
          marginBottom={3}
          >
            Selectionner l'addresse :
          </Typography>
          <Paper aria-label="secondary mailbox folders"
          >
            <Typography
              marginInline={2}
              fontWeight={400}
              variant="h6"
            >
              Nom du RH : <strong> {selectedEnterprise?.rhname}</strong>
            </Typography>
            <List title="">
              {
                [
                  { "label": "Email DU DRH", "value": selectedEnterprise?.rhemail },
                  { "label": "Email entreprise", "value": selectedEnterprise?.email },

                ]?.map((same, index) => {
                  return (
                    <>
                      <ListItem key={index} disablePadding

                      >
                        <ListItemButton
                          onClick={() => handleSend(same?.value)}
                        >
                          <ListItemText primary={`${same?.label} : ${same?.value} `}
                          />
                        </ListItemButton>

                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  )
                })
              }
            </List>
          </Paper>
          <Divider />
          <div className="flex justify-between p-4">
            <div>
              <MButton
                variant={"danger"}
                onClick={(e) => {
                  e?.stopPropagation()
                  e?.preventDefault()
                  onClose();
                  // window.location.reload();
                }}>Fermer</MButton>
            </div>
            {/* <MButton
              onClick={() => postAxiosData()}
              disabled={loading}
              isLoading={loading}

              variant={"primary"} type="submit" colorScheme="green">
              Notifier
            </MButton> */}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Notify;
