
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Error from "components/message/Error";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { AppBar, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import { CloseModalButton } from "components/Utils";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { formatDate } from "utils";
import { Badge } from "@chakra-ui/react";
import { useAuthUser } from "react-auth-kit";
import { ENTERPRISE_STATUS } from "Constaints";
import { ClosedIcon } from "components/TableIcon";
import { DeletedIcon } from "components/TableIcon";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";

const MergeEnterprises = (props) => {
  const canEdit = true
  const { isOpen, onClose, selectedEnterprise } = props;
  const auth = useAuthUser();

  /** Form validation config */
  const schema = yup.object().shape({
    // participants: yup.string().required("Participants is a required field"),
    // description: yup.string(),
    // start: yup.string().required("Phone Number is a required field"),
    // end: yup.string().required("Phone Number is a required field"),

  });

  // const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  // console.log("🚀 ~ AddMissionEnterprise ~ selectedEnterprise:", selectedEnterprise)

  const [loadingM, errorM, sames, fetchSames] = useDataFetching(
    selectedEnterprise?.nui ? `${config.app.api_url}/enterprises/search-all-by-nui/${selectedEnterprise?.nui}` : null
  );
  // console.log("🚀 ~ MergeEnterprises ~ selectedEnterprise:", selectedEnterprise)
  useEffect(() => {
    selectedEnterprise && fetchSames()
  }, [selectedEnterprise]);

  // const [participants, setParticipants] = useState([]);
  // const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
  //   `${config.app.api_url}/enterprises/search`
  // );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("🚀 ~ AddMission ~ errors:", errors)
  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(selectedEnterprise?.id ? `enterprises/${selectedEnterprise?.id}/merge-employees/${selectedEnterprise?.nui}?creatorId=${auth()?.user?.id}` : null, "POST");
  const handleSave = async (dataYub) => {
    // console.log("🚀 ~ AssignEnterpriseToMission ~ mission:", mission)
    // console.log("dataYub", dataYub);
    // const data = Array.of(selectedEnterprise?.id)


    // console.log("🚀 ~ handleSave ~ data:", data)

    selectedEnterprise?.nui && await postAxiosData();
  };



  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Fusion effectuer avec success."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      // props?.endFetch()
      // setSelectedEnterprise(null)
      fetchSames()
      onClose()

      // handleSignin(result);
    }
  }, [successMessage]);



  // console.log("🚀 ~ AddUser ~ regions:", regions)

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      // sx={{minHeight : 400}}
      // TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className="bg-gray-50"
          >
            <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              {`Fusionner les employees de   ${selectedEnterprise?.socialRaison} -- ${selectedEnterprise?.nui}   `}</Typography>
            <CloseModalButton iconColor={'red'} handleClose={onClose} />

          </Toolbar>
        </AppBar>

        <DialogContent dividers>

          <Typography variant="h6">
            Les enterprise avec le même NIU <Badge  >{sames?.data?.filter((d) => d?.id != selectedEnterprise?.id)?.length || 0}</Badge>
          </Typography>
          <Paper className="mt-3" aria-label="secondary mailbox folders">
            <List title="">


              {
                sames?.data ? sames?.data?.filter((d) => d?.id != selectedEnterprise?.id).map((same) => {
                  return (
                    <>
                      <ListItem key={same?.id} disablePadding
                        className={`
                        ${same?.status == ENTERPRISE_STATUS.CLOSED.value ? 'text-gray-500' : ''}
                        ${same?.status == ENTERPRISE_STATUS.DELETED.value ? 'text-gray-500' : ''}
                     
                        `}
                      >
                        <ListItemIcon  as={

                          same?.status == ENTERPRISE_STATUS.CLOSED.value ?
                            ClosedIcon :
                            same?.status == ENTERPRISE_STATUS.DELETED.value ?
                              DeletedIcon :

                              same?.fromArchive ?
                                () => <FromArchiveIcon /> :
                                () => <FromTheFieldIcon />
                        } color='green.500' />
                        <ListItemButton>
                          <ListItemText primary={`${same?.socialRaison} -- ${same?.nui} ${same?.id == selectedEnterprise?.id ? "(Courante)" : ''}`} />
                        </ListItemButton>

                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  )
                })
                  :
                  <p>Aucun</p>
              }
            </List>
          </Paper>
          <Divider />
          <div className="flex justify-between p-4">
            <div>
              <MButton
                variant={"danger"}
                onClick={(e) => {
                  e?.stopPropagation()
                  e?.preventDefault()
                  onClose();
                  // window.location.reload();
                }}>Fermer</MButton>
            </div>
            {sames?.data?.length > 0 && <MButton
              loa
              onClick={() => postAxiosData()}
              disabled={loading}
              isLoading={loading}

              variant={"primary"} type="submit" colorScheme="green">
              Fusionner
            </MButton>}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MergeEnterprises;
