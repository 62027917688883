import { Menu, MenuButton, MenuItem, MenuList, IconButton } from "@chakra-ui/react";
import {  BsThreeDotsVertical } from "react-icons/bs";

export default function MenuDrop({menu}) {

   
    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label={menu?.label || 'Options'}
                icon={menu.icon || <BsThreeDotsVertical />}
                variant='outline'
            />
            <MenuList>
                {
                    menu?.Items?.map((item) => {

                        return <MenuItem  icon={item?.icon} onClick={item?.handleClick}>
                            {item?.label}
                        </MenuItem>
                    })
                }
            </MenuList>
        </Menu>
    )
}