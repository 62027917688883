import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

export default function useCustomSignin(data, redirectUrl) {
    const signIn = useSignIn();
    const navigate = useNavigate();

    function handleSignin(data, redirectUrl) {
        if (data && redirectUrl) {
            console.log("🚀 ~ file: useCunstomSigin.js:10 ~ handleSignin ~ data:", data)

            const res = signIn({
                token: data?.access_token,
                refreshToken: data?.refresh_token,
                expiresIn: data?.jwtExpiration,
                refreshTokenExpireIn: data?.refreshExpiration,
                tokenType: "Bearer",
                authState: {
                    user: data?.user
                },
               // Only if you are using refreshToken feature
            });
            if (res) {
                navigate(redirectUrl);
            }

        }
    }
    return handleSignin

}