import React from 'react'

const TextLogo = () => {
    return (
        <div className='hidden md:flex flex-col text-center mt-10'>
            <div>
                <span className='font-bold text-3xl text-white'>FNTEC</span>
            </div>
            <div>
                <span className='text-primary'>Fichier National des Travailleurs Etrangers du Cameroun.</span>
            </div>
        </div>
    )
}

export default TextLogo
