import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/card";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "../employes/modals/EmployeeModalInfos";

const EmployersByUsers = () => {
  const navigation = useNavigate();
  const [employers, setemployers] = useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  let { agentId } = useParams('agentId')
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    if (location?.state?.agent) {
      setAgent(location?.state?.agent);
    }
  }, [location]);


  useEffect(() => {
    const fetchEnt = async (region) => {
      if (agentId && agentId !== null) {
        setLoading(true);
        await fetch(
          `${config.app.api_url}${`/employers?creatorId=${agentId}`
          }`
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("employers ", data)
            setemployers(data);
          })
          .catch(() => {
            setemployers([]);
          })
          .finally(() => setLoading(false));
      }
    };
    fetchEnt();
  }, []);
  const [selectedEm, setSetselectedEm] = useState('');
  const [open, setOpen] = useState(false);

  const handleClick = (item) => {
    if (item) {
      setSetselectedEm(item)
      setOpen(true)
    }
  }
  return (
    <div>

      <MModal open={open} setOpen={setOpen} item={selectedEm} />

      {(loading) && <PreloaderSpinner />}
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <div className="grid grid-cols-2 items-baseline gap-3">

          {agent && <div className=" text-end">
            expatriés enregistrés par l'agent : <strong> {agent?.firstName} {agent?.lastName}</strong>

          </div>}
        </div>

        <Card extra={"w-full h-full p-4"}>
          {/* <TableContainer> */}
          <div className="tableFixHead">
            <Table variant="simple">
              {/* <TableCaption>Top 5 des entreprises par secteur d'activite</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>Noms</Th>
                  <Th>Genre</Th>
                  <Th>Poste</Th>
                  <Th>Salaire</Th>
                  <Th>Nationalité</Th>
                  <Th>Numero de Permis </Th>
                  <Th>Status</Th>
                  <Th>Option</Th>
                </Tr>
              </Thead>

              {/* {console.log(employers)} */}
              <Tbody>
                {employers &&
                  employers?.data &&
                  employers?.data?.length &&
                  employers?.data
                    // ?.sort((a, b) => b?.employerCount - a?.employerCount)
                    ?.map((item) => {
                      return (
                        <>
                          <Tr className={new Date(item?.permitDate) <= new Date() ? 'text-danger' : ''}>

                            <Td>{item?.firstName} {item?.lastName}</Td>
                            <Td>{item?.sexe}</Td>
                            <Td>{item?.occupation?.name}</Td>
                            <Td>{item?.salary?.toLocaleString()}</Td>
                            <Td>{item?.nationality}</Td>
                            <Td>{item?.permitNumber}</Td>
                            <Td>{new Date(item?.permitDate) <= new Date() ? 'Expiré' : 'Valide'}</Td>
                            <Td>
                              <Button onClick={() => handleClick(item)}>
                                Consulter
                              </Button>
                            </Td>
                          </Tr>
                        </>
                      );
                    })}
              </Tbody>
              {/* <Tfoot>
                <Tr>
                  <Th>Raison sociale</Th>
                  <Th>Region</Th>
                  <Th>Secteur d'activite</Th>
                  <Th>Arrondissement</Th>
                  <Th>Nombre de travailleurs étrangers</Th>
                  <Th>Option</Th>
                </Tr>
              </Tfoot> */}
            </Table>
          </div>
          {/* </TableContainer> */}
        </Card>
      </div>
    </div>
  );
};

export default EmployersByUsers;
