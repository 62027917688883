import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Select } from "@chakra-ui/react";
import { ROLES } from "Constaints";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import { AppBar, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import { CloseModalButton } from "components/Utils";
import MuiAsyncAutocomplete from "components/MuiAsyncAutocomplete";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { formatDate } from "utils";

const AssignEnterpriseToMission = (props) => {
  const canEdit = true
  const { isOpen, onClose, selectedEnterprise } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    // participants: yup.string().required("Participants is a required field"),
    // description: yup.string(),
    // start: yup.string().required("Phone Number is a required field"),
    // end: yup.string().required("Phone Number is a required field"),

  });

  // const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  // console.log("🚀 ~ AddMissionEnterprise ~ selectedEnterprise:", selectedEnterprise)

  const [loadingR, errorR, missions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/missions`
  );


  const [loadingP, errorP, pastMissions, fetchDataP] = useDataFetching(
    selectedEnterprise?.id ? `${config.app.api_url}/enterprises/${selectedEnterprise?.id}/missions` : null
  );

  const [mission, setMission] = useState(null);

  // const [participants, setParticipants] = useState([]);
  // const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
  //   `${config.app.api_url}/enterprises/search`
  // );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("🚀 ~ AddMission ~ errors:", errors)
  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`missions/${mission}/add-enterprise`, "POST");
  const handleSave = async (dataYub) => {
    console.log("🚀 ~ AssignEnterpriseToMission ~ mission:", mission)
    // console.log("dataYub", dataYub);
    const data = Array.of(selectedEnterprise?.id)


    console.log("🚀 ~ handleSave ~ data:", data)

    mission && await postAxiosData(data);
  };



  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Utilisateur ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      // props?.endFetch()
      // setSelectedEnterprise(null)
      onClose()


      // handleSignin(result);
    }
  }, [successMessage]);



  // console.log("🚀 ~ AddUser ~ regions:", regions)

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        // sx={{minHeight : 400}}
      // TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className="bg-gray-50"
          >            
          <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              {`Assigner a ${selectedEnterprise?.socialRaison} une mission `}</Typography>
            <CloseModalButton iconColor={'red'} handleClose={onClose} />

          </Toolbar>
        </AppBar>

        <DialogContent dividers>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="my-4 grid gap-2 md:grid-cols-1">
              {errorMassage?.message && (
                <Error
                  message={
                    errorMassage?.message !== "" && errorMassage?.message
                  }
                />
              )}

              <MuiAUtocomplete
                value={mission}
                label={'Selectioner une mission'}
                // options={missions?.data}
                options={missions?.data?.map((item) => {
                  return { value: item?.id, label: `${item?.description} (Du ${formatDate(item?.start)} au ${formatDate(item?.end)})` }
                })}
                // format={{ value: 'id', label: 'description' }}
                // value={params?.regionId}
                // defaultFormat={true}
                onChange={(e, item) => { setMission(item.value) }}

                // onChange={(event) => setMission(event?.target?.value)}
                // onChange={(e, item) => console.log(item)}
                wrapperClasses={"flex-1 filter-item anime slideRight mb-3"}
              />

            </div>
            <Typography variant="h6">
              Les precendentes missions
            </Typography>
            <Paper aria-label="secondary mailbox folders">
              <List title="">
                {
                  pastMissions?.data?.map((m) => {
                    return (
                      <>
                      <ListItem key={m?.id} disablePadding>
                        <ListItemButton>
                          <ListItemText primary={`${m?.description} (Du ${formatDate(m?.start)} au ${formatDate(m?.end)})`} />
                        </ListItemButton>

                      </ListItem>
                           <Divider variant="inset" component="li" />
                           </>
                    )
                  })
                }
              </List>
            </Paper>
            <Divider/>
            <div className="flex justify-between p-4">
              <div>
                <MButton
                  variant={"danger"}
                  onClick={(e) => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    onClose();
                    // window.location.reload();
                  }}>Fermer</MButton>
              </div>
              <div>
                <MButton variant={"primary"} type="submit" colorScheme="green">
                  Assigner
                </MButton>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignEnterpriseToMission;
