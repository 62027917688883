


import { useEffect, useState } from "react";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import PreloaderSpinner from "components/PreloaderSpinner";
import axios from "axios";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import BarChart from "./components/BarChart";

import { useAuthUser } from "react-auth-kit";
import BasicPie from "components/charts/PieChart";
import { Typography } from "@mui/material";
import MuiLineChart from "components/charts/MuiLineChart";
import MuiBarCharts from "components/charts/MUIBarCharts";
import { formatNumber } from "utils";
import { capitalyse } from "utils";
import MuiSelect from "components/MuiSelect";
import { castToRequestParams } from "utils";

const TabContent2 = ({ regionId, regionParams }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const auth = useAuthUser();

  // const [loading, setLoading] = useState(false);
  // const [statsNationality, setStatsNationality] = useState([]);
  // const [region, setRegion] = useState(regionId ||
  //   location?.state?.region_id != undefined ||
  //   location?.state?.region_id != null
  //   ? location?.state?.region_id
  //   : "National"
  // );

  const [departement, setDepartement] = useState(null)
  // console.log("🚀 ~ TabContent2 ~ departement:", departement)
  const [arrondissement, setArrondissement] = useState(null)

  const [params, setParams] = useState({
    enterpriseId: null,
    start: null,
    end: null,
    visaExpired: null,
    regionId: regionId ||
      location?.state?.region_id != undefined ||
      location?.state?.region_id != null
      ? location?.state?.region_id
      : null,
    departmentId: null,
    arrondissementId: null,
    page: null,
    limit: null,
    fromArchive: null
  });
  const authHeader = useAuthUser()
  // console.log("region", region)

  useEffect(() => {
    setParams({ ...params, regionId: regionId })
  }, [regionId]);


  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );

  // fetcing departments by region id
  const [loadingD, errorD, departements, fetchDataD] = useDataFetching(
    regionId ? `${config.app.api_url}/regions/${regionId}/departments` : null
  );

  // fetcing arrondisements by department id
  const [loadingA, errorA, arrondisements, fetchDataA] = useDataFetching(
    (departement !== null || departements !== "") ? `${config.app.api_url}/departments/${departement}/arrondissements` : null
  );

  const [loadingsR, errorsR, stats, fetchDatasR] = useDataFetching(
    `${config.app.api_url}/enterprises/stats-details?regionId=${regionId ? regionId : ''}${params?.departmentId ? `&departmentId=${params?.departmentId}` : ''}${params?.arrondissementId ? `&arrondissementId=${params?.arrondissementId}` : ''}`
  );
  // useEffect(() => {
  //   fetchDatasR()
  // }, [departement, params,regionId]);


  const [loadingsRT, errorsRT, statsT, fetchDatasRT] = useDataFetching(
    `${config.app.api_url}/enterprises/stats-details?fromArchive=false&${castToRequestParams(params)}`
  );
  const [loadingE, errorE, statsE, fetchDataE] = useDataFetching(
    `${config.app.api_url}/employers/stats-details?${castToRequestParams(params)}`
  );


  const [loadingTE, errorTE, statsTE, fetchDataTE] = useDataFetching(
    `${config.app.api_url}/employers/stats-details?fromArchive=false&${castToRequestParams(params)}`
  );


  const [loadingNE, errorNE, statsNat, fetchDataNE] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-nationality?${castToRequestParams(params)}`
  );

  const [loadingNTE, errorNTE, statsNatTE, fetchDataNTE] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-nationality?fromArchive=false&${castToRequestParams(params)}`
  );


  const [loadingP, errorP, statsPerms, fetchDataP] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-permit-date?${castToRequestParams(params)}`
  );



  const [loadingSal, errorSal, statsSalary, fetchDataSal] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-salary?${castToRequestParams(params)}`
  );

  const [loadingSex, errorSex, sexStats, fetchDataSex] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-sexe?${castToRequestParams(params)}`
  );


  const [loadingSexTE, errorSexTE, sexStatsTE, fetchDataSexTE] = useDataFetching(
    `${config.app.api_url}/employers/stats-by-sexe?fromArchive=false&${castToRequestParams(params)}`
  );


  const [loadingNR, errorNR, NRStats, fetchDataNR] = useDataFetching(
    `${config.app.api_url}/employers/stats-national-regions`
  );


  const [loadingDR, errorDR, DRStats, fetchDataDR] = useDataFetching(
    `${config.app.api_url}/employers/stats-departments-by-region${regionId && regionId != 'National' ? '?regionId=' + regionId : null}`
  );

  const [loadingENR, errorENR, ENRStats, fetchDataENR] = useDataFetching(
    `${config.app.api_url}/enterprises/stats-national-regions`
  );


  const [loadingENDR, errorENDR, ENDRStats, fetchDataENDR] = useDataFetching(
    `${config.app.api_url}/enterprises/stats-departments-by-region${regionId && regionId != 'National' ? '?regionId=' + regionId : null}`
  );

  useEffect(() => {

  }, [regionId]);




  // const [sexStats, setSexStats] = useState([]);
  // useEffect(() => {
  // console.log("🚀 ~ TabContent2 ~ ENDRStats:", ENDRStats)
  //   setLoading(true);
  //   const rr = Object.keys(params)?.map((key) => {
  //     console.log(typeof params[key] == "string");
  //     return params[key] ? key + "=" + params[key] : "-1";
  //   });
  //   const tt = rr?.filter((r) => r !== "-1")?.join("&");
  //   axios
  //     .get(`${config.app.api_url}/employers/stats-by-sexe?${tt}`)
  //     .then((res) => {
  //       setSexStats(res?.data?.data);
  //       // console.log("🚀 ~ .then ~ res?.data?:", res?.data?.data?.content)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSexStats([]);
  //     })
  //     .finally(() => setLoading(false));
  // }, [params
  // ]);

  const Item = ({ title, nb, type, onClick }) => {
    return (
      <div className=" transition-all duration-300 cursor-pointer hover:shadow-md  hover:font-bold  hover:bg-green-50 rounded-xl  hover:text-[16px] " onClick={onClick}>
        {<strong class=" text-gray-700">{title || "Non defini"}</strong>}

        <h6 class={`font-bold  text-${type || "blue-500"}`}>{nb}</h6>
      </div>
    )
  }

  const Card = ({ items, type, direction, onClick }) => {

    return (
      <>
        <div class={` flex flex-${direction || 'col'} flex-wrap gap-2    p-4`}>
          {items?.map((item, index) => {
            return (
              item?.nb != null && item?.nb != undefined && <Item
                onClick={item?.onClick}
                key={index} nb={formatNumber(item?.nb)} type={item?.type} title={item?.title} />
            )
          })}
        </div>
      </>)
  }



  const items = [

  ]


  return (
    <div>
      {/* {(loadingR || loadingD || loadingA || loading || loadingE || loadingNE || loadingSal || loadingSex) && (
        <PreloaderSpinner />
      )} */}
      <div className='flex gap-2 justify-center overflow-auto p-2 pb-2 mb-2 bg-white
         justify-between items-center  rounded '>
        <h1 className="text-5xl text-primary font-bold text-center leading-tight">
          {`${regionParams?.name || 'National'} >`}
        </h1>
        <div className="flex gap-2">

          {departements?.data &&

            <MuiSelect
              size={'small'}

              label={'Département'}
              options={departements?.data}
              emptyLabel={'Régional'}
              // className={"bg-white"}
              value={departement}
              defaultFormat={true}
              onChange={(e) => {
                setDepartement(e.target.value);
                setParams({ ...params, departmentId: e.target.value })
                setArrondissement("");
              }}
              wrapperClasses={" filter-item anime slideLeft"}
            />
          }

          {/* Filtre arrondissement */}
          {arrondisements?.data &&


            <MuiSelect
              size={'small'}
              label={'Arrondissement'}
              emptyLabel={'Départemental'}

              options={arrondisements?.data}
              value={arrondissement?.id}
              defaultFormat={true}
              onChange={(e) => {
                setArrondissement(e.target.value);
                setParams({ ...params, arrondissementId: e.target.value })

              }}
              wrapperClasses={" filter-item anime slideRight"}
            />
          }
        </div>

      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1  gap-3">
        {/* <TabBar /> */}
        {/* <div className="flex  flex-col gap-4 content-center shadow"> */}
        <div class=" flex flex-col flex-nowrap gap-4 content-center bg-blur-green 
         justify-center items-center  rounded py-0   
        ">

          <div className="w-full flex flex-col items-center bg-blur-white p-2 rounded-lg shadow px-5">
            <strong className="text-xl">Statistiques globales sur les entreprises</strong>

            <span className="text-blue-700 italic cursor-pointer font-bold hover:shadow-md  hover:font-bold  hover:bg-green-50 rounded-xl  hover:text-[16px]"
              onClick={() => { navigate('/admin/enterprises', { state: { ...params, regionId: regionId } }) }}>
              Total : {formatNumber(stats?.data?.total)}</span>
            {/* <Item nb={stats?.data?.total} type={'blue-500'} title={"Total des entreprises"} /> */}
          </div>
          <div
            class="w-full  flex gap-2 flex-wrap justify-center items-center overflow-auto "
          >
            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
            bg-white
            ">

              <Card

                items={[
                  // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                  {
                    title: "En règle", nb: stats?.data?.regular, type: 'green-500',
                    onClick: () => navigate('/admin/enterprises', { state: { ...params, isRegular: true, regionId: regionId } })
                  },
                  {
                    title: "En irrégularite", nb: stats?.data?.irregular, type: 'red-500',
                    onClick: () => navigate('/admin/enterprises', { state: { ...params, isRegular: false, regionId: regionId } })

                  },
                ]} />
              <BasicPie data={stats?.data?.total ? [
                { id: 1, label: "En règle", value: stats?.data?.regular, color: '#50C878', },
                { id: 2, label: "En irrégularite", value: stats?.data?.irregular, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878']}
                // width={400}
                count={stats?.data?.total}
              />
            </div>
            <div className=" h-[450px]   border border-gray-500 rounded-lg shadow-md bg-white">


              <Card items={[
                {
                  title: "Collecté", nb: stats?.data?.collected, type: 'green-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, fromArchive: false, regionId: regionId } })

                },
                {
                  title: "Non Collecté (Archives de base)", nb: stats?.data?.fromArchive, type: 'gray-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, fromArchive: true, regionId: regionId } })

                },
              ]} />
              <BasicPie data={stats?.data?.total ? [
                { id: 1, label: "Collecté", value: stats?.data?.collected, color: '#50C878' },
                { id: 2, label: "Non Collecté", value: stats?.data?.fromArchive, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878', 'orange', 'gray']}
                // width={400}
                count={stats?.data?.total}

              />
            </div>

            <div className=" h-[450px]  border border-gray-500 rounded-lg shadow-md bg-white">

              {/* <Card title={"Entreprises en règle"} nb={stats?.data?.regular} type={'green-500'} /> */}
              <Card items={[

                // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                // {
                //   title: "Visitées | Par repérage", nb: formatNumber(stats?.data?.visited) + ' | '+ formatNumber(stats?.data?.visitedSpotting) +
                //   ' = '+ formatNumber(stats?.data?.visited + stats?.data?.visitedSpotting), type: 'green-500',
                //   onClick: () => navigate('/admin/enterprises', { state: {...params, located: true, regionId: regionId } })
                // },

                {
                  title: "Visitées ", nb: formatNumber(stats?.data?.visited), type: 'green-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, located: true, regionId: regionId } })

                },

                {
                  title: "Non Visite", nb: stats?.data?.nonVisited, type: 'gray-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, located: false, regionId: regionId } })

                },
              ]} />
              <BasicPie data={stats?.data?.total ? [
                { id: 1, label: "Visitées", value: stats?.data?.visited, color: '#50C878' },
                { id: 2, label: "Non Visitées", value: stats?.data?.nonVisited, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878', 'orange', 'gray']}
                // width={400}
                count={stats?.data?.total}

              />

            </div>

            <div className=" h-[450px]  border border-gray-500 rounded-lg shadow-md bg-white">

              {/* <Card title={"Entreprises en règle"} nb={stats?.data?.regular} type={'green-500'} /> */}
              <Card items={[
                // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                {
                  title: "Missionées", nb: stats?.data?.missioned, type: 'green-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, mission: { id: -2 }, regionId: regionId } })

                },
                {
                  title: "Non Missionées", nb: stats?.data?.nonMissioned, type: 'gray-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, mission: { id: -1 }, regionId: regionId } })

                },
              ]} />
              <BasicPie data={stats?.data?.total ? [
                { id: 1, label: "Missionées", value: stats?.data?.missioned, color: '#50C878' },
                { id: 2, label: "Non Missionées", value: stats?.data?.nonMissioned, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878', 'orange', 'gray']}
                // width={400}
                count={stats?.data?.total}

              />

            </div>





          </div>


        </div>

        <div class=" flex flex-col flex-nowrap gap-4 content-center bg-blur-green 
         justify-center items-center  rounded py-0   
        ">

          <div className="w-full flex flex-col items-center bg-blur-white p-2 rounded-lg shadow px-5">
            <strong className="text-xl">Statistiques du terrain sur les entreprises</strong>

            <span className="text-blue-700 italic cursor-pointer font-bold hover:shadow-md  hover:font-bold  hover:bg-green-50 rounded-xl  hover:text-[16px]"
              onClick={() => { navigate('/admin/enterprises', { state: { ...params, fromArchive : false, regionId: regionId } }) }}>
              Total : {formatNumber(statsT?.data?.total)}
            </span>
            {/* <Item nb={stats?.data?.total} type={'blue-500'} title={"Total des entreprises"} /> */}
          </div>
          <div
            class="w-full  flex gap-2 flex-wrap justify-center items-center overflow-auto "
          >
            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
            bg-white
            ">

              <Card

                items={[
                  // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                  {
                    title: "En règle", nb: statsT?.data?.regular, type: 'green-500',
                    onClick: () => navigate('/admin/enterprises', { state: { ...params, isRegular: true, fromArchive: false, regionId: regionId } })
                  },
                  {
                    title: "En irrégularite", nb: statsT?.data?.irregular, type: 'red-500',
                    onClick: () => navigate('/admin/enterprises', { state: { ...params, isRegular: false, fromArchive: false, regionId: regionId } })

                  },
                ]} />
              <BasicPie data={statsT?.data?.total ? [
                { id: 1, label: "En règle", value: statsT?.data?.regular, color: '#50C878', },
                { id: 2, label: "En irrégularite", value: statsT?.data?.irregular, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878']}
                // width={400}
                count={statsT?.data?.total}
              />
            </div>

            <div className=" h-[450px]  border border-gray-500 rounded-lg shadow-md bg-white">

              {/* <Card title={"Entreprises en règle"} nb={stats?.data?.regular} type={'green-500'} /> */}
              <Card items={[

                {
                  title: "Visitées ", nb: formatNumber(statsT?.data?.visited), type: 'green-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, located: true, fromArchive: false, regionId: regionId } })

                },

                {
                  title: "Non Visite", nb: statsT?.data?.nonVisited, type: 'gray-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, located: false, fromArchive: false, regionId: regionId } })

                },
              ]} />
              <BasicPie data={statsT?.data?.total ? [
                { id: 1, label: "Visitées", value: statsT?.data?.visited, color: '#50C878' },
                { id: 2, label: "Non Visitées", value: statsT?.data?.nonVisited, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878', 'orange', 'gray']}
                // width={400}
                count={statsT?.data?.total}

              />

            </div>

            <div className=" h-[450px]  border border-gray-500 rounded-lg shadow-md bg-white">

              {/* <Card title={"Entreprises en règle"} nb={stats?.data?.regular} type={'green-500'} /> */}
              <Card items={[
                // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                {
                  title: "Missionées", nb: statsT?.data?.missioned, type: 'green-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, mission: { id: -2 }, fromArchive: false, regionId: regionId } })

                },
                {
                  title: "Non Missionées", nb: statsT?.data?.nonMissioned, type: 'gray-500',
                  onClick: () => navigate('/admin/enterprises', { state: { ...params, mission: { id: -1 }, fromArchive: false, regionId: regionId } })

                },
              ]} />
              <BasicPie data={statsT?.data?.total ? [
                { id: 1, label: "Missionées", value: statsT?.data?.missioned, color: '#50C878' },
                { id: 2, label: "Non Missionées", value: statsT?.data?.nonMissioned, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878', 'orange', 'gray']}
                // width={400}
                count={stats?.data?.total}

              />

            </div>




          </div>


        </div>



        <div className="flex flex-col  gap-4   justify-center items-center  rounded py-0 my-0">

          <div className="flex flex-col items-center bg-blur-white p-3 px-5 rounded-lg w-full ">
            <strong className="text-xl">Statistiques globales sur les expatriés</strong>
            <span className="text-blue-700 italic cursor-pointer font-bold hover:shadow-md  hover:font-bold  hover:bg-green-50 rounded-xl  hover:text-[16px]"
              onClick={() => { navigate('/admin/employes', { state: { ...params, regionId: regionId } }) }}>
              Total : {formatNumber(statsE?.data?.total)}</span>
            {/* <Item nb={stats?.data?.total} type={'blue-500'} title={"Total des entreprises"} /> */}
          </div>


          <div class="w-full flex gap-3 justify-center items-center ">
            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
            bg-white
            ">
              <Card items={[
                // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                {
                  title: "En règle", nb: statsE?.data?.regular, type: 'green-500',
                  onClick: () => navigate('/admin/employes', { state: { ...params, visaExpired: false, regionId: regionId } })

                },
                {
                  title: "En irrégularite", nb: statsE?.data?.irregular, type: 'red-500',
                  onClick: () => navigate('/admin/employes', { state: { ...params, visaExpired: true, regionId: regionId } })

                },
              ]} />

              <BasicPie data={statsE?.data?.total ? [
                {
                  id: 1, label: "En règle", value: statsE?.data?.regular, color: '#50C878',

                },
                { id: 2, label: "En irrégularité", value: statsE?.data?.irregular, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878']}
                // width={400}
                count={statsE?.data?.total}

              />

            </div>

            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
             bg-white ">
              <Card items={sexStats?.data?.map((e, index) => {
                if (e?.label == "Female" || e?.label == "Feminin" || e?.label == "Male" || e?.label == "Masculin") { return { id: index, nb: e?.number, title: e?.label, type: 'green-500' } }
              })}
              />


              <BasicPie data={sexStats?.data?.map((e, index) => {
                if (e?.label == "Female" || e?.label == "Feminin" || e?.label == "Male" || e?.label == "Masculin") { return { id: index, value: e?.number, label: e?.label } }
              })}
                count={statsE?.data?.total}

              />
            </div>



            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
             bg-white
            ">
              <Card
                direction={'row'}

                items={statsNat?.data
                  ?.sort((a, b) => b.number - a.number)
                  ?.slice(0, 4).map((a, index) => {
                    if (a?.percent > 1) { return { id: index, nb: parseInt(a.number), title: capitalyse(a?.label) } }
                  })} />

              <BasicPie data={statsNat?.data
                ?.sort((a, b) => b.number - a.number)
                ?.slice(0, 4).map((a, index) => {
                  if (a?.percent > 1) { return { id: index, value: parseInt(a.number), label: a?.label?.toLowerCase() } }
                })}
                count={statsE?.data?.total}
                legendDirection={"row"}

              />
            </div>

            {/* {region == 'National' ?
            <div className="h-[450px] w-[400px]  border border-gray-500 rounded-lg shadow-md overflow-auto">
              <Card
                direction={'row'}

                items={ENRStats?.data
                  ?.sort((a, b) => b.number - a.number)
                  ?.map((a, index) => {
                    if (true || a?.percent > 1) { return { id: index, nb: parseInt(a.number), title: a?.label } }
                  })} />


              <BasicPie data={ENRStats?.data
                ?.sort((a, b) => b.number - a.number)
                ?.map((a, index) => {
                  if (true || a?.percent > 1) { return { id: index, value: parseInt(a.number), label: a?.label?.toLowerCase() } }
                })}
                count={statsE?.data?.total}
                legendDirection={"row"}

              />
            </div>
            :
            <div className="h-[450px] w-[400px]  border border-gray-500 rounded-lg shadow-md overflow-auto">
              <Card
                direction={'row'}

                items={ENDRStats?.data
                  ?.sort((a, b) => b.number - a.number)
                  ?.map((a, index) => {
                    if (true || a?.percent > 1) { return { id: index, nb: parseInt(a.number), title: a?.label } }
                  })} />


              <BasicPie data={ENDRStats?.data
                ?.sort((a, b) => b.number - a.number)
                ?.map((a, index) => {
                  if (true || a?.percent > 1) { return { id: index, value: parseInt(a.number), label: a?.label?.toLowerCase() } }
                })}
                count={statsE?.data?.total}
                legendDirection={"row"}

              />
            </div>
            
          } */}

          </div>







        </div>



        <div className="flex flex-col  gap-4   justify-center items-center  rounded py-0 my-0">

          <div className="flex flex-col items-center bg-blur-white p-3 px-5 rounded-lg w-full ">
            <strong className="text-xl">Statistiques du terrain sur les expatriés</strong>
            <span className="text-blue-700 italic cursor-pointer font-bold hover:shadow-md  hover:font-bold  hover:bg-green-50 rounded-xl  hover:text-[16px]"
              onClick={() => { navigate('/admin/employes', { state: { ...params, fromArchive: false, regionId: regionId } }) }}
            // onClick: () => navigate('/admin/employes', { state: {...params, visaExpired: true, fromArchive : false, regionId: regionId } })
            >Total : {formatNumber(statsTE?.data?.total)}</span>
            {/* <Item nb={stats?.data?.total} type={'blue-500'} title={"Total des entreprises"} /> */}
          </div>


          <div class="w-full flex gap-3 justify-center items-center ">
            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto
  bg-white
  ">
              <Card items={[
                // {title : "Total des entreprises", nb : stats?.data?.total, type : 'blue-500'},
                {
                  title: "En règle", nb: statsTE?.data?.regular, type: 'green-500',
                  onClick: () => navigate('/admin/employes', { state: { ...params, visaExpired: false, fromArchive: false, regionId: regionId } })

                },
                {
                  title: "En irrégularite", nb: statsTE?.data?.irregular, type: 'red-500',
                  onClick: () => navigate('/admin/employes', { state: { ...params, visaExpired: true, fromArchive: false, regionId: regionId } })

                },
              ]} />

              <BasicPie data={statsTE?.data?.total ? [
                {
                  id: 1, label: "En règle", value: statsTE?.data?.regular, color: '#50C878',

                },
                { id: 2, label: "En irrégularité", value: statsTE?.data?.irregular, color: '#FF1493' },
              ] : []}
                colors={['#FF1493', 'blue', '#50C878']}
                // width={400}
                count={statsTE?.data?.total}

              />

            </div>

            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto bg-white ">
              <Card items={sexStatsTE?.data?.map((e, index) => {
                if (e?.label == "Female" || e?.label == "Feminin" || e?.label == "Male" || e?.label == "Masculin") { return { id: index, nb: e?.number, title: e?.label, type: 'green-500' } }
              })}
              />


              <BasicPie data={sexStatsTE?.data?.map((e, index) => {
                if (e?.label == "Female" || e?.label == "Feminin" || e?.label == "Male" || e?.label == "Masculin") { return { id: index, value: e?.number, label: e?.label } }
              })}
                count={statsTE?.data?.total}

              />
            </div>



            <div className="h-[450px]  border border-gray-500 rounded-lg shadow-md overflow-auto bg-white">
              <Card
                direction={'row'}
                items={statsNatTE?.data
                  ?.sort((a, b) => b.number - a.number)
                  ?.slice(0, 4).map((a, index) => {
                    if (a?.percent > 1) { return { id: index, nb: parseInt(a.number), title: capitalyse(a?.label) } }
                  })} />

              <BasicPie data={statsNatTE?.data
                ?.sort((a, b) => b.number - a.number)
                ?.slice(0, 4).map((a, index) => {
                  if (a?.percent > 1) { return { id: index, value: parseInt(a.number), label: a?.label?.toLowerCase() } }
                })}
                count={statsNatTE?.data?.total}
                legendDirection={"row"}
              />
            </div>



          </div>







        </div>
      </div>


      <div className="grid grid-cols-1 sm:grid-cols-3  mt-5 gap-3">

        {ENDRStats?.data?.length > 0 && (
          <>
            <MuiBarCharts

              // legends={statsSalary?.data}
              data={ENDRStats?.data?.sort((a, b) => b.number - a.number)?.map((a) => { if (a?.percent > 0) { return parseInt(a.number) } })}
              xLabel={ENDRStats?.data?.filter((s) => { return s?.percent > 0 })?.sort((a, b) => b.number - a.number)?.map((a) => a.label)}
              title={"Repartition des entreprises par departements"}
            />

          </>
        )
        }

        {statsPerms?.data && <MuiLineChart xAxis={statsPerms?.data?.filter((s) => s?.year != null)?.map((e, index) => {
          return e?.year
        })} data={statsPerms?.data?.filter((s) => s?.year != null)?.map((e, index) => {
          return e?.number
        })}
          title={"Evolution du nombre de permis de travail délivrés"}

        />}

        {statsSalary?.data?.length > 0 && (
          <MuiBarCharts
            // legends={statsSalary?.data}
            data={statsSalary?.data?.map((d) => d?.number)}
            xLabel={statsSalary?.data
              ?.map((a) => a?.range)}
            title={"Répartition des travailleurs étrangers par grille salariale"}
          />
        )}



        {statsNat?.data?.length > 0 ? (
          <>
            <MuiBarCharts

              // legends={statsSalary?.data}
              data={statsNat?.data?.sort((a, b) => b.number - a.number)?.slice(0, 10).map((a) => { if (a?.percent > 1) { return parseInt(a.number) } })}
              xLabel={statsNat?.data?.filter((s) => { return s?.percent > 1 })?.sort((a, b) => b.number - a.number)?.slice(0, 10).map((a) => a.label)}
              title={"Top 10 Des Nationalités les plus représentées"}
            />
            {/* <BarChart
            noPercent={true}

            legends={statsNat?.data?.slice(0, 10)}
            data={statsNat?.data?.sort((a, b) => b.number - a.number)?.slice(0, 10).map((a) => { if (a?.percent > 1) { return parseInt(a.number) } }
            )}
            options={statsNat?.data?.filter((s) => { return s?.percent > 1 })?.sort((a, b) => b.number - a.number)?.slice(0, 10).map((a) => a.label)}
            title={
              " Top 10 Des Nationalités les plus représentées"
            }
          /> */}
          </>
        ) :
          <p>Pas de données</p>
        }
      </div>
    </div>
  );
};

export default TabContent2;
