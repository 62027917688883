import React, { useEffect, useState } from "react";
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import { Alert, AlertIcon, AlertTitle, Badge, Button, Tooltip } from "@chakra-ui/react";

// import { Alert, AlertIcon, AlertTitle, Badge, Button, Tooltip } from "@chakra-ui/react";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useAuthUser } from "react-auth-kit";
import { BsBan, BsBanFill, BsCarFront, BsEnvelopeExclamation, BsGeoAlt, BsIntersect, BsListTask, BsMailbox, BsPencilSquare, BsPeople, BsPerson, BsSearch, BsSignMergeLeftFill, BsUnion, BsXCircleFill } from "react-icons/bs";
import PrintButton from "../default/components/PrintButton";
/** validation de formulaire */
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import EmployesByEnterpriseModal from "../employes/EmployesByEnterpriseModal";
import { AppBar, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Slide, Switch, Toolbar } from "@mui/material";
import { CloseModalButton } from "components/Utils";
import { createMapLink } from "utils";
import MuiSelect from "components/MuiSelect";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { ZONES } from "Constaints";
import AssignEnterpriseToMission from "../Missions/AssignEnterpriseToMission";
import { BlindsClosed, CloseFullscreenSharp, ClosedCaptionOff, DeleteForeverSharp, Height, Remove, RestorePageRounded } from "@mui/icons-material";
import MergeEnterprises from "./MergeEnterprises";
import MMenus from "components/MMenus";
import { ENTERPRISE_STATUS } from "Constaints";
import PreloaderSpinner from "components/PreloaderSpinner";
import AssignEnterpriseTotask from "../Tasks/AssignEnterpriseTotask";
import { formatDate } from "utils";
import { formatDateTime } from "utils";
import MuiCollapse from "components/MuiCollapse";
import Notify from "./Notify";
import MuiAsyncAutocomplete from "components/MuiAsyncAutocomplete";
import MButton from "components/Mbutton";
import FormSkeleton from "components/FormSkeleton";
// import { Tooltip } from "@chakra-ui/tooltip";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddEnterprise({ open, handleClose, handleCloseOnly,selectedEnterpriseP, get }) {
  console.log("🚀 ~ selectedEnterpriseP:", selectedEnterpriseP)
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const [loadingEN, errorEn, resleutEn, fetchEnterprise] = useDataFetching(
    selectedEnterpriseP?.id ? `${config.app.api_url}/enterprises/${selectedEnterpriseP?.id}` : null
  );
  // useEffect(() => {
  //   selectedEnterpriseP && fetchEnterprise()
  // }, [selectedEnterpriseP]);
  useEffect(() => {
    resleutEn && setSelectedEnterprise(resleutEn?.data)
  }, [resleutEn]);
  console.log("🚀 ~ AddEnterprise ~ selectedEnterprise:", selectedEnterprise)

  const navigate = useNavigate();
  const [openAss, setOpenAss] = useState(false);
  const [openAssTask, setOpenAsstask] = useState(false);
  const [openMerge, setOpenMerge] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  // console.log("🚀 ~ AddEnterprise ~ selectedEnterprise:", selectedEnterprise)
  const auth = useAuthUser();
  const [canEdit, setCanEdit] = useState(false);
  const [region, setRegion] = useState(null);
  const [departement, setDepartement] = useState(null);
  const [arrondissement, setArrondissement] = useState(null);
  const [zone, setZone] = useState(null);
  const [sector, setSector] = useState([]);
  const [spotting, setSpotting] = useState(selectedEnterprise?.spotting);
  const [mainOffice, setMainOffice] = useState(true);
  // console.log("🚀 ~ selectedEnterprise?.relationType:", selectedEnterprise?.relationType == 'IS_MAIN')
  // console.log("🚀 ~ mainOffice:", mainOffice)
  const [fromArchive, setFromArchive] = useState(selectedEnterprise?.fromArchive);
  const [relatedMainOffice, setrelatedMainOffice] = useState(null);
  // console.log("🚀 ~ AddEnterprise ~ relatedMainOffice:", relatedMainOffice)
  // console.log("🚀 ~ AddEnterprise ~ sector:", sector)
  const handleChange = (event) => {
    const newOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSector(newOptions);
  };
  const [loadingS, errorS, sectors, fetchDataS] = useDataFetching(
    `${config.app.api_url}/activity-sectors`
  );

  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );


  const [loadingD, errorD, departments, fetchDataD] = useDataFetching(
    region ? `${config.app.api_url}/regions/${region}/departments` : null
  );

  const [loadingA, errorA, arrondissements, fetchDataA] = useDataFetching(
    departement ? `${config.app.api_url}/departments/${departement}/arrondissements` : null
  );

  const [loadingZ, errorZ, zones, fetchZones] = useDataFetching(
    departement ? `${config.app.api_url}/departments/${departement}/zones` : null
  );



  const [loadingM, errorM, modifications, fetcModifs] = useDataFetching(
    selectedEnterprise?.id ? `${config.app.api_url}/enterprises/${selectedEnterprise?.id}/modifications` : null
  );


  const [loadingRelated, errorRelated, relateds, fetcRelated] = useDataFetching(
    selectedEnterprise?.id ? `${config.app.api_url}/enterprises/${selectedEnterprise?.id}/related${selectedEnterprise?.relatedMainOffice ? "?mainEnterpriseId=" + selectedEnterprise?.relatedMainOffice?.id : ''}` : null
  );


  useEffect(() => { }, [region, departement]);

  const schema = yup.object().shape({
    socialRaison: yup.string().required("Ce champ est requis"),
    sigle: yup.string(),
    locality: yup.string(),
    // email: yup.string().email().required('Ce champ est requis'),
    // phone: yup.string().required('Ce champ est requis'),
    postal: yup.string(),
    nui: yup.string(),
    email: yup.string().email(),
    phone: yup.string(),

    website: yup.string(),
    employersNumber: yup.string(),

    RHName: yup.string(),
    RHEmail: yup.string().email(),
    RHPhone: yup.string(),
    RHPhoneWh: yup.string(),
    street: yup.string(),
    longitude: yup.string(),
    latitude: yup.string(),

    searchLongitude: yup.string(),
    searchLongitude: yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors, },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create employer */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`enterprises`, "POST");

  useEffect(() => {
    loading && setCanEdit(false)
  }, [loading]);

  const handleSave = async (formData) => {
    const data = {
      id: selectedEnterprise?.id || null,
      creatorId: selectedEnterprise?.id ? null : auth()?.user?.id,
      editorId: auth()?.user?.id,
      socialRaison: formData?.socialRaison?.toUpperCase(),
      employersNumber: formData?.employersNumber,
      sigle: formData?.sigle?.toUpperCase(),
      locality: formData?.locality,
      street: formData?.street,
      nui: formData?.nui,
      email: formData?.email,
      phone: formData?.phone,
      rhname: formData?.RHName,
      rhemail: formData?.RHEmail,
      rhphone: formData?.RHPhone,
      rhphonewh: formData?.RHPhoneWh,
      postal: formData?.postal,
      regionId: parseInt(region),
      arrondissementId: parseInt(arrondissement),
      zoneId: parseInt(zone),
      activitySectors: sector?.map((s) => parseInt(s?.value))?.toString(),
      departmentId: parseInt(departement),
      website: formData?.website,
      latitude: formData?.latitude,
      longitude: formData?.longitude,

      searchLatitude: formData?.searchLatitude,
      searchLongitude: formData?.searchLongitude,
      observation: formData?.observation,
      relatedMainOfficeId: relatedMainOffice?.value,
      relationType: mainOffice ? 'IS_MAIN' : 'IS_CHILD',
      spotting,
      fromArchive
    };
    // console.log("🚀 ~ handleSave ~ data:", data)

    await postAxiosData(data);
  };

  /** gestion des erreurs et succès */
  useEffect(() => {
    errorMassage?.message && setCanEdit(true)
  }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      handleCloseInside()
      get()

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Entreprise ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
    }
  }, [successMessage]);

  useEffect(() => {
    if (selectedEnterprise) {
      setCanEdit(false)
      setValue('socialRaison', selectedEnterprise?.socialRaison?.toUpperCase() || undefined)
      setValue('employersNumber', selectedEnterprise?.employersNumber || selectedEnterprise?.employersRealNumber || undefined)
      setValue('sigle', selectedEnterprise?.sigle?.toUpperCase() || undefined)
      setValue('locality', selectedEnterprise?.locality || undefined)
      setValue('street', selectedEnterprise?.street || undefined)
      setValue('nui', selectedEnterprise?.nui || undefined)
      setValue('email', selectedEnterprise?.email || undefined)
      setValue('phone', selectedEnterprise?.phone || undefined)

      setValue('RHName', selectedEnterprise?.rhname || undefined)
      setValue('RHEmail', selectedEnterprise?.rhemail || undefined)
      setValue('RHPhone', selectedEnterprise?.rhphone || undefined)
      setValue('RHPhoneWh', selectedEnterprise?.rhphonewh || undefined)
      setValue('postal', selectedEnterprise?.postal || undefined)

      setValue('website', selectedEnterprise?.website || undefined)
      setValue('latitude', selectedEnterprise?.latitude || undefined)
      setValue('longitude', selectedEnterprise?.longitude || undefined)

      setValue('searchLatitude', selectedEnterprise?.searchLatitude || undefined)
      setValue('searchLongitude', selectedEnterprise?.searchLongitude || undefined)

      setValue('observation', selectedEnterprise?.observation || undefined)


      // setRegion(regions?.data?.find((r)=>r.id==10))
      setRegion(selectedEnterprise?.region?.id)
      setDepartement(selectedEnterprise?.department?.id)
      setArrondissement(selectedEnterprise?.arrondissement?.id)
      setZone(selectedEnterprise?.zone?.id)
      setSpotting(selectedEnterprise?.spotting)
      setMainOffice(selectedEnterprise?.relationType == 'IS_MAIN' || selectedEnterprise?.relationType == null || selectedEnterprise?.relationType == undefined)
      setFromArchive(selectedEnterprise?.fromArchive)
      setrelatedMainOffice(selectedEnterprise?.relatedMainOffice ? { value: selectedEnterprise?.relatedMainOffice?.id, label: selectedEnterprise?.relatedMainOffice?.socialRaison } : null)
      // setArrondissement([124,121])
      // setSector(["15","16"])
      setSector(selectedEnterprise?.activitySectors?.map((a) => { return { value: a?.id, label: a?.name } }))
      // console.log(selectedEnterprise)


    } else {
      setCanEdit(true)
      handleReset()


    }
  }, [selectedEnterprise]);
  const handleReset = () => {
    setRegion(null)
    setDepartement(null)
    setArrondissement(null)
    setZone(null)
    setSector([])
    setrelatedMainOffice(null)
    // setCanEdit(false)
    reset()
  }

  const [openList, setOpenList] = useState(false);

  const handleCloseInside = () => {
    if(handleCloseOnly){
      handleCloseOnly()
    }else{
    handleReset()
    handleClose()
    setSelectedEnterprise(null)
    setFromArchive(false)
    setSpotting(false)
    setCanEdit(true)
    }
  }
  /** post create employer */
  const [loadingC, postAxiosChange, errorMassageC, successMessageC, resultC] =
    usePostAxiosData(`enterprises/${selectedEnterprise?.id}/change-status`, "PUT");

  const changeStatus = (status) => {

    window.confirm((ENTERPRISE_STATUS[status]?.alert)) && postAxiosChange({ enterpriseStatus: status, userId: auth()?.user?.id })
  }

  useEffect(() => {
    if (successMessageC?.status === 200 || successMessageC?.status === 201) {
      handleClose()
      get()

      toast.success(
        `${successMessageC?.message != null
          ? successMessageC?.message
          : "Etat de l'entreprise mis a jour avec success."
        } `,
        {
          duration: 4000,
          className: "bg-success text-white",
        }
      );
    }
  }, [successMessageC]);
  const [activityName, setActivityName] = useState('');

  const [loadingSec, createSector, errorMassageO, successMessageO, createdOccupation] =
    usePostAxiosData(`activity-sectors`, "POST");


  const handleAddSector = () => {
    activityName && window.confirm(`Etes vous sur de vouloir ajouter ce secteur d'activiter ? ${activityName}`) && createSector({ name: activityName, creatorId: auth()?.user?.id })
  }

  useEffect(() => {
    if (successMessageO?.status === 200 || successMessageO?.status === 201) {
      fetchDataS()
      toast.success(
        `${successMessageO?.message != null
          ? successMessageO?.message
          : `Seceteur d'activites  ajoute avec succès.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessageO]);
  const Related = () => {
    return (
      relateds?.data?.length > 0 && <MuiCollapse
        title={`Liens avec d'autres entreprises (${relateds?.data?.length || 0})`}
        bgColor={'rgba(1,0,15,0.1)'}

      >
        <List>
          {relateds?.data?.map((m) => {
            // console.log("🚀 ~ {relateds?.data?.map ~ m:", m)

            return (

              <ListItem disablePadding divider color={m?.relationType == "IS_MAIN" ? 'green' : 'red'}>
                <ListItemButton
                // onClick={()=>navigate(`enterprises/${selectedEnterprise?.id}`, {state : selectedEnterprise})}
                >
                  <ListItemText primary={`${m?.relationType == "IS_MAIN" ? '--[Maison Mere]' : ''}--${m?.socialRaison}--${m?.region?.name}--${m?.department?.name}--${m?.arrondissement?.name}--${m?.street}`} />
                </ListItemButton>

              </ListItem>
            )
          })}
        </List>
      </MuiCollapse>
    )
  }

  return (
    <React.Fragment>
      {loadingC && loadingEN && <PreloaderSpinner />}
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <EmployesByEnterpriseModal Fullscreen={true} title={"titre 1"} open={openList}
        setOpen={setOpenList} handleClose={() => setOpenList(false)} selectedEnterprise={selectedEnterprise} />
      <AssignEnterpriseToMission isOpen={openAss} onClose={() => setOpenAss(false)} selectedEnterprise={selectedEnterprise} />
      <AssignEnterpriseTotask isOpen={openAssTask} onClose={() => setOpenAsstask(false)} selectedEnterprise={selectedEnterprise} />
      <MergeEnterprises isOpen={openMerge} onClose={() => setOpenMerge(false)} selectedEnterprise={selectedEnterprise} />
      <Notify isOpen={openNotify} onClose={() => setOpenNotify(false)} selectedEnterprise={selectedEnterprise} />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleCloseInside}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className={selectedEnterprise?.status == ENTERPRISE_STATUS.CLOSED.value ? "bg-yellow-200" :
              selectedEnterprise?.status == ENTERPRISE_STATUS.DELETED.value ? "bg-red-300" : "bg-gray-50"}
          >

            <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              {selectedEnterprise ? ` ${!canEdit ? "Informations sur l'" : "Editer l'"}   entreprise` : loadingEN ? "--" : "Nouvelle entreprise"}

              {selectedEnterprise?.socialRaison && <span className="text-center text-dark ">
                {` :${selectedEnterprise?.sigle ? "(" + selectedEnterprise?.sigle + ")" : ''} ${selectedEnterprise?.socialRaison}`}

              </span>}
            </Typography>
            <div className="flex justify-end gap-2 pr-10">


              {selectedEnterprise && <Tooltip hasArrow label={"Cliquez ici pour voir ses expatriés "} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  // onClick={() => navigation(`/admin/employees/${selectedEnterprise?.id}`, { state: { enterprise: selectedEnterprise } })}
                  onClick={() => setOpenList(true)}
                >

                  <BsPeople size={30} className="text-green-500" />

                </button>
              </Tooltip>}
              {selectedEnterprise && <Tooltip hasArrow label={canEdit ? "Cliquez ici pour vérouiller" : "Cliquez ici pour éditer"} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setCanEdit(!canEdit)}
                >

                  {canEdit ? <BsBan size={25} className="text-green-500" /> : <BsPencilSquare size={25} className="text-blue-500" />}

                </button>
              </Tooltip>}

              {selectedEnterprise && <Tooltip hasArrow label={(selectedEnterprise?.latitude && selectedEnterprise?.longitude) ? "Voir sur la map" : "Recherhcer sur la map"} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => {

                    (selectedEnterprise?.latitude && selectedEnterprise?.longitude) ?
                      window.open(createMapLink(`${selectedEnterprise?.latitude},${selectedEnterprise?.longitude}`), '_blank', 'noopener,noreferrer')
                      :
                      (selectedEnterprise?.searchLatitude && selectedEnterprise?.searchLongitude) ?
                        window.open(createMapLink(`${selectedEnterprise?.searchLatitude},${selectedEnterprise?.searchLongitude}`), '_blank', 'noopener,noreferrer')
                        :
                        window.open(createMapLink(`${selectedEnterprise?.socialRaison} ${selectedEnterprise?.sigle} ${selectedEnterprise?.street} CAMEROON`), '_blank', 'noopener,noreferrer'
                        )

                  }}

                >

                  {((selectedEnterprise?.latitude && selectedEnterprise?.longitude) || (selectedEnterprise?.searchLatitude && selectedEnterprise?.searchLongitude)) ? <BsGeoAlt size={25} className="text-green-500" /> : <BsSearch size={25} className="text-gray-500" />}

                </button>
              </Tooltip>}

              {selectedEnterprise && <Tooltip hasArrow label={"Cliquez ici pour assigner a une mission"} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setOpenAss(true)}
                >

                  <BsCarFront size={25} className="text-green-500" />

                </button>
              </Tooltip>}

              {selectedEnterprise && <Tooltip hasArrow label={"Cliquez ici pour assigner a une tache"} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setOpenAsstask(true)}
                >

                  <BsListTask size={25} className="text-green-500" />

                </button>
              </Tooltip>}

              {selectedEnterprise && <Tooltip hasArrow label={"Fusionner des enterprises"} bg='green.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setOpenMerge(true)}
                >

                  <BsIntersect size={25} className="text-green-500" />

                </button>
              </Tooltip>}

              {selectedEnterprise && <Tooltip hasArrow label={"Notifier l'entreprise"} bg='red.200' color='black' placement="bottom">

                <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setOpenNotify(true)}
                >

                  <BsEnvelopeExclamation size={25} className="text-red-500" />

                </button>
              </Tooltip>}

              {/* {console.log("🚀 ~ AddEnterprise ~ ENTERPRISE_STATUS:", ENTERPRISE_STATUS)} */}
              {selectedEnterprise && <MMenus options={[
                {
                  icon: <BlindsClosed color="Warning" />,
                  label: "Entreprise fermée",
                  handleClick: () => changeStatus('CLOSED')
                },
                {
                  icon: <DeleteForeverSharp color="danger" />,
                  label: "Supprimer",
                  handleClick: () => changeStatus('DELETED')

                },
                selectedEnterprise?.status != ENTERPRISE_STATUS.CREATED.value && {
                  icon: <RestorePageRounded color="action" />,
                  label: "Restaurer",
                  handleClick: () => changeStatus('CREATED')

                }
              ]
              } />}
            </div>
            <CloseModalButton iconColor={"red"} handleClose={handleCloseInside} />

          </Toolbar>
        </AppBar>
        {loadingEN ? <FormSkeleton />
          :
          <DialogContent dividers>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              {errorMassage?.message && (
                <Error
                  message={
                    errorMassage?.message !== "" && errorMassage?.message
                  }
                />
              )}
              {/* {console.log("🚀 ~ AddEnterprise ~ selectedEnterprise?.fromArchive:", selectedEnterprise?.fromArchive)} */}
              {/* {     console.log("🚀 ~ AddEnterprise ~ selectedEnterprise?.status :", selectedEnterprise?.status )} */}
              {(selectedEnterprise?.status == ENTERPRISE_STATUS.CLOSED.value || selectedEnterprise?.status == ENTERPRISE_STATUS.DELETED.value) &&
                <Alert className="mb-5 rounded-lg shadow" status={ENTERPRISE_STATUS.CLOSED.value == selectedEnterprise?.status ? 'warning' : 'error'}>
                  <AlertIcon />
                  <AlertTitle>{ENTERPRISE_STATUS.CLOSED.value == selectedEnterprise?.status ? "Entreprise fermée" : "Entreprise supprimée"}
                  </AlertTitle>
                  {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                </Alert>
              }
              {(selectedEnterprise && (selectedEnterprise?.fromArchive == true)) ?
                (selectedEnterprise && selectedEnterprise?.searchLatitude || selectedEnterprise?.searchLongitude) ?
                  <Alert className="mb-5 rounded-lg shadow" status='warning'>
                    <AlertIcon />
                    <AlertTitle>Entreprise non visitée mais géolocalisée à distance
                    </AlertTitle>
                    {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                  </Alert>
                  :
                  <Alert className="mb-5 rounded-lg shadow" status='error'>
                    <AlertIcon />
                    <AlertTitle>Entreprises non visites
                    </AlertTitle>
                    {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                  </Alert>
                :
                (selectedEnterprise && selectedEnterprise?.latitude || selectedEnterprise?.longitude) ?
                  <Alert className="mb-5 rounded-lg shadow" status='success'>
                    <AlertIcon />
                    <AlertTitle>Entreprise  visitée et géolocalisée avec succès
                    </AlertTitle>
                    {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                  </Alert>
                  :

                  selectedEnterprise && <Alert className="mb-5 rounded-lg shadow" status='error'>
                    <AlertIcon />
                    <AlertTitle> "Une erreur s'est produite lors de la prise de coordonnées GPS
                    </AlertTitle>
                    {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                  </Alert>
              }
              {selectedEnterprise?.spotting &&
                <Alert className="mb-5 rounded-lg shadow" status={'warning'}>
                  <AlertIcon />
                  <AlertTitle>{"Informations sur l'entreprise collectées en mode repérrage"}
                  </AlertTitle>
                  {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
                </Alert>
              }
              <div>
                <div
                  className="flex justify-center "
                >

                  <FormControlLabel onChange={(e) => {
                    // console.log("🚀 ~ AddEnterprise ~ e:", e?.target?.checked)
                    setSpotting(e?.target?.checked)
                  }} control={<Switch disabled={!canEdit} checked={spotting} color="success" />} labelPlacement="start" label="En mode reperage ?" />

                  <FormControlLabel onChange={(e) => {
                    // console.log("🚀 ~ AddEnterprise ~ e:", e?.target?.checked)
                    setFromArchive(e?.target?.checked)
                  }} control={<Switch disabled={!canEdit} checked={fromArchive} color="success" />} labelPlacement="start" label="Provenant des archives  ?" />
                </div>
                <div
                  className="grid gap-2 md:grid-cols-2 justify-between py-1 border bg-gray-100 shadow"
                >


                  <FormControlLabel onChange={(e) => {
                    // console.log("🚀 ~ AddEnterprise ~ e:", e?.target?.checked)
                    setMainOffice(e?.target?.checked)
                  }} control={<Switch disabled={!canEdit} checked={mainOffice} color="success" />} labelPlacement="start" label="Es ce la maison mère ?" />

                  {!mainOffice && <MuiAsyncAutocomplete
                    // multiple={true}
                    error={!relatedMainOffice}
                    fullWidth
                    disabled={!canEdit}
                    label={"Resigner la maison mere *"}
                    value={relatedMainOffice}
                    format={{ value: 'id', label: 'socialRaison' }}
                    // defaultFormat={true}
                    fetch={'enterprises/search-all-by-term'}
                    handleChange={(e, item) => {
                      // console.log("🚀 ~ AddMissionEnterprise ~ items:", item)
                      setrelatedMainOffice(item)
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideRight "}

                  />}
                </div>

                <Related />
                <div className="my-4 grid gap-2 md:grid-cols-2">
                  <div>
                    <InputField
                      disabled={!canEdit}
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Raison sociale"
                      // required={true}
                      // placeholder={selectedEnterprise ? "" : "Alshadows Technologies"}
                      id="socialRaison"
                      name="socialRaison"
                      type="text"
                      error={
                        errors.socialRaison?.message !== ""
                          ? errors.socialRaison?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      disabled={!canEdit}

                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Sigle"
                      // required={true}
                      // placeholder={selectedEnterprise ? "" : "AT"}
                      id="sigle"
                      name="sigle"
                      type="text"
                      error={
                        errors.sigle?.message !== ""
                          ? errors.sigle?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      disabled={!canEdit}

                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="NIU"
                      required={false}
                      // placeholder={selectedEnterprise ? "" : "M09XXXXXX"}
                      id="nui"
                      name="nui"
                      type="text"
                      error={
                        errors.nui?.message !== "" ? errors.nui?.message : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      disabled={!canEdit}

                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Email de l'entreprise"
                      required={false}
                      // placeholder={selectedEnterprise ? "" : "email@company.com"}
                      id="email"
                      name="email"
                      type="text"
                      error={
                        errors.email?.message !== "" ? errors.email?.message : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      disabled={!canEdit}

                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Numero de l'entreprise"
                      required={false}
                      // placeholder={selectedEnterprise ? "" : "6XXXXXXXX"}
                      id="phone"
                      name="phone"
                      type="text"
                      error={
                        errors.phone?.message !== "" ? errors.phone?.message : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      disabled={!canEdit}

                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nombre d'expatrié"
                      min="0"

                      // required={true}
                      // placeholder={selectedEnterprise ? "" : "5"}
                      id="employersNumber"
                      name="employersNumber"
                      type="number"
                      error={
                        errors.employersNumber?.message !== ""
                          ? errors.employersNumber?.message
                          : ""
                      }
                    />
                  </div>
                  {/* Select occupations */}
                  {/* <div>
                  <label
                    htmlFor={"sector"}
                    className={`ml-3 text-sm font-medium  text-navy-700 dark:text-white`}
                  >
                    Secteur d’activité
                    <Badge
                      fontSize={15}
                      rounded={5}
                      padding={1}
                      paddingInline={2}
                      colorScheme="green"
                      className="ms-2"
                    >
                      {sector?.length || 0}
                    </Badge>
                  </label>
                  <select
                    disabled={!canEdit}

                    // onChange={(e) =>{ console.log(e.target.value); setSector(e.target.value)}}
                    onChange={handleChange}
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
                    name="sector"
                    // required={true}
                    id="sector"
                    multiple={true}

                    style={{ height: canEdit ? 200 : 100 }}
                  >
                    {canEdit && <option value="">Selectionner le secteur</option>}
                    {!canEdit && sectors?.data && sector &&
                      sector?.map((it) => {
                        // console.log("🚀 ~ sector?.map ~ it:", it)

                        return (
                          <option selected>{sectors?.data?.find((s) => s?.id == it)?.name}</option>
                        );
                      })}

                    {canEdit && sectors?.data &&
                      sectors?.data?.map((item) => {
                        return (
                          <option selected={sector?.find((s) => s == item?.id)} value={item?.id}>{item?.name}</option>
                        );
                      })}
                  </select>
                </div> */}
                  <MuiAUtocomplete
                    disabled={!canEdit || sector?.length > 4}
                    multiple={true}
                    value={sector}
                    label={'Secteur(s) d’activité(s)'}
                    options={sectors?.data?.sort((a, b) => a?.name > b?.name ? 1 : -1)}
                    format={{ value: 'id', label: 'name' }}
                    // value={params?.regionId}
                    // defaultFormat={true}
                    onChange={(event, items) => setSector(items)}
                    // onChange={(e, item) => console.log(item)}
                    wrapperClasses={"flex-1 filter-item anime slideRight"}
                    inputValue={activityName}
                    onInputChange={(event, newInputValue) => {
                      setActivityName(newInputValue);
                    }}
                    handleNoOption={
                      <MButton onClick={handleAddSector} >
                        Ajouter ce sectur d'activite
                      </MButton>
                    }
                  />
                </div>
                <div>
                  <div>
                    <h2 className="font-bold text-green-500">Adresse</h2>
                    <hr />
                  </div>
                  <div className="my-4 grid gap-2 md:grid-cols-2">
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Boîte postale"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "00000"}
                        id="postal"
                        name="postal"
                        type="text"
                        error={
                          errors.postal?.message !== ""
                            ? errors.postal?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Street"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "Awae escalier"}
                        id="street"
                        name="street"
                        type="text"
                        error={
                          errors.street?.message !== ""
                            ? errors.street?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Localité"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "Yaoundé"}
                        id="locality"
                        name="locality"
                        type="text"
                        error={
                          errors.locality?.message !== ""
                            ? errors.locality?.message
                            : ""
                        }
                      />
                    </div>

                    {/* Filtre region */}

                    <MuiSelect
                      disabled={!canEdit}
                      label={"Region"}
                      // marginRight={5}
                      value={region}
                      options={regions.data}
                      format={{ value: "id", label: 'name' }}

                      onChange={(event) => { setRegion(event.target.value) }}
                      emptyLabel={"Aucun"}

                    />

                    {/* Filtre departement */}
                    <MuiSelect
                      disabled={!canEdit}
                      label={"Département"}
                      // marginRight={5}
                      value={departement}
                      options={departments.data}
                      format={{ value: "id", label: 'name' }}
                      onChange={(event) => { setDepartement(event.target.value) }}
                      emptyLabel={"Aucun"}

                    />

                    {/* Filtre departement */}
                    {/* <div>
                    <label
                      htmlFor={"arrondissement"}
                      className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                    >
                      Arrondissement
                    </label>
                    <select
                      disabled={!canEdit}

                      onChange={(e) => setArrondissement(e.target.value)}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
                      name="arrondissement"
                      id="arrondissement"
                    >
                      <option value="">Sélectionner l'Arrondissment</option>
                      {arrondissements?.data &&
                        arrondissements.data.map((item) => {
                          return (
                            <option selected={arrondissement == item?.id} value={item?.id}>{item?.name}</option>
                          );
                        })}
                    </select>
                  </div> */}
                    <MuiSelect
                      disabled={!canEdit}
                      label={"Arrondissement"}
                      // marginRight={5}
                      value={arrondissement}
                      options={arrondissements.data}
                      format={{ value: "id", label: 'name' }}
                      onChange={(event) => { setArrondissement(event.target.value) }}
                      emptyLabel={"Aucun"}

                    />

                    {/* Filtre region */}

                    <MuiSelect
                      disabled={!canEdit}
                      label={"Zone"}
                      // marginRight={5}
                      value={zone}
                      options={zones?.data?.map((z) => { return { value: z?.id, label: `Zone ${z?.number} ${z?.description ? "--" + z?.description : ''}` } })}
                      // format={{ value: "value", label: 'number' }}

                      onChange={(event) => { setZone(event.target.value) }}
                      emptyLabel={"Aucun"}

                    />
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Site Web"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "https://www.google.com"}
                        id="website"
                        name="website"
                        type="text"
                        error={
                          errors.website?.message !== ""
                            ? errors.website?.message
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <h2 className="font-bold text-green-500">
                      Directeur des ressources humaines
                    </h2>
                    <hr />
                  </div>
                  <div className="my-4 grid gap-2 md:grid-cols-2">
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Noms et prenoms"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "Marc TSOBGNY"}
                        id="RHName"
                        name="RHName"
                        type="text"
                        error={
                          errors.RHName?.message !== ""
                            ? errors.RHName?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Mail"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "marc@gmail.com"}
                        id="RHEmail"
                        name="RHEmail"
                        type="text"
                        error={
                          errors.RHEmail?.message !== ""
                            ? errors.RHEmail?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Téléphone"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "690128903"}
                        id="RHPhone"
                        name="RHPhone"
                        type="text"
                        error={
                          errors.RHPhone?.message !== ""
                            ? errors.RHPhone?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Numéro whatsapp"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "690128903"}
                        id="RHPhoneWh"
                        name="RHPhoneWh"
                        type="text"
                        error={
                          errors.RHPhoneWh?.message !== ""
                            ? errors.RHPhoneWh?.message
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <h2 className="font-bold text-green-500">Positions</h2>
                    <hr />
                  </div>
                  <div className="my-4 grid gap-2 md:grid-cols-2">

                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Latitude"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "12.3"}
                        id="latitude"
                        name="latitude"
                        type="text"
                        error={
                          errors.latitude?.message !== ""
                            ? errors.latitude?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Longitude"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "12.3"}
                        id="longitude"
                        name="longitude"
                        type="text"
                        error={
                          errors.longitude?.message !== ""
                            ? errors.longitude?.message
                            : ""
                        }
                      />
                    </div>

                    <h2 className="font-bold text-green-500">Localisation Google map</h2>
                    <h2></h2>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Latitude Google"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "12.3"}
                        id="searchLatitude"
                        name="searchLatitude"
                        type="text"
                        error={
                          errors.searchLatitude?.message !== ""
                            ? errors.searchLatitude?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        disabled={!canEdit}

                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Longitude Google"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "12.3"}
                        id="searchLongitude"
                        name="searchLongitude"
                        type="text"
                        error={
                          errors.searchLongitude?.message !== ""
                            ? errors.searchLongitude?.message
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        // sx={{Height : 300}}
                        disabled={!canEdit}
                        multiline={true}
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Observations"
                        required={false}
                        // placeholder={selectedEnterprise ? "" : "12.3"}
                        id="observation"
                        name="observation"
                        type="text"
                        error={
                          errors.observation?.message !== ""
                            ? errors.observation?.message
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedEnterprise && <Badge>{`Collecté par : ${selectedEnterprise?.creator ? ` ${selectedEnterprise?.creator?.firstName} ${selectedEnterprise?.creator?.lastName}` : '--'}`}</Badge>}
              {selectedEnterprise && <Badge color={'black'} backgroundColor={'orange'} >{` ${selectedEnterprise ? ` ${formatDateTime(selectedEnterprise?.createdAt)}` : '--'}`}</Badge>}

              {selectedEnterprise && <MuiCollapse
                title={`Historique des modifications (${modifications?.data?.length || 0})`}
                bgColor={'rgba(150,0,0,0.1)'}

              >
                <List>
                  {modifications?.data?.sort((a, b) => a?.editedAt < b?.editedAt)?.map((m) => {

                    return (

                      <ListItem disablePadding divider>
                        <ListItemButton>
                          <ListItemText primary={`${m?.editedBy?.firstName || ''} ${m?.editedBy?.lastName || ''} --${formatDateTime(m?.editedAt)}`}
                          secondary={m?.description}
                          />
                        </ListItemButton>

                      </ListItem>
                    )
                  })}
                </List>
              </MuiCollapse>}
              <DialogActions className="flex justify-between" >
                {/* <div className="flex justify-between w-100 bg-red-500"> */}
                <Button
                  variant={"danger"}
                  className="btn-danger"
                  onClick={handleCloseInside}
                >
                  Fermer
                </Button>

                {((selectedEnterprise && canEdit) || (selectedEnterprise == null || selectedEnterprise == undefined)) && <Button type="submit" colorScheme="green" isLoading={loading}

                >
                  {selectedEnterprise ? "Modifier" : "Sauvegarder"}
                </Button>}

                {/* </div> */}
              </DialogActions>
            </form>
          </DialogContent>
        }
      </Dialog>
    </React.Fragment>
  );
}