import React, { useEffect, useState } from "react";
/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import toast from "react-hot-toast";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import InputField from "components/fields/InputField";
import { Alert, AlertIcon, AlertTitle, Badge, Button, Tooltip } from "@chakra-ui/react";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import axios from "axios";
import { format } from "prettier";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import { BsBan, BsPencilSquare, BsPlusCircle, BsPlusCircleFill, BsXCircleFill } from "react-icons/bs";
import { checkIsValidPermit } from "utils";
import { getExpiredPermitDate } from "utils";
import 'dayjs/locale/fr' // load on demand
import { checkIsValidPermitDate } from "utils";
import { AppBar, Chip, Dialog, DialogContent, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Slide, Toolbar, Typography, capitalize } from "@mui/material";
import { CloseModalButton } from "components/Utils";
import MuiAsyncAutocomplete from "components/MuiAsyncAutocomplete";
import MuiCollapse from "components/MuiCollapse";
import MButton from "components/Mbutton";
import { formatDate } from "utils";
import { checkIsValidContractDate } from "utils";
import { checkIsValidContrat } from "utils";
import MuiAUtocomplete from "components/MuiAutocomplete";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import { formatDateTime } from "utils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddContract = ({ open, handleClose,handleCloseOnly, get, selectedContract, selectedEmployer, contract, fixedEnterprise, setContract }) => {
  // console.log("🚀 ~ AddEmployer ~ selectedContract:", selectedContract)
  /** Form validation config */
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
const [occupationName, setOccupationName] = useState('');
// console.log("🚀 ~ AddContract ~ occupationName:", occupationName)

  const schema = yup.object().shape({
    // contratNumber: yup.string(),
    permitNumber: yup.string(),
    salary: yup.string().default("0"),
    indTransport: yup.string().default("0"),
    indLogement: yup.string().default("0"),
    indResponsabilite: yup.string().default("0"),
    indRepresentation: yup.string().default("0"),
    primeExploitation: yup.string().default("0"),
    sursalaire: yup.string().default("0"),
    primeSalissure: yup.string().default("0"),
    indCaisse: yup.string().default("0"),
    bonus: yup.string().default("0"),
    primePanier: yup.string().default("0"),
    billetAvion: yup.string().default("0"),
    autresPrimes: yup.string().default("0"),

  });
  const auth = useAuthUser();
  const [contractDuration, setContractDuration] = useState(null);

  const [hasPermit, setHasPermit] = useState("Oui");


  const [loadingM, errorM, modifications, fetcModifs] = useDataFetching(
selectedContract?.id ? `${config.app.api_url}/contracts/${selectedContract?.id}/modifications` : null
  );




  const [date, setDate] = useState(null);

  const [occupation, setOccupation] = useState(null);
  console.log("🚀 ~ AddContract ~ occupation:", occupation)
  // const [category, setCategory] = useState(null);
  const [enterprise, setEnterprise] = useState(null);



  const [canEdit, setCanEdit] = useState(false);
  const handleReset = () => {
    reset()
    // setresidencePermitExpDate(null)
    setDate(null)
    setOccupation(null)
    setEnterprise(null)
    setContractDuration(null)
    // setCanEdit(false)
    setHasPermit("Non")
  }

  const durations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];



  //   fetch categories
  const [loadingC, errorC, categories, fetchDataC] = useDataFetching(
    `${config.app.api_url}/categories`
  );

  //   fetch categories
  const [loadingE, errorE, enterprises, fetchDataE] = useDataFetching(
    `${config.app.api_url}/enterprises/labels`
  );

  //   fetch categories
  const [loadingO, errorO, occupations, fetchDataO] = useDataFetching(
    `${config.app.api_url}/occupations/all`
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log("🚀 ~ AddEmployer ~ errors:", errors)

  const onSubmitHandler = (data) => {
    handleSave(data);
    // reset();
  };

  /** post create employer */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`employers/${selectedEmployer?.id}/add-contract`, "POST");

      /** post create employer */
  const [loadingOc, createOccupation, errorMassageO, successMessageO, createdOccupation] =
  usePostAxiosData(`occupations`, "POST");
  
  // console.log("🚀 ~ AddContract ~ createdOccupation:", createdOccupation)

  const handleSave = async (formData) => {
    const data = {
      ...formData,
      id: selectedContract?.id || null,
      creatorId: selectedContract?.id ? null : auth()?.user?.id,
      editorId: auth()?.user?.id,

      // enterpriseId: fixedEnterprise?.id ? fixedEnterprise?.id : enterprise ? parseInt(enterprise) : null,
      contratDuration: parseInt(contractDuration),


      permitDate: date ? dayjs(new Date(date)).format('YYYY-MM-DD') : null,

      hasPermit: hasPermit == "Oui" ? "true" : "false",

      occupationId: parseInt(occupation),

    };

    console.log("data to saved", data);
    if (selectedEmployer) { await postAxiosData(data) } else { setContract(data); handleClose() }
  };

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      get()
      handleReset()
      handleCloseInside()
      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : `Cntract ${selectedContract ? "modifié" : "ajouté"} avec succès.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessage]);



  useEffect(() => {
    setCanEdit(!loading)
  }, [loading]);

  useEffect(() => {
    const selectedContractP = (selectedContract || contract)
    // console.log("🚀 ~ useEffect ~ contract:", contract)
    if (selectedContract) {
      setCanEdit(false)

      setValue('contratNumber', selectedContractP?.contratNumber || undefined)
      setValue('salary', selectedContractP?.salary || undefined)
      setValue('indTransport', selectedContractP?.indTransport || undefined)
      setValue('indLogement', selectedContractP?.indLogement || undefined)
      setValue('indResponsabilite', selectedContractP?.indResponsabilite || undefined)
      setValue('indRepresentation', selectedContractP?.indRepresentation || undefined)
      setValue('primeExploitation', selectedContractP?.primeExploitation || undefined)
      setValue('sursalaire', selectedContractP?.sursalaire || undefined)
      setValue('primeSalissure', selectedContractP?.primeSalissure || undefined)
      setValue('indCaisse', selectedContractP?.indCaisse || undefined)
      setValue('bonus', selectedContractP?.bonus || undefined)
      setValue('primePanier', selectedContractP?.primePanier || undefined)
      setValue('billetAvion', selectedContractP?.billetAvion || undefined)
      setValue('autresPrimes', selectedContractP?.autresPrimes || undefined)

      setValue('qualification', selectedContractP?.qualification || undefined)
      setValue('permitNumber', selectedContractP?.permitNumber || undefined)

      setContractDuration(selectedContractP?.contratDuration)
      setHasPermit((selectedContractP?.permitDate?.length > 0 || selectedContractP?.permitDate?.length > 0 || selectedContractP?.hasPermit == true) ? 'Oui' : 'Non')
      // setHasPermit(selectedContract?.hasPermit == true ? 'Oui' : 'Non')

      setDate(selectedContractP?.permitDate)

      setOccupation(selectedContractP?.occupation?.id || selectedContractP?.occupationId)
      setEnterprise(selectedContractP?.enterprise?.id)



    } else {
      setCanEdit(true)

      // alert("rien")
      handleReset()
    }
  }, [selectedContract, contract]);

  const handleCloseInside = () => {
if(handleCloseOnly){
  handleCloseOnly()
}else{

  handleReset()
  handleClose()
}
  }
  const handleAddPoste = ()=>{
occupationName && window.confirm(`Etes vous sur de vouloir ajouter ce poste? ${occupationName}`) && createOccupation({name : occupationName, creatorId : auth()?.user?.id})
  }

  useEffect(() => {
    if (successMessageO?.status === 200 || successMessageO?.status === 201) {
      fetchDataO()
      toast.success(
        `${successMessageO?.message != null
          ? successMessageO?.message
          : `Poste ajoute avec succès.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessageO]);

// useEffect(() => {
//   createdOccupation && setOccupation(createdOccupation?.data?.id)
// }, [createdOccupation]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      onClose={handleCloseInside}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: 'relative' }}>
        <Toolbar
          className="bg-gray-50"
        >


          <Typography sx={{ ml: 2, flex: 1 }} color={'green'} variant="h6" component="div" >


            {selectedContract ? ` ${!canEdit ? "Informations sur le contract" : "Editer le"}   contrat de ` : `Ajouter un nouveau contrat à`}
            {selectedEmployer && <strong className=" text-center ms-2">
              {"<<" + (selectedEmployer?.fullName || (selectedEmployer?.firstName + " " + selectedEmployer?.lastName)) + ">>"}

            </strong>}
          </Typography>

          {/* <PrintButton
                // toPDF={toPDF} 
              /> */}
          <div className="flex justify-end gap-2 pr-10">

            {selectedContract && <Tooltip hasArrow label={canEdit ? "Cliquez ici pour vérouiller" : "Cliquez ici pour éditer"} bg='red.200' color='black' placement="bottom">

              {/* <button
                  className={`flex items-center  gap-2 rounded-md p-1 shadow bg-white`}
                  onClick={() => setCanEdit(!canEdit)}
                > */}
              <IconButton
                edge="start"
                color="primary"
                onClick={() => setCanEdit(!canEdit)}

                aria-label="close"
              >

                {canEdit ? <BsBan className="text-red-500" /> : <BsPencilSquare className="text-green-500" />}

              </IconButton>
            </Tooltip>}
          </div>
          <CloseModalButton iconColor={"red"}  handleClose={handleCloseInside} />
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {selectedContract && !checkIsValidContrat(selectedContract) ?
          <Alert className="mb-5 rounded-lg shadow " status='error'>
            <AlertIcon />
            <AlertTitle>{selectedContract?.permitNumber ? '' : '(Numéro de permis non foruni)'}-Permit de travail {checkIsValidPermitDate(selectedContract) ? 'expire le' : 'expiré le'} : {dayjs(getExpiredPermitDate(selectedContract?.permitDate)).format('DD-MM-YYYY') || '--'} </AlertTitle>

            ( {selectedContract?.permitDate ? dayjs(getExpiredPermitDate(selectedContract?.permitDate)).locale('fr').fromNow() : "--"})
          </Alert>
          :
          selectedContract && <Alert className="mb-5 rounded-lg shadow" status='success'>
            <AlertIcon />
            <AlertTitle>Permit de travail valide jusqu'au : {dayjs(getExpiredPermitDate(selectedContract?.permitDate)).format('DD-MM-YYYY') || '--'}
              ( Expire {selectedContract?.permitDate ? dayjs(new Date()).locale('fr').to(dayjs(getExpiredPermitDate(selectedContract?.permitDate)).locale('fr')) : "--"})
            </AlertTitle>
            {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {errorMassage?.message && (
            <Error
              message={
                errorMassage?.message !== "" && errorMassage?.message
              }
            />
          )}




          <Paper className="p-3" >


            <div>
              <div className="flex justify-between">
                <h2 className="font-bold text-green-500">
                  Informations sur le contrat
                </h2>
                {/* <BsPl<h2 className="font-bold text-green-500">
                  Informations sur le contrat
                </h2>usCircleFill color="green" size={30} /> */}
              </div>
              <hr />

              <div className="my-4 grid gap-2 md:grid-cols-2">
                {/* Select enterprises */}

                <MuiAUtocomplete
                  disabled={!canEdit}
                  value={occupation}
                  label={'Selectionner le poste'}
                  options={occupations?.data}
                  format={{ value: 'id', label: 'name' }}
                  // value={occupation}
                  // defaultFormat={true}
                  inputValue={occupationName}
                  onInputChange={(event, newInputValue) => {
                    setOccupationName(newInputValue);
                  }}
                  onChange={(e, item) => setOccupation(item.value)}
                  handleNoOption={
                    <MButton onClick={handleAddPoste} >
                      Ajouter ce poste
                    </MButton>
                  }
                  wrapperClasses={"flex-1 filter-item anime slideRight"}
                />
                {/* <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    variant="auth"
                    extra="mb-3"
                    label="Numéro de contrat"
                    // required={true}
                    // placeholder="FFGJKE4545FS"
                    id="contratNumber"
                    name="contratNumber"
                    type="text"
                    error={
                      errors.contratNumber?.message !== ""
                        ? errors.contratNumber?.message
                        : ""
                    }
                  />
                </div> */}
                {/* Select contract duration */}
                <MuiSelect
                  disabled={!canEdit}
                  label={"Selectionner la durée du contrat"}
                  // marginRight={5}
                  value={contractDuration}
                  options={durations?.map((d) => { return { label: d, value: d } })}
                  onChange={(event) => { setContractDuration(event.target.value) }}
                />
              </div>
            </div>

            {/* infos sur le salaire */}
            <div>
              <div>
                <h2 className="font-bold text-green-500">
                  Informations sur le salaire
                </h2>
                <hr />
              </div>
              <div className="my-4 grid gap-2 md:grid-cols-6">
                {/* Select categories */}

                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    variant="auth"
                    extra="mb-3"
                    label="Salaire de Base (XAF)"
                    // required={true}
                    defaultValue={"0"}
                    // placeholder="100000000"
                    id="salary"
                    name="salary"
                    type="number"
                    error={
                      errors.salary?.message !== ""
                        ? errors.salary?.message
                        : ""
                    }
                    min="0"

                  />
                </div>
                <div>
                  <InputField
                    min="0"
                    disabled={!canEdit}
                    register={register}
                    variant="auth"
                    defaultValue={"0"}
                    extra="mb-3"
                    label="indemnite de transport"
                    required={true}
                    // placeholder="20000000"
                    id="indTransport"
                    name="indTransport"
                    type="number"
                    error={
                      errors.indTransport?.message !== ""
                        ? errors.indTransport?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    min="0"
                    disabled={!canEdit}
                    register={register}
                    variant="auth"
                    defaultValue={"0"}
                    extra="mb-3"
                    label="indemnite de logement"
                    required={true}
                    // placeholder="20000000"
                    id="indLogement"
                    name="indLogement"
                    type="number"
                    error={
                      errors.indLogement?.message !== ""
                        ? errors.indLogement?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="prime de responsabilite"
                    // required={true}
                    // placeholder="1000000"
                    id="indResponsabilite"
                    name="indResponsabilite"
                    type="number"
                    error={
                      errors.indResponsabilite?.message !== ""
                        ? errors.indResponsabilite?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    variant="auth"
                    defaultValue={"0"}
                    extra="mb-3"
                    label="prime de representation"
                    // required={true}
                    // placeholder="105545"
                    id="indRepresentation"
                    name="indRepresentation"
                    type="number"
                    error={
                      errors.indRepresentation?.message !== ""
                        ? errors.indRepresentation?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="prime d'expatriation"
                    // required={true}
                    // placeholder="500000"
                    id="primeExploitation"
                    name="primeExploitation"
                    type="number"
                    error={
                      errors.primeExploitation?.message !== ""
                        ? errors.primeExploitation?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="Sursalaire"
                    // required={true}
                    // placeholder="125066"
                    id="sursalaire"
                    name="sursalaire"
                    type="number"
                    error={
                      errors.sursalaire?.message !== ""
                        ? errors.sursalaire?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="prime de salissure"
                    // required={true}
                    // placeholder="125066"
                    id="primeSalissure"
                    name="primeSalissure"
                    type="number"
                    error={
                      errors.primeSalissure?.message !== ""
                        ? errors.primeSalissure?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="indenmite de caisse"
                    // required={true}
                    // placeholder="125066"
                    id="indCaisse"
                    name="indCaisse"
                    type="number"
                    error={
                      errors.indCaisse?.message !== ""
                        ? errors.indCaisse?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="Bonus"
                    // required={true}
                    // placeholder="125066"
                    id="bonus"
                    name="bonus"
                    type="number"
                    error={
                      errors.bonus?.message !== ""
                        ? errors.bonus?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="prime de panier"
                    // required={true}
                    // placeholder="125066"
                    id="primePanier"
                    name="primePanier"
                    type="number"
                    error={
                      errors.primePanier?.message !== ""
                        ? errors.primePanier?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="billet d'avion"
                    // required={true}
                    // placeholder="125066"
                    id="billetAvion"
                    name="billetAvion"
                    type="number"
                    error={
                      errors.bonus?.message !== ""
                        ? errors.bonus?.message
                        : ""
                    }
                  />
                </div>
                <div>
                  <InputField
                    disabled={!canEdit}
                    register={register}
                    defaultValue={"0"}
                    variant="auth"
                    extra="mb-3"
                    label="Autres primes"
                    // required={true}
                    // placeholder="125066"
                    id="autresPrimes"
                    name="autresPrimes"
                    type="number"
                    error={
                      errors.autresPrimes?.message !== ""
                        ? errors.autresPrimes?.message
                        : ""
                    }
                  />
                </div>

              </div>
              <div>
                <div>
                  <h2 className="font-bold text-green-500">
                    Informations sur le permis de travail
                  </h2>
                  <hr />
                </div>


                <MuiSelect
                  disabled={!canEdit}
                  label={"As t'il un permis de travail ?"}
                  // marginRight={5}
                  value={hasPermit}
                  options={[{ label: "Oui", value: 'Oui' },
                  { label: "Non", value: 'Non' },
                  ]}
                  onChange={(event) => { setHasPermit(event.target.value) }}
                />

                <div className="my-4 grid gap-2 md:grid-cols-2 anime slideUp ">
                  {hasPermit == "Oui" &&
                    <>
                      <div>
                        <InputField
                          disabled={!canEdit}
                          register={register}
                          variant="auth"
                          extra="mb-3"
                          label="Numéro de permis"
                          // required={true}
                          // placeholder="GJHJSDK65656JJJ"
                          id="permitNumber"
                          name="permitNumber"
                          type="text"
                          error={
                            errors.permitNumber?.message !== ""
                              ? errors.permitNumber?.message
                              : ""
                          }
                        />
                      </div>


                      <MuiDatePicker
                        label={"Date de signature du permis de travail"}
                        value={date}
                        disabled={!canEdit}
                        fullWidth={true}
                        onChange={(newValue) => setDate(new Date(newValue)?.getTime())}

                        wrapperClasses={"flex-1 filter-item anime  w-full slideLeft "}

                      />
                    </>
                  }
                </div>

              </div>
            </div>

          </Paper>
          {selectedContract && <Badge>{`Collecté par : ${selectedContract?.creator ? ` ${selectedContract?.creator?.firstName} ${selectedContract?.creator?.lastName}` : '--'}`}</Badge>}

          {selectedContract && <MuiCollapse
            title={`Historique des modifications (${modifications?.data?.length || 0})`}
            bgColor={'rgba(150,0,0,0.1)'}

          >
            <List>
              {modifications?.data?.sort((a, b) => a?.editedAt < b?.editedAt)?.map((m) => {

                return (

                  <ListItem disablePadding divider>
                    <ListItemButton>
                      <ListItemText primary={`${m?.editedBy?.firstName || ''} ${m?.editedBy?.lastName || ''} --${formatDateTime(m?.editedAt)}`} 
                          secondary={m?.description}
                      
                      />
                    </ListItemButton>

                  </ListItem>
                )
              })}
            </List>
          </MuiCollapse>}
          <div className="flex justify-between p-4">
            <div>
              <MButton
                variant={'danger'}

                onClick={(e) => {
                  e?.stopPropagation()
                  e?.preventDefault()
                  handleCloseInside();
                  // get();
                }}
              >
                Fermer
              </MButton>
            </div>
            <div>
              {((selectedContract && canEdit) || (selectedContract == null || selectedContract == undefined)) && <MButton type="submit" colorScheme="green" isLoading={loading}

              >
                {selectedContract ? "Enregistrer les Modifications" : "Ajouter"}
              </MButton>}
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddContract;
