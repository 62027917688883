import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { BsBellFill } from 'react-icons/bs';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { onMessageListener } from 'firebaseConf/firebaseConfig2';
import { requestPermission } from 'firebaseConf/firebaseConfig2';
import toast from 'react-hot-toast';
import { Badge } from '@mui/material';
import styled from '@emotion/styled';
import { MdNotifications, MdNotificationsActive } from 'react-icons/md';

export default function AccountMenu() {

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '120%',
        height: '120%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '5px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(3.4)',
        opacity: 0,
      },
    },
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useAuthUser()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const signOut = useSignOut();

  const navigate = useNavigate()
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNot = (event) => {
    // setAnchorEl(event.currentTarget);
  };

  const [newNotif, setnewNotif] = React.useState(false);


  React.useEffect(() => {
    requestPermission();

    onMessageListener().then((payload) => {
      setnewNotif(true)
      console.log('Received foreground message ', payload);
      toast(
        (t) => (
          <span className="flex items-center min-w-[100px]">
            <span className="">
              <p className="font-bold">{payload?.notification?.title}</p>
              <p>{payload?.notification?.body}</p>
            </span>
            <span className="">
              <i className="bi bi-x-circle  text-red-300 " onClick={() => toast.dismiss(t.id)} />
            </span>

          </span>
        ),
        {
          // icon: <i className="bi bi-x-circle"/>,
          duration: 100000,
          position: "top-right",
                  }
      );
    }).catch((err) => {
      console.log('Failed: ', err);
    });
  }, []);

  return (
    <React.Fragment >
      <Box className='className="flex items-center text-center absolute right-5 top-1 z-50'>
        <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ backgroundColor: 'orange' }}
              className='shadow-lg '
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
          <StyledBadge
            overlap="circular"
            // color='error'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            variant={newNotif ? "dot" : "standart"}
          >
              <Avatar className='bg-green-200' sx={{ width: 42, height: 42 }}>{auth()?.user?.firstName[0] + auth()?.user?.lastName[0]}</Avatar>
          </StyledBadge>
            </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() =>{setnewNotif(false); navigate("/admin/notifications")}}>
          <ListItemIcon>
       {newNotif ?     <MdNotificationsActive size={25} color='red'/> :  <MdNotifications />}
          </ListItemIcon>

          Notifications
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
