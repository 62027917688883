import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Select } from "@chakra-ui/react";
import { ROLES } from "Constaints";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import { AppBar, Dialog, DialogContent, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import { CloseModalButton } from "components/Utils";

const AddZone = (props) => {
  const canEdit = true
  const { isOpen, onClose } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    number: yup.string().required("Number is a required field"),
    description: yup.string(),
    // start: yup.string().required("Phone Number is a required field"),
    // end: yup.string().required("Phone Number is a required field"),

  });

  
  const [departement, setDepartement] = useState(null);
  const [regionId, setRegionId] = useState(null);

  // const [participants, setParticipants] = useState([]);
  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );

  const [loadingD, errorD, departments, fetchDataD] = useDataFetching(
    `${config.app.api_url}/regions/${regionId !== "*" && regionId}/departments`
  );


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  console.log("🚀 ~ AddMission ~ errors:", errors)
  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`zones`, "POST");
  const handleSave = async (dataYub) => {
    // console.log("dataYub", dataYub);
    const data = {
      ...dataYub,
      regionId,
      departmentId : departement
  
      
    };
    // console.log("🚀 ~ handleSave ~ data:", data)

    await postAxiosData(data);
  };



  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Utilisateur ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      props?.endFetch()
      onClose()
      // handleSignin(result);
    }
  }, [successMessage]);



  // console.log("🚀 ~ AddUser ~ regions:", regions)

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      // TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar
            className="bg-gray-50"
          >           
           <Typography sx={{ ml: 2, flex: 1 }} color={'green'} fontWeight={'bold'} variant="h6" component="div" >
              Creer une zone</Typography>
            <CloseModalButton iconColor={'red'} handleClose={onClose} />

          </Toolbar>
        </AppBar>

        <DialogContent dividers>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="my-4 grid gap-2 md:grid-cols-1">
              {errorMassage?.message && (
                <Error
                  message={
                    errorMassage?.message !== "" && errorMassage?.message
                  }
                />
              )}

              <MuiSelect
                disabled={!canEdit}
                label={"Region"}
                // marginRight={5}
                value={regionId}
                options={regions.data}
                format={{ value: "id", label: 'name' }}

                onChange={(event) => { setRegionId(event.target.value) }}
                emptyLabel={"Aucun"}

              />
              <MuiSelect
                disabled={!canEdit}
                label={"Département"}
                // marginRight={5}
                value={departement}
                options={departments.data}
                format={{ value: "id", label: 'name' }}
                onChange={(event) => { setDepartement(event.target.value) }}
                emptyLabel={"Aucun"}

              />
              <InputField
                register={register}
                variant="auth"
                extra="mb-3"
                label="Numero"
                required={true}
                placeholder="number"
                id="number"
                name="number"
                type="number"
                error={
                  errors.number?.message !== ""
                    ? errors.number?.message
                    : ""
                }
              />
             
             <InputField
                register={register}
                variant="auth"
                extra="mb-3"
                label="Description"
                required={true}
                placeholder="Description"
                id="description"
                name="description"
                type="text"
                error={
                  errors.number?.message !== ""
                    ? errors.number?.message
                    : ""
                }
              />
             
            </div>
            <div className="flex justify-between p-4">
              <div>
                <MButton
                  variant={"danger"}
                  onClick={(e) => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    onClose();
                    // window.location.reload();
                  }}>Fermer</MButton>
              </div>
              <div>
                <MButton variant={"primary"} type="submit" colorScheme="green">
                  Creer
                </MButton>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddZone;
