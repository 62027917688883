import { Typography } from "@mui/material"

export  const  Title = ({title})=>{


    return(
        <div className="flex flex-col gap-1">
        <Typography variant="h5" color={'green'}>{title}</Typography>
        <span className="w-full h-[5px] bg-green-200 rounded"></span>

        </div>)
}