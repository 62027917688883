import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Checkbox } from "@chakra-ui/react";
import { PERMISSIONS } from "Constaints";
import { CHECK_PERMISSIONS } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";

const AddRole = (props) => {
  const { isOpen, onClose, getRoles } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    name: yup.string().required("Name is a required field"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  const [permissionToSaved, setPermissionsToSaved] = useState([]);
  let permissions = [];
  let nouveauTableau = [];

  const handleCheckedPermission = (e) => {
    if (e.target.checked === true) {
      // console.log("Ajout");
      // permissionToSaved?.length > 0 && permissionToSaved
      permissions.push(e.target.name);
      setPermissionsToSaved([...permissionToSaved, permissions]);
    } else {
      nouveauTableau = permissionToSaved.filter(
        (element) => element[0] !== e.target.name
      );
      console.log("Per", nouveauTableau);
      setPermissionsToSaved(nouveauTableau);
      // permissionArray.remove(e.target.name);
      // setPermissions(permissionArray);

      // const index = myArray.indexOf(2);

      // const x = myArray.splice(index, 1);
    }
  };
  console.log("permissions final: ", permissionToSaved);

  useEffect(() => {}, [permissionToSaved]);

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`roles`, "POST");

  const handleSave = async (dataYub) => {
    // console.log("dataYub", dataYub);
    let dataP = [];
    permissionToSaved?.length > 0 &&
      permissionToSaved?.forEach((per) => {
        dataP.push(per[0]);
      });

    console.log(dataP);
    const data = {
      name: dataYub?.name,
      permissions: dataP.length > 0 && dataP,
    };

    await postAxiosData(data);

    setPermissionsToSaved([]);
  };

  const auth = useAuthUser();
  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  /** gestion des erreurs et succès */
  useEffect(() => {}, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
    reset();
    getRoles();

      toast.success(
        `${
          successMessage?.message != null
            ? successMessage?.message
            : "Role ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );

      // handleSignin(result);
    }
  }, [successMessage]);

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered={true}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div className="text-center">
                <span className="text-3xl uppercase">Ajouter un role</span>
              </div>
            </AlertDialogHeader>

            <AlertDialogBody>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="my-4 grid gap-2 md:grid-cols-1">
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom"
                      required={true}
                      placeholder="AGENT"
                      id="name"
                      name="name"
                      type="text"
                      error={
                        errors.name?.message !== "" ? errors.name?.message : ""
                      }
                    />
                  </div>
                  {usr?.data?.roles.length > 0 &&
                    usr?.data?.roles?.map((role) => {
                      return (
                        CHECK_PERMISSIONS(
                          role?.permissions,
                          "Attribuer des permissions à un rôle"
                        ) && (
                          <div className="ms-1">
                            <div className="mb-2 text-start">
                              <span className="text-xl font-medium">
                                Attribuer des permissions au rôle
                              </span>
                            </div>
                            {PERMISSIONS.length > 0 &&
                              PERMISSIONS.map((permission, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    <Checkbox
                                      name={permission}
                                      onChange={(e) => {
                                        handleCheckedPermission(e);
                                      }}
                                    />
                                    <span className="ms-2">{permission}</span>
                                  </div>
                                );
                              })}
                          </div>
                        )
                      );
                    })}
                </div>
                <div className="flex justify-between p-4">
                  <div>
                    <Button
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Annuler
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" colorScheme="green">
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </form>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddRole;
