import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { NotFoundResults } from './Utils';
import { Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { BsChevronBarDown, BsChevronBarUp, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Badge } from '@chakra-ui/react';




export default function StickyHeadTable({ columns, rows, limit=25, hidePagiation, loading, textEmpty, className }) {
    //   console.log("🚀 ~ StickyHeadTable ~ rows:", rows)
    // const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(limit || 25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const [rrr, setRrr] = React.useState([]);
    React.useEffect(() => {
        if (rows) {
            hidePagiation == true ? setRrr(rows) : setRrr(rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
        }
    }, [rows]);
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (

            < React.Fragment >
                <TableRow onClick={row?.handleClick} sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: 0 } }}>
                    {row?.history &&
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => { e?.stopPropagation(); setOpen(!open) }}
                            >
                                {open ? <BsChevronUp color='green' /> : <BsChevronDown color='green' />}
                            </IconButton>
                        </TableCell>}


                    {
                        Object.keys(row?.datas)?.map((key) => {
                            // console.log("columns[key]?.align", columns?.find((c)=>c?.id ==key)?.align)
                            return (
                                <TableCell align={columns?.find((c) => c?.id == key)?.align} style={{ color: row?.color }} > {row?.datas[key] ?? "--"} </TableCell>
                            )
                        })
                    }
                </TableRow>
                {
                    row?.history && <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="body1" gutterBottom component="div">
                                        {row?.history?.title}
                                        <Badge
                                            fontSize={15}
                                            rounded={5}
                                            padding={1}
                                            paddingInline={2}
                                            colorScheme="orange"
                                            color={"black"}
                                            marginLeft={2}
                                        >{row?.history?.datas?.length}</Badge>
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                {row?.history?.header?.map((h) => {
                                                    return <TableCell>{h}</TableCell>
                                                })}

                                                {/* <TableCell>Salaire (FAFC)</TableCell>
                                            <TableCell>Indenmités</TableCell>
                                            <TableCell>Numero de permis</TableCell>
                                            <TableCell>Date d'optention du permit</TableCell>
                                            <TableCell>Date d'expiration du permit</TableCell> */}
                                                {/* <TableCell align="right">Status</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row?.history?.datas?.length > 0 ? row?.history?.datas?.map((historyRow) => (
                                                <TableRow key={historyRow?.index}>

                                                    <TableCell scope="row">
                                                        {historyRow?.salary}
                                                    </TableCell>
                                                    <TableCell align='right'>{historyRow?.idenmites}</TableCell>
                                                    <TableCell>{historyRow?.permitNumber}</TableCell>
                                                    <TableCell>{historyRow?.permitOptDate}</TableCell>
                                                    <TableCell>{historyRow?.permitDate}</TableCell>
                                                    {/* <TableCell>{historyRow.status}</TableCell> */}
                                                </TableRow>
                                            )) :
                                                <NotFoundResults title={textEmpty} />

                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                }
            </React.Fragment >
        );
    }


    return (
        // loading ?
        //     <div className={`flex flex-col gap-2 ${className}`}>
        //         {
        //             Array(limit).fill(0)?.map(() => <Skeleton variant='rectangular' height={50} width={'100%'} />)

        //         }
        //     </div> :
        <Paper className={`bg-white ${className}`} sx={{ width: '100%', overflow: 'hidden', backgroundColor: loading ? 'white' : 'none' }}>
            <TableContainer sx={{ maxHeight: "85dvh" }}>
                <Table stickyHeader size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column?.maxWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {loading ?
                        <TableBody>


                            {
                                Array(limit).fill(0)?.map(() => {
                                    return <>
                                        <TableRow>
                                            {
                                                columns?.map(() => {

                                                    return <TableCell> <Skeleton variant='rectangular' height={50} width={'100%'} /></TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </>

                                })

                            }
                        </TableBody>

                        :
                        <TableBody>
                            {rrr?.length > 0 && rrr
                                .map((row) => {
                                    return (
                                        <Row key={row?.name} row={row} />


                                    );
                                })}
                        </TableBody>}
                </Table>
                {hidePagiation == true ? <></> : <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rrr?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </TableContainer>
            {rows?.length === 0 && <NotFoundResults />}
        </Paper>

        // :
    );
}