import { Avatar, IconButton } from "@mui/material";
import axios from "axios";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { BsPlusCircle, BsTrashFill } from "react-icons/bs";
import { formatDateFrom } from "utils";
import { config } from "variables/config";

const MultilineTextInputList = ({ setElemets, elements, labelName, handleFetechRemoveInput }) => {
  // const [elements, setElemets] = useState(elements || ['']);
  // console.log("🚀 ~ MultilineTextInputList ~ elements:", elements)

  const handleAddInput = () => {
    elements ?  setElemets([...elements, '']) : setElemets(['']);
  };

  const handleInputChange = (index, value) => {
    const newelements = [...elements];
    newelements[index] = value;
    setElemets(newelements);
  };
  const handleRemoveInput = (index, item) => {
    const newelements = [...elements];
    newelements.splice(index, 1);
    setElemets(newelements);
    handleFetechRemoveInput(item)
  };
  // useEffect(() => {
  //   setElemets(elements)
  // }, [elements]);

  // useEffect(() => {
  //   setElemets(elements)
  // }, [elements]);

  return (


    <div className="flex flex-col gap-4">
      {elements && elements?.map((item, index) => (

        <div key={index} className="flex justify-between items-center gap-4">
          <Avatar>
            {index + 1}
          </Avatar>
          <div className="flex-1 ">

            <InputField
              value={item?.message}
              // sx={{Height : 300}}
              multiline={true}
              variant="auth"
              extra="mb-3"
              label="Observations"
              required={false}
              // placeholder={selectedEnterprise ? "" : "12.3"}
              id="observation"
              name="observation"
              type="text"
              onChange={(value) => handleInputChange(index, { id: item?.id || null, message: value?.target?.value })}

            // error={
            //   errors.observation?.message !== ""
            //     ? errors.observation?.message
            //     : ""
            // }
            />
          {item?.id &&   <div className="flex  justify-end gap-2">
              <small className="text-gray-400"> By {item?.user?.firstName} {item?.user?.lastName},</small>
              <small className="text-gray-400">{formatDateFrom(item?.createdAt)}</small>
            </div>}
          </div>

          <IconButton>
            <BsTrashFill size={30}
              onClick={() => handleRemoveInput(index, item)}
              className="text-red-500" />
          </IconButton>

        </div>
      ))}
      <div
        className="flex justify-center"
      >
        <IconButton>

          <BsPlusCircle
            size={35}
            onClick={handleAddInput}
            className="text-green-500 "
          />
        </IconButton>
        {/* <Button title="Add Input" onPress={handleAddInput} /> */}
      </div>
    </div>



  );
};


export default MultilineTextInputList;
