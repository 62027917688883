import { Skeleton } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import Chart from "react-apexcharts";

const BasicPie = ({ data, colors, width, height, count, legendDirection }) => {
  // const { series, options } = props;
  function roundToHundredth(number) {
    // Multiply by 100 to convert to cents (hundredth)
    const hundredths = number * 100;

    // Use Math.round for rounding
    const roundedHundredths = Math.round(hundredths);

    // Divide by 100 to get the number back in its original form with two decimal places
    return roundedHundredths / 100;
  }

  return (
    data?.length > 0 ?
      <div className="flex flex-1 flex-wrap  w-full py-1">

        <PieChart
          // onItemClick={(i, p) => console.log(p)}
          // colors={['red', 'blue', 'green']}
          colors={colors}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              fontSize: 12,


            },
          }}
          // {...size}
          margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
          slotProps={{


            legend: {
              direction: legendDirection || 'column',
              itemGap : 5,
              
              padding : {top : 80, left : 20},
            
              itemMarkHeight : 15,
              itemMarkWidth : 13,
              // markGap : 30,
              
              
              labelStyle: {
                fontSize: 14,
                textTransform: 'capitalize',
                // color : 'white',
                // colorScheme : 'white',
                //  caretColor : 'white',
                //  color
              },

              position: { vertical: 'bottom', horizontal: 'left' }
            }
          }} // Hide the legend
          series={[
            {
              data,
              arcLabel: (item) => `${roundToHundredth(item.value * 100 / count)}%`,


              arcLabelMinAngle: 45,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -20, color: 'gray' },
              innerRadius: 10,
              outerRadius: 90,
              paddingAngle: 2,
              cornerRadius: 5,
              // startAngle: -90,
              // endAngle: 180,
              // cx: 25,
              cy: 100,
              

            },
          ]}
          width={width || 300}
          height={height || 300}
        // onItemClick={(event, d) => console.log(event)}
        />
      </div>

      :
      <div className="flex flex-col content-center items-center">
        <Skeleton variant="circular" width={220} height={230} className="m-3" />
        <span>Pas de données</span>
      </div>
  );
};

export default BasicPie;
