
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import usePostAxiosData from "hooks/usePostAxiosData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { config } from "variables/config";
import { ROLES } from "Constaints";
import PDFViewer from "components/PDFViewer";
import AddEnterprise, { Transition } from "../tables/AddEnterprise";
import { AppBar, capitalize, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, IconButton, Radio, Skeleton, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { ENTERPRISE_HEADER } from "Constaints";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";
import { CHECK_PERMISSIONS } from "Constaints";
import { BsActivity, BsArrowRight, BsCheck2All, BsCheckCircleFill, BsClockFill, BsDoorOpen, BsExclamationCircleFill, BsGeoAlt, BsLink, BsList, BsOpencollective, BsPeople, BsPersonCheckFill, BsPersonDown, BsPersonFillX, BsQuestion, BsSearch, BsXCircleFill } from "react-icons/bs";
import { createMapLink } from "utils";
import MuiTable from "components/MuiTable";
import { CloseModalButton } from "components/Utils";
import MuiCollapse from "components/MuiCollapse";
import { Badge } from "@chakra-ui/react";
import PaginateBox from "components/PaginateBox";
import { lime } from "@mui/material/colors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { COLORS } from "constant/COLORS";
import { useAuthUser } from "react-auth-kit";
import { forEach } from "lodash";

const EnterpriseAccountDetails = ({selectedUser, openM, onCloseM, get}) => {
  // const { isOpen, onClose, setIsOpen, selectedUserP, fetchData } = props;
  const location = useLocation();
  const navigation = useNavigate()
  const id = selectedUser?.id;
  // const { id } = useParams();
  // const { selectedUser } = location.state || {}; // Destructure the data from state
  // console.log("🚀 ~ EnterpriseAccountDetails ~ selectedUser:", selectedUser)
  const [toDisplayEnterprise, settoDisplayEnterprise] = useState(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ selectedEnterprise:", selectedEnterprise)
  const auth = useAuthUser();
  /** get user by id */
  const [request, setRequest] = useState(null);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ request:", request)

  const [loadingU, errorU, user, fetchDataU] = useDataFetching(`${config.app.api_url}/users/${id || selectedUser?.id}`);
  const [loadingrequest, errorRequest, requests, getRequests] = useDataFetching(user?.data?.id ? `${config.app.api_url}/user-requests?userId=${user?.data?.id}&requestEntityType=ENTERPRISE&requestType=CREATE` : null);
  // const [loadingCheckLing, errorLink, alreadyLinked, CheckLink] = useDataFetching(selectedEnterprise?.nui ? `${config.app.api_url}/users/check-existing-linked-enterprise?niu=${selectedEnterprise?.nui}` : null);
  const [loadingCheckLingEx, errorLinkEx, linkedAccounts, getLinkAccount] = useDataFetching(request?.nui ? `${config.app.api_url}/users/get-existing-linked-enterprise?niu=${request?.nui}` : null);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ requests:", requests)
  // const [loadingEN, errorEnNiu, enterPriseByNui, getEnterpriseByniu] = useDataFetching((!user?.data?.enable && selectedUser?.myEnterpriseNiu) ? `${config.app.api_url}/enterprises/search-by-nui/${selectedUser?.myEnterpriseNiu}` : null);
  const [loadingEN, errorEnNiu, enterPriseByNui, getEnterpriseByniu] = useDataFetching(request?.nui ? `${config.app.api_url}/enterprises/search-by-nui/${request?.nui}` : null);

  const [loadingEnter, errorENter, enterprises, getSimilarEnterprises] = useDataFetching((request?.socialRaison) ? `${config.app.api_url}/enterprises/search-like/${request?.socialRaison}` : null);

  const [loadingEnableA, postEnableA, errorPostA, successMessageEnableA, resultEnableA] = usePostAxiosData(`users/${id || selectedUser?.id}/enable-account`, "POST");
  const [loadingEnable, postEnable, errorPost, successMessageEnable, resultEnable] = usePostAxiosData(`users/${id || selectedUser?.id}/enable-enterprise-account`, "POST");
  const [loadingDisable, postDisable, errorPostDisable, successMessageDisable, resultDisable] = usePostAxiosData(`users/${id || selectedUser?.id}/disable-account`, "POST");
  const [loadingDisableEnter, postDisableEnt, errorPostDisableEnter, successMessageDisableEnter, resultDisableEnter] = usePostAxiosData(`users/${id || selectedUser?.id}/disable-enterprise-account?requestId=${request?.id}`, "POST");

  const getAll = () => {
    fetchDataU()
    getRequests()
    getLinkAccount()
    getEnterpriseByniu()
    getSimilarEnterprises()
    get()
  }
 
  const [rowsChanges, setRowChange] =  useState([]);
 
  const [updateFromRequest, setUpdateFromRequest] = useState(false);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ updateFromRequest:", updateFromRequest)

  // console.log("🚀 ~ EnterpriseAccountDetails ~ request:", request)
  useEffect(() => {
    requests?.data?.content?.length > 0 ? setRequest(requests?.data?.content[requests?.data?.content?.length - 1]) : setRequest(null)
  }, [requests]);

  useEffect(() => {
    if (request && request?.selectedRequestFieldsByAdmin) {
      try {
        // console.log("🚀 ~ useEffect ~ JSON.parse(request?.selectedRequestFieldsByAdmin):", JSON.parse(request?.selectedRequestFieldsByAdmin))

        setUpdateFromRequest(JSON.parse(request?.selectedRequestFieldsByAdmin)?.updateFromRequest)
        setselectedFields(Object.keys(JSON.parse(request?.selectedRequestFieldsByAdmin))?.filter((key) => key !== 'updateFromRequest')?.map((key) => key))
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error)

      }
    }
    // console.log("🚀 ~ useEffect ~ request details update:", request)

  }, [request]);
  useEffect(() => {
    setRowChange([
      { label: "Raison sociale", current: selectedEnterprise?.socialRaison, change: request?.socialRaison, name: "socialRaison" },
      { label: "Sigle", current: selectedEnterprise?.sigle, change: request?.sigle, name: "sigle" },
      { label: "Numéro d'identification unique (NUI)", current: selectedEnterprise?.nui, change: request?.nui, name: "nui" },
      { label: "Localité", current: selectedEnterprise?.locality, change: request?.locality, name: "locality" },
      { label: "Code postal", current: selectedEnterprise?.postal, change: request?.postal, name: "postal" },
      { label: "Rue", current: selectedEnterprise?.street, change: request?.street, name: "street" },
      { label: "Site web", current: selectedEnterprise?.website, change: request?.website, name: "website" },
      { label: "Email", current: selectedEnterprise?.email, change: request?.email, name: "email" },
      { label: "Téléphone", current: selectedEnterprise?.phone, change: request?.phone, name: "phone" },
      { label: "Nom du responsable RH", current: selectedEnterprise?.rhname, change: request?.rhname, name: "rhname" },
      { label: "Email du responsable RH", current: selectedEnterprise?.rhemail, change: request?.rhemail, name: "rhemail" },
      { label: "Téléphone du responsable RH", current: selectedEnterprise?.rhphone, change: request?.rhphone, name: "rhphone" },
      { label: "WhatsApp du responsable RH", current: selectedEnterprise?.rhphonewh, change: request?.rhphonewh, name: "rhphonewh" },
      { label: "Longitude", current: selectedEnterprise?.longitude, change: request?.longitude, name: "longitude" },
      { label: "Latitude", current: selectedEnterprise?.latitude, change: request?.latitude, name: "latitude" },
      { label: "Région", current: selectedEnterprise?.region?.name, change: request?.region?.name, name: "region" },
      { label: "Département", current: selectedEnterprise?.department?.name, change: request?.department?.name, name: "department" },
      { label: "Arrondissement", current: selectedEnterprise?.arrondissement?.name, change: request?.arrondissement?.name, name: "arrondissement" },
      { label: "Secteurs d'activité", current: selectedEnterprise?.activitySectorsIds?.join(", "), change: request?.activitySectorsIds?.join(", "), name: "activitySectorsIds" }
    ])
  }, [selectedEnterprise,request]);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ request:", request)
  // useEffect(() => {
  //   getEnterpriseByniu()
  //   getSimilarEnterprises()
  // }, [user]);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ enterprises:", enterprises)
  /** Form validation config */

  const [params, setParams] = useState({
    page: 0,
    limit: 25
  });
  const schema = yup.object().shape({
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
    phone: yup.string().nullable(),
    email: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    // handleUpdate(data);
    // reset();
  };

  //   console.log('user', user?.data?.lastName);

  const [roles, setRoles] = useState([]);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ roles:", roles)

  useEffect(() => {
    setValue("lastname", user?.data?.lastName);
    setValue("firstname", user?.data?.firstName);
    setValue("phone", user?.data?.phone);
    setValue("email", user?.data?.mail);
    setValue("myEnterpriseName", user?.data?.myEnterpriseName);
    setValue("myEnterpriseNiu", user?.data?.myEnterpriseNiu);
    setRoles(user?.data?.roles?.map((r) => r?.name))
  }, [user]);

  //   console.log("lastname", lastname);

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users/${selectedUser?.id}`, "POST");
  const navigate = useNavigate()
  const handleUpdate = async (dataYub) => {
    // console.log('dataYub', dataYub);
    const data = {
      firstName: dataYub?.firstname,
      lastName: dataYub?.lastname,
      phone: dataYub?.phone,
      email: dataYub?.email,
      roles: roles,
    };

    await postAxiosData(data);
  };

  const handleChangeValue = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(value);
  };

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessageEnableA?.status === 200 || successMessageEnableA?.status === 201) {
      toast.success(`${successMessageEnableA?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      getAll()

      // handleSignin(result);
    }
  }, [successMessageEnableA]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      getAll()

      // handleSignin(result);
    }
  }, [successMessage]);
  useEffect(() => {
    if (successMessageDisable?.status === 200 || successMessageDisable?.status === 201) {
      toast.success(`${successMessageDisable?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      getAll()
      // handleSignin(result);
    }
  }, [successMessageDisable]);
  useEffect(() => {
    if (successMessageEnable?.status === 200 || successMessageEnable?.status === 201) {
      toast.success(`${successMessageEnable?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      getAll()
    }
  }, [successMessageEnable]);

  useEffect(() => {
    if (successMessageDisableEnter?.status === 200 || successMessageDisableEnter?.status === 201) {
      toast.success(`${successMessageDisableEnter?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      getAll()
    }
  }, [successMessageDisableEnter]);



  const [open, setOpen] = useState(false);
  const handleClickEnter = (item) => {
    // navigate(`/admin/enterprises/${item?.id}`)
    settoDisplayEnterprise(item)
    setOpen(true)
  }

  const handleSelectEnterprise = (item) => {
    item && setSelectedEnterprise(item)
  }



  const handleUnSelectEnterprise = (item) => {
    item && setSelectedEnterprise(enterPriseByNui?.data)
  }



  useEffect(() => {
    if (user?.data?.enable) {
      setSelectedEnterprise(user?.data?.myEnterprise)
    } else {
      enterPriseByNui?.data && setSelectedEnterprise(enterPriseByNui?.data)

    }
  }, [enterPriseByNui, user]);



  function createData(N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options) {
    // const density = population / size;
    return { N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options };
  }
  const rows = enterprises?.data?.content?.map((item, index) => {
    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: () => handleClickEnter(item),

      datas: createData((params?.page * params?.limit) + (index + 1), <>{item?.fromArchive ?
        <FromArchiveIcon />
        :

        <FromTheFieldIcon />}

        <span className="fs-c">{(item?.socialRaison)}</span>
      </>
        ,
        item?.activity,
        item?.arrondissement,
        (item?.employeesNumber != null ? item?.employeesNumber : '--'),
        user?.data?.roles.length > 0 &&
        user?.data?.roles?.map((role) => CHECK_PERMISSIONS(
          role?.permissions,
          "Voir une entreprise sur google map"
        ) ? (
          <>
            {(item?.latitude && item?.longitude) ?
              <>
                <Chip
                  icon={<BsGeoAlt />}
                  color="primary"
                  className="capitalize-none"

                  variant="outlined"
                  label={capitalize("Voir sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();

                    if (item?.latitude && item?.longitude) {
                      window.open(createMapLink(`${item?.latitude},${item?.longitude}`), '_blank', 'noopener,noreferrer');
                    } else { alert("Coordonées incomplètes"); }
                  }} />

              </>
              :
              (item?.searchLatitude && item?.searchLongitude) ?
                <>
                  <Chip
                    icon={<BsGeoAlt />}
                    color="warning"
                    className="capitalize-none"

                    variant="outlined"
                    label={capitalize("Vérifier sur la map")}
                    onClick={(e) => {
                      e.preventDefault();
                      e?.stopPropagation();

                      if (item?.searchLatitude && item?.searchLongitude) {
                        window.open(createMapLink(`${item?.searchLatitude},${item?.searchLongitude}`), '_blank', 'noopener,noreferrer');
                      } else { alert("Coordonées incomplètes"); }
                    }} />

                </> :
                <Chip
                  icon={<BsSearch />}
                  // color="warning"
                  // variant="outlined"
                  className="capitalize-none"
                  label={capitalize("Recherher sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();
                    window.open(createMapLink(`${item?.socialRaison} ${item?.sigle} ${item?.street} CAMEROON`), '_blank', 'noopener,noreferrer');
                  }} />

            }
          </>
        ) : (
          <></>

        ))
        // ,
        // <span>--</span>
        ,
        selectedEnterprise?.nui == item?.nui ? <MButton
          variant={"primary"}
          onClick={(e) => {
            handleUnSelectEnterprise(item)
            e.preventDefault();
            e?.stopPropagation()
          }}>
          {/* <BsList /> */}
          Vous l'avez selectionne
        </MButton> :
          <MButton
            variant={selectedEnterprise?.nui == item?.nui ? "primary" : "white"}
            onClick={(e) => {
              handleSelectEnterprise(item)
              e.preventDefault(); e?.stopPropagation()
            }}>
            {/* <BsList /> */}
            Selectionner
          </MButton>
      )

    }
  }
  )


  const handleClose = () => {
    setRequest(null)
    setselectedFields(null)
    onCloseM()
  }

  const handleEnableAccountOnly = () => {

    const confirm = window.confirm("Etes vous sur de vouloir activer ce compte ?")

    if (confirm) {

      postEnableA({
        adminId: auth()?.user?.id,
      })
    }
  }



  const handleEnable = () => {
    if (selectedFields?.length < 1) {
      alert("Merci de selectionner des champs a prendre en compte")
      return
    }

    let confirm = true
    if (!selectedEnterprise) {
      confirm = window.confirm("Pas d'entreprise selectionner! poursuivre cette action engendra la creation d'une nouvelle entreprise")
    }
if(!updateFromRequest){

 const c = window.confirm("Vous n'avez pas accepter les modification provenant de la requete de l'tulisateur. Voulez vous continuer ?")

 if(!c) return
}
    if (confirm) {
      let selected = selectedFields?.reduce((acc, f) => {
        acc[f] = request[f];
        return acc;
      }, {})
      postEnable({
        selectedEnterpriseId: selectedEnterprise?.id,
        requestId: request?.id,
        adminId: auth()?.user?.id,
        updateFromRequest: updateFromRequest || false,
        selectedRequestFieldsByAdmin: Object.values(selected)?.length < 1 ? null : selected
      })
    }
  }

  const handleDisable = () => {
    let confirm = true
    confirm = window.confirm("Etes vous sur de bien vouloir desactiver ce compte ? ")
    confirm && postDisable({})
  }

  const handleDisableEnterAccount = () => {
    let confirm = true
    confirm = window.confirm("Etes vous sur de bien vouloir rompre le lien entre ce compte et son entreprise ? ")
    confirm && postDisableEnt({})
  }
  useEffect(() => {
    if (successMessageEnable?.status === 200 || successMessageEnable?.status === 201) {
      fetchDataU()
      toast.success(
        `${successMessageEnable?.message != null
          ? successMessageEnable?.message
          : "Etat de l'entreprise mis a jour avec success."
        } `,
        {
          duration: 4000,
          className: "bg-success text-white",
        }
      );
    }
  }, [successMessageEnable]);

  const DisplayStatus = () => {
    if (loadingU) {
      return <CircularProgress className="mx-auto" color="success" />
    }
    if (user?.data?.enable) {
      if (user?.data?.myEnterprise) {

        return <span className="bg-green-50 text-green-500 rounded-[60px] p-2 py-1 flex gap-2 content-center items-center"><BsPersonCheckFill size={20} color="orange" /> Compte Actif et lie a une entreprise</span>
      } else {
        return <span className="bg-orange-50 text-irange-500 rounded-[60px] p-2 py-1  flex gap-2 content-center items-center"><BsPersonCheckFill size={20} color="orange" /> Compte Actif, pas lier a une entreprise</span>

      }

    } else {
      if (user?.data?.myEnterprise) {

        return <span className="bg-red-100 text-red-500 rounded-[60px] flex items-center gap-2 py-1"> <BsPersonFillX /> Compte Inactif et lie a cette entreprise</span>
      } else {
        return <span className="bg-red-100 text-red-500 rounded-[60px] flex items-center gap-2 py-1"> <BsPersonFillX />Compte Inactif</span>

      }


    }
  }
  const DisplayLinkStatus = () => {
    return (
      <div className="flex gap-2 ms-4 itens-center justify-end">
        {!selectedEnterprise && <Typography className="bg-orange-200 text-dark-400 rounded-[60px] text-center p-2 flex items-center justify-center gap-2"><BsExclamationCircleFill /> <Typography variant="caption">Une nouvelle entreprise sera cree </Typography></Typography>}

        {(selectedEnterprise && !selectedEnterprise?.nui) &&
          <Typography variant="body1" color={'red'} > Entreprise sans NIU selectionnee </Typography>
        }
        {(loadingCheckLingEx) &&
          <CircularProgress size={20} className="mx-auto" color="success" />
        }

        {(!linkedAccounts || linkedAccounts?.length < 1 || linkedAccounts?.data?.length < 1) ?
          <small className="bg-green-50 text-green-500 rounded-[60px] p-2">Liason possible</small>

          :
          <>
            {(selectedEnterprise && user?.data?.myEnterprise && selectedEnterprise?.nui == user?.data?.myEnterprise?.nui) &&
              <small className="bg-green-100 text-green-500 rounded-[60px] p-2 py-1 flex gap-2 content-center items-center"><BsLink size={30} color="orange" /> <Typography variant="caption">Entreprise  lie a ce compte</Typography></small>
            }
            {(linkedAccounts?.data?.length > 0) &&

              <small className="bg-red-100 text-red-500 rounded-[60px] p-2">Entreprise deja lie a un autre compte : {linkedAccounts?.data?.map((d) => {
                return(<strong>{d?.firstName} {d?.lastName} / {d?.mail} </strong>)
              })}
              </small>
            }
          </>
        }
      </div>
    )
  }

  useEffect(() => {
    if (selectedEnterprise) {
      getLinkAccount()
    }
  }, [selectedEnterprise]);
  const [selectedFields, setselectedFields] = useState([]);
  console.log("🚀 ~ EnterpriseAccountDetails ~ selectedFields:", selectedFields)
  // console.log("🚀 ~ EnterpriseAccountDetails ~ request:", Object.keys(request)?.map((key) => key))
  // console.log("selectedEnterprise?.find((f)=>f==name) ", selectedFields?.find((f) => f == "socialRaison"))
  const handleSelectAllFields = (e, value) => {

    if (value) {

      setselectedFields(rowsChanges?.map((r) => r?.name))
    } else {
      setselectedFields([])

    }
  }
  const handleSelectField = (checked, name) => {

    if (checked) {
      setselectedFields([...selectedFields, name])
    } else {
      setselectedFields(selectedFields?.filter((f) => f !== name))

    }
  }


  const Row = ({ label, change, current, name }) => {
    return (
      <div className="flex gap-4 items-center content-center">


        <InputField
          color={request?.status == 'COMPLETED' ? 'green' : 'red'}
          label={label}
          disabled={true}
          type="text"
          multiple={true}
          value={change || "--"}
        />
        <div className="flex items-center ">
          <Checkbox disabled={user?.data?.myEnterprise || !updateFromRequest} onChange={(e, event) => { handleSelectField(event, name) }} checked={selectedFields?.find((f) => f === name) ? true : false} />
          {/* <Checkbox disabled={(user?.data?.enable != null || user?.data?.enable !== undefined) ? user?.data?.enable : !updateFromRequest} onChange={(e, event) => { handleSelectField(event, name) }} checked={selectedFields?.find((f) => f === name) ? true : false} /> */}
          {
            request?.status == 'REJECTED' ? <div className="flex items-center "> <BsArrowRight size={30} color={"orange"} /><BsXCircleFill size={20} color="red" /> </div> :
              request?.status == 'COMPLETED' ? <div className="flex items-center"> <BsArrowRight size={30} color={"orange"} />{!selectedFields?.find((f) => f == name) ? <BsXCircleFill size={20} color="red" /> : <BsCheckCircleFill size={20} color="green" />} </div> :
                request?.status == 'PENDING' ? <div className="flex items-center"> <BsArrowRight size={30} color={"orange"} />{!selectedFields?.find((f) => f == name) ? <BsXCircleFill size={20} color="red" /> : <BsClockFill size={20} color="orange" />} </div>
                  : <BsQuestion size={30} color={"brown"} />}

        </div>

        <InputField
          label={label}
          disabled={true}
          type="text"
          multiple={true}
          value={selectedFields?.find((f) => f === name) ? request[name]?.name || request[name] : current || '--'}
        />
      </div>
    )
  }


  return (
    <>
      {open && toDisplayEnterprise && <AddEnterprise open={open}
        selectedEnterpriseP={toDisplayEnterprise}
        handleClose={() => { setOpen(false); settoDisplayEnterprise(null) }}
        get={() => null} />}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openM}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar className="flex justify-between bg-gray-50">
            <Typography color={'black'}>
              Informations du compte enterprise
            </Typography>
            <div className="flex gap-2">

              <DisplayStatus />
            </div>
            <div className="flex gap-4">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => navigation(`requests`)}

                aria-label="close"

              >

                <BsList className="text-blue-500" />

              </IconButton>
              <CloseModalButton iconColor={"red"} handleClose={handleClose} />
            </div>

          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
            {errorPost?.message && (
              <Error
                message={
                  errorPost?.message !== "" && errorPost?.message
                }
              />
            )}
            {loadingU ? <Skeleton height={250} width={'100%'} /> :
              <>
                {errorMassage?.message && (
                  <Error
                    message={
                      errorMassage?.message !== "" && errorMassage?.message
                    }
                  />
                )}
                <div className="mt-4 grid gap-2 md:grid-cols-2">
                  <div>
                    {/* <span className="ms-1">Change user role(s)</span>
                  <Select
                    className="mt-2"
                    height={20}
                    value={roles}
                    onChange={(e) => handleChangeValue(e)}
                  >
                    {ROLES?.map((role) => {
                      return <option value={role}>{role}</option>;
                    })}
                  </Select> */}
                    {roles && <MuiAUtocomplete
                      // disabled={!canEdit || sector?.length > 4}
                      multiple={true}
                      value={roles}
                      label={'Roles'}
                      options={ROLES?.map((r) => { return { value: r, label: r } })}
                      format={{ value: 'value', label: 'label' }}
                      // value={params?.regionId}
                      // defaultFormat={true}
                      onChange={(event, items) => setRoles(items)}
                      // onChange={(e, item) => console.log(item)}
                      wrapperClasses={"flex-1 filter-item anime slideRight"}
                    // inputValue={activityName}
                    // onInputChange={(event, newInputValue) => {
                    //   setActivityName(newInputValue);
                    // }}
                    // handleNoOption={
                    //   <MButton onClick={handleAddSector} >
                    //     Ajouter ce sectur d'activite
                    //   </MButton>
                    // }
                    />}

                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Prénom(s)"
                      required={true}
                      placeholder="Prénom"
                      id="firstname"
                      name="firstname"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom(s)"
                      required={true}
                      placeholder="Nom"
                      id="lastname"
                      name="lastname"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Numéro de téléphone"
                      required={true}
                      placeholder="690128925"
                      id="phone"
                      name="phone"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Adresse Mail"
                      required={true}
                      placeholder="adresse@gmail.com"
                      id="email"
                      name="email"
                      type="email"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Raison social"
                      required={true}
                      placeholder="Nom de l'entreprise"
                      id="myEnterpriseName"
                      name="myEnterpriseName"
                      type="text"
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Entrer la raison Sociale"
                      required={true}
                      placeholder="Entre le niu"
                      id="myEnterpriseNiu"
                      name="myEnterpriseNiu"
                      type="text"
                    />
                  </div>

                </div>

              </>

            }
            <Divider />

            <div className="bg-gray-100 rounded p-5  flex flex-col gap-5">
              {loadingEN || loadingrequest ? <Skeleton height={250} width={'100%'} /> :
                // selectedEnterprise ?
                !request ? <Typography color={'red'}>Pas de requete emise</Typography> : <>
                  <MuiCollapse index={1} isExpandedP={true}
                    title={user?.data?.enable ? "Lie a l'entreprise suivante " + selectedEnterprise?.socialRaison : selectedEnterprise ? "Entreprise : " + selectedEnterprise?.socialRaison : 'Nouvelle entreprise : ' + request?.socialRaison}
                    subTitle={selectedEnterprise?.nui == user?.data?.myEnterprise?.nui ? <> <DisplayLinkStatus /></> :
                      <div className="flex items-center flex-1 ms-2 gap-2 justify-between">
                        <DisplayLinkStatus />
                        {selectedEnterprise?.nui !== request?.nui &&
                          <MButton
                            variant={"secondary"}
                            disabled={user?.data?.myEnterprise}
                            onClick={(e) => {
                              e?.preventDefault()
                              e?.stopPropagation()
                              setSelectedEnterprise(enterPriseByNui?.data)
                            }}>
                            {/* <BsList /> */}
                            Reinitialiser
                          </MButton>}
                      </div>

                    }
                  // bgColor={COLORS}
                  >
                    <FormControlLabel className="mb-2" control={<Checkbox disabled={user?.data?.myEnterprise} checked={updateFromRequest} onChange={(e, value) => { if (value) { setUpdateFromRequest(value) } else { setUpdateFromRequest(value); setselectedFields([]) } }} />} label="Considerer certaines mises a jour provenant de l'utilisateur ?" />
                    <div className="flex flex-col gap-3">
                      <div className="grid grid-cols-2 ">
                        <div>

                          <Typography variant="h6">Information provenant de la requete : </Typography>
                          {selectedFields && <FormControlLabel checked={selectedFields?.length === rowsChanges?.length} className="mb-2" disabled={user?.data?.myEnterprise || !updateFromRequest} control={<Checkbox onChange={handleSelectAllFields} />} label={"Tout selectionner ?"} />}
                        </div>

                        <div>

                          <div className="flex justify-center items-center me-3">
                            <Typography variant="h6">Information provenant du system : </Typography>
                            {selectedEnterprise && <Link
                              className="text-blue-500  bg-blue-100 p-2 rounded-[60px]"
                              onClick={(e) => {
                                e?.preventDefault()
                                e?.stopPropagation()
                                handleClickEnter(selectedEnterprise)
                              }}>Details...</Link>}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 justify-center ">

                      </div>
                      {rowsChanges.map((row, index) => (
                        <Row
                          key={index}
                          label={row.label}
                          current={row.current}
                          change={row.change}
                          name={row.name}
                        />
                      ))}

                    </div>

                  </MuiCollapse>
                </>

              }


              <Divider />
              {!user?.data?.enable &&
                <MuiCollapse index={2} isExpandedP={!enterPriseByNui?.data && rows?.length > 0}
                  subTitle={<Badge
                    fontSize={13}
                    rounded={5}
                    padding={1}
                    paddingInline={2}
                    colorScheme="green"
                    className="mx-1"
                  // style={{ wi: -15 }}
                  >
                    {enterprises?.data?.totalElements || '--'}
                  </Badge>} title={"Liste des entreprise avec la raison social presque similaires"}>
                  {loadingEnter ? <Skeleton height={250} width={'100%'} /> : <MuiTable hidePagiation={true} columns={ENTERPRISE_HEADER}
                    rows={rows} textEmpty={"Pas de permis de travail"} />}

                  <PaginateBox

                    total={enterprises?.data?.totalElements}
                    rowsPerPage={params?.limit}
                    handleChangeRowsPerPage={(event) => {
                      // alert("change")
                      // console.log("🚀 ~ EmployeList ~ event:", event.target.value)
                      setParams({
                        ...params
                        , limit: parseInt(event.target.value),
                        page: 0,
                      });
                      // (0);
                    }}
                    page={params.page}
                    count={enterprises?.data?.totalPages - 1}
                    handleChange={(event, value) => {
                      setParams({ ...params, page: value });
                    }} />

                </MuiCollapse>
              }
            </div>

            <Divider />
            <div>
              <Typography variant="h5" align="center">
                Attestation d'immatriculation
              </Typography>
              <PDFViewer fileName={user?.data?.myNiuPathFile} />
            </div>

          </form>


        </DialogContent>
        <DialogActions className="flex justify-between  gap-2 p-4">
          {/* <div className="flex justify-end flex-1 gap-4"> */}
          {/* <MButton variant='danger' onClick={() => setIsOpen(false)}>Fermer</MButton> */}

          {user?.data?.enable ?
            <MButton variant='danger'
              onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleDisable() }}
              isLoading={loadingDisable}

            >Desactiver</MButton>
            :
            <>
              {user?.data?.myEnterprise && <MButton variant='danger'
                onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleDisableEnterAccount() }}
                // disabled={selectedEnterprise ? false : true}
                isLoading={loadingDisableEnter}
              >
                Separer de l'entreprise
              </MButton>}
              {!user?.data?.myEnterprise && <MButton variant='primary'
                onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleEnable() }}
                // disabled={selectedEnterprise ? false : true}
                isLoading={loadingEnable}
                
              >
                Activer & lier
              </MButton>
              }


              {user?.data?.myEnterprise && <MButton variant='primary'
                onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleEnableAccountOnly() }}
                // disabled={selectedEnterprise ? false : true}
                isLoading={loadingEnable}
              >
                Activer
              </MButton>
              }
            </>
          }
          {/* </div> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnterpriseAccountDetails;
