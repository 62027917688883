import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function MuiLineChart({xAxis, data,title }) {
  return (
    <div className='bg-white shadow-lg p-2 rounded'>

    <strong className="text-xl font-bold  text-navy-700 dark:text-white">
      {title}
    </strong>
    <LineChart
      xAxis={[{ data: xAxis,
        scaleType: 'point',

        valueFormatter: (date) =>{date?.toString()},
    }]}
      series={[
        {
          data: data,
          area: true,
        //   curve: "linear",
        color : "orange"
        },
      ]}
      // width={700}
      height={300}
    />
    </div>
  );
}