import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/card";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "../employes/modals/EmployeeModalInfos";

const EnterprisesByUsers = () => {
  const navigation = useNavigate();
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  let {agentId} = useParams('agentId')
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    if (location?.state?.agent) {
      setAgent(location?.state?.agent);
    }
  }, [location]);
  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );
  const [region, setRegion] = useState("*");

  useEffect(() => {
    if (regions?.data?.length > 0) {
      // setRegion(regions?.data[0]?.id);
    }
  }, [regions]);

  useEffect(() => {
    const fetchEnt = async (region) => {
      if (region && agentId && agentId !== null) {
        setLoading(true);
        await fetch(
          `${config.app.api_url}${
            region === "*"
              ? `/enterprises?creatorId=${agentId}`
              : `/enterprises/users/${agentId}/regions/${region}`
          }`
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("Enterprises ", data)
            setEnterprises(data);
          })
          .catch(() => {
            setEnterprises([]);
          })
          .finally(() => setLoading(false));
      }
    };
    fetchEnt(region);
  }, [region]);

  return (
    <div>
      {(loading || loadingR) && <PreloaderSpinner />}
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <div className="grid grid-cols-2 items-baseline gap-3">
          <div>
            <label
              htmlFor={"region"}
              className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
            >
              Region
            </label>
            <select
              onChange={(e) => setRegion(e.target.value)}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
              name="region"
              id="region"
            >
              <option selected value="*">
                National
              </option>
              {regions?.data &&
                regions.data.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
            </select>
          </div>
        {agent && <div className=" text-end">
          Enterprises enregistrées par l'agent : <strong> {agent?.firstName} {agent?.lastName}</strong> 
         
          </div>}
        </div>

        <Card extra={"w-full h-full p-4"}>
          {/* <TableContainer> */}
          <div className="tableFixHead">
            <Table variant="simple">
              {/* <TableCaption>Top 5 des entreprises par secteur d'activite</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>Raison sociale </Th>
                  {/* <Th>Region</Th> */}
                  <Th>Secteur d'activite</Th>
                  <Th>Arrondissement</Th>
                  {/* {!agent && <Th>Nombre de travailleurs étrangers</Th>} */}
                  <Th>Coordonnees</Th>
                  <Th>Option</Th>
                </Tr>
              </Thead>


              <Tbody>
                {enterprises &&
                 enterprises?.data &&
                  enterprises?.data?.length &&
                  enterprises?.data
                    ?.sort((a, b) => b?.employerCount - a?.employerCount)
                    ?.map((item) => {
                      return (
                        <>
                          <Tr>
                            <Td>
                              {item?.socialRaison
                                || item?.enterprise?.socialRaison}
                            </Td>
                            {/* <Td>{item?.enterprise?.region?.name}</Td> */}
                            <Td>
                              {item?.activitySectors
                                    ?.map((ac) => {
                                      return ac?.name;
                                    })
                                    ?.join(", ")
                                || item?.enterprise?.activitySectors
                                    ?.map((ac) => {
                                      return ac?.name;
                                    })
                                    ?.join(", ")}
                            </Td>
                            <Td>
                              {
                                 item?.arrondissement?.name
                               || item?.enterprise?.arrondissement?.name}
                            </Td>
                            {/* {!agent && <Td>{item?.employerCount}</Td>} */}
                            <Td>
                              <a
                                target="_blank"
                                className="text-sm text-blue-500 underline"
                                href={`http://maps.google.com/?q=${
                                   item?.latitude
                                    || item?.enterprise?.latitude
                                },${
                                  item?.longitude
                                    ||item?.enterprise?.longitude
                                }`}
                              >
                                Voir sur la map
                              </a>
                            </Td>
                            <Td>
                              <Button
                                onClick={() =>
                                  navigation(
                                    `/admin/employees/${item?.enterprise?.id}`
                                  )
                                }
                              >
                                Consulter
                              </Button>
                            </Td>
                          </Tr>
                        </>
                      );
                    })}
              </Tbody>
              {/* <Tfoot>
                <Tr>
                  <Th>Raison sociale</Th>
                  <Th>Region</Th>
                  <Th>Secteur d'activite</Th>
                  <Th>Arrondissement</Th>
                  <Th>Nombre de travailleurs étrangers</Th>
                  <Th>Option</Th>
                </Tr>
              </Tfoot> */}
            </Table>
          </div>
          {/* </TableContainer> */}
        </Card>
      </div>
    </div>
  );
};

export default EnterprisesByUsers;
