import {

  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import AddUser from "./AddUser";
import UpdateUserInformations from "./UpdateUserInformations";
import { useState } from "react";
import PreloaderSpinner from "components/PreloaderSpinner";
import { ROLES } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import { Box, capitalize, Chip, TextField, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import MuiTable from "components/MuiTable";
import { ACCOUNT_HEADER } from "Constaints";
import usePostAxiosData from "hooks/usePostAxiosData";
import EnterpriseAccountDetails from "./EnterpriseAccountDetails";
import PaginateBox from "components/PaginateBox";
import MuiSelect from "components/MuiSelect";
import { castToRequestParams } from "utils";
import { Title } from "components/Title";

const EnterpriseAcccount = () => {
  const navigation = useNavigate();


  const [isOpenUM, setIsOpenUM] = useState(false);
  const [closeUM, setCloseUM] = useState(false);


  const auth = useAuthUser();
  const [params, setParams] = useState({ limit: 50, page: 0 });

  const [loadingU, errorU, users, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/enterprises?${castToRequestParams(params)}`
  );

  const [filteredUsers, setFilteredUsers] = useState([]);
  console.log("🚀 ~ EnterpriseAcccount ~ filteredUsers:", filteredUsers)

  const [selectedUser, setselectedUser] = useState(null);
  const [searchterm, setSearchterm] = useState('');
  useEffect(() => {
    if (!users) return
    if (searchterm) {
      setFilteredUsers({ ...filteredUsers, data: users?.data?.filter((u) => JSON.stringify(u)?.toLocaleLowerCase()?.includes(searchterm.toLocaleLowerCase())) })
      // console.log("🚀 ~ useEffect ~ (users?.data?.content?.filter((u)=>JSON.stringify(u)?.includes(searchterm))):", (users?.data?.filter((u)=>JSON.stringify(u)?.includes(searchterm))))
    } else {
      setFilteredUsers(users)
    }
  }, [searchterm, users]);

  function createData(N, FirstName, LastName, Email, Phone, Enable, Options) {
    // const density = population / size;
    return {
      N, FirstName, LastName, Email, Phone, Enable, Options

    };
  }
  const [openDetail, setOpenDetail] = useState(false);
  const handleClick = (user) => {
    // navigation(`/admin/account/enterprises/${user.id}`, { state: { selectedUser: user } })

    // setIsOpenUM(true);
    setselectedUser(user);
    setOpenDetail(true)
  }
  const handleCloseModal = () => {
    setselectedUser(null)
    setOpenDetail(false)
  }
  useEffect(() => {
    fetchDataU()
  }, [params]);
  const rows = filteredUsers?.data?.sort((a,b)=>a.id< b.id)?.map((item, index) => {
    // const l = getLastContract(item?.contracts)

    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: (e) => handleClick(item),

      datas: createData((index + 1),

        item.firstName,
        item?.lastName
        ,
        item?.mail,
        // ENTRY_TYPE[item?.entryType] || '--',

        item?.phone,

        item?.enable ?
          <span className="bg-green-50 text-green-500 rounded-[60px] p-2 flex gap-2 content-center items-center"> Compte Actif</span>
          :
          <span className="bg-red-100 text-red-500 rounded-[60px] p-2">Compte Inactif</span>


        ,
        <div
          onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
          className="flex item-center justify-center gap-2">
          {/* {item?.enable === false &&

            <MButton
              variant={"danger"}

              style={{ borderRadius: 20 }} onClick={() => handleUnlinkAccount(item)}>
              Unlink enterprise
            </MButton>
          } */}
          <MButton
            variant={"white"}

            style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
            Consulter
          </MButton>
        </div>

      ),

    }
  }
  )

  return (
    <div>
      {/* Modification des informations du user */}
      {selectedUser && <EnterpriseAccountDetails
        fetchData={fetchDataU}
        openM={openDetail}
        // setIsOpen={setIsOpenUM}
        onCloseM={handleCloseModal}
        get={fetchDataU}
        selectedUser={selectedUser}
      />}

      {/* {loadingU && <PreloaderSpinner />} */}
      <div className="flex flex-col bg-white">
        <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"rgba(255, 255, 255, 1)"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-center justify-between"
        >
          <Title title={"Liste des comptes entreprise"} />
          <div className="flex justify-end gap-2 pe-5">
            <TextField value={searchterm} onChange={(e) => setSearchterm(e.target.value)} id="outlined-search" label="Rechercher ici" type="search" />
            <MuiSelect
              wrapperClasses={"bg-white"}
              label={'Choisir un status'}
              sort={false}
              value={params.status}
              emptyLabel={"Tous"}
              options={[
                // {label : "Tous", value : '*'},
                { label: "En Attente", value: 'PENDING' },
                { label: "Valider", value: 'COMPLETED' },
                { label: "Rejetter", value: 'REJECTED' },
              ]}
              onChange={(event) => setParams({ ...params, status: event.target.value })}
            />
          </div>

        </Box>

        <MuiTable
          hidePagiation={false}

          loading={loadingU}
          limit={params?.limit}
          columns={ACCOUNT_HEADER}
          rows={rows} textEmpty={"Pas de permis de travail"} />
      
      </div>





    </div>
  );
};

export default EnterpriseAcccount;
