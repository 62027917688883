import { MISSION_HEADER } from "Constaints";
import useDataFetching from "hooks/useDataFetching";
import { formatDate } from "utils";
import { config } from "variables/config";
import MuiTable from "components/MuiTable";
import MButton from "components/Mbutton";
import { useNavigate } from "react-router-dom";
import { ZONE_HEADER } from "Constaints";
import { BsList, BsPlusCircle } from "react-icons/bs";
import AddZone from "./AddZone";
import { useState } from "react";
import AddZoneEnterprise from "./AddZoneEnterprise";
import PreloaderSpinner from "components/PreloaderSpinner";

export default function Zones() {
    const navigate = useNavigate()
    const [selectedZone, setSelectedZone] = useState(null);

    const handleClick = (item) => {
        setSelectedZone(item)
    }
    const [loading, errorE, zones, fetchDataE] = useDataFetching(
        `${config.app.api_url}/zones`
    );

    const [open, setOpen] = useState(false);
    const [openE, setOpenE] = useState(false);

    function createData(N, nom, description, departement, region, actions) {
        // const density = population / size;
        return {
            N, nom, description, departement, region, actions

        };
    }
    const rows = zones?.data?.map((item, index) => {

        return {
            //   color: !checkIsValidPermitDate(item) ? 'red' : '',
            //   handleClick: () => handleClick(item),


            datas: createData(index + 1, `Zone ${item?.number || '--'}`, item?.description, item?.department?.name, item?.department?.region?.name,
                <div className="flex flex-col gap-2">

                    <MButton
                        onClick={() => navigate("/admin/enterprises", { state: { zone: item } })}
                    >
                        <BsList/>
                        Ses entreprises</MButton>
                    <MButton
                        onClick={() => { setSelectedZone(item); setOpenE(true) }}
                    >
                        <BsPlusCircle />
                        Assigner une entreprise</MButton>
                </div>
            ),

        }
    }
    )




    return (
        <>
      {/* {loading && <PreloaderSpinner />} */}

            {/* <AddZone /> */}
            <AddZone endFetch={fetchDataE} isOpen={open} onClose={() => setOpen(false)} />
            <AddZoneEnterprise endFetch={fetchDataE} zone={selectedZone} isOpen={openE} onClose={() => setOpenE(false)} />


            <div className="flex justify-end my-2">

                <MButton variant={"primary"}
                    onClick={() => { setOpen(true) }}
                ><BsPlusCircle /> Creer une zone</MButton>
            </div>
            <MuiTable 
          loading={loading}
            
            hidePagiation={true} columns={ZONE_HEADER}
                rows={rows} textEmpty={"Pas de missions"} />
        </>
    )
}