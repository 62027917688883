import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import usePostAxiosData from "hooks/usePostAxiosData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { config } from "variables/config";
import { PERMISSIONS } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";

const UpdateRole = (props) => {
  const { isOpen, onClose, setIsOpen, roleId, fetchData } = props;

  /** get user by id */
  const [loadingR, errorR, role, fetchDataR] = useDataFetching(
    `${config.app.api_url}/roles/${roleId}`
  );

  /** Form validation config */
  const schema = yup.object().shape({
    name: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleUpdate(data);
    // reset();
  };

  const [permissionsToUpdate, setPermissionsToUpdate] = useState([]);

  useEffect(() => {
    let permissions = [];

    PERMISSIONS.length > 0 &&
      PERMISSIONS.forEach((permission) => {
        const isChecked =
          role?.data?.permissions.length > 0 &&
          role?.data?.permissions.find((permRole) => {
            return permRole?.name === permission ? true : false;
          });
        // console.log("isChecked", isChecked)
        permissions.push({
          name: permission,
          checked: isChecked && isChecked !== undefined ? true : false,
        });
      });

    setPermissionsToUpdate(permissions);
  }, [role?.data]);

  const auth = useAuthUser();
  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );
  // console.log("permissionsToUpdate", permissionsToUpdate);

  useEffect(() => {
    setValue("name", role?.data?.name);
  }, [role?.data]);

  //   console.log("role", role);

  const handleCheckedPermission = (e) => {
    let newPermissions = [];
    permissionsToUpdate.length > 0 &&
      permissionsToUpdate.map((permission) => {
        const isChecked =
          permission?.name === e.target.value &&
          (permission?.checked === true ? false : true);
        // console.log("isChecked", isChecked);
        newPermissions.push({
          name: permission?.name,
          checked:
            permission?.name === e.target.value
              ? isChecked
              : permission?.checked,
        });
      });
    setPermissionsToUpdate(newPermissions);
  };
  useEffect(() => {}, [permissionsToUpdate]);

  /** post update role */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`roles/${roleId}`, "POST");

  const handleUpdate = async (dataYub) => {
    let dataP = [];
    permissionsToUpdate?.length > 0 &&
      permissionsToUpdate?.forEach((per) => {
        per?.checked === true && dataP.push(per?.name);
      });
    // console.log("dataYub", dataP);
    const data = {
      name: dataYub?.name,
      permissions: dataP.length > 0 && dataP,
    };

    await postAxiosData(data);

    window.location.reload();
  };

  /** gestion des erreurs et succès */
  useEffect(() => {}, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      setIsOpen(false);
      fetchData();

      // handleSignin(result);
    }
  }, [successMessage]);

  return (
    <>
      <AlertDialog isOpen={isOpen} isCentered={true}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div className="text-center">
                <span className="text-3xl uppercase">
                  Modifier la typographie du role
                </span>
              </div>
            </AlertDialogHeader>

            <AlertDialogBody>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="my-4 grid gap-2 md:grid-cols-1">
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom"
                      required={true}
                      placeholder="AGENT"
                      id="name"
                      name="name"
                      type="text"
                    />
                  </div>
                  {usr?.data?.roles.length > 0 &&
                    usr?.data?.roles?.map((role) => {
                      return (
                        CHECK_PERMISSIONS(
                          role?.permissions,
                          "Attribuer des permissions à un rôle"
                        ) && (
                          <div>
                            {permissionsToUpdate.length > 0 &&
                              permissionsToUpdate.map((permission, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    {/* {role?.data?.permissions.length > 0 && role?.data?.permissions.filter((permRole) => {
                  permRole === permission ? console.log("yes") : console.log("yes") ;
                })} */}
                                    <input
                                      type="checkbox"
                                      className="form-control"
                                      checked={permission?.checked}
                                      value={permission?.name}
                                      onChange={(e) => {
                                        handleCheckedPermission(e);
                                      }}
                                    />
                                    <span className="ms-2">
                                      {permission?.name}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        )
                      );
                    })}
                </div>
                <div className="flex justify-between p-4">
                  <div>
                    <Button onClick={() => setIsOpen(false)}>Annuler</Button>
                  </div>
                  <div>
                    <Button type="submit" colorScheme="green">
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </form>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default UpdateRole;
