import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "./modals/EmployeeModalInfos";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import axios from "axios";
import { checkIsValidPermit } from "utils";
import PrintButtonExcel from "../default/components/PrintButtonExcel";
import dayjs from "dayjs";
import { MdAdd, MdRestartAlt } from "react-icons/md";
import AddEmployer from "./AddEmployer";
import Pagination from '@mui/material/Pagination';
import PaginateBox from "components/PaginateBox";
import MenuDrop from "components/MenuDrop";
import { BsFiletypeXlsx, BsPersonFill, BsPlusCircle, BsSearch, BsThreeDotsVertical, BsXCircle } from "react-icons/bs";
import MButton from "components/Mbutton";
import SearchModal from "components/SearchModal";
import { formatNumber } from "utils";
import { getExpiredPermitDate } from "utils";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";
import MuiTable from "components/MuiTable";

import { checkIsValidPermitDate } from "utils";
import { EMPLOYER_HEADER } from "Constaints";
import { ButtonGroup, Chip, capitalize } from "@mui/material";
import MuiAUtocomplete from "components/MuiAutocomplete";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import MuiAsyncAutocomplete from "components/MuiAsyncAutocomplete";
import { ENTRY_TYPE } from "Constaints";
import MuiTableCollapse from "components/MuiTableCollapse";
import { formatDate } from "utils";
import { getLastContract } from "utils";
import { useLocation } from "react-router-dom";
import { checkIsValidContractDate } from "utils";
import { checkIsValidDate } from "utils";
const EmployeList = () => {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();

  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  // console.log("🚀 ~ EmployeList ~ selectedEmployer:", selectedEmployer)
  const location = useLocation()
  // console.log("🚀 ~ EmployeList ~ location:", location)

  const [creator, setCreator] = useState(null);

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [expired, setExpired] = useState("");
  const [selectedEnterprise, setSelectedEnterprise] = useState("");

  const [params, setParams] = useState({
    enterpriseId: location.state?.enterpriseId,
    start: location.state?.start,
    end: location.state?.end,
    startUpdate: location.state?.startUpdate,
    endUpdate: location.state?.endUpdate,
    visaExpired: location.state?.visaExpired,
    expiredIn: location.state?.expiredIn,

    regionId: location.state?.regionId,
    departmentId: location.state?.departmentId,
    arrondissementId: location.state?.arrondissementId,
    page: 0,
    limit: 50,
    fromArchive: location.state?.fromArchive,
    creatorId: location?.state?.creator?.id
  });

  useEffect(() => {
    if (location.state) {
      // console.log("🚀 ~ useEffect ~ location.state:", location.state)

      setCreator(location?.state?.creator)
      setParams({
        enterpriseId: location.state?.enterpriseId,
        start: location.state?.start,
        end: location.state?.end,
        startUpdate: location.state?.startUpdate,
        endUpdate: location.state?.endUpdate,
        visaExpired: location.state?.visaExpired,
        expiredIn: location.state?.expiredIn,
        regionId: location.state?.regionId,
        departmentId: location.state?.departmentId,
        arrondissementId: location.state?.arrondissementId,
        page: 0,
        limit: 50,
        fromArchive: location.state?.fromArchive,
        creatorId: location?.state?.creator?.id
      })
    }
  }, [location]);


  /* Fecthing enterprises datas */
  const [loadingE, errorE, enterprises, fetchDataE] = useDataFetching(
    `${config.app.api_url}/enterprises/labels`
  );

  const [loadingSearch, errorSearch, searchEmployes, fetchDataSearch] = useDataFetching(
    searchTerm?.length > 2 ? `${config.app.api_url}/employers/search/${searchTerm}` : null
  );

  const [loadingU, errorU, user, fetchDataU] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );
  const [region, setRegion] = useState("*");

  const [departement, setDepartement] = useState(null);
  const [arrondissement, setArrondissement] = useState(null);
  // fetcing departments by region id
  const [loadingD, errorD, departements, fetchDataD] = useDataFetching(
    `${config.app.api_url}/regions/${region !== "*" && region}/departments`
  );

  // fetcing arrondisements by department id
  const [loadingA, errorA, arrondisements, fetchDataA] = useDataFetching(
    `${config.app.api_url}/departments/${departement}/arrondissements`
  );


  const getEmployees = () => {
    setLoading(true);
    const rr = Object.keys(params)?.map((key) => {
      // console.log(typeof params[key] == "string");
      // if(params[key]){
      return (params[key] != null && params[key] != undefined) ? key + "=" + params[key] : "-1";
      // }
    });
    const tt = rr?.filter((r) => r !== "-1")?.join("&");
    axios
      .get(`${config.app.api_url}/employers/query-names-only?${tt}`,
        {
          headers: {
            Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setEmployees(res?.data?.data);
        // console.log("🚀 ~ .then ~ res?.data?:", res?.data?.data?.content)
      })
      .catch((err) => {
        console.log(err);
        setEmployees([]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {

    getEmployees()
  }, [params
  ]);

  const [open, setOpen] = useState(false);
  const [selectedEm, setSetselectedEm] = useState("");

  const handleClick = (item) => {
    if (item) {
      // setSetselectedEm(item);
      setSelectedEmployer(item)

      setOpenAdd(true);
    }
  };

  // const getEmployees = () => {
  //   return employees;
  // };

  const headerTable = [
    "Noms Complet",
    "Sexe",
    "Enterprise",
    "Poste",
    "Salaire",
    "Nationalite",

    // "Numero de Visa",
    // "Date d'optention du visa",
    "Numéro de passeport",

    "Numero de Permis",
    "Date d'expiration du du permis",
    "Status du Permis",
  ];

  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [openAdd, setOpenAdd] = useState(false)

  const handleRestFilter = () => {
    setParams({
      start: "", end: "", enterpriseId: "", visaExpired: "", arrondissementId: "", departmentId: "",
      fromArchive: "", regionId: ""
    });
    setRegion("National");
    setArrondissement("");
    setDepartement("");
    setStart("")
    setEnd("")
  };
  const exportPdf = <PrintButtonExcel
    text={"Exporter"}
    filename={
      // `expatriés_${params?.enterpriseId ? enterprises?.data?.find((e) => e?.id == params?.enterpriseId)?.socialRaison?.trim()?.slice(0, 25) + '_' : ''}${dayjs(new Date())?.format("DD/MM/YYYY")}`
      `expatriés_${dayjs(new Date())?.format("DD/MM/YYYY")}`
    }
    header={headerTable}
    body={employees?.content?.length > 0 && employees?.content?.map((e,index) => {
      return [
        index+1,
        e?.fullName || e?.firstName + " " + e?.lastName,
        e?.sexe,
        e?.enterpriseName,
        e?.occupation,
        // e?.arrondissement?.name,
        // e?.salary,
        e?.nationality,
        // e?.visaNumber,
        // e?.visaOptDate,
        // e?.passportNumber,
        // e?.passportExpDate,
        e?.permitNumber,
        // e?.permitDate ? dayjs(getExpiredPermitDate(e?.permitDate)).format("DD-MM-YYYY") : '--',
        // e?.permitNumber,

        // !checkIsValidPermit(e) ? "Expiré" : "Valide",
      ];
    })}
  />
  const menu = {
    icon: <BsThreeDotsVertical />,
    label: "Options",
    Items: [
      {
        label: "Ajouter un expatrié",
        icon: <BsPlusCircle />,
        handleClick: () => setOpenAdd(true)
      },
      // {
      //   label: "",
      //   icon: <SearchModal
      //     // data={items}
      //     searchTerm={searchTerm}
      //     setSearchTerm={setSearchTerm}
      //     searchResults={searchEmployes?.data}
      //     searchFunction={fetchDataSearch}
      //     iconLabel={"Rechercher un expatrié"}
      //     placeholder={"Enter son nom oui nummero de passport,..."}
      //     title={"Rechercher un expatrié"}
      //     handleClickResult={(item) => {
      //       setSelectedEmployer(item)
      //       onOpen()
      //     }}
      //     loading={loadingSearch}
      //   />,
      //   handleClick: () => console.log("add")

      // },
      {
        label: "",
        icon: exportPdf,
        // handleClick: () => console.log("add")

      },
      {
        label: "Réinitialiser le filtre",
        icon: <BsXCircle />,
        handleClick: handleRestFilter

      },
    ]
  }


  function createData(N, Noms, Poste, Salaire, Nationalité, entryType, Date, Status, Options) {
    // const density = population / size;
    return {
      N, Noms, Poste, Salaire, Nationalité, entryType, Date, Status, Options,

    };
  }
  const rows = employees?.content?.map((item, index) => {
    // const l = getLastContract(item?.contracts)

    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: () => handleClick(item),

      datas: createData((params?.page * params?.limit) + (index + 1), <>{item?.fromArchive ?
        <FromArchiveIcon />
        :

        <FromTheFieldIcon />}

        {<span className="fs-c">{(item?.fullName || item?.firstName + " " + item?.lastName)}</span>}
      </>
        // , item?.sexe
        ,
        item.occupation,
        item?.enterpriseName
        ,
        formatNumber(item?.salary),
        item?.nationality,
        // ENTRY_TYPE[item?.entryType] || '--',

        // item?.permitNumber, 

        item?.permitDate ? dayjs(getExpiredPermitDate(item?.permitDate)).format("DD-MM-YYYY") : '--',
        !item?.permitNumber && !item?.permitDate ?
          <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Pas de permis")} />
          :
          !checkIsValidDate(item?.permitDate) ?
            <Chip color='error' className="capitalize-none" label={capitalize("Expiré")} /> :
            item?.permitNumber ? <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
              <Chip className="capitalize-none" variant="outlined" color='warning' label="A vérifier" />,
        <MButton
          variant={"white"}

          style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
          Consulter
        </MButton>,

      ),
      history: item?.contracts ? {
        title: " Historique des contrats",
        header: [
          "Salaire (FAFC)",
          "Indenmités",
          "Numero de permis",
          "Date d'optention du permit",
          "Date d'expiration du permit",

        ],
        datas: item?.contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))?.map((c, index) => {
          return {
            index: index,
            salary: formatNumber(c?.salary),
            idenmites: (c?.indLogement || 0) + (c?.indTransport || 0) + (c?.indResponsabilite || 0) + (c?.indRepresentation || 0) + (c?.primeExploitation || 0)
              + (c?.sursalaire || 0) + (c?.primeSalissure || 0) + (c?.indCaisse || 0) + (c?.bonus || 0) + (c?.primePanier || 0)
              + (c?.billetAvion || 0) + (c?.autresPrimes || 0) + (c?.sursalary || 0),
            permitOptDate: c?.permitDate ? formatDate(c?.permitDate) : '--',
            permitDate: c?.permitDate ? formatDate(getExpiredPermitDate(c?.permitDate)) : '--',
            permitNumber: c?.permitNumber || '--',
            // status: ch,
          }
        }
        )
      }
        : []
    }
  }
  )

  return (
    <div>
      {/* {loading && <PreloaderSpinner />} */}
      <MModal open={open} setOpen={setOpen} item={selectedEm} />
      <div className=" grid h-full grid-cols-1 gap-5">
        {user?.data?.roles.length > 0 &&
          user?.data?.roles?.map((role) => {
            return (
              CHECK_PERMISSIONS(role?.permissions, "Filtres et Affichages") && (
                <Box
                  // bg={"rgba(0, 223, 52, 0.10)"}
                  bg={"rgba(255, 255, 255, 1)"}
                  // className="bg-gray-500"
                  boxShadow="lg"
                  p={2}
                  rounded={"md"}
                  border="1px"
                  borderColor="gray.200"
                  className=" flex flex-wrap gap-1  items-end justify-end"
                >
                  {/* <div className="mt-1 grid h-full  grid-cols-1 gap-5 md:grid-cols-8"> */}
                  {/* <div className="flex flex-wrap gap-2  items-end justify-between bg-red-200"> */}

                  <MuiDatePicker
                    label={"Debut de la periode"}
                    value={params?.start}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        start: new Date(newValue)?.getTime(),
                      })
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideLeft"}

                  />


                  <MuiDatePicker
                    value={params?.end}

                    label={"Fin de la periode"}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        end: new Date(newValue)?.getTime(),
                      })
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideLeft"}

                  />

             

                  <MuiDatePicker
                    label={"Debut Modif"}
                    value={params?.startUpdate}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        startUpdate: new Date(newValue)?.getTime(),
                      })
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideLeft"}

                  />


                  <MuiDatePicker
                    value={params?.endUpdate}

                    label={"Fin Modif"}
                    onChange={(newValue) => {
                      setParams({
                        ...params,
                        endUpdate: new Date(newValue)?.getTime(),
                      })
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideLeft"}

                  />


                  <MuiSelect
                    label={'Région'}
                    options={regions?.data}
                    emptyLabel={'National'}
                    value={params?.regionId}
                    defaultFormat={true}
                    onChange={(e) => {
                      // console.log("e.target.value", e.target.value)
                      setRegion(e.target.value);
                      setArrondissement("");
                      setDepartement(""
                      );
                      setParams({
                        ...params,
                        regionId: e.target.value,
                        departmentId: null,
                        arrondissementId: null,
                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideLeft"}
                  />

                  {/* Filtre departement */}
                  {departements?.data &&

                    <MuiSelect
                      label={'Département'}
                      options={departements?.data}
                      emptyLabel={'Régional'}

                      value={params?.departmentId}
                      defaultFormat={true}
                      onChange={(e) => {
                        setDepartement(e.target.value);
                        setArrondissement("");
                        setParams({
                          ...params,
                          departmentId: e.target.value,
                          arrondissementId: null,
                        });
                      }}
                      wrapperClasses={"flex-1 filter-item anime slideDown"}
                    />
                  }

                  {/* Filtre arrondissement */}
                  {arrondisements?.data &&


                    <MuiSelect
                      label={'Arrondissement'}
                      emptyLabel={'Départemental'}

                      options={arrondisements?.data}
                      value={params?.arrondissementId}
                      defaultFormat={true}
                      onChange={(e) => {
                        setArrondissement(e.target.value);
                        setParams({
                          ...params,
                          arrondissementId: e.target.value,
                        });
                      }}
                      wrapperClasses={"flex-1 filter-item anime slideDown"}
                    />
                  }

                  {/* <MuiAsyncAutocomplete

                  label={"Entreprises"}
                    value={selectedEnterprise}
                    format={{value : 'id', label : 'socialRaison'}}
                    // defaultFormat={true}
                    fetch={'enterprises/search'}
                    handleChange={(e, newValue) => {
                      setSelectedEnterprise(newValue)
                      setParams({
                        ...params,
                        enterpriseId: newValue?.value
                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideRight"}
                    
                  /> */}

                  <MuiAUtocomplete
                    label={'Entrepise'}
                    options={enterprises?.data}
                    format={{ value: 'id', label: 'socialRaison' }}
                    value={params?.enterpriseId}
                    // defaultFormat={true}
                    onChange={(e, item) => {
                      setParams({
                        ...params,
                        enterpriseId: item?.value
                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideRight"}
                  />
                  {/* <div className="flex-1 filter-item anime slideRight">
                      <label
                        htmlFor={"display"}
                        className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                      >
                        Entrepise
                      </label>
                      <select
                        onChange={(value) => {
                          setParams({
                            ...params,
                            enterpriseId:
                              value.target.value != "-1"
                                ? value.target.value
                                : null,
                          });
                        }}
                        className="flex h-10 w-full items-center justify-center rounded-xl border bg-white p-2 text-sm outline-none"
                        name="display"
                        id="display"
                      >
                        <option value={"-1"}>Selectionner une valeur</option>

                        {enterprises &&
                          enterprises?.data?.sort((a, b) => a?.socialRaison > b?.socialRaison ? 1 : -1)?.map((en) => {
                            return (
                              <option value={en?.id}>{en?.socialRaison}</option>
                            );
                          })}
                      </select>
                    </div> */}

                  <MuiSelect
                    label={'Permis expiré ?'}
                    emptyLabel={'Tous'}
                    value={params?.visaExpired}

                    options={[
                      { value: false, label: 'Non' },
                      { value: true, label: 'Oui' },
                    ]}
                    onChange={(e) => {
                      // console.log("e.target.value 11", e.target.value)
                      setParams({
                        ...params,
                        visaExpired: e.target.value?.toString(),
                        // expiredIn : e.target.value ='false' ? 24 : params?.expiredIn

                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideUp"}
                  />

                  {params?.visaExpired == 'false' && <MuiSelect
                    label={'Expire dans moins (en mois)'}
                    emptyLabel={'Default'}
                    value={params?.expiredIn}
                    sort={false}
                    options={Array.from({ length: 24 }, (_, i) => i + 1)?.map((v) => {
                      return { value: parseInt(v), label: parseInt(v) + " mois  " + `${Number.isInteger((parseInt(v)) / 12) ? "--" + (parseInt(v)) / 12 + " ans" : ''}` }
                    })}
                    onChange={(e) => {
                      // console.log("e.target.value 11", e.target.value)
                      // alert("Fonctionalite non disponible")
                      setParams({
                        ...params,
                        expiredIn: e.target.value?.toString()

                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideRight"}
                  />}

                  <MuiSelect
                    label={'Source'}
                    emptyLabel={'Tous'}
                    value={params?.fromArchive}

                    options={[
                      { value: false, label: 'Terrain' },
                      { value: true, label: 'Archive' },
                    ]}
                    onChange={(e) => {
                      // alert("Fonctionalitée non disponible pour le moment!")
                      setParams({
                        ...params,
                        fromArchive:
                          e.target.value != "-1"
                            ? e.target.value
                            : "",
                      });
                    }}
                    wrapperClasses={"flex-1 filter-item anime slideRight"}
                  />
                  <MenuDrop menu={menu} />


                  {/* </div> */}

                </Box>
              )
            );
          })}

        {creator && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"red.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex gap-2 ">
            <BsPersonFill size={20} className="text-blue-400" />
            Entreprises collectées par :
            <strong>
              {creator?.creator?.firstName} {creator?.lastName}
            </strong>
          </span>
        </Box>
        }
        <Card extra={"w-full h-full pb-4"}>

          <div className="flex justify-between ">

            <div>
              <strong>

                Total :
                <Badge
                  fontSize={13}
                  rounded={5}
                  padding={1}
                  paddingInline={2}
                  colorScheme="green"
                  className="m-0 ms-2"
                >
                  {formatNumber(employees?.totalElements) || '--'}
                </Badge>
              </strong>
            </div>
            <div className="flex flex-1 justify-center">

              <span
                style={{ marginTop: -15 }}

              >
                <ButtonGroup variant="text" aria-label="Basic button group">

                  <SearchModal
                    // data={items}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchResults={searchEmployes?.data}
                    searchFunction={fetchDataSearch}
                    iconLabel={"Rechercher"}
                    placeholder={"Entrer son nom oui nummero de passport,..."}
                    title={"Rechercher un expatrié"}
                    handleClickResult={(item) => {
                      setSelectedEmployer(item)
                      setOpenAdd(true)
                    }}
                    loading={loadingSearch}
                  />
                  <MButton
                    variant={"white"}
                    onClick={() => setOpenAdd(true)}
                  >
                    <BsPlusCircle />
                    Ajouter un expatrié
                  </MButton>
                  {exportPdf}

                </ButtonGroup>

              </span>
            </div>
          </div>

          <MuiTable 
          loading={loading}
          hidePagiation={true}
           columns={EMPLOYER_HEADER}
            rows={rows} textEmpty={"Pas de permis de travail"} />
          {/* <MuiTableCollapse/> */}



          <PaginateBox

            total={employees?.totalElements}
            rowsPerPage={params?.limit}
            handleChangeRowsPerPage={(event) => {
              // alert("change")
              // console.log("🚀 ~ EmployeList ~ event:", event.target.value)
              setParams({
                ...params
                , limit: parseInt(event.target.value),
                page: 0,
              });
              // (0);
            }}
            page={params.page}
            count={employees?.totalPages - 1}
            handleChange={(event, value) => {
              setParams({ ...params, page: value });
            }} />

        </Card>
      </div>

      {/* Modal création d'un employer */}
      <AddEmployer get={getEmployees} open={openAdd} handleClose={() => { setSelectedEmployer(null); setOpenAdd(false) }} selectedEmployerP={selectedEmployer} />
    </div>
  );
};

export default EmployeList;
