import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import React, { useEffect, useState } from "react";
import { Button, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "./modals/EmployeeModalInfos";
import { checkIsValidPermit } from "utils";
import AddEmployer from "./AddEmployer";
import { useDisclosure, Badge } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
import { formatNumber } from "utils";
import dayjs from "dayjs";
import { getExpiredPermitDate } from "utils";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";
import { checkIsValidPermitDate } from "utils";
import { AppBar, Chip, Dialog, Divider, Fab, IconButton, InputBase, Slide, TextField, Toolbar, Typography, capitalize } from "@mui/material";
// import CloseIcon from "components/icons/CloseIcon";
import CloseIcon from '@mui/icons-material/Close';
import MuiSelect from "components/MuiSelect";
import { BsArrowLeft, BsArrowLeftCircleFill, BsBack, BsBoxArrowLeft, BsPlus, BsPlusCircle, BsSearch, BsTrash2Fill, BsXCircleFill } from "react-icons/bs";
import { NotFoundResults } from "components/Utils";
import { CloseModalButton } from "components/Utils";
// import styled from "@emotion/styled";
import SearchIcon from "components/icons/SearchIcon";
import { styled, alpha } from '@mui/material/styles';
import MuiTable from "components/MuiTable";
import { AddIcCallOutlined } from "@mui/icons-material";
import { EMPLOYER_HEADER } from "Constaints";
import { ENTRY_TYPE } from "Constaints";
import MButton from "components/Mbutton";
import { formatDate } from "utils";
import { getLastContract } from "utils";
import PrintButtonExcel from "../default/components/PrintButtonExcel";
import { checkIsValidContractDate } from "utils";
import { ENTERPRISE_STATUS } from "Constaints";
import { EMPLOYER_STATUS } from "Constaints";
import { checkIsValidContrat } from "utils";
import axios from "axios";
import { Related } from "./Related";
import MuiCollapse from "components/MuiCollapse";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EmployesByEnterprise = ({ openP, setOpenP, title, handleClose, Fullscreen, selectedEnterprise }) => {
  const { pid } = useParams();
  let location = useLocation();
  let id = pid ?? selectedEnterprise?.id

  const [loading, error, employees, fetchData] = useDataFetching(
    id ? `${config.app.api_url}/enterprises/${id}/employers` : null
  );
  const [loadingre, errorre, enterprisesRelateds, fetchrelated] = useDataFetching(
    id ? `${config.app.api_url}/enterprises/${id}/enterprises-related` : null
  );
  const [open, setOpen] = useState(false);
  // const [selectedEm, setSetselectedEm] = useState('');
  const [selectedEmployer, setSelectedEmployer] = useState(null);

  // useEffect(() => {
  // console.log("🚀 ~ EmployesByEnterprise ~ enterprisesRelateds:", enterprisesRelateds)
  // console.log("🚀 ~ EmployesByEnterprise ~ employees:", employees)
  // console.log("🚀 ~ EmployesByEnterprise ~ employees:", employees)
  //   if(selectedEm){
  //     setOpen(true)

  //   }
  // }, [selectedEm]);
  const handleClick = (item) => {
    if (item) {
      setSelectedEmployer(item)
      // setOpen(true)
      setOpen(true)
    }
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState(null)
  const [fromArchive, setFromArchive] = useState(null)


  const [filteredDatas, setFilteredDatas] = useState([])
  const [validDatas, setValidDatas] = useState([]);
  const [dsabledDatas, setDesabledDatas] = useState([]);
  const [showTrash, setshowTrash] = useState(false);
  const [filteredDatasSearch, setFilteredDatasSearch] = useState([])
  const [searchTerm, setSearchTerm] = useState(null)

  // useEffect(() => {
  //   if(searchTerm){
  //     console.log("🚀 ~ useEffect ~ searchTerm:", searchTerm)
  //   setFilteredDatas(filteredDatas?.filter((item)=>item.toString().includes(searchTerm)))
  //   }
  // }, [searchTerm])
  useEffect(() => {
    let valid = filteredDatas?.filter((d) => (d?.status != EMPLOYER_STATUS?.GONE.value && d?.status != EMPLOYER_STATUS?.DELETED.value))
    let desa = filteredDatas?.filter((d) => (d?.status == EMPLOYER_STATUS?.GONE.value || d?.status == EMPLOYER_STATUS?.DELETED.value))
    // console.log("🚀 ~ useEffect ~ desa:", desa)
    // console.log("🚀 ~ useEffect ~ valid:", valid)
    setDesabledDatas(desa)
    setValidDatas(valid)
  }, [filteredDatas]);

  useEffect(() => {
    employees?.data && setFilteredDatas(employees?.data)
  }, [employees])
  useEffect(() => {
    let dd = employees?.data
    // console.log("🚀 ~ useEffect ~ status:", status)
    // console.log("🚀 ~ useEffect ~ fromArchive:", fromArchive)
    if (fromArchive == -1) {
      dd = employees?.data


    }
    else if (fromArchive == 0) {
      dd = employees?.data?.filter((item) => item?.fromArchive != true)
    }
    else if (fromArchive == 1) {
      dd = employees?.data?.filter((item) => item?.fromArchive == true)
    }

    if (status == -1) {
      dd = dd

    }
    else if (status == 0) {
      dd = dd?.filter((item) => {
        return checkIsValidPermit(item) ? item : null
      })
    }
    else if (status == 1) {
      dd = dd?.filter((item) => {
        return checkIsValidPermitDate(item) && (item?.permitNumber == null || item?.permitNumber == undefined) ? item : null
      })
    }
    else if (status == 2) {
      dd = dd?.filter((item) => {
        return !checkIsValidPermitDate(item) ? item : null
      })
    }
    if (searchTerm) {
      dd = dd?.filter((d) => {
        // console.log("🚀 ~ dd=dd?.filter ~  d.toString():",  d?.fullName)
        return (d?.fullName + d?.firstName + d?.lastName)?.toLowerCase().includes(searchTerm?.toLowerCase()) ? d : null

      })
    }
    setFilteredDatas(dd)
  }, [fromArchive, status, searchTerm])


  const handleCloseInside = () => {
    // setFilteredDatas([])
    setFromArchive(null)
    setStatus(null)
    setSearchTerm(null)
    handleClose()
  }
  const columns = EMPLOYER_HEADER

  function createData(N, Noms, Poste, Salaire, Nationalité, residentType, Date, Status, Options) {
    // const density = population / size;
    return {
      N, Noms, Poste, Salaire, Nationalité,residentType, Date, Status, Options,

    };
  }
  const rows = (datas) => datas?.map((item, index) => {
    // console.log("🚀 ~ rows ~ item:", item)
    const lastContract = getLastContract(item?.contracts)
    // console.log("🚀 ~ rows ~ lastContract:", lastContract)
    return {
      color: (item?.status == EMPLOYER_STATUS.GONE.value || item?.status == EMPLOYER_STATUS.DELETED.value) ? 'gray' : !checkIsValidContractDate(lastContract) ? 'red' : '',
      handleClick: () => handleClick(item),

      datas: createData(index + 1, <>{
        EMPLOYER_STATUS[item?.status]?.icon ? EMPLOYER_STATUS[item?.status]?.icon :
          item?.fromArchive == true ?
            <FromArchiveIcon />
            :
            <FromTheFieldIcon />
      }


        <span className="fs-c">{(item?.fullName || item?.firstName + " " + item?.lastName)}</span>

      </>
        ,
        lastContract?.occupation?.name,
        item?.enterprise?.socialRaison
        ,
        formatNumber(lastContract?.salary),
        item?.nationality,
        // ENTRY_TYPE[item?.entryType] || '--',

        // item?.permitNumber, 

        lastContract?.permitDate ? dayjs(getExpiredPermitDate(lastContract?.permitDate)).format("DD-MM-YYYY") : '--',
        !lastContract?.permitNumber && !lastContract?.permitDate ?
          <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Pas de permis")} />
          :
          !checkIsValidContractDate(lastContract) ?
            <Chip color='error' className="capitalize-none" label={capitalize("Expiré")} />
            :
            lastContract?.permitNumber ? <Chip className="capitalize-none" variant="outlined" color='success' label="Valide" /> :
              <Chip className="capitalize-none" variant="outlined" color='warning' label="A vérifier" />,
        <MButton style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
          Consulter
        </MButton>,
      )
      ,
      history: item?.contracts ? {
        title: " Historique des contrats",
        header: [
          "Salaire (FAFC)",
          "Indenmités",
          "Numero de permis",
          "Date d'optention du permit",
          "Date d'expiration du permit",

        ],
        datas: item?.contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))?.map((c, index) => {
          return {
            index: index,
            salary: formatNumber(c?.salary),
            idenmites: (c?.indLogement || 0) + (c?.indTransport || 0) + (c?.indResponsabilite || 0) + (c?.indRepresentation || 0) + (c?.primeExploitation || 0)
              + (c?.sursalaire || 0) + (c?.primeSalissure || 0) + (c?.indCaisse || 0) + (c?.bonus || 0) + (c?.primePanier || 0)
              + (c?.billetAvion || 0) + (c?.autresPrimes || 0) + (c?.sursalary || 0),
            permitOptDate: c?.permitDate ? formatDate(c?.permitDate) : '--',
            permitDate: c?.permitDate ? formatDate(getExpiredPermitDate(c?.permitDate),) : '--',
            permitNumber: c?.permitNumber || '--',
            // status: ch,
          }
        }
        )
      }
        : []

    }
  }
  )

  const headerTable = [
    "#",
    "Nom Complet",
    "Sexe",
    "Poste",
    "Salaire",
    "Nationalite",

    // "Numero de Visa",
    // "Date d'optention du visa",
    "Numéro de passeport",

    "Numéro de Permis",
    "Date d'expiration du du permis",
    "Status du Permis",
  ];



  return (
    <React.Fragment>
      <Dialog
        fullScreen={Fullscreen}
        open={openP}
        onClose={handleCloseInside}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className="bg-gray-50 flex justify-between">
            <IconButton title="Back">

              <BsArrowLeftCircleFill size={40} color={'red'} onClick={handleCloseInside} />
            </IconButton>

            <span className="flex flex-col align-center">

              <Typography color={'green'} sx={{ ml: 2 }} variant="h6" component="div">
                {/* {selectedEnterprise?.socialRaison} */}
                {`${selectedEnterprise?.sigle ? "(" + selectedEnterprise?.sigle + ")" : ''} ${selectedEnterprise?.socialRaison}`}

              </Typography>
              <Typography color={'blue'} variant="subtitle2" textAlign={'center'} component="div">
                NIU: {selectedEnterprise?.nui}
              </Typography>
            </span>
            <TextField
              style={{
                width: '30%',
                color: 'white',
                background: 'rgba(255,255,255,0.3)', border: 'none'
              }}
              className=' text-white'
              variant="filled"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={"Rechercher ici..."}
              id="outlined-search"
              // label={placeholder}
              type="search"
              color="success"
            // focused
            // color="wh"



            />

            <div className="flex justify-center gap-2">


              <Fab variant="extended"
                onClick={() => setOpen(true)}
                sx={{ mr: 2 }}
                color="green"
              >
                <BsPlus size={30} />
                Ajouter
              </Fab>
              <MuiSelect
                // marginRight={5}
                value={fromArchive}
                label={"Source"}
                options={[
                  { value: -1, label: 'Tous' },
                  { value: 0, label: 'Terrain' },
                  { value: 1, label: 'Archive' },
                ]}
                onChange={(event) => { setFromArchive(event.target.value) }}
              />
              <MuiSelect
                // marginRight={5}
                value={status}

                label={"Status"}
                options={[
                  { value: -1, label: 'Tous' },
                  { value: 0, label: 'Validé' },
                  { value: 1, label: 'A vérifier' },
                  { value: 2, label: 'Expiré' },
                  { value: 3, label: 'Sans contrat' },
                ]}
                onChange={(event) => { setStatus(event.target.value) }}
              />
              {/* <CloseModalButton  iconColor={'red'} handleClose={handleCloseInside} /> */}
            </div>


          </Toolbar>
        </AppBar>

        {loading && <PreloaderSpinner />}
        {/* <MModal open={open} setOpen={setOpen} item={selectedEm}/> */}
        <AddEmployer get={fetchData} open={open} handleClose={() => { setSelectedEmployer(null); setOpen(false) }} selectedEmployerP={
          selectedEmployer} fixedEnterprise={selectedEnterprise} />
        <div className="m-2 w-100 flex justify-end items-center gap-2"> <PrintButtonExcel
          text={"Exporter en excel"}
          filename={
            // `expatriés_${params?.enterpriseId ? enterprises?.data?.find((e) => e?.id == params?.enterpriseId)?.socialRaison?.trim()?.slice(0, 25) + '_' : ''}${dayjs(new Date())?.format("DD/MM/YYYY")}`
            `expatriés_${selectedEnterprise?.socialRaison?.trim()?.replaceAll(".", '')}_${dayjs(new Date())?.format("DD/MM/YYYY")}`
          }
          header={headerTable}
          body={validDatas?.length > 0 && validDatas?.map((e, i) => {
            const l = getLastContract(validDatas)
            return [
              i + 1,
              (e?.fullName || (e?.firstName + " " + e?.lastName)),
              e?.sexe,
              l?.occupation?.name,
              // e?.arrondissement?.name,
              l?.salary,
              e?.nationality,
              // e?.visaNumber,
              // e?.visaOptDate,
              l?.passportNumber,
              // e?.passportExpDate,
              l?.permitNumber,
              l?.permitDate ? dayjs(getExpiredPermitDate(l?.permitDate)).format("DD-MM-YYYY") : '--',
              // e?.permitNumber,

              !checkIsValidContrat(l) ? "Expiré" : "Valide",
            ];
          })}
        />
          {showTrash ? <BsBoxArrowLeft onClick={() => setshowTrash(false)} size={25} /> : <BsTrash2Fill onClick={() => setshowTrash(true)} size={24} />}
        </div>
        <div className=" grid h-full grid-cols-1 gap-5">
          <Card extra={"w-full h-full p-4"}>
            <div className="flex  flex-col content-center text-center rounded-lg shadow mb-3">
              <strong className="text-xl uppercase w-full bg-green-200 ">
                {/* Les employeés de l'entreprise :  */}
                {location?.state?.enterprise?.socialRaison}
              </strong>
              <div className="flex justify-center mt-2">
                <strong>
                  Total :
                  <Badge
                    fontSize={13}
                    rounded={5}
                    padding={1}
                    paddingInline={2}
                    colorScheme="green"
                    className="mx-1"
                  // style={{ wi: -15 }}
                  >
                    {formatNumber(showTrash ? dsabledDatas?.length : validDatas?.length) || '--'}
                  </Badge>
                </strong>
                {!showTrash &&
                  <>
                    <strong>
                      Collectées sur le terrain :
                      <Badge
                        fontSize={13}
                        rounded={5}
                        padding={1}
                        paddingInline={2}
                        colorScheme="blue"
                        className="mx-1"
                      // style={{ wi: -15 }}
                      >
                        {formatNumber(validDatas?.filter((e) => !e?.fromArchive)?.length) || '--'}
                      </Badge>
                    </strong>

                    <strong>
                      Provenant des archives :
                      <Badge
                        fontSize={13}
                        rounded={5}
                        padding={1}
                        paddingInline={2}
                        colorScheme="yellow"
                        className="mx-1"
                      // style={{ wi: -15 }}
                      >
                        {formatNumber(validDatas?.filter((e) => e?.fromArchive)?.length) || '--'}

                      </Badge>
                    </strong>
                    <strong>
                      Nombre de circusales :
                      <Badge
                        fontSize={13}
                        rounded={5}
                        padding={1}
                        paddingInline={2}
                        colorScheme="yellow"
                        className="mx-1"
                      // style={{ wi: -15 }}
                      >
                        {formatNumber(enterprisesRelateds?.data?.length) || '--'}

                      </Badge>
                    </strong>

                    <strong>
                      Employers partis ou supprimer :
                      <Badge
                        fontSize={13}
                        rounded={5}
                        padding={1}
                        paddingInline={2}
                        colorScheme="red"
                        className="mx-1"
                      // style={{ wi: -15 }}
                      >
                        {formatNumber(dsabledDatas?.length) || '--'}

                      </Badge>
                    </strong>
                  </>
                }
              </div>
            </div>

            {!showTrash && validDatas && <MuiTable hidePagiation={true} columns={columns}
              rows={rows(validDatas)} />
            }
            {showTrash && <>
              <Divider />
              <div className="flex gap-1 content-center items-center mt-[75px]"><BsTrash2Fill size={25} color="red" />
                <Typography variant="h5" >  Corbeille</Typography>
              </div>
              {dsabledDatas && <MuiTable hidePagiation={true} columns={columns}
                rows={rows(dsabledDatas)} />
              }
            </>}
            
            {filteredDatas?.length == 0 &&
              <NotFoundResults />
            }
            <div>
              <MuiCollapse title={
                <div className="flex gap-1 items-center">
                <Typography variant="h5">Liste des circusales</Typography>
             <Badge
             fontSize={13}
             rounded={5}
             padding={1}
             paddingInline={2}
             colorScheme="green"
             className="mx-1"
             // style={{ wi: -15 }}
             >
             {formatNumber(enterprisesRelateds?.data?.length) || '--'}
           </Badge>  
             </div>
            }
              bgColor={'rgba(100,1,1,0.1)'}
              >

              {enterprisesRelateds?.data?.length > 0 && enterprisesRelateds?.data?.map((r, index) => {
                // console.log("r?.id",r?.id)
                return <Related key={index} rows={rows} columns={columns} enterprise={r} />
                
              })}
              </MuiCollapse>
            </div>


          </Card>
        </div>

      </Dialog>

    </React.Fragment>
  );
};

export default EmployesByEnterprise;


