// Custom components
import { TextField } from "@mui/material";
import React from "react";

function InputField(props) {
  const {
    multiline,
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    name,
    register,
    error,
    required,
    value,
    defaultValue,
    onChange,
    autoComplete,
    min,
    sx,
    color
  } = props;

  return (
    // <div className={`${extra}`}>
    //   <label
    //     htmlFor={id}
    //     className={`text-sm text-navy-700 dark:text-white ${
    //       variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
    //     }`}
    //   >
    //     {label} {required ? <span className="text-red-800">*</span> : <></>}
    //   </label>

    register ? (
      <div>
        <TextField
          // sx={{height : 300}}
          label={label}
          sx={{
            '& .MuiInputLabel-root': { color: color ? color : '' },
  
            // Target the focused label color
            '& .MuiInputLabel-root.Mui-focused': { color: color ? color : 'green' },
  
            // Target the input field (root) outline border
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: color ? color : '',
              },
              // Change border color when focused
              '&:hover fieldset': {
                borderColor: color ? color : 'green',
              },
              '&.Mui-focused fieldset': {
                borderColor: color ? color : 'green',
              },
            },
  
            // Target the input text
            '& .MuiInputBase-input': { color: color ? color : '' },
          }}
          // disabled={disabled}
          readOnly={disabled}
          // className="text-white-500"

          fullWidth
          classes={extra}
          step="any"
          min={min}
          {...register(`${name}`)}
          disabled={disabled}

          type={type}
          id={id}
          value={value}
          name={name}
          multiline={multiline}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete == false ? "new-password" : ""}
        // className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
        //   disabled === true
        //     ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
        //     : state === "error"
        //     ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
        //     : state === "success"
        //     ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
        //     : "border-gray-200 dark:!border-white/10 dark:text-white"
        // }`}
        />

        <p className="p-1 text-red-600 ">{error && error}</p>
      </div>
    ) : (
      <div className="flex flex-col flex-1">
      <TextField
        // sx={sx}
        sx={{
          '& .MuiInputLabel-root': { color: color ? color : '' },

          // Target the focused label color
          '& .MuiInputLabel-root.Mui-focused': { color: color ? color : 'green' },

          // Target the input field (root) outline border
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: color ? color : '',
            },
            // Change border color when focused
            '&:hover fieldset': {
              borderColor: color ? color : 'green',
            },
            '&.Mui-focused fieldset': {
              borderColor: color ? color : 'green',
            },
          },

          // Target the input text
          '& .MuiInputBase-input': { color: color ? color : '' },
        }}
        label={label}
        error={error ? true : false}
        fullWidth
        // disabled={disabled}
        multiline={multiline}

        // disabled={disabled}
        readOnly={disabled}
        type={type}
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      // className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
      //   ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
      //   : state === "error"
      //     ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
      //     : state === "success"
      //       ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
      //       : "border-gray-200 dark:!border-white/10 dark:text-white"
      //   }`}
      />
      <p className="p-1 text-red-600 ">{error && error}</p>
</div>
    )
    // </div>
  );
}

export default InputField;
