import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useEffect, useState } from "react";
import { Button, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";
import PreloaderSpinner from "components/PreloaderSpinner";
import MModal from "./modals/EmployeeModalInfos";

const UserEmployes = ({ route }) => {
  const { id, userId } = useParams();

  const [loading, error, employees, fetchData] = useDataFetching(
    `${config.app.api_url}/employers/users/${userId}/enterprises/${id}`
  );
  const [open, setOpen] = useState(false);
  const [selectedEm, setSetselectedEm] = useState('');
  // useEffect(() => {
  //   if(selectedEm){
  //     setOpen(true)

  //   }
  // }, [selectedEm]);
  const handleClick = (item)=>{
    if(item){
      setSetselectedEm(item)
      setOpen(true)
    }
  }

  return (
    <div>

      {loading && <PreloaderSpinner/>}
        <MModal open={open} setOpen={setOpen} item={selectedEm}/>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <Card extra={"w-full h-full p-4"}>
          <div className="tableFixHead">
          {/* <TableContainer> */}
            <Table variant='simple'>
              {/* <TableCaption>Top 5 des entreprises par secteur d'activite</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>Noms</Th>
                  <Th>Genre</Th>
                  <Th>Poste</Th>
                  <Th>Salaire</Th>
                  <Th>Nationalite</Th>
                  <Th>Numero de Permis</Th>
                  <Th>Status</Th>
                  <Th>Option</Th>
                </Tr>
              </Thead>
              <Tbody>
                {employees?.data && employees?.data.map((item) => {
                  return (
                    <Tr className={new Date(item?.permitDate) <= new Date() ? 'text-danger' : ''}>
                      <Td>{item?.fullName}</Td>
                      <Td>{item?.sexe}</Td>
                      <Td>{item?.occupation?.name}</Td>
                      <Td>{item?.salary?.toLocaleString()}</Td>
                      <Td>{item?.nationality}</Td>
                      <Td>{item?.permitNumber}</Td>
                      <Td>{new Date(item?.permitDate) <= new Date() ? 'Expiré' : 'Valide'}</Td>
                      <Td>
                        <Button onClick={()=>handleClick(item)}>
                          Consulter
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
              {/* <Tfoot>
                <Tr>
                  <Th>Noms</Th>
                  <Th>Genre</Th>
                  <Th>Poste</Th>
                  <Th>Salaire</Th>
                  <Th>Nationalite</Th>
                  <Th>Numero de Permis</Th>
                  <Th>Option</Th>
                </Tr>
              </Tfoot> */}
            </Table>
          {/* </TableContainer> */}
            </div>
        </Card>
      </div>
    </div>
  );
};

export default UserEmployes;
