import { useEffect, useState } from "react";
import {

  Badge,
  Box,

} from "@chakra-ui/react";
import Card from "components/card";
import PreloaderSpinner from "components/PreloaderSpinner";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import axios from "axios";
import { checkIsValidPermit } from "utils";
import { BsArrowBarLeft, BsArrowRight, BsArrowRightCircle, BsArrowRightCircleFill, BsChatLeft, BsFiletypeXlsx, BsPersonFill, BsPlusCircle, BsSearch, BsThreeDotsVertical, BsXCircle } from "react-icons/bs";
import MButton from "components/Mbutton";
import SearchModal from "components/SearchModal";
import { formatNumber } from "utils";
import MuiTable from "components/MuiTable";

import { ButtonGroup, Chip, Typography, capitalize } from "@mui/material";
import MuiAUtocomplete from "components/MuiAutocomplete";
import MuiSelect from "components/MuiSelect";
import MuiDatePicker from "components/MuiDatePicker";
import { ENTRY_TYPE } from "Constaints";
import { formatDate } from "utils";
import { getLastContract } from "utils";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { checkIsValidContractDate } from "utils";
import ReportModalInfos from "./ReportModalInfos";
import { REPORT_HEADER } from "Constaints";
import StatsCard from "./StatsCard";
import PaginateBox from "components/PaginateBox";
const Reports = ({ compact }) => {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();

  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedReportployer, setselectedReportployer] = useState(null);
  // console.log("🚀 ~ EmployeList ~ selectedReportployer:", selectedReportployer)
  const location = useLocation()
  const navigate = useNavigate()
  const [creator, setCreator] = useState(null);

  const [reports, setreports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [expired, setExpired] = useState("");
  const [selectedEnterprise, setSelectedEnterprise] = useState("");

  const [params, setParams] = useState({
    start: location.state?.start,
    end: location.state?.end,
    isValidated: location.state?.isValidated,
    page: 0,
    limit: 50,
    creatorId: location?.state?.creator?.id
  });


  const [loadingU, errorU, agents, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/3/users`
  );

  // console.log("🚀 ~ agents:", agents)
  useEffect(() => {
    if (location.state) {
      // console.log("🚀 ~ useEffect ~ location.state:", location.state)

      setCreator(location?.state?.creator)
      setParams({
        start: location.state?.start,
        end: location.state?.end,
        isValidated: location.state?.isValidated,
        page: 0,
        limit: 50,
        creatorId: location?.state?.creator?.id
      })
    }
  }, [location]);








  const getReports = () => {
    setLoading(true);
    const rr = Object.keys(params)?.map((key) => {
      // console.log(typeof params[key] == "string");
      // if(params[key]){
      return (params[key] != null && params[key] != undefined) ? key + "=" + params[key] : "-1";
      // }
    });
    const tt = rr?.filter((r) => r !== "-1")?.join("&");
    axios
      .get(`${config.app.api_url}/reports/query?${tt}`,
        {
          headers: {
            Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setreports(res?.data?.data);
        // console.log("🚀 ~ .then ~ res?.data?:", res?.data?.data?.content)
      })
      .catch((err) => {
        console.log(err);
        setreports([]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {

    getReports()
  }, [params
  ]);

  const [open, setOpen] = useState(false);
  const [selectedReport, setSetselectedReport] = useState("");

  const handleClick = (item) => {
    if (item) {
      // setSetselectedReport(item);
      setSetselectedReport(item)

      setOpen(true);
    }
  };

  // const getReports = () => {
  //   return reports;
  // };

  const headerTable = [
    "Noms Complet",
    "Sexe",
    "Enterprise",
    "Poste",
    "Salaire",
    "Nationalite",

    // "Numero de Visa",
    // "Date d'optention du visa",
    "Numéro de passeport",

    "Numero de Permis",
    "Date d'expiration du du permis",
    "Status du Permis",
  ];



  const handleRestFilter = () => {
    setParams({
      start: "", end: "", visaExpired: "", isValidated: ""
    });

    setStart("")
    setEnd("")
  };
  // const exportPdf = <PrintButtonExcel
  //   text={"Exporter"}
  //   filename={
  //     // `expatriés_${params?.enterpriseId ? enterprises?.data?.find((e) => e?.id == params?.enterpriseId)?.socialRaison?.trim()?.slice(0, 25) + '_' : ''}${dayjs(new Date())?.format("DD/MM/YYYY")}`
  //     `expatriés_${dayjs(new Date())?.format("DD/MM/YYYY")}`
  //   }
  //   header={headerTable}
  //   body={reports?.content?.length > 0 && reports?.content?.map((e) => {
  //     return [
  //       e?.fullName || e?.firstName + " " + e?.lastName,
  //       e?.sexe,
  //       e?.enterprise?.socialRaison,
  //       e?.occupation?.name,
  //       // e?.arrondissement?.name,
  //       e?.salary,
  //       e?.nationality,
  //       // e?.visaNumber,
  //       // e?.visaOptDate,
  //       e?.passportNumber,
  //       // e?.passportExpDate,
  //       e?.permitNumber,
  //       e?.permitDate ? dayjs(getExpiredPermitDate(e?.permitDate)).format("DD-MM-YYYY") : '--',
  //       // e?.permitNumber,

  //       !checkIsValidPermit(e) ? "Expiré" : "Valide",
  //     ];
  //   })}
  // />


  function createData(N, Noms, Date, Title, Observations, Rating, Valide, Options) {
    // const density = population / size;
    return {
      N, Noms, Date, Title, Observations, Rating,Valide, Options,
    };
  }
  const rows = reports?.content?.map((item, index) => {

    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: () => handleClick(item),

      datas: createData((params?.page * params?.limit) + (index + 1),
        item?.fullName,
        formatDate(item?.date)
        // , item?.sexe
        ,
        item?.title,
        // item?.issues?.join(",")
        // ,
        formatDate(item?.createdAt)
        ,
        item?.rating ? item?.rating + "/5" : '--',
        item?.isValidated == false ?
          <Chip color='error' variant="outlined" className="capitalize-none" label={capitalize("Mauvais")} />
          :
          item?.isValidated == true ?
            <Chip className="capitalize-none" variant="outlined" color='success' label="Bon" />
            :
            <Chip className="capitalize-none" variant="outlined" color='warning' label="En attende de verification" />
        ,
        <MButton
          variant={"white"}

          style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
          Consulter
        </MButton>,

      ),

    }
  }
  )

  return (
    <div>
      {/* {loading && <PreloaderSpinner />} */}
      <ReportModalInfos get={getReports} open={open} setOpen={setOpen} selectedReport={selectedReport} handleClose={() => { setSetselectedReport(null); setOpen(false) }}


      />
      <div className=" grid h-full grid-cols-1 gap-5">

        {creator && <Box
          // bg={"rgba(0, 223, 52, 0.10)"}
          bg={"red.100"}
          // className="bg-gray-500"
          boxShadow="lg"
          p={2}
          rounded={"md"}
          border="1px"
          borderColor="gray.200"
          className=" flex flex-wrap gap-1  items-end justify-center"
        >
          <span className="flex gap-2 ">
            <BsPersonFill size={20} className="text-blue-400" />
            Entreprises collectées par :
            <strong>
              {creator?.creator?.firstName} {creator?.lastName}
            </strong>
          </span>
        </Box>
        }
        <div className={"w-full h-full p-4 rounded-0 bg-white"}>
          <div class="w-full flex justify-between text-start py-5">
            <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Recent Reports
            </h2>

            {compact ? <div onClick={() => {
              navigate('/admin/reports')
            }} className="flex items-center me-4 gap-4 hover:curso-pointer"><Typography variant="h6" >Voir plus</Typography> <BsArrowRightCircle className="text-primary" color="primary" size={40} /></div> :
              <Box
                // bg={"rgba(0, 223, 52, 0.10)"}
                bg={"rgba(255, 255, 255, 1)"}
                // className="bg-gray-500"
                boxShadow="lg"
                p={2}
                rounded={"md"}
                // border="1px"
                borderColor="gray.200"
                className=" flex flex-wrap gap-1  items-end justify-end"
              >
                {/* <div className="mt-1 grid h-full  grid-cols-1 gap-5 md:grid-cols-8"> */}
                {/* <div className="flex flex-wrap gap-2  items-end justify-between bg-red-200"> */}

                <MuiDatePicker
                  label={"Debut de la periode"}
                  value={params?.start}
                  onChange={(newValue) => {
                    setParams({
                      ...params,
                      start: new Date(newValue)?.getTime(),
                    })
                  }}
                  wrapperClasses={"flex-1 filter-item anime slideLeft"}


                />



                <MuiDatePicker
                  value={params?.end}

                  label={"Fin de la periode"}
                  onChange={(newValue) => {
                    setParams({
                      ...params,
                      end: new Date(newValue)?.getTime(),
                    })
                  }}
                  wrapperClasses={"flex-1 filter-item anime slideLeft"}

                />


                <MuiSelect
                  label={'rappport valider ?'}
                  emptyLabel={'Tous'}
                  value={params?.visaExpired}

                  options={[
                    { value: false, label: 'Non' },
                    { value: true, label: 'Oui' },
                  ]}
                  onChange={(e) => {
                    // console.log("e.target.value 11", e.target.value)
                    setParams({
                      ...params,
                      visaExpired: e.target.value?.toString(),
                      // expiredIn : e.target.value ='false' ? 24 : params?.expiredIn

                    });
                  }}
                  wrapperClasses={"flex-1 filter-item anime slideUp"}
                />


                {/* Select nationalities */}
                <MuiAUtocomplete
                  value={params?.creatorId}
                  // defaultValue={nationality}
                  label={'Selectionner un agent'}
                  options={agents?.dada?.map((item) => {
                    // console.log("🚀 ~ options={nationalities?.map ~ item:", item?.demonyms?.fra?.f)
                    // return { value: item?.name?.common, label: item?.demonyms?.fra?.f || item?.translations?.fra?.common }

                    return { value: item?.id, label: item?.firstName || item?.lastName }
                  })}
                  // format={{ value: 'id', label: 'name' }}
                  // value={params?.regionId}
                  // defaultFormat={true}
                  onChange={(e, item) => setParams({ creatorId: item?.value })}

                  wrapperClasses={"flex-1 filter-item anime slideRight"}
                />


                {/* </div> */}

              </Box>
            }
          </div>
          <div className="flex justify-between ">

            <div>
              <strong>

                Total :
                <Badge
                  fontSize={13}
                  rounded={5}
                  padding={1}
                  paddingInline={2}
                  colorScheme="green"
                  className="m-0 ms-2"
                >
                  {formatNumber(reports?.totalElements) || '--'}
                </Badge>
              </strong>
            </div>

          </div>

          <MuiTable hidePagiation={true} columns={REPORT_HEADER}
            loading={loading}
            limit={params?.limit}
            rows={rows} textEmpty={"Pas de raports"} />
          {/* <MuiTableCollapse/> */}



          {!compact && <PaginateBox

            total={reports?.totalElements}
            rowsPerPage={params?.limit}
            handleChangeRowsPerPage={(event) => {
              // alert("change")
              // console.log("🚀 ~ EmployeList ~ event:", event.target.value)
              setParams({
                ...params
                , limit: parseInt(event.target.value),
                page: 0,
              });
              // (0);
            }}
            page={params.page}
            count={reports?.totalPages - 1}
            handleChange={(event, value) => {
              setParams({ ...params, page: value });
            }} />}

        </div>
      </div>

      {/* Modal création d'un employer */}
    </div>
  );
};

export default Reports;
