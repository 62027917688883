import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";

/** validation de formulaire */
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "hooks/usePostAxiosData";
import { useEffect } from "react";
import toast from "react-hot-toast";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import { Button, Select } from "@chakra-ui/react";
import { ROLES } from "Constaints";
import { config } from "variables/config";
import useDataFetching from "hooks/useDataFetching";

const AddUser = (props) => {
  const { isOpen, onClose } = props;

  /** Form validation config */
  const schema = yup.object().shape({
    firstname: yup.string().required("First Name is a required field"),
    lastname: yup.string().required("Last Name is a required field"),
    phone: yup.string().required("Phone Number is a required field"),
    password: yup.string(),

    email: yup
      .string()
      .email("Invalid email")
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`auth/register`, "POST");
  const [regionId, setRegionId] = useState(null);
  const handleSave = async (dataYub) => {
    // console.log("dataYub", dataYub);
    const data = {
      firstname: dataYub?.firstname,
      lastname: dataYub?.lastname,
      phone: dataYub?.phone,
      email: dataYub?.email,
      password: dataYub?.password,
      regionId,

      // password: "password",
      roles: ["AGENT"],
    };

    await postAxiosData(data);
  };

  const handleChangeValue = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(value);
  };

  const [roles, setRoles] = useState();

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
    reset();

      toast.success(
        `${successMessage?.message != null
          ? successMessage?.message
          : "Utilisateur ajouté avec succès."
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      props?.endFetch()
      onClose()
      // handleSignin(result);
    }
  }, [successMessage]);

  const [loadingR, errorR, regions, fetchDataR] = useDataFetching(
    `${config.app.api_url}/regions`
  );


  // console.log("🚀 ~ AddUser ~ regions:", regions)

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered={true}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div className="text-center">
                <span className="text-3xl uppercase">Ajouter un compte</span>
              </div>
            </AlertDialogHeader>

            <AlertDialogBody>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="my-4 grid gap-2 md:grid-cols-1">
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div>
                    {/* <span className="ms-1">Add role to user</span> */}
                    {/* <Select
                      className="my-2"
                      height={20}
                      multiple
                      value={roles}
                      onChange={(e) => handleChangeValue(e)}
                    >
                      {ROLES?.map((role) => {
                        return <option value={role}>{role}</option>;
                      })}
                    </Select> */}
                    <div className="mb-2">
                      <label
                        htmlFor={"region"}
                        className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
                      >
                        Region
                      </label>
                      <select
                        onChange={(e) => {
                          setRegionId(e.target.value)
                        }}
                        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none"
                        name="region"
                        id="region"
                        required={true}
                      >
                        <option selected value="*">
                          Region
                        </option>
                        {regions?.data &&
                          regions.data.map((item) => {
                            return <option value={item?.id}>{item?.name}</option>;
                          })}
                      </select>
                    </div>

                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Prénom(s)"
                      required={true}
                      placeholder="Prénom"
                      id="firstname"
                      name="firstname"
                      type="text"
                      error={
                        errors.firstname?.message !== ""
                          ? errors.firstname?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Nom(s)"
                      required={true}
                      placeholder="Nom"
                      id="lastname"
                      name="lastname"
                      type="text"
                      error={
                        errors.lastname?.message !== ""
                          ? errors.lastname?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Numéro de téléphone"
                      required={true}
                      placeholder="690128925"
                      id="phone"
                      name="phone"
                      type="text"
                      error={
                        errors.phone?.message !== ""
                          ? errors.phone?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Adresse Mail"
                      // required={true}
                      placeholder="adresse@gmail.com"
                      id="email"
                      name="email"
                      type="email"
                      error={
                        errors.email?.message !== ""
                          ? errors.email?.message
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      register={register}
                      variant="auth"
                      extra="mb-3"
                      label="Mot de passe"
                      // required={true}
                      placeholder="**********"
                      id="password"
                      name="password"
                      type="password"
                      error={
                        errors.password?.message !== ""
                          ? errors.password?.message
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-between p-4">
                  <div>
                    <Button onClick={() => {
                      onClose();
                      // window.location.reload();
                    }}>Fermer</Button>
                  </div>
                  <div>
                    <Button type="submit" colorScheme="green">
                      Sauvegarder
                    </Button>
                  </div>
                </div>
              </form>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AddUser;
