import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useAuthHeader } from 'react-auth-kit';

const useDataFetching = (url, now=true) => {
  const authHeader = useAuthHeader()
  // console.log("🚀 ~ useDataFetching ~ authHeader:", authHeader())
  // console.log("🚀 ~ useDataFetching ~ authHeader:", authHeader)
  // console.log("🚀 ~ useDataFetching ~ useAuthHeader:", useAuthHeader)
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
  if(url){  try {
      setLoading(true);
      const data = await fetch(url, {
        method: 'GET',
        headers :{
          'Authorization' : authHeader()
        }
      });
      const result = await data.json();
      if (result) {
        setData(result);
        setLoading(false);
      }
    } catch (e) {
      setData([])
      setError(e?.message);
      console.log("error ", e)
      setLoading(false);
    }}
  }, [url]);

  useEffect(() => {
    now && fetchData();
    // TODO: delete cash
  }, [url]);

  return [loading, error, data, fetchData];
};

export default useDataFetching;
