import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { useEffect, useState } from "react";

// second-chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const PieChartCard = (props) => {
  const { title, data, options, legends } = props;
  const [titles, setTitles] = useState();
  const [dataPie, setDataPie] = useState();
  const [colorsLengend, setColorsLegend] = useState();
  const [legendName, setLegendName] = useState();


  const generateColor = (number) => {
    let table = [];
    for (let i = 0; i < number; i++) {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      if(!table.includes("#" + randomColor)) {
        table.push("#" + randomColor);
      }
    }
    return table;
  }

  // console.log("data", data)
  // colors = generateColor(options?.length);
  let colors = []

  useEffect(() => {
    if (options && options?.length > 0) {
      setColorsLegend(generateColor(options?.length));
      colors = generateColor(options?.length);

      setDataPie({
        labels: options,
        datasets: [
          {
            label: 'Percent',
            data: data,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      });
      
      // setTitles({
      //   labels: options,
      //   colors: colors,
      //   chart: {
      //     width: "50px",
      //   },
      //   states: {
      //     hover: {
      //       filter: {
      //         type: "none",
      //       },
      //     },
      //   },
      //   legend: {
      //     show: true,
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   hover: { mode: null },
      //   plotOptions: {
      //     donut: {
      //       expandOnClick: false,
      //       donut: {
      //         labels: {
      //           show: false,
      //         },
      //       },
      //     },
      //   },
      //   fill: {
      //     colors: colors,
      //   },
      //   tooltip: {
      //     enabled: true,
      //     theme: "dark",
      //     style: {
      //       fontSize: "12px",
      //       fontFamily: undefined,
      //       backgroundColor: "#000000"
      //     },
      //   },
      // });
    }
  }, [options]);

  useEffect(() => {
    let arry = []
    colorsLengend && colorsLengend.length > 0 && colorsLengend?.map((color) => {
      arry.push(color)
    })

    let names = []
    options && options?.forEach((value, index) => {
      names.push({ color: arry[index], label: value })
    })
    setLegendName(names)
  }, [])
  // console.log("names", legendName)




  return (
    <Card extra="rounded-[5px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            {title}
          </h4>
        </div>

        {/* <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div> */}
      </div>
      <div className="mb-auto">
        {/* {titles && <PieChart options={titles} series={data} />} */}
        <div>
          {dataPie && <Pie data={dataPie} options={{ maintainAspectRatio: false }} />}
        </div>
      </div>
      {/* LEgend */}
      {/* <div className="rounded-2 align-items-center overflow-y-auto h-40 shadow p-2 my-2">
        {
          legendName && legendName.length > 0 && legendName?.map((item) => {
            return (
              <div className="flex items-center">
                <p className="h-2 w-2" style={{ backgroundColor: `${item?.color}` }}></p>
                <span className="ml-1 text-sm font-normal text-gray-600">{item?.label}</span>
              </div>
            )
          })
        }
      </div> */}
      {/* <div className="flex flex-row !justify-between flex-wrap rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        {legends && legends.map((item) => {
          return (
            <>
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <div className="h-2 w-2 rounded-full bg-brand-500" />
                  <p className="ml-1 text-sm font-normal text-gray-600">{item?.label}</p>
                </div>
                <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                  {item?.percent}
                </p>
              </div>

              <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />
            </>
          )
        })}
      </div> */}
    </Card>
  );
};

export default PieChartCard;
