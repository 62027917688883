import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Fullscreen } from '@mui/icons-material';
import EmployesByEnterprise from './EmployesByEnterprise';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmployesByEnterpriseModal({open,setOpen, title , handleClose, Fullscreen,selectedEnterprise}) {
  // const [open, setOpen] = React.useState(openP);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <EmployesByEnterprise openP={open} setOpenP={setOpen} Fullscreen={Fullscreen} 
    handleClose={handleClose}
    selectedEnterprise={selectedEnterprise}
    />

  );
}
